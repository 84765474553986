import { useEffect, useState } from "react";

const useBaseCameraMultiple = (
  getAttachment,
  attachments,
  setter,
  callOnMount = true
) => {
  const [isFetchingAttachments, setIsFetchingAttachments] = useState(false);

  const isAttachmentIdsEmpty = attachments?.length === 0;

  const fetchAttachments = () => {
    if (Array.isArray(attachments) && !isAttachmentIdsEmpty) {
      for (let id of attachments) {
        setIsFetchingAttachments(true);
        getAttachment(id, true)
          .unwrap()
          .then((responseData) => {
            setter((state) => {
              if (Array.isArray(state) && state?.length < attachments?.length) {
                return state.concat({ id, photo: responseData.content });
              } else return state;
            });
          })
          .then((_) => {
            setIsFetchingAttachments(false);
          })
          .catch((err) => {
            setIsFetchingAttachments(false);
          });
      }
    }
  };

  useEffect(() => {
    if (callOnMount) {
      fetchAttachments();
    }
  }, []);

  return {
    isFetchingAttachments,
    isAttachmentIdsEmpty,
    fetchAttachments,
  };
};

export default useBaseCameraMultiple;
