import { useState, useEffect } from "react";

const useDebouncedQuery = (queryFunction, debounceTime = 500) => {
  const [params, setParams] = useState(null);
  const [shouldTriggerQuery, setShouldTriggerQuery] = useState(false);
  const [trigger, response] = queryFunction ? queryFunction() : [() => {}, {}];

  useEffect(() => {
    if (shouldTriggerQuery) {
      const handler = setTimeout(() => {
        trigger(params);
        setShouldTriggerQuery(false); // Reset the trigger flag after the query has been made
      }, debounceTime);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [params, shouldTriggerQuery, trigger, debounceTime]);

  // Update setParams to also set shouldTriggerQuery to true
  const updateParamsAndTriggerQuery = (newParams) => {
    setParams(newParams);
    setShouldTriggerQuery(true);
  };

  return [updateParamsAndTriggerQuery, response];
};

export default useDebouncedQuery;
