import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { formattedNumber } from "utils/Numbers/Numbers";

const OneUseContent = ({ withAddition = 0, data }) => {
  const totalCost = data?.deductionDetailsDTO?.combinedFieldTestCost;

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#EDEFF2",
        padding: 10,
        fontSize: 8,
      }}
    >
      <View
        style={{
          fontSize: 8,
          textAlign: "left",
          marginLeft: "31px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text style={{ marginRight: "2px" }}>نقطة</Text>
        <Text>
          {formattedNumber(totalCost + withAddition || data?.deductedPoints)}
        </Text>
      </View>
      <View
        style={{
          textAlign: "right",
          marginRight: "10px",
        }}
      >
        <Text>التكلفة الكلية</Text>
      </View>
    </View>
  );
};

OneUseContent.propTypes = {
  withAddition: PropTypes.number,
  data: PropTypes.object,
};

export default OneUseContent;
