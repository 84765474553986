import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";

const AddCompanyButton = (props) => {
  return (
    <BaseButton
      onClick={() => {
        props.setShowModal(true);
      }}
    >
      إضافة شركة مقاولات
    </BaseButton>
  );
};

AddCompanyButton.propTypes = {
  setShowModal: PropTypes.func,
};
export default AddCompanyButton;
