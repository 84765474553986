import { useTheme } from "@emotion/react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import {
  useLazyGetLayersDataQuery,
  useLazyGetMaterialsDataQuery,
  useLazyGetDevicesDataQuery,
} from "modules/Contractor/apis/inspection-apis/inspection-api";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";

import { getStationsIds } from "utils/Station/Station";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import StationsTable from "./StationsTable/StationsTable";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import { isContentReady } from "utils/Array/Arrays";
import PropTypes from "prop-types";

const FieldAddCheckupSiteSpecs = (props) => {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const [getLayersData] = useLazyGetLayersDataQuery();

  const [getMaterialsData] = useLazyGetMaterialsDataQuery();

  const [getDevicesData] = useLazyGetDevicesDataQuery();

  useEffect(() => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          layersData: [props.formData.licenseLayer],
          licenseLayer: props.formData.licenseLayer,
        },
      })
    );
  }, [props.formData.licenseLayer]);

  const onChangeLicenseTestTypesHandler = () => {
    getMaterialsData()
      .unwrap()
      .then((responseData) => {
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { fetchedMaterials: responseData },
          })
        );
      });
  };

  useEffect(onChangeLicenseTestTypesHandler, []);

  const onChangeMaterial = (value) => {
    props.setValue("device", undefined);
    const stationsIds = getStationsIds(props.formData);
    stationsIds?.map((id) => props.unregister(`station-${id}-layerTest`));

    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: { material: value },
      })
    );

    getDevicesData({ materialId: value?.id })
      .unwrap()
      .then((responseData) => {
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { fetchedDevices: responseData },
          })
        );
      });
  };

  const onChangeDevice = (value) => {
    const stationsIds = getStationsIds(props.formData);
    stationsIds?.map((id) => props.unregister(`station-${id}-layerTest`));

    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: { device: value },
      })
    );

    getLayersData({ deviceId: value?.id, testType: "FIELD" })
      .unwrap()
      .then((responseData) => {
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { fetchedFieldLayersTemplates: responseData },
          })
        );
      });
  };

  if (
    !props.raiseNewRequestData?.fetchedMaterials ||
    !isContentReady(props.raiseNewRequestData?.fetchedMaterials)
  ) {
    return <BaseLoader />;
  }

  return (
    <>
      <Stack direction={"row"} flexWrap={"wrap"} mt={1} gap={2}>
        <Box mb={sm ? 0 : 7} sx={{ flex: 1 }}>
          <BaseDropdown
            control={props.control}
            required
            name={"material"}
            errors={props.errors}
            label={"نوع المادة"}
            labelStyles={{
              color: "grayDark.color",
              fontSize: "1.4rem",
              fontWeight: 500,
            }}
            mainStyles={{
              minWidth: "25rem",
              width: "100%",
            }}
            placeholder="تحديد نوع المادة"
            data={props.raiseNewRequestData?.fetchedMaterials}
            itemNameKey={"serviceNameAr"}
            onChange={onChangeMaterial}
            selectMenuStyles={{ height: "10rem" }}
          />
        </Box>
        <Box mb={sm ? 0 : 7} sx={{ flex: 1 }}>
          <BaseDropdown
            control={props.control}
            itemDirection="ltr"
            required
            name={"device"}
            errors={props.errors}
            label={"نوع الجهاز"}
            labelStyles={{
              color: "grayDark.color",
              fontSize: "1.4rem",
              fontWeight: 500,
            }}
            mainStyles={{
              minWidth: "25rem",
              width: "100%",
            }}
            placeholder="تحديد نوع الجهاز"
            data={props.raiseNewRequestData?.fetchedDevices || []}
            selectMenuStyles={{ height: "10rem" }}
            onChange={onChangeDevice}
            emptyListPlaceholder="يرجى تحديد نوع المادة أولا"
          />
        </Box>
      </Stack>

      <StationsTable
        register={props.register}
        errors={props.errors}
        control={props.control}
        unregister={props.unregister}
        formData={props.formData}
        setValue={props.setValue}
        clearErrors={props.clearErrors}
        setError={props.setError}
        raiseNewRequestData={props.raiseNewRequestData}
        sm={sm}
      />

      <TimelineCallToActions
        timeline={props.timeline}
        isNextButtonDisabled={props.isTestsTemplatesFetching}
        isLoading={props.isTestsTemplatesFetching}
      />
    </>
  );
};

FieldAddCheckupSiteSpecs.propTypes = {
  formData: PropTypes.object,
  raiseNewRequestData: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  timeline: PropTypes.object,
  setError: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  register: PropTypes.func,
  clearErrors: PropTypes.func,
  isTestsTemplatesFetching: PropTypes.bool,
};

export default FieldAddCheckupSiteSpecs;
