import { useTranslation } from "react-i18next";
import BaseDropdown from "./BaseDropdownComponent";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

const BaseDropdownComponent = (props) => {
  return (
    <BaseDropdown
      data={props.data || []}
      itemNameKey={props.itemNameKey}
      itemDirection={props.itemDirection}
      emptyListPlaceholder={props.emptyListPlaceholder}
      label={props.label}
      dropdownRef={props.dropdownRef}
      pickup={props.pickup}
      pickupData={props.pickupData}
      name={props.name}
      defaultSingleValue={props.defaultSingleValue}
      value={props.value}
      errors={props.errors}
      customError={props.customError}
      onChange={props.onChange}
      width={props.width}
      placeholder={props.placeholder}
      helperText={props.helperText}
      sx={props.sx}
      mainStyles={props.mainStyles}
      labelStyles={props.labelStyles}
      selectMenuStyles={props.selectMenuStyles}
      isLoading={props.isLoading}
      disabled={props.disabled}
      ignoreStackingContext={props.ignoreStackingContext || false}
    />
  );
};

const BaseDropdownWrapper = (props) => {
  const { t } = useTranslation(["dashboard", "auth"]);
  return props.control ? (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        ...props.rules,
        required: {
          value: props.required === false ? false : true,
          message:
            props.required?.message ||
            `${t("field", { ns: "auth" })} ${t(props.name, {
              ns: "dashboard",
            })} ${t("required", { ns: "auth" })}`,
        },
      }}
      render={({ field: { onChange, value, name, ref } }) => {
        return (
          <BaseDropdownComponent
            {...props}
            onChange={(v) => {
              if (props.isLoading) return;
              if (props.onChange) {
                props.onChange(v);
              }
              onChange(v);
            }}
            value={props.value || value}
            name={name}
          />
        );
      }}
    />
  ) : (
    <BaseDropdownComponent {...props} />
  );
};

export default BaseDropdownWrapper;

BaseDropdownWrapper.defaultProps = {
  placeholder: "اختر من القائمة",
  pickup: false,
  required: false,
};

BaseDropdownWrapper.propTypes = {
  control: PropTypes.object,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  placeholder: PropTypes.string,
  pickup: PropTypes.bool,
};

BaseDropdownComponent.propTypes = {
  defaultSingleValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  pickupData: PropTypes.object,
  errors: PropTypes.object,
  mainStyles: PropTypes.object,
  dropdownRef: PropTypes.object,
  sx: PropTypes.object,
  selectMenuStyles: PropTypes.object,
  data: PropTypes.array,
  labelStyles: PropTypes.object,
  name: PropTypes.string,
  customError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  itemNameKey: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  emptyListPlaceholder: PropTypes.string,
  pickup: PropTypes.bool,
  ignoreStackingContext: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  itemDirection: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
