import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { GeneralInstructionsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import GeneralInstructionsContent from "components/GeneralInstructionsContent/GeneralInstructionsContent";

const GeneralInstructions = () => {
  const { t } = useTranslation();

  return (
    <>
      <DashboardIntro
        title={t("general instructions", { ns: "dashboard" })}
        description={t("general instructions description", { ns: "dashboard" })}
        breadcrumbData={GeneralInstructionsBreadcrumb(t)}
      />

      <GeneralInstructionsContent />
    </>
  );
};

export default GeneralInstructions;
