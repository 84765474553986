import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddUserButtonAction = () => {
  const navigation = useNavigate();
  const { t } = useTranslation(["dashboard"]);


  return (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={() => navigation("/users-management/add")}
    >
      {t("add user")}
    </BaseButton>
  );
};

export default AddUserButtonAction;
