import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import {
  renderAppointmentTimeLabel,
  renderLabAppointmentTimeLabel,
} from "utils/BookAppointment/BookAppointment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { TestTypes } from "constants/TestTypes";
import PropTypes from "prop-types";

const DisplayRequestAppointmentDate = (props) => {
  if (!props.appointmentDate || !props.scheduledAppointment)
    return defaultEmptyValue;

  const isField = props?.testType === TestTypes.FIELD_TEST;
  const LabtimeLabel = renderLabAppointmentTimeLabel(
    props.scheduledAppointment
  );

  const timeLabel = renderAppointmentTimeLabel(props.scheduledAppointment);

  return (
    <>
      {isField ? (
        <Typography
          component={"span"}
          sx={{ fontSize: "1.6rem" }}
        >{`${formatDateYYYYMMDD(
          props.appointmentDate
        )} - ${timeLabel}`}</Typography>
      ) : (
        <Typography
          component={"span"}
          sx={{ fontSize: "1.6rem" }}
        >{`${formatDateYYYYMMDD(
          props.appointmentDate
        )} - ${LabtimeLabel}`}</Typography>
      )}
    </>
  );
};

DisplayRequestAppointmentDate.propTypes = {
  appointmentDate: PropTypes.string,
  scheduledAppointment: PropTypes.string,
  testType: PropTypes.string,
};

export default DisplayRequestAppointmentDate;
