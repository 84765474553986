import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import { Typography } from "@mui/material";
import { enLabelSx } from "constants/Typography";
import ValueUpdatedScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueUpdatedScore/ValueUpdatedScore";
import ValueCurrentScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueCurrentScore/ValueCurrentScore";
import PropTypes from "prop-types";

const DoubleLayerData = ({ subTests }) => {
  const formatter = (cell, row) => {
    return (
      <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{cell}</Typography>
    );
  };
  const siveAnalysis = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: formatter,
    },
    {
      serverKey: "",
      name: "",
    },
    {
      serverKey: "",
      name: "",
    },
    {
      serverKey: "currentScore",
      name: "",
      hasComponent: {
        customProps: "currentScore",
        comp: <ValueCurrentScore />,
      },
    },
    {
      serverKey: "updatedScore",
      name: "",
      hasComponent: {
        customProps: "updatedScore",
        comp: <ValueUpdatedScore />,
      },
    },
    {
      serverKey: "",
      name: "",
    },
  ];

  return (
    <BaseTableCard
      tableHeader={siveAnalysis}
      content={subTests?.[0]}
      tableSx={{
        td: {
          borderBottom: "0.1rem solid background.disabled",
        },
      }}
    />
  );
};

DoubleLayerData.propTypes = {
  subTests: PropTypes.array,
};

export default DoubleLayerData;
