import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import classes from "core-ui/Layouts/SideMenu/SideMenu.module.scss";
import mobClasses from "core-ui/Layouts/MobSideMenu/MobSideMenu.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { showSubmenuChildrenListLinksIfActive } from "utils/DOM/DOM";
import PropTypes from "prop-types";

const SidemenuNavLink = (props) => {
  const { t } = useTranslation();

  const browsingCurrentLink = (navData) => {
    if (props.forceActiveClass) {
      return `
        ${classes["sidemenu__link"]} 
        ${classes["sidemenu__link--active"]}} 
        ${mobClasses["mob-sidemenu__link--active"]}
      `;
    }
    if (props.type === "child" && navData.isActive) {
      showSubmenuChildrenListLinksIfActive(props.id);
    }
    return `${classes["sidemenu__link"]} ${
      props.isForMobile && navData.isActive && !props.navItem.children
        ? mobClasses["mob-sidemenu__link--active"]
        : undefined
    } ${
      navData.isActive && !props.navItem.children
        ? classes["sidemenu__link--active"]
        : undefined
    }`;
  };

  const _to = props.navItem.children
    ? props.navItem.children[0].to
    : props.navItem.to;

  const getClasses = () => {
    if (props.isActive(props.index + 1) && props.isForMobile) {
      return classes["sidemenu__list-item--active"];
    } else if (props.isForMobile) {
      return mobClasses["mob-sidemenu__list-item--active"];
    } else {
      return "";
    }
  };

  return (
    <NavLink
      data-item-id={`${props.type}-${props.id}`}
      to={_to}
      className={browsingCurrentLink}
      data-index={props.index + 1}
    >
      <ListItem
        aria-label="parent-sidemenu-item"
        className={`${classes["sidemenu__list-item"]} ${
          props.isForMobile ? mobClasses["mob-sidemenu__list-item"] : undefined
        } ${getClasses()} `}
        onClick={props.selectItemHandler.bind(null, props.index + 1, {
          type: props.type,
          id: props.id,
        })}
      >
        {props.navItem.icon && (
          <ListItemIcon
            className={`${classes["sidemenu__list-icon"]} ${
              props.isForMobile
                ? mobClasses["mob-sidemenu__list-icon"]
                : undefined
            }`}
          >
            {props.isActive(props.index + 1) &&
            props.isForMobile &&
            !props.navItem.children
              ? props.navItem.iconMobile
              : props.navItem.icon}
          </ListItemIcon>
        )}

        <ListItemText
          className={`${classes["sidemenu__list-label"]} ${
            props.isForMobile
              ? mobClasses["mob-sidemenu__list-label"]
              : undefined
          }`}
        >
          {t(props.navItem.label, { ns: "dashboard" })}
        </ListItemText>
        {props.navItem.children && <KeyboardArrowDownIcon fontSize="large" />}
      </ListItem>
    </NavLink>
  );
};

SidemenuNavLink.propTypes = {
  isForMobile: PropTypes.bool,
  type: PropTypes.string,
  navItem: PropTypes.object,
  id: PropTypes.number,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.func,
  forceActiveClass: PropTypes.bool,
  selectItemHandler: PropTypes.func,
};

export default SidemenuNavLink;
