import dayjs from "dayjs";
import { formatDateYYYYMMDD } from "utils/Date/Date";

export class ScheduleAppointment {
  #formattedCurrentDate;
  #minDateHours;
  #maxDateHours;
  #isLab;
  #isField;
  #labTestTimeRange = 12;
  #fieldTestTimeRange = 2;
  #userSelectedDate;

  constructor(userSelectedDate, { isLab, isField }) {
    this.#formattedCurrentDate = formatDateYYYYMMDD(new Date());
    this.#minDateHours = 6;
    this.#maxDateHours = 18;
    this.#isLab = isLab;
    this.#isField = isField;
    this.#userSelectedDate = userSelectedDate;
  }

  get getLabTestTimeRange() {
    return this.#labTestTimeRange;
  }

  get getFieldTestTimeRange() {
    return this.#fieldTestTimeRange;
  }

  minDateCalculation() {
    let today = new Date();
    let tomorrow = this.#getTomorrowDate();
    if (this.#isLab) {
      if (today.getHours() === 11 && today.getMinutes() >= 30) {
        return tomorrow;
      }
      if (today.getHours() < 12) {
        return today;
      }
      return tomorrow;
    }
    if (this.#isField) {
      if (today.getHours() + this.#fieldTestTimeRange >= this.#maxDateHours) {
        return tomorrow;
      }
      return today;
    }
  }

  #getTomorrowDate() {
    let today = new Date();
    let tomorrow = today.setDate(today.getDate() + 1);
    return new Date(tomorrow);
  }

  minTimeCalculation() {
    if (this.#isLab) {
      return this.#setLabTestMinTimeCalculation();
    }
    if (this.#isField) {
      return this.#setFieldTestMinTimeCalculation();
    }
  }

  #setLabTestMinTimeCalculation() {
    let date = new Date();

    let areCurrentMinutesGreaterThan30 = date.getMinutes() >= 30;
    let twelveHours = this.#labTestTimeRange;

    let hh = 0;
    let mm = 0;

    const nextAvailableDate = new Date(
      date.setHours(date.getHours() + twelveHours)
    );

    // prettier-ignore
    const nextTwelveHours = nextAvailableDate.getHours();

    if (
      // USER
      //   formData?.date?.day === nextAvailableDate.getDate()
      // CLASS
      this.#userSelectedDate?.day === nextAvailableDate.getDate()
    ) {
      mm = areCurrentMinutesGreaterThan30 ? 0 : 30;
      // if minutes > 30 so next time slot should be the next hour
      // but if less than 30 so it will be the same hour but for next 30 minutes
      hh = areCurrentMinutesGreaterThan30
        ? nextTwelveHours + 1
        : nextTwelveHours;
    }

    // if the selected date is after tomorrow

    return dayjs(`${this.#formattedCurrentDate}T${hh}:${mm}`);
  }

  #setFieldTestMinTimeCalculation() {
    let hh = this.#minDateHours;
    let mm = 0;
    if (this.#isCurrentDate(this.#userSelectedDate)) {
      // compare hours - this (6) is a business constraint for field test
      let nextAvailableAppointmentHours = this.#fieldTestTimeRange;
      if (
        new Date().getHours() + nextAvailableAppointmentHours <
        this.#maxDateHours
      ) {
        hh = new Date().getHours() + nextAvailableAppointmentHours;

        if (new Date().getMinutes() < 30) {
          mm = 30;
        } else {
          hh = hh + 1;
        }
      }
    }

    return dayjs(`${this.#formattedCurrentDate}T${hh}:${mm}`);
  }

  #isCurrentDate() {
    const _date = this.#userSelectedDate;

    if (!_date) return null;

    const date = new Date(_date);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    return currentDate.getTime() === date.getTime();
  }

  maxTimeCalculation() {
    if (this.#isLab) {
      return;
    }
    if (this.#isField) {
      return dayjs(`${this.#formattedCurrentDate}T${this.#maxDateHours}:00`);
    }
  }
}
