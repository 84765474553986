import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { ReactComponent as ExclamationMarkIcon } from "assets/icons/exclamationMark.svg";
import { useSelector } from "react-redux";
import { TestTypes } from "constants/TestTypes";
import RaiseNewRequestService from "modules/Contractor/features/RaiseNewRequest/RaiseNewRequestService";
import PropTypes from "prop-types";

const ChunksContentDataModal = (props) => {
  const { palette } = useTheme();

  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const { labTotalTestsCost, fieldTotalTestsCost } =
    RaiseNewRequestService.getTotalCostForTest(raiseNewRequestData);

  const points =
    raiseNewRequestData?.testType === TestTypes.LAB_TEST
      ? labTotalTestsCost
      : fieldTotalTestsCost;

  const { t } = useTranslation(["dashboard"]);

  const onLeavePageHandler = () => {
    // ADD RESET TO COLLECTED SAMPLE DATA HERE FROM REDUX
    props.onHide();
    // navigate(-1);
  };

  const onConfirmHandler = () => {
    onLeavePageHandler();
    props.confirm();
  };

  return (
    <>
      <Box sx={{ mb: 14 }}>
        <Box sx={{ display: "flex", mb: 2 }}>
          <ExclamationMarkIcon />

          <Typography
            sx={{
              mt: 2,
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
              ml: 2,
              mb: 1.5,
            }}
          >
            تأكيد عملية خصم النقاط
          </Typography>
        </Box>
        <hr />
        <Typography
          sx={{
            mt: 3,
            fontSize: "1.6rem",
            color: "grayDark.color1",
          }}
        >
          لإتمام هذا الطلب سوف يتم خصم{" "}
          <Typography
            component={"span"}
            sx={{ fontWeight: "bold", fontSize: "inherit" }}
          >
            {points}
          </Typography>{" "}
          نقطة من محفظتك،
          <Typography
            component={"span"}
            sx={{
              fontWeight: "bold",
              fontSize: "inherit",
              color: "base.invalid",
            }}
          >
            {" "}
            علماَ أنه لا يمكن إلغاء الطلب أو استرداد النقاط في حال تبقى أقل من
            ساعتين على موعد الفحص أو موعد جمع العينة.
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
            color: `${palette.text.tags.blueSoft}`,
          }}
          variant={"transparent"}
          onClick={props.onHide}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          variant={"secondary"}
          onClick={onConfirmHandler}
        >
          {t("تأكيد")}
        </BaseButton>
      </Box>
    </>
  );
};

ChunksContentDataModal.propTypes = {
  onHide: PropTypes.func,
  confirm: PropTypes.func,
};

export default ChunksContentDataModal;
