import { Box } from "@mui/material";
import { TransactionTypes } from "constants/TransactionTypes";
import PrintTransaction from "modules/Contractor/components/PrintTransaction/PrintTransaction";
import PropTypes from "prop-types";

const DashboardIntroAction = ({ transactionData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <PrintTransaction
        disabled={transactionData?.status === TransactionTypes.HELD}
        data={transactionData}
      />
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  transactionData: PropTypes.object,
};

export default DashboardIntroAction;
