import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import PropTypes from "prop-types";

function HistoryItem(props) {
  return (
    <Box
      sx={{
        order: props.order,
      }}
    >
      <BaseCard variant="disabled" mb={2} py={"0rem"} mt={4}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          flexWrap={"wrap"}
          px={1}
          py={2}
        >
          <Box>
            <Typography sx={{ fontSize: "1.6rem" }}>
              {formatDateYYYYMMDD(props.date)}
            </Typography>

            <Typography fontSize={"1.6rem"}>
              {displayFormattedTimeHHMM(props.date)}
            </Typography>
          </Box>

          <Box direction={"row"} alignItems={"center"} spacing={0.5} ml={5}>
            <Typography sx={{ fontSize: "1.6rem" }}>
              {props.label}
              {props.value}
            </Typography>
          </Box>
        </Stack>
      </BaseCard>
    </Box>
  );
}

HistoryItem.propTypes = {
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  value: PropTypes.string,
};

export default HistoryItem;
