import React from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { formattedNumber } from "utils/Numbers/Numbers";
import PropTypes from "prop-types";

function PointsCard(props) {
  return (
    <Box>
      <BaseCard
        variant="primary-fancy"
        borderRadius="1.0rem"
        sx={{ width: "25rem", height: "6rem" }}
        py={"1.5rem"}
        px={"3rem"}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            color: "base.white",
          }}
        >
          <Typography sx={{ mr: 1, fontSize: "2.4rem", fontWeight: "bold" }}>
            {props.isFetching ? 0.0 : formattedNumber(props.points || 0)}
          </Typography>
          <Typography sx={{ fontSize: "1.8rem" }}>نقطة</Typography>
        </Box>
      </BaseCard>
    </Box>
  );
}

PointsCard.propTypes = {
  isFetching: PropTypes.bool,
  points: PropTypes.number,
};

export default PointsCard;
