import BaseInput from "core-ui/BaseInput/BaseInput";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { onProctorFieldKeyDown } from "utils/DOM/DOM";

const OptimumProctor = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const { formValues } = props;

  const isStationNotApplicable = formValues?.[props.id]?.[`not-applicable`];

  const optimumProctorName = `${props.id}.optimum-proctor`;

  const customError = props.errors?.[props.id]?.["optimum-proctor"];

  const isDisabled =
    (props.isDraftedNotSubmitted && !props.enableDrafting) ||
    isStationNotApplicable;

  useEffect(() => {
    if (props.isDraftedNotSubmitted || props.isSubmitted) {
      props.setValue(optimumProctorName, props.optimumProctor);
    }
  }, []);

  useEffect(() => {
    if (isStationNotApplicable) {
      props.setValue(optimumProctorName, "");
      props.unregister(optimumProctorName);
    }
  }, [isStationNotApplicable, formValues[optimumProctorName]]);
  return (
    <BaseInput
      sx={{ width: "10rem" }}
      inputContainerStyles={{ margin: 0 }}
      inputStyles={{ margin: 0 }}
      placeholder={"مثال 2.7"}
      name={optimumProctorName}
      onKeyDown={onProctorFieldKeyDown}
      type={"number"}
      disabled={isDisabled}
      step={"any"}
      max={999999999}
      maxLength={5}
      register={{
        ...props.register(optimumProctorName, {
          required: {
            value: !isStationNotApplicable,
            message: `${t("field")} الرطوبة المثلى ${t("required")}`,
          },
          max: {
            value: 999999999,
            message: `الرقم تخطي الحد المسموح`,
          },
        }),
      }}
      customError={customError}
    />
  );
};

OptimumProctor.propTypes = {
  formValues: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  optimumProctor: PropTypes.number,
  isDraftedNotSubmitted: PropTypes.bool,
  enableDrafting: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

export default OptimumProctor;
