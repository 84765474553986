import React, { useState, useEffect } from "react";
import { CompanyPaymentsHeader } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Box, Stack } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { isContentReady } from "utils/Array/Arrays";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { useLazyGetCompanyPaymentsQuery } from "modules/Admin/apis/contractor-apis/companies-apis";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import BaseFilter from "core-ui/BaseFilter/BaseFilter";
import { paymentFilterCriteria } from "services/StaticLookup/Filter";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";

function CompanyPayments(props) {
  const { t } = useTranslation(["dashboard"]);

  const [paymentType, setPaymentType] = useState("");

  const [transactionId, setTransactionId] = useState("");

  const [getCompanyPayments, getCompanyPaymentsResponse] =
    useLazyGetCompanyPaymentsQuery();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  useEffect(() => {
    let timer = setTimeout(
      getCompanyPayments.bind(null, {
        query: {
          page,
          size: pageSize,
          sortId: "id,desc",
          contractorId: props?.companyId,
          type: paymentType,
          transactionId,
        },
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, paymentType, transactionId]);

  const isNoSearchData =
    (paymentType || transactionId) &&
    !isContentReady(getCompanyPaymentsResponse?.data?.content);

  const isNoRefundSearchData =
    paymentType === "REFUND" &&
    !isContentReady(getCompanyPaymentsResponse?.data?.content);
  const isNoDeductSearchData =
    paymentType === "DEDUCTED" &&
    !isContentReady(getCompanyPaymentsResponse?.data?.content);

  const emptyTitle = t("No payments");

  const emptyDesc = () => {
    if (isNoSearchData) {
      if (isNoRefundSearchData) {
        return t("No payments refund desc");
      } else if (isNoDeductSearchData) {
        return t("No payments deduct desc");
      } else return t("No payments desc");
    } else return t("No payments desc");
  };

  const onPaymentChange = (filterBy) => {
    setPaymentType(filterBy);
  };

  return (
    <Box mt={4}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
        mb={2}
      >
        <BaseInput
          inputContainerStyles={{ marginBottom: "0px" }}
          sx={{ width: `35rem` }}
          htmlFor="transactionId"
          type="number"
          placeholder={"يمكنك البحث برقم العملية"}
          name="transactionId"
          icon="search"
          onChange={setTransactionId}
        />
        <BaseFilter data={paymentFilterCriteria} onChange={onPaymentChange} />
      </Stack>
      <BaseTableIndex
        tableHeader={CompanyPaymentsHeader}
        injectProps={{ tableId: TABLE_ID.COMPANY_PAYMENTS }}
        content={getCompanyPaymentsResponse?.data?.content || []}
        isLoading={getCompanyPaymentsResponse?.isFetching}
        emptyData={{
          title: emptyTitle,
          desc: emptyDesc(),
          img: publicAssetsPath("images/documents2.svg"),
        }}
        withDetails={{
          paramsKey: "id",
          to: (id) => `/companies/${props?.companyId}/payments/${id}`,
          //   state: { },
        }}
        pagination={{
          displayBy: TABLE_DISPLAY_BY,
          label: "عملية",
          onChangePaginationDataHandler: onChangePaginationDataHandler,
          totalCount: getCompanyPaymentsResponse?.data?.totalElements,
        }}
      />
    </Box>
  );
}

CompanyPayments.propTypes = {
  companyId: PropTypes.string,
};

export default CompanyPayments;
