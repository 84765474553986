import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import messages from "services/AlertMessages/messages";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";

const ContractorAppintmentBooking = (props) => {
  return (
    <Typography
      component={"div"}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Typography
        component={"span"}
        sx={{ fontSize: "1.6rem", fontWeight: "bold" }}
      >
        عزيزي المقاول,
      </Typography>
      <Typography component={"span"} mt={1} fontSize={"1.4rem"}>
        {messages[ErrorCodes.CONTRACTOR_APPOINTMENT_BOOKING]}
        <Typography
          component={"span"}
          sx={{
            cursor: "pointer",
            fontSize: "inherit",
            color: "#1F766C",
            fontWeight: "bold",
            marginRight: 2,
          }}
          onClick={() => {
            props.hideAlertHandler();
            props.goPrev();
          }}
        >
          {" "}
          تحديد الموعد
        </Typography>
      </Typography>
    </Typography>
  );
};

ContractorAppintmentBooking.propTypes = {
  hideAlertHandler: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
};

export default ContractorAppintmentBooking;
