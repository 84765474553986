import { Box, Typography } from "@mui/material";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import PropTypes from "prop-types";
import { formatDate } from "utils/Date/Date";
import { publicAssetsPath } from "utils/Paths/Paths";

const PreviewAttachment = (props) => {
  return (
    <>
      <BaseDataItem
        label={"تاريخ التصوير"}
        value={formatDate(props.attachedAt)}
      />
      <BaseDataItem
        label={"وقت التصوير"}
        value={props.formattedAttachedAtTimes}
      />
      <BaseDataItem
        label={"صور النتائج"}
        valueComponentAs={"div"}
        value={
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            onClick={props.previewImageHandler}
          >
            <Typography className="request-data-details__link">
              الصورة
            </Typography>
            <img
              src={publicAssetsPath("icons/popout-svgrepo.svg")}
              width="auto"
              alt="popout-preview-attachment"
            />
          </Box>
        }
      />
    </>
  );
};

PreviewAttachment.propTypes = {
  attachedAt: PropTypes.string,
  formattedAttachedAtTimes: PropTypes.string,
  previewImageHandler: PropTypes.func,
};

export default PreviewAttachment;
