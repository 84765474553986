import contractorApi from "modules/Contractor/apis";

const contractorConsultantApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentForConsultant: builder.query({
      query: (appointmentId) => ({
        url: `/api/contractor/appointments/v2/consultant/${appointmentId}`,
      }),
    }),
    getInspectionForConsultant: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspections/v2/consultant/${inspectionLayerId}`,
      }),
    }),
    getTestsForConsultant: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionTests/v2/consultant/${inspectionLayerId}`,
      }),
    }),
  }),
});

export const {
  useGetAppointmentForConsultantQuery,
  useGetInspectionForConsultantQuery,
  useGetTestsForConsultantQuery,
} = contractorConsultantApi;

export default contractorConsultantApi;
