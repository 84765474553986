import { Box } from "@mui/material";
import classes from "./CaptureButton.module.scss";
import PropTypes from "prop-types";

const CaptureButton = (props) => {
  return <Box className={classes.capture} onClick={props.onCapture}></Box>;
};

CaptureButton.propTypes = {
  onCapture: PropTypes.func,
};

export default CaptureButton;
