import { useLocation, useOutletContext, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ExaminationRequests } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useState, useEffect } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Helmet } from "react-helmet";
import { TestTypes } from "constants/TestTypes";
import { DATA_ID } from "constants/Details";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import {
  useGetAppointmentForConsultantQuery,
  useGetInspectionForConsultantQuery,
} from "modules/Consultant/apis/consultant-apis/contractor-apis";
import LabExaminationRequests from "./Lab";
import FieldExaminationRequests from "./Field";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

import {
  ViweAsphaltReport,
  ViweConcreteProductReport,
  ViweSoilReport,
} from "../../../../../components/ActionButtons";

const ExaminationRequestsDetails = () => {
  const params = useParams();

  const { t } = useTranslation();

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const { state } = useLocation();

  useEffect(() => {
    if (state?.activeTab) {
      setTabsActiveIndex(state?.activeTab);
    }
  }, []);

  const {
    data: inspection,
    isSuccess,
    isFetching,
  } = useGetInspectionForConsultantQuery(params.id);

  const isLab = inspection?.testType === TestTypes.LAB_TEST;
  const inspectionLayer = inspection?.inspectionLayersList[0];

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );
  const isSoil = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_SOIL
  );
  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );
  const isConcreteProduct = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_CONCRETE_PRODUCTS
  );

  const isRequestDone =
    inspection?.status === "SUCCEED" || inspection?.status === "FAILED";

  const isRequestCancelled =
    inspection?.status === "OUTDATED" || inspection?.status === "CANCELED";

  const { data: labTestAppointmentForConsultant } =
    useGetAppointmentForConsultantQuery(params.id);

  const { globalAlert, onHideGlobalAlert } = useOutletContext();

  const render = () => {
    if (isLab) {
      return (
        <LabExaminationRequests
          requestDetails={inspection}
          dataId={DATA_ID.CON_REQUESTS}
          labTestAppointment={labTestAppointmentForConsultant}
        />
      );
    } else {
      return (
        <FieldExaminationRequests
          requestDetails={inspection}
          dataId={DATA_ID.CON_REQUESTS}
          labTestAppointment={labTestAppointmentForConsultant}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص - رقم الطلب	${inspection?.id}`}</title>
      </Helmet>

      <DashboardIntro
        title={t(` رقم الطلب ${inspection?.id || ""}`, {
          ns: "dashboard",
        })}
        description={"يمكنك متابعة تقدم اختبارات الفحص و الاطلاع على النتائج"}
        action={
          <Box>
            {!isRequestCancelled && (isSoil || isABC) && isLab && (
              <ViweSoilReport
                inspection={inspection}
                isRequestDone={isRequestDone}
                isABC={isABC}
                isLab={isLab}
                isSoil={isSoil}
              />
            )}
            {!isRequestCancelled && isAsphalt && isLab && (
              <ViweAsphaltReport
                inspection={inspection}
                isAsphalt={isAsphalt}
                isLab={isLab}
                isRequestDone={isRequestDone}
              />
            )}
            {!isRequestCancelled && isConcreteProduct && isLab && (
              <ViweConcreteProductReport
                isConcreteProduct={isConcreteProduct}
                isLab={isLab}
                isRequestDone={isRequestDone}
                inspection={inspection}
              />
            )}
            <BaseAlert
              show={globalAlert.show}
              type={globalAlert.type}
              message={globalAlert.message}
              autoClose={3}
              interpolation={globalAlert.interpolation}
              destroy={onHideGlobalAlert}
            />
          </Box>
        }
        breadcrumbData={ExaminationRequests({
          id: inspection?.id,
        })}
      />

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"5rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching || !isSuccess ? (
          <BaseLoader />
        ) : (
          tabsActiveIndex === 1 && render()
        )}
      </BaseCard>
    </>
  );
};

export default ExaminationRequestsDetails;
