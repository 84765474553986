import { createAsyncThunk } from "@reduxjs/toolkit";
import authApi from "modules/Auth/apis";
import contractorApi from "modules/Contractor/apis";
import adminApi from "modules/Admin/apis";
import inspectionApi from "modules/Inspection/apis";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import { consultantActions } from "store/consultant-slice/consultant-slice";
import { authActions } from ".";
import { clearPackageAction } from "store/wallet-slice/wallet-thunk-actions";
import publicApi from "modules/Public/apis";

//prettier-ignore
export const logoutAction = createAsyncThunk("auth/logout", async (data, {dispatch, getState}) => {
  dispatch(authActions.logout());
  dispatch(clearPackageAction());
  dispatch(contractorActions.resetRaiseNewRequestData());
  dispatch(consultantActions.resetRaiseNewRequestData());
  dispatch(authApi.util.resetApiState());
  dispatch(contractorApi.util.resetApiState());
  dispatch(adminApi.util.resetApiState());
  dispatch(inspectionApi.util.resetApiState());
  dispatch(publicApi.util.resetApiState());
  }
);
