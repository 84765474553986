import { AlertTypes } from "constants/AlertTypes";
import { useEffect, useState } from "react";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { _void } from "utils/Objects/Objects";
import BaseButton from "core-ui/BaseButton/BaseButton";

const ManageUserButtonAction = ({
  user,
  activateUser,
  deActivateUser,
  isUserActionSubmitting,
  showAlertHandler,
}) => {
  const [isUserAccountActive, setIsUserAccountActive] = useState(false);

  const { t } = useTranslation(["dashboard"]);

  useEffect(() => {
    setIsUserAccountActive(!!user?.isActive);
  }, [user]);

  const changeUserActivationState = (fn, msg) => {
    fn({ userId: user.id })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes[msg],
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };
  const msg = () => {
    if (!user) {
      return _void;
    } else if (user.isActive) {
      return "USER_DECTIVATED_SUCCESSFULLY";
    } else {
      return "USER_ACTIVATED_SUCCESSFULLY";
    }
  };
  const fn = () => {
    if (!user) {
      return _void;
    } else if (user.isActive) {
      return deActivateUser;
    } else {
      return activateUser;
    }
  };
  return (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={changeUserActivationState.bind(null, fn(), msg())}
      variant={isUserAccountActive ? "secondary" : ""}
      isLoading={isUserActionSubmitting}
    >
      {isUserAccountActive ? t("deactivate account") : t("activate account")}
    </BaseButton>
  );
};

ManageUserButtonAction.propTypes = {
  user: PropTypes.object,
  activateUser: PropTypes.func,
  deActivateUser: PropTypes.func,
  isUserActionSubmitting: PropTypes.bool,
  showAlertHandler: PropTypes.func,
};

export default ManageUserButtonAction;
