import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import TableTitle from "components/Dashboard/TableTitle";
import {
  drillingLicences,
  examinationRequests,
} from "services/StaticLookup/TableHeader/Consultant";
import { useTheme } from "@emotion/react";
import { publicAssetsPath } from "utils/Paths/Paths";
import { TABLE_ID, TABLE_LIMIT_PAGE_SIZE } from "constants/Table";
import { Helmet } from "react-helmet";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import {
  useGetConsultantLicensesQuery,
  useGetConsultantRequestsQuery,
} from "modules/Consultant/apis/consultation-requests-apis/consultation-requests-apis";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const AddCheckupRequestButton = () => {
  const { t } = useTranslation(["dashboard"]);
  const navigate = useNavigate();

  return (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={() => navigate("/consultation-requests/raise-request")}
    >
      {t("request for attribution of license")}
    </BaseButton>
  );
};

const DashboardIndex = () => {
  const { t } = useTranslation(["dashboard"]);
  const { alert, hideAlertHandler } = useAlert();
  const { palette } = useTheme();

  const { data: consultantRequestsData, isRequestsFetching } =
    useGetConsultantRequestsQuery({
      page: 0,
      size: TABLE_LIMIT_PAGE_SIZE,
      sortId: "id,desc",
    });

  const { data: consultantLicensesData, isFetching: isLicensesFetching } =
    useGetConsultantLicensesQuery({
      page: 0,
      size: TABLE_LIMIT_PAGE_SIZE,
      sortId: "id,desc",
    });

  const tableEmptyData = {
    img: publicAssetsPath("images/documents5.svg"),
    title: t("لا يوجد رخص تم إسنادها لك"),
    desc: t("يمكنك طلب إسناد الرخص لك وذلك من خلال الضغط على"),
    to: "/consultation-requests/raise-request",
    link: t("request for attribution of license"),
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"الرئيسية"}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("home page")}
        description={t(
          "تستعرض هذه الصفحة أحدث الرخص المسندة لك و أحدث طلبات الفحص"
        )}
        action={<AddCheckupRequestButton />}
        warning={
          <BaseAlert
            mb={3}
            show={alert.show}
            labelSx={{ fontWeight: "bold", color: "black" }}
            type={alert.type}
            icon={false}
            destroy={hideAlertHandler}
            message={alert.message}
            closeIconColor={"grayDark.color"}
          />
        }
      />

      {/* first section drillingLicences */}
      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("drilling licences")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/drilling-licenses"}
        />
        <BaseTableIndex
          tableHeader={drillingLicences}
          content={consultantLicensesData?.content || []}
          isLoading={isRequestsFetching}
          injectProps={{ tableId: TABLE_ID.LICENSE }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/drilling-licenses/${id}`,
          }}
        />
      </BaseCard>

      {/* second section examination_requests */}
      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("Examination requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/examination-requests"}
        />
        <BaseTableIndex
          tableHeader={examinationRequests}
          content={consultantRequestsData?.content || []}
          injectProps={{ tableId: TABLE_ID.REQUEST }}
          isLoading={isLicensesFetching}
          emptyData={{
            title: t("لا توجد طلبات"),
            desc: t("ستجد هنا جميع طلبات الفحص التابعة للرخص المسندة لك"),
            img: publicAssetsPath("images/documents6.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/examination-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DashboardIndex;
