import { useState } from "react";
import { InputLabel, FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Box } from "@mui/system";
import "./custom.css";
import PropTypes from "prop-types";

const BasePhoneInput = (props) => {
  const {
    requiredMessage = "حقل رقم الجوال مطلوب",
    invalidMessage = "رقم الجوال غير صحيح",
  } = props;

  const [value, setValue] = useState("");

  const inputHasError = props.errors ? props.errors[props.name] : false;

  return (
    <Box mb={5} sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          ...props.labelStyles,
          // mb: 2,
          color: "grayDark.color",
          fontSize: "1.4rem",
          fontWeight: 500,
          marginBottom: "1rem",
        }}
        htmlFor={props.htmlFor}
      >
        {props.label}
      </InputLabel>
      <Box sx={{ position: "relative", ...props.sx }}>
        <PhoneInput
          className={`custom-phone ${inputHasError ? "error " : " "}`}
          name={props.name}
          placeholder={props.placeholder}
          value={value}
          onChange={setValue}
          flags={["SA"]}
          countries={["SA"]}
          country="SA"
          locale="ar"
          control={props.control}
          international={false}
          rules={{
            required: {
              value: true,
              message: requiredMessage,
            },
            validate: (value) => {
              if (!isValidPhoneNumber(value)) {
                return invalidMessage;
              }
            },
          }}
        />
      </Box>

      {inputHasError && (
        <Box mb={-2.9}>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export default BasePhoneInput;

BasePhoneInput.defaultProps = {
  requiredMessage: "حقل رقم الجوال مطلوب",
  invalidMessage: "رقم الجوال غير صحيح",
};

BasePhoneInput.propTypes = {
  requiredMessage: PropTypes.string,
  invalidMessage: PropTypes.string,
  name: PropTypes.string,
  htmlFor: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  labelStyles: PropTypes.object,
  sx: PropTypes.object,
  control: PropTypes.object,
};
