import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DashboardIntroAction = ({
  alert,
  hideAlertHandler,
  editRequestsDetails,
  rejectEditRequestResponse,
  approveEditRequestResponse,
  setShowAcceptModal,
  setShowRejectionModal,
  isFetching,
}) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <BaseAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        destroy={hideAlertHandler}
        autoClose={10}
      />
      {editRequestsDetails?.status === "PENDING" && (
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          <BaseButton
            onClick={() => setShowRejectionModal(true)}
            sx={{
              minWidth: "12.2rem",
            }}
            variant="secondary"
            isLoading={rejectEditRequestResponse.isLoading}
            disabled={approveEditRequestResponse.isLoading || isFetching}
          >
            {t("reject")}
          </BaseButton>
          <BaseButton
            sx={{
              minWidth: "12.2rem",
            }}
            onClick={() => setShowAcceptModal(true)}
            isLoading={approveEditRequestResponse.isLoading}
            disabled={rejectEditRequestResponse.isLoading || isFetching}
          >
            {t("accept")}
          </BaseButton>
        </Box>
      )}
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  alert: PropTypes.object,
  hideAlertHandler: PropTypes.func,
  editRequestsDetails: PropTypes.object,
  rejectEditRequestResponse: PropTypes.object,
  approveEditRequestResponse: PropTypes.object,
  setShowAcceptModal: PropTypes.func,
  setShowRejectionModal: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default DashboardIntroAction;
