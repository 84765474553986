import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import classes from "./BaseDropdown.module.scss";
import useClickOutsideModal from "hooks/useClickOutsideModal";
import DropdownMainBar from "./DropdownMainBar/DropdownMainBar";
import DropdownList from "./DropdownList/DropdownList";
import PickupList from "./PickupList/PickupList";
import PropTypes from "prop-types";

const BaseDropdown = (props) => {
  const dropdownRef = useRef();

  const pickupListRef = useRef();

  const [open, setOpen] = useState(false);

  const [pickupData, setPickupData] = useState(
    props.pickupData || {
      allData: [],
      item: null,
    }
  );

  const dropdownInputHasError = () => {
    if (props?.name && props?.customError) {
      return props.customError;
    } else if (props?.errors) {
      return props?.errors[props.name];
    } else return false;
  };

  const safeArea = "base-dropdown-safe-content";

  useClickOutsideModal(
    { main: dropdownRef, safeArea: pickupListRef },
    {
      close: () => setOpen(false),
    }
  );

  const [dropdownPosition, setDropdownPosition] = useState(null);

  const stackingContextHandler = () => {
    if (dropdownRef?.current) {
      setDropdownPosition(dropdownRef.current.getBoundingClientRect());
    }
  };

  return (
    <Box
      mt={props.mt}
      sx={{
        width: `min(${props.width}, 100%)`,
        marginBottom: 0,
        ...props.mainStyles,
      }}
    >
      {props.label && (
        <Typography mb={"1rem"} fontSize={"1.4rem"} sx={props.labelStyles}>
          {props.label}
        </Typography>
      )}

      <Box
        ref={dropdownRef}
        className={classes["base-dropdown"]}
        name={props.name}
      >
        <DropdownMainBar
          ignoreStackingContext={props.ignoreStackingContext}
          stackingContextHandler={stackingContextHandler}
          ref={props.dropdownRef}
          dropdownInputHasError={dropdownInputHasError()}
          helperText={props.helperText}
          disabled={props.disabled}
          pickup={props.pickup}
          placeholder={props.placeholder}
          setOpen={setOpen}
          value={props.value}
          pickupData={pickupData}
          isLoading={props.isLoading}
          itemNameKey={props.itemNameKey}
        />

        <DropdownList
          ignoreStackingContext={props.ignoreStackingContext}
          dropdownPosition={dropdownPosition}
          pickup={props.pickup}
          selectMenuStyles={props.selectMenuStyles}
          disabled={props.disabled}
          open={open}
          isFetching={props.isFetching}
          data={props.data}
          itemNameKey={props.itemNameKey}
          itemDirection={props.itemDirection}
          emptyListPlaceholder={props.emptyListPlaceholder}
          setOpen={setOpen}
          value={props.value}
          pickupData={pickupData}
          setPickupData={setPickupData}
          onChange={props.onChange}
        />
      </Box>
      {props.pickup && (
        <PickupList
          pickup={props.pickup}
          safeArea={safeArea}
          pickupListRef={pickupListRef}
          pickupData={pickupData}
          setPickupData={setPickupData}
          onChange={props.onChange}
        />
      )}
    </Box>
  );
};

export default BaseDropdown;

BaseDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  pickupData: PropTypes.object,
  errors: PropTypes.object,
  mainStyles: PropTypes.object,
  dropdownRef: PropTypes.object,
  selectMenuStyles: PropTypes.object,
  data: PropTypes.array,
  labelStyles: PropTypes.object,
  name: PropTypes.string,
  customError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  itemNameKey: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  emptyListPlaceholder: PropTypes.string,
  pickup: PropTypes.bool,
  ignoreStackingContext: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  itemDirection: PropTypes.string,
  mt: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
