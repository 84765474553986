import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ContractingPointsPaymentsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { Box, Typography } from "@mui/material";
import { useGetPaymentQuery } from "modules/Contractor/apis/payment-apis/payment-apis";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import {
  companyPaymentsDetails,
  pointsPaymentsDetails,
} from "services/StaticLookup/TableHeader/TableHeader";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import TableTitle from "components/Dashboard/TableTitle";
import { formattedNumber } from "utils/Numbers/Numbers";
import { _void } from "utils/Objects/Objects";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { TestTypes } from "constants/TestTypes";
import MultipleLicensesDataItem from "components/MultipleLicensesDataItem/MultipleLicensesDataItem";
import LicenseLink from "components/LicenseLink/LicenseLink";
import DashboardIntroAction from "./DashboardIntroAction";

const PointsPaymentsDetails = () => {
  const params = useParams();

  const {
    data: transactionData,
    isLoading,
    isFetching,
  } = useGetPaymentQuery(params?.id, {
    skip: !params?.id,
  });

  const fieldTableHeader = [{ serverKey: "subTestName", name: "description" }];
  const isField = transactionData?.testtype === TestTypes.FIELD_TEST;

  const isSingleLicense = transactionData?.licenses?.length === 1;

  return (
    <>
      {isLoading && <BaseLoader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`مدفوعات النقاط - ${transactionData?.id}`}</title>
      </Helmet>

      <DashboardIntro
        title={`بيانات العملية رقم ${transactionData?.id}`}
        action={<DashboardIntroAction transactionData={transactionData} />}
        breadcrumbData={
          !transactionData
            ? _void
            : ContractingPointsPaymentsDetailsBreadcrumb(transactionData)
        }
      />
      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <BaseDataWrapper
          title={"المعلومات العامة"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem
            label={"اسم شركة المقاولات"}
            value={transactionData?.contractorName}
          />
          <BaseDataItem label={"رقم العملية"} value={transactionData?.id} />
          <BaseDataItem
            label={"نوع العملية"}
            valueComponentAs={"div"}
            value={<BaseStatus status={transactionData?.status} />}
          />
          <BaseDataItem
            label={"التاريخ"}
            value={`${formatDateYYYYMMDD(
              transactionData?.transactionTime
            )} - ${displayFormattedTimeHHMM(transactionData?.transactionTime)}`}
          />
          {isSingleLicense ? (
            <LicenseLink license={transactionData?.licenses?.[0]} />
          ) : (
            <MultipleLicensesDataItem
              requestId={transactionData?.inspection}
              data={transactionData?.licenses}
            />
          )}
          <BaseDataItem
            label={"رقم الطلب"}
            value={transactionData?.inspectionLayer}
          />
          <BaseDataItem
            label={"نوع الفحص"}
            valueComponentAs={"div"}
            value={<BaseTypeTag type={transactionData?.testtype} />}
          />
          <BaseDataItem
            label={"اختبار مواد الأعمال المدنية"}
            value={transactionData?.inspectionLayerName}
          />
        </BaseDataWrapper>
        <Box mt={10}>
          <TableTitle title="ملخص الطلب" />
          {transactionData?.deductionDetailsDTO &&
            transactionData?.deductionDetailsDTO.length !== 0 && (
              <BaseTableIndex
                tableSx={{
                  thLastOfType: {
                    textAlign: isField ? "" : "end",
                  },
                  td: {
                    borderBottom: "0.1rem solid background.disabled",
                  },
                  tdLastOfType: {
                    textAlign: isField ? "" : "end",
                  },
                }}
                tableHeader={
                  isField ? fieldTableHeader : companyPaymentsDetails
                }
                content={
                  transactionData?.deductionDetailsDTO?.map((d) => ({
                    ...d,
                    id: crypto.randomUUID(),
                  })) || []
                }
                isLoading={isFetching}
              />
            )}
          {transactionData?.status !== "REFUND" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box mt={1}>
                {transactionData?.testtype === "LAB" && (
                  <>
                    <Typography
                      sx={{ fontSize: "1.6rem" }}
                      color={"grayDark.color1"}
                      ml={2}
                      mb={3}
                      mt={3}
                    >
                      تكلفة الاختبارات المعملية
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.6rem" }}
                      color={"grayDark.color1"}
                      ml={2}
                      mb={3}
                    >
                      تكلفة جمع العينات
                    </Typography>
                  </>
                )}
                <Typography
                  sx={{ fontSize: "1.7rem", fontWeight: 500 }}
                  color={"grayDark.color1"}
                  ml={2}
                  mb={3}
                >
                  {transactionData?.status === "REFUND"
                    ? "إجمالي القيمة المستردة"
                    : "التكلفة الكلية"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box mt={1}>
                  {transactionData?.testtype === "LAB" && (
                    <>
                      <Typography
                        sx={{ fontSize: "1.6rem", mb: 3, mt: 3 }}
                        color={"grayDark.color1"}
                      >
                        {`${formattedNumber(
                          transactionData?.combinedLabTestCost
                        )} نقطة`}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "1.6rem", mb: 3 }}
                        color={"grayDark.color1"}
                      >
                        {`${formattedNumber(
                          transactionData?.combinedSampleCollectionCost
                        )} نقطة`}
                      </Typography>
                    </>
                  )}
                  <Typography
                    sx={{ fontSize: "1.7rem", fontWeight: 500, mb: 3 }}
                    color={"primary.colorSoft10"}
                  >
                    {`${formattedNumber(transactionData?.deductedPoints)} `}
                    <span style={{ color: "black" }}>نقطة</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <BaseTableIndex
              tableSx={{
                thLastOfType: {
                  textAlign: "end",
                },
                tdLastOfType: {
                  textAlign: "end",
                },
              }}
              tableHeader={pointsPaymentsDetails}
              content={[
                {
                  id: "101-100-101",
                  description: "إجمالي القيمة المستردة",
                  deductedPoints: transactionData?.deductedPoints,
                  bold: true,
                },
              ]}
            />
          )}
        </Box>
      </BaseCard>
    </>
  );
};

export default PointsPaymentsDetails;
