import React, { Fragment } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useNavigate } from "react-router-dom";
import ActionRow from "../ActionRow/ActionRow";
import PropTypes from "prop-types";

const MainTableBody = (props) => {
  const navigate = useNavigate();

  const navigateWithDataHandler = (element) => {
    let toPath = element[[props.withDetails.paramsKey]];
    if (props.withDetails.serverKey && !props.withDetails.innerKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.paramsKey];
    } else if (props.withDetails.serverKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.innerKey][
          props.withDetails.paramsKey
        ];
    }
    const doNavigation = () => {
      if (props.withDetails.transferData) {
        return {
          state: { ...element },
        };
      } else if (props.withDetails.state) {
        return {
          state: { ...props.withDetails.state },
        };
      } else return {};
    };
    navigate(props.withDetails.to(toPath), doNavigation());
    if (props.withDetails.action) {
      props.withDetails.action(element);
    }
  };

  const InjectedComponent = (rowProps) =>
    React.cloneElement(props.injectCompBetweenRows, {
      ...rowProps,
      ...props.injectProps,
      insertProps: {
        props: { ...props.insertedProps },
        insertNewPropsHandler: props.insertNewPropsHandler,
      },
    });

  return (
    <TableBody>
      {!props.isTableContentEmpty ? (
        props.content.map((element, idx) => {
          return (
            <Fragment key={element[props.dataItemKey]}>
              {props.children({ navigateWithDataHandler, element, ...props })}

              {props.injectCompBetweenRows && (
                <TableRow>
                  <TableCell colSpan={"100%"}>
                    {InjectedComponent(element)}
                  </TableCell>
                </TableRow>
              )}
              {props.withActionRow && idx === props.content.length - 1 && (
                <ActionRow
                  colSpan={props.tableHeader.length}
                  rowStyles={props.withActionRow?.rowStyles}
                  cellStyles={props.withActionRow?.cellStyles}
                  textStyles={props.withActionRow?.textStyles}
                  description={props.withActionRow?.description}
                />
              )}
            </Fragment>
          );
        })
      ) : (
        <BaseLoader />
      )}
    </TableBody>
  );
};

MainTableBody.defaultProps = {
  dataItemKey: "id",
};

MainTableBody.propTypes = {
  content: PropTypes.array,
  tableHeader: PropTypes.array,
  injectProps: PropTypes.object,
  withActionRow: PropTypes.object,
  withDetails: PropTypes.object,
  insertedProps: PropTypes.object,
  isTableContentEmpty: PropTypes.bool,
  description: PropTypes.string,
  dataItemKey: PropTypes.string,
  insertNewPropsHandler: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  injectCompBetweenRows: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default MainTableBody;
