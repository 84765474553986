import { TestTypes } from "constants/TestTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import LabTechnicianRequestDetails from "./Lab";
import FieldTechnicianRequestDetails from "./Field";
import PropTypes from "prop-types";

const RequestData = (props) => {
  const isLab = props.inspection?.testType === TestTypes.LAB_TEST;

  const render = () => {
    if (props.isInspectionSuccess) {
      if (isLab) {
        return (
          <LabTechnicianRequestDetails
            inspection={props.inspection}
            dataId={props.dataId}
          />
        );
      } else {
        return (
          <FieldTechnicianRequestDetails
            inspection={props.inspection}
            dataId={props.dataId}
          />
        );
      }
    } else {
      return <BaseLoader />;
    }
  };
  return render();
};

RequestData.propTypes = {
  inspection: PropTypes.object,
};

export default RequestData;
