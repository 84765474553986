import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { TechnicianRequestsIndex } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import {
  useLazyGetAssignmentsQuery,
  useLazyGetTechniciansQuery,
  useReassignTechnicianMutation,
  useLazyGetIsTechnicianAvailableQuery,
} from "modules/Admin/apis/reassign-apis/reassign-apis";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import TechnicianSearch from "../../../components/TechnicianSearch/TechnicianSearch";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ReassignTechnicianModal from "components/AllModalContent/ReassignTechnicianModal/ReassignTechnicianModal";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import { useForm } from "react-hook-form";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import messages from "services/AlertMessages/messages";
import { Box, Typography } from "@mui/material";
import { TechReassignBreadcrumb } from "services/StaticLookup/Breadcrumb";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import ViewDetails from "components/TableUtilitiesComponents/TableActionsComponents/ViewDetails/ViewDetails";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const TechnicianRequests = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation(["dashboard"]);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [technicianName, setTechnicianName] = useState("");
  const [requestType, setRequestType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [assignmentId, setAssignmentId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [isAvailable, setIsAvailable] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getAssignments, getAssignmentsResponse] = useLazyGetAssignmentsQuery();
  const [getTechnicians, getTechniciansResponse] = useLazyGetTechniciansQuery();
  const [reassignTechnician, reassignTechnicianResponse] =
    useReassignTechnicianMutation();
  const [getIsTechnicianAvailable, getIsTechnicianAvailableResponse] =
    useLazyGetIsTechnicianAvailableQuery();

  useEffect(() => {
    setIsAvailable(getIsTechnicianAvailableResponse?.data?.isAvailable);
  }, [getIsTechnicianAvailableResponse]);

  useEffect(() => {
    let timer = setTimeout(
      getAssignments.bind(null, {
        page,
        size: pageSize,
        sortId: "id,desc",
        nameQuery: technicianName,
        testType: requestType,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, technicianName, requestType]);

  const getTechnicianName = (technicianName) => {
    setTechnicianName(technicianName);
  };

  const getRequestType = (requestType) => {
    setRequestType(requestType);
  };
  const checkTechnicianAvailability = (data) => {
    getIsTechnicianAvailable({
      inspectionLayerId: requestId,
      technicianId: data?.id,
    });
  };

  const isNoSearchData =
    (technicianName || requestType) &&
    !isContentReady(getAssignmentsResponse?.data?.content);
  const emptyTitle = isNoSearchData
    ? t("No Search")
    : t("No assigned requests");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No assigned requests desc");

  const onReassignHandler = (el) => {
    setAssignmentId(el?.assignmentId);
    setRequestId(el?.inspectionId);
    getTechnicians({ assignmentId: el?.assignmentId })
      .unwrap()
      .then(() => setShowModal(true));
  };

  const onLeavePageHandler = () => {
    reset({ technicianName: null });
    setIsAvailable("");
    setShowModal(false);
  };

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    reassignTechnician({
      assignmentId: assignmentId,
      newAssigneeUserId: data?.technicianName?.id,
    })
      .unwrap()
      .then((responseData) => {
        onLeavePageHandler();
        getAssignments({
          page,
          size: pageSize,
          sortId: "id,desc",
          nameQuery: technicianName,
          testType: requestType,
        });
        showAlertHandler({
          show: true,
          message: SuccessCodes.TECHNICIAN_REASSIGNED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        return responseData;
      })
      .catch((err) => {
        onLeavePageHandler();
        showAlertHandler({
          show: true,
          message: ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة لفني الرقابة`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"5rem"}
        onHide={onLeavePageHandler}
        title={`إعادة إسناد الطلب رقم ${requestId}`}
        mainContainerSx={{
          width: "55rem",
        }}
        containerSx={{
          height: "39rem",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <ReassignTechnicianModal
            isAvailable={isAvailable}
            onHide={onLeavePageHandler}
            control={control}
            errors={{ ...errors }}
            register={register}
            checkTechnicianAvailability={checkTechnicianAvailability}
            techniciansData={getTechniciansResponse?.data}
            isLoading={reassignTechnicianResponse.isLoading}
          />
        </form>
      </BaseModal>

      <DashboardIntro
        title={t("technician Requests")}
        breadcrumbData={TechReassignBreadcrumb(t)}
        description={t(
          "تمكنك هذه الصفحة من الاطلاع على الطلبات الحالية لجمع و تسليم العينات والفحص الحقلي، كما يمكنك أيضًا إعادة إسناد العاملين على هذه الطلبات في حال رغبتك بذلك."
        )}
        withAlertHandler={{
          comp: (
            <Box
              sx={{ mt: "2rem", justifyContent: "flex-end", display: "flex" }}
            >
              <BaseAlert
                sx={{ width: "40rem", justifyContent: "flex-end" }}
                show={alert.show}
                type={alert.type}
                destroy={hideAlertHandler}
              >
                <Typography component={"p"}>
                  {messages[alert.message]}
                </Typography>
              </BaseAlert>
            </Box>
          ),
        }}
      />

      <TechnicianSearch
        getTechnicianName={getTechnicianName}
        getRequestType={getRequestType}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={TechnicianRequestsIndex}
          content={getAssignmentsResponse?.data?.content || []}
          isLoading={getAssignmentsResponse.isFetching}
          dataItemKey={"assignmentId"}
          injectProps={{
            tableId: TABLE_ID.ADMIN_TECHNICIAN_REQUESTS,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withAction={{
            comp: <ViewDetails label={"إعادة إسناد الطلب"} />,
            action: onReassignHandler,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getAssignmentsResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default TechnicianRequests;
