import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import { Typography } from "@mui/material";
import { enLabelSx } from "constants/Typography";
import ValueUpdatedScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueUpdatedScore/ValueUpdatedScore";
import ValueCurrentScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueCurrentScore/ValueCurrentScore";
import { isContentReady } from "utils/Array/Arrays";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import PropTypes from "prop-types";

const formmatter = (cell) => {
  return (
    <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{cell}</Typography>
  );
};

const DoubleLayerData = ({ subTests }) => {
  const siveAnalysis = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: formmatter,
    },
    {
      serverKey: "",
      name: " ",
    },
    {
      serverKey: "",
      name: "  ",
    },
    {
      serverKey: "currentScore",
      name: "   ",
      hasComponent: {
        customProps: "currentScore",
        comp: <ValueCurrentScore />,
      },
    },
    {
      serverKey: "updatedScore",
      name: "     ",
      hasComponent: {
        customProps: "updatedScore",
        comp: <ValueUpdatedScore />,
      },
    },
    {
      serverKey: "",
      name: "      ",
    },
  ];
  if (!isContentReady(subTests?.[0])) return <BaseLoader />;
  return (
    <BaseTableCard
      tableHeader={siveAnalysis}
      content={subTests?.[0]}
      tableSx={{
        td: {
          borderBottom: "0.1rem solid background.disabled",
        },
      }}
    />
  );
};

DoubleLayerData.propTypes = {
  subTests: PropTypes.array,
};

export default DoubleLayerData;
