import React from "react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import {
  companyPaymentsDetails,
  pointsPaymentsDetails,
} from "services/StaticLookup/TableHeader/TableHeader";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import TableTitle from "components/Dashboard/TableTitle";
import { formattedNumber } from "utils/Numbers/Numbers";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { TestTypes } from "constants/TestTypes";
import MultipleLicensesDataItem from "components/MultipleLicensesDataItem/MultipleLicensesDataItem";
import LicenseLink from "components/LicenseLink/LicenseLink";
import PropTypes from "prop-types";

const CompanyPaymentData = (props) => {
  const transactionTime = new Date(props.data?.transactionTime);
  const transactionTimeHours = transactionTime?.getHours();
  const transactionTimeMinutes = transactionTime?.getHours();
  const transactionTimeString = `${transactionTimeHours || "00"}:${
    transactionTimeMinutes || "00"
  }:00`;

  const fieldTableHeader = [{ serverKey: "subTestName", name: "description" }];
  const isField = props.data?.testtype === TestTypes.FIELD_TEST;

  const isSingleLicense = props.data?.licenses?.length === 1;

  return (
    <Box className={"request-data-details"} mt={4}>
      <Box className={"request-data-details__wrapper"} mb={5}>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اسم شركة المقاولات
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.data?.contractorName}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم العملية
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.data?.id}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <BaseDataItem
              label={"نوع العملية"}
              valueComponentAs={"div"}
              value={<BaseStatus status={props.data?.status} />}
            />
          </Box>
          <Box className={"request-data-details__item"}>
            <BaseDataItem
              label={"التاريخ"}
              value={`${formatDateYYYYMMDD(
                props.data?.transactionTime
              )} - ${renderDateTimeInAMPM(transactionTimeString)}`}
            />
          </Box>
          {isSingleLicense ? (
            <LicenseLink license={props?.data?.licenses?.[0]} />
          ) : (
            <MultipleLicensesDataItem
              requestId={props.data?.inspection}
              data={props.data?.licenses}
            />
          )}
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الطلب
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.data?.inspectionLayer}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              نوع الفحص
            </Typography>
            <Typography className={"request-data-details__value"}>
              <BaseTypeTag type={props.data?.testtype} />
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اختبار مواد الأعمال المدنية
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.data?.inspectionLayerName}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <Box mt={10}>
        <TableTitle title="ملخص الطلب" />
        {props.data?.deductionDetailsDTO &&
          props?.data?.status !== "REFUND" &&
          props.data?.deductionDetailsDTO.length !== 0 && (
            <BaseTableIndex
              tableSx={{
                thLastOfType: {
                  textAlign: isField ? "" : "end",
                },
                td: {
                  borderBottom: "0.1rem solid background.disabled",
                },
                tdLastOfType: {
                  textAlign: isField ? "" : "end",
                },
              }}
              tableHeader={isField ? fieldTableHeader : companyPaymentsDetails}
              content={props.data?.deductionDetailsDTO || []}
              isLoading={props.isFetching}
            />
          )}
        {props?.data?.status !== "REFUND" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box mt={1}>
              {props?.data?.testtype === "LAB" && (
                <>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    ml={2}
                    mb={3}
                    mt={3}
                  >
                    تكلفة الاختبارات المعملية
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    ml={2}
                    mb={3}
                  >
                    تكلفة جمع العينات
                  </Typography>
                </>
              )}
              <Typography
                sx={{ fontSize: "1.7rem", fontWeight: 500 }}
                color={"grayDark.color1"}
                ml={2}
                mb={3}
              >
                التكلفة الكلية
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box mt={1}>
                {props?.data?.testtype === "LAB" && (
                  <>
                    <Typography
                      sx={{ fontSize: "1.6rem", mb: 3, mt: 3 }}
                      color={"grayDark.color1"}
                    >
                      {`${formattedNumber(
                        props?.data?.combinedLabTestCost
                      )} نقطة`}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.6rem", mb: 3 }}
                      color={"grayDark.color1"}
                    >
                      {`${formattedNumber(
                        props?.data?.combinedSampleCollectionCost
                      )} نقطة`}
                    </Typography>
                  </>
                )}
                <Typography
                  sx={{ fontSize: "1.7rem", fontWeight: 500, mb: 3 }}
                  color={"primary.colorSoft10"}
                >
                  {`${formattedNumber(props?.data?.deductedPoints)} `}
                  <span style={{ color: "black" }}>نقطة</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <BaseTableIndex
            tableSx={{
              thLastOfType: {
                textAlign: "end",
              },
              tdLastOfType: {
                textAlign: "end",
              },
            }}
            tableHeader={pointsPaymentsDetails}
            content={[
              {
                id: "100-100-100",
                description: "إجمالي القيمة المستردة",
                deductedPoints: props.data?.deductedPoints,
                bold: true,
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
};

CompanyPaymentData.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  columns: PropTypes.number,
};

export default CompanyPaymentData;
