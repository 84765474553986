import React from "react";
import { Typography } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import PropTypes from "prop-types";

function BaseViewModalDataItem(props) {
  return (
    <Typography
      className={"request-data-details__value"}
      onClick={props.onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        cursor: "grab",
      }}
    >
      {props.value}
      <img
        src={publicAssetsPath("icons/popout-svgrepo.svg")}
        alt="popout-svgrepo"
        width="auto"
      />
    </Typography>
  );
}

BaseViewModalDataItem.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default BaseViewModalDataItem;
