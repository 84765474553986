import { TableRow, TableCell, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ActionRow = ({
  colSpan,
  description,
  rowStyles,
  cellStyles,
  textStyles,
}) => {
  return (
    <TableRow sx={{ ...rowStyles }}>
      <TableCell
        colSpan={colSpan}
        style={{ padding: "15px 15px" }}
        sx={{ ...cellStyles }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontSize: "1.4rem",
            color: "grayDark.color",
            ...textStyles,
          }}
        >
          {description}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

ActionRow.propTypes = {
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  rowStyles: PropTypes.object,
  cellStyles: PropTypes.object,
  textStyles: PropTypes.object,
};

export default ActionRow;
