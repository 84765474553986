import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import EditRequestField from "modules/Laboratory/components/DataDetails/EditRequest/EditRequestField";
import PropTypes from "prop-types";
import React from "react";

function FieldEditRequestDetails({ data }) {
  const { stations } = useGetStationsWithTestData({ id: data?.inspectionId });

  return <EditRequestField data={data} stations={stations} />;
}

FieldEditRequestDetails.propTypes = {
  data: PropTypes.object,
};

export default FieldEditRequestDetails;
