import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AssignRequestToLabButton = ({
  isLab,
  assignRequestToLab,
  sendLabAssignmentDataResponse,
  setShowModal,
  isFetching,
}) => {
  const { t } = useTranslation(["dashboard"]);

  return isLab ? (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={assignRequestToLab}
      isLoading={sendLabAssignmentDataResponse.isLoading}
    >
      {t("إسناد الطلب إلى المختبر", { ns: "dashboard" })}
    </BaseButton>
  ) : (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={() => setShowModal(true)}
      isLoading={isFetching}
    >
      {t("إسناد الطلب إلى المختبر", { ns: "dashboard" })}
    </BaseButton>
  );
};

AssignRequestToLabButton.propTypes = {
  isLab: PropTypes.bool,
  assignRequestToLab: PropTypes.func,
  sendLabAssignmentDataResponse: PropTypes.object,
  setShowModal: PropTypes.func,
  isFetching: PropTypes.func,
};

export default AssignRequestToLabButton;
