import { defaultEmptyValue } from "constants/Empty";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { isNotValidServerKey } from "utils/Numbers/Numbers";

const HiddenColumn = (props) => {
  const { myHiddenColumns } = props;

  const shouldDisplayTheResult =
    props.shouldDisplayTheResult === false ? false : true;

  useEffect(() => {
    props.insertProps.insertNewPropsHandler({
      hiddenColumns: myHiddenColumns,
    });
  }, [myHiddenColumns]);

  useEffect(() => {
    function setMyHiddenColumnsHandler() {
      if (!shouldDisplayTheResult) return;
      if (
        isNotValidServerKey(
          props.customServerKey && props.customServerKey?.(props) !== true
            ? props.customServerKey?.(props)
            : props[props.tableHeaderItem.serverKey]
        )
      ) {
        props.setMyHiddenColumns((state) => {
          return [...state].concat(`${props.columnIndex}-${props.id}`);
        });
      }
    }
    setMyHiddenColumnsHandler();
  }, []);

  if (props.custom) {
    return props.custom(props[props.tableHeaderItem.serverKey]);
  }

  const displayedValue = Array.isArray(props[props.tableHeaderItem.serverKey])
    ? props[props.tableHeaderItem.serverKey]
        .map((item) => {
          return item?.[props.hiddenColumnKeyFromList || "score"];
        })
        .filter((s) => {
          return s !== null && s !== undefined;
        })
        .join(", ")
    : props[props.hiddenColumnDisplayValueSelectedKey] ??
      props[props.tableHeaderItem.serverKey];

  if (!shouldDisplayTheResult) return defaultEmptyValue;

  return <div>{displayedValue ?? defaultEmptyValue}</div>;
};

HiddenColumn.propTypes = {
  myHiddenColumns: PropTypes.array,
  insertProps: PropTypes.object,
  tableHeaderItem: PropTypes.object,
  hiddenColumnKeyFromList: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  shouldDisplayTheResult: PropTypes.bool,
  customServerKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  id: PropTypes.number,
  hiddenColumnDisplayValueSelectedKey: PropTypes.string,
  columnIndex: PropTypes.number,
  setMyHiddenColumns: PropTypes.func,
  custom: PropTypes.func,
};

export default HiddenColumn;
