import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import classes from "./BaseFancyRadioGroup.module.scss";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const BaseFancyRadio = (props) => {
  const { t } = useTranslation(["common", "dashboard"]);

  const { palette } = useTheme();

  const isActive = props.value === props.dataItem.value;

  const background = `linear-gradient(160deg, ${palette.secondary.main} -50%, ${palette.primary.main} 60%)`;

  return (
    <Box
      className={classes.fancyRadioGroup}
      sx={{
        background: isActive ? background : "",
        ...props.labelSx,
      }}
    >
      <FormControlLabel
        value={props.dataItem.value}
        control={
          <Radio
            className={classes["fancyRadioGroup__radio"]}
            sx={{
              color: "primary.colorSoft6",
              marginInlineEnd: "1rem",
              "&.Mui-checked": {
                color: "base.white",
              },
            }}
            disabled={props.disabled}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "500",
              color: isActive ? "base.white" : "grayDark.color",
            }}
          >
            {t(props.dataItem.name, { ns: "common" })}
          </Typography>
        }
      />
    </Box>
  );
};

BaseFancyRadio.propTypes = {
  value: PropTypes.string,
  dataItem: PropTypes.object,
  labelSx: PropTypes.object,
  disabled: PropTypes.bool,
};

export default BaseFancyRadio;
