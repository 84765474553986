import React from "react";
import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { displayInvoiceDetails } from "services/StaticLookup/TableHeader/TableHeader";
import { useGetInvoiceDetailsQuery } from "modules/Admin/apis/contractor-apis/companies-apis";
import { useParams } from "react-router-dom";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { adminInvoicesDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useTranslation } from "react-i18next";
import { formattedNumber } from "utils/Numbers/Numbers";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import TaxValue from "components/TableUtilitiesComponents/TableExtraRowsComponents/TaxValue/TaxValue";
import classes from "./InvoicesStatus.module.scss";

import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import ExportInvoiceButton from "./ExportInvoiceButton";

function InvoicesDetails() {
  const { t } = useTranslation(["wallet"]);

  const params = useParams();

  const { data: invoiceDetails } = useGetInvoiceDetailsQuery(params?.id);

  const formattedInvoiceDetails = [
    {
      id: "101-101-101",
      points: invoiceDetails?.points,
      cost: invoiceDetails?.cost,
    },
  ];

  if (!invoiceDetails) return <BaseLoader />;

  return (
    <>
      <DashboardIntro
        title={t(`بيانات العملية رقم ${params?.id}`, { ns: "wallet" })}
        variant="secondary"
        breadcrumbData={adminInvoicesDetailsBreadcrumb({
          id: params?.id,
          companyId: invoiceDetails?.contractor,
          name: invoiceDetails?.contractorName,
        })}
        action={<ExportInvoiceButton />}
      />
      <BaseCard
        px={"3rem"}
        py={"3rem"}
        mt={5}
        sx={{
          minHeight: "70vh",
          paddingBottom: { sm: "10rem", xs: "17rem" },
        }}
      >
        <Box mt={6} ml={4} mr={4}>
          <BaseDataWrapper mt={1} title={"بيانات المورد"}>
            <BaseDataItem label={"الاسم"} value={"شركة رساء لخدمات الاعمال"} />
            <BaseDataItem label={"الرقم الضريبي"} value={"311596767800003"} />
          </BaseDataWrapper>
          <BaseDataWrapper mt={1} title={"تفاصيل الفاتورة"}>
            <BaseDataItem
              label={"اسم العميل"}
              value={invoiceDetails?.contractorName}
            />
            <BaseDataItem label={"رقم الفاتورة"} value={invoiceDetails?.id} />
            <BaseDataItem
              label={"نوع العملية"}
              value={" شحن المحفظة"}
              valueClassName={classes["invoice-status__text"]}
            />
            <BaseDataItem
              label={"التاريخ"}
              value={`${formatDateYYYYMMDD(
                invoiceDetails?.paymentTime,
                "dddd, YYYY/MM/DD"
              )} - ${displayFormattedTimeHHMM(invoiceDetails?.paymentTime)}`}
            />
          </BaseDataWrapper>
          <BaseTableIndex
            tableHeader={displayInvoiceDetails}
            content={formattedInvoiceDetails || []}
            title={"ملخص الطلب"}
            injectCompBetweenRows={
              <TaxValue
                value={formattedNumber(invoiceDetails?.vatPercentageCost)}
              />
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "1.6rem", fontWeight: 500 }}
              color={"grayDark.color1"}
              ml={2}
            >
              التكلفة الكلية
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{ fontSize: "1.6rem", fontWeight: 500 }}
                color={"primary.colorSoft10"}
              >
                {formattedNumber(invoiceDetails?.totalCost)} ريال
              </Typography>
            </Box>
          </Box>
        </Box>
      </BaseCard>
    </>
  );
}

export default InvoicesDetails;
