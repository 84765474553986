import styles from "./TransactionContentStyle";
import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

const TransactionContent = ({ transactionData }) => {
  return (
    <>
      {transactionData.map((item) => {
        return (
          <React.Fragment key={item.subTestName}>
            <View
              style={[
                styles.tableContainer,
                { marginTop: 20, borderBottomWidth: 1 },
              ]}
            >
              <View style={styles.decContent}>
                <Text>{item.subTestName}</Text>
              </View>
            </View>
          </React.Fragment>
        );
      })}
    </>
  );
};

TransactionContent.propTypes = {
  transactionData: PropTypes.object,
};

export default TransactionContent;
