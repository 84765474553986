import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";

const IndexPageDescription = ({ setShowModal }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Typography component="span" color={"grayDark.color1"} fontSize={"1.6rem"}>
      {t("lab desc")}{" "}
      <Typography
        component="span"
        color={"primary.main"}
        fontSize={"1.6rem"}
        className="request-data-details__link"
        onClick={() => setShowModal(true)}
      >
        جدول أسعار الإختبارات{" "}
        <img
          src={publicAssetsPath("icons/popout-svgrepo.svg")}
          width="auto"
          alt="popout-prices"
        />
      </Typography>
    </Typography>
  );
};

IndexPageDescription.propTypes = {
  setShowModal: PropTypes.func,
};

export default IndexPageDescription;
