import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseFilter from "core-ui/BaseFilter/BaseFilter";
import BaseCard from "core-ui/BaseCard/BaseCard";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ContractingPointsPaymentsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { pointsPayments } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseInput from "core-ui/BaseInput/BaseInput";
import RestoreButton from "components/RestoreButton/RestoreButton";
import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { useLazyGetPaymentsQuery } from "modules/Contractor/apis/payment-apis/payment-apis";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { isContentReady } from "utils/Array/Arrays";
import { paymentFilterCriteria } from "services/StaticLookup/Filter";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const PointsPayments = () => {
  const { t } = useTranslation();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [transactionSearchValue, setTransactionSearchValue] = useState("");
  const [transactionTypeValue, setTransactionTypeValue] = useState("");

  const [getPayments, getPaymentsResponse] = useLazyGetPaymentsQuery();

  useEffect(() => {
    let timer = setTimeout(
      getPayments.bind(null, {
        query: {
          page,
          size: pageSize,
          sortId: "id,desc",
          type: transactionTypeValue,
          transactionId: transactionSearchValue,
        },
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, transactionSearchValue, transactionTypeValue]);

  const isNoSearchData =
    (transactionSearchValue || transactionTypeValue) &&
    !isContentReady(getPaymentsResponse?.data?.content);

  const isNoRefundSearchData =
    transactionTypeValue === "REFUND" &&
    !isContentReady(getPaymentsResponse?.data?.content);
  const isNoDeductSearchData =
    transactionTypeValue === "DEDUCTED" &&
    !isContentReady(getPaymentsResponse?.data?.content);

  const tableEmptyDescription = () => {
    if (isNoSearchData) {
      if (isNoRefundSearchData) {
        return t("No payments refund desc", { ns: "dashboard" });
      } else if (isNoDeductSearchData) {
        return t("No payments deduct desc", { ns: "dashboard" });
      } else {
        t("No Search Desc", { ns: "dashboard" });
      }
    } else return t("no points payments description", { ns: "dashboard" });
  };

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: t("no points payments", { ns: "dashboard" }),
    desc: tableEmptyDescription(),
  };

  const onChangeTransactionSearchValue = (v) => {
    setTransactionSearchValue(v);
  };
  const onChangeTransactionTypeValue = (v) => {
    setTransactionTypeValue(v);
  };

  const onRestore = () => {
    setTransactionSearchValue("");
    setTransactionTypeValue("");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`مدفوعات النقاط`}</title>
      </Helmet>

      <DashboardIntro
        title={`${t("points payments", {
          ns: "dashboard",
        })}`}
        description={t("points payments description", {
          ns: "dashboard",
        })}
        breadcrumbData={ContractingPointsPaymentsBreadcrumb({})}
      />
      <BaseCard mt={6} py={"3rem"} px={"3rem"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          columnGap={1.5}
        >
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            gap={"4rem"}
            flexWrap={"wrap"}
          >
            <BaseInput
              sx={{ width: "55rem" }}
              inputContainerStyles={{ mb: 0 }}
              value={transactionSearchValue}
              type="text"
              htmlFor="transaction-search"
              label="رقم العملية"
              placeholder="يمكنك البحث برقم العملية"
              name="transaction-search"
              icon={"search"}
              onChange={onChangeTransactionSearchValue}
            />
            <BaseFilter
              data={paymentFilterCriteria}
              onChange={onChangeTransactionTypeValue}
              value={transactionTypeValue}
              label={"نوع العملية"}
            />
          </Stack>
          <RestoreButton
            onRestore={onRestore}
            values={[transactionSearchValue, transactionTypeValue]}
          />
        </Stack>
      </BaseCard>
      <BaseCard mt={1} py={"3rem"} px={"3rem"}>
        <BaseTableIndex
          tableHeader={pointsPayments}
          injectProps={{ tableId: TABLE_ID.CONTRACTOR_PAYMENTS }}
          content={getPaymentsResponse?.data?.content || []}
          isLoading={getPaymentsResponse.isFetching}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/points-payments/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "عملية",
            onChangePaginationDataHandler,
            totalCount: getPaymentsResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default PointsPayments;
