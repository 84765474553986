export const InspectionLayerFlags = {
  IS_SOIL: "IS_SOIL",
  IS_ASPHALT: "IS_ASPHALT",
  IS_ABC: "IS_ABC",
  IS_CONCRETE_PRODUCTS: "IS_CONCRETE_PRODUCTS",
};

export const inspectionLayerFlagChecker = (flag, value) => {
  return flag === value;
};
