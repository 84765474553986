import { daysLookup } from "services/StaticLookup/Time";
//arabic calendar & locale
import arabic from "react-date-object/calendars/arabic";
import arabic_en from "react-date-object/locales/arabic_en";

const notDate = (date) => !date || !new Date(date).getTime();

/**
 *
 * @param {Date} date - Date object to be formatted
 * @param {string} separator - The separator between date elements MM ( / ) DD ( / ) YYYY
 * @returns {string} - It returns a formatted string of date in MM/DD/YYYY format
 */
export const formatDate = (date, separator) => {
  if (notDate(date)) return null;
  let formattedDate = new Date(date);
  let finalDateFormat = formattedDate.toLocaleDateString();
  if (separator) {
    return finalDateFormat.replaceAll("/", separator);
  }
  return finalDateFormat;
};

/**
 *
 * @param {Date} date - Date object to be formatted
 * @param {string} separator - The separator between date elements MM ( / ) DD ( / ) YYYY
 * @returns {string} - It returns a formatted string of date in Arabic MM/DD/YYYY format
 */
export const formatDateLocal = (date, separator) => {
  if (notDate(date)) return null;
  let formattedDate = new Date(date);
  let finalDateFormat = formattedDate.toLocaleDateString("ar-AR");
  if (separator) {
    return finalDateFormat.replaceAll("/", separator);
  }
  return finalDateFormat;
};

/**
 *
 * @param {Date} date - It takes a date object that convert to time string in HH:MM:SS format
 * @returns {string} - It returns a time string in format of HH:MM AM/PM in arabic
 */
//
export const displayFormattedTimeHHMM = (date) => {
  if (!date) return null;

  let formattedDate = new Date(date);
  return formattedDate.toLocaleString("ar-AR", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

/**
 *
 * @param {Date} date
 * @returns {string} - It returns a string of time in format HH:MM, without AM/PM
 */
//
export const displayFormattedTimeHHMMwithoutPMAM = (date) => {
  if (!date) return null;
  const HH_MM = { hour: "2-digit", minute: "2-digit" };
  let formattedDate = new Date(date);
  return formattedDate
    .toLocaleTimeString("en-US", HH_MM)
    .replace(/AM|PM/, "")
    .trim();
};

/**
 *
 * @param {Date} date - The date to be resetted
 * @returns {Date} - it returns the date with resetted minutes and seconds to zero but hours will be as it is
 */
export const transformDateFromISOToStandard = (date) => {
  if (notDate(date)) return null;

  const _date = new Date(date);
  const hours = _date.getHours();

  _date.setHours(hours, 0, 0);
  return _date;
};

/**
 *
 * @param {Date} date
 * @returns {string} - It returns the name of the day in arabic from daysLookup lookup from `services/StaticLookup/Time.js`
 */
export const getDayNameInArabic = (date) => {
  if (notDate(date)) return null;
  return daysLookup[new Date(date).getDay()];
};

/**
 *
 * @param {Date} date - Date object to be formatted
 * @returns {string} - It returns a formatted string of date in YYYY/MM/DD format
 */
export const formatDateYYYYMMDD = (_date) => {
  if (!_date) return null;

  const date = new Date(_date);

  const year = date.getFullYear();

  //prettier-ignore
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

  //prettier-ignore
  const day = date.getDate() < 10 ? `0${date.getDate() }` : date.getDate();

  const formattedExpiryDate = `${year}/${month}/${day}`;

  return formattedExpiryDate;
};

/**
 *
 * @returns {number} - It returns the current hijri year as number
 */
export const getHijriYearAsIntegar = () => {
  const hijriDate = new Intl.DateTimeFormat("ar-TN-u-ca-islamic", {
    year: "numeric",
  }).format(Date.now());
  return parseInt(hijriDate);
};

/**
 *
 * @param {Date} date - to be formatted date
 * @returns {string} - It returns formatted hijri date
 */
export const formatHijriDate = (date) => {
  if (notDate(date)) return null;
  return new Intl.DateTimeFormat("ar-TN-u-ca-islamic", {
    day: "numeric",
    month: "long",
    weekday: "long",
    year: "numeric",
  }).format(date);
};

/**
 *
 * @param {Date} baseCalendarDate - Its not a normal date JS object, but its the date object that you received from BaseCalendar component
 * @param {string} format - The default format returned string is YYYY-MM-DD, but you can provide any format
 * @param {boolean} isHijri - It returns hijri date string in case of true
 * @returns {string} - It returns a formatted date string by default YYYY-MM-DD
 */
export const baseCalendarDateFormatter = (
  baseCalendarDate,
  format = "YYYY-MM-DD",
  isHijri = false
) => {
  if (!baseCalendarDate) return null;
  if (isHijri) {
    return baseCalendarDate.convert(arabic, arabic_en).format(format);
  }
  return baseCalendarDate.convert().format(format);
};

/**
 *
 * @param {string} dateString
 * @returns {{hh: string | number, mm: string | number, ss: string | number}} - It returns an object that has keys of hh,mm,ss (hours, minutes, seconds)
 */
export const retrieveTimeFromHHMMSSstring = (dateString) => {
  if (!dateString || typeof dateString !== "string")
    return { hh: 0, mm: 0, ss: 0 };

  let hh = +dateString.split(":")[0] || 0;
  let mm = +dateString.split(":")[1] || 0;
  let ss = +dateString.split(":")[2] || 0;

  if (hh < 10) hh = `0${hh}`;
  if (mm < 10) mm = `0${mm}`;
  if (ss < 10) ss = `0${ss}`;

  return { hh, mm, ss };
};

/**
 *
 * @param {string} item - It's a string in time format HH:MM:SS
 * @returns {string} - It returns a string of time format HH:MM(en) AM/PM(ar)
 */
export const renderDateTimeInAMPM = (timeString) => {
  if (!timeString) return null;
  let { hh, mm } = retrieveTimeFromHHMMSSstring(timeString);
  if (hh >= 12) {
    if (hh > 12) {
      hh -= 12;
    }
    return `${hh}:${mm} م`;
  }

  let _hh = hh === "00" ? "12" : hh;

  return `${_hh}:${mm} ص`;
};

/**
 *
 * @param {Date} _date
 * @param {string} _time
 * @returns {boolean} - it returns true in case the current date and time hours/minutes > given date and given input hours/minutes
 */
export const isCurrentDate = (_date, _time) => {
  if (notDate(_date)) return null;

  const date = new Date(_date);
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  if (currentDate.getTime() === date.getTime()) {
    return isCurrentTime(_time);
  } else if (currentDate.getTime() > date.getTime()) {
    return true;
  } else {
    return false;
  }
};

/**
 *
 * @param {string | undefined} time
 * @returns {boolean} - It returns true in case of the current time larger than given time
 */
export const isCurrentTime = (time) => {
  if (!time) return null;

  const currentDate = new Date();
  const [hours, minutes] = time.split(":");

  const currentTime = new Date(
    currentDate.getFullYear(),
    0,
    1,
    +currentDate.getHours(),
    +currentDate.getMinutes()
  );
  const fomrattedTime = new Date(
    currentDate.getFullYear(),
    0,
    1,
    +hours,
    +minutes
  );

  return currentTime.getTime() > fomrattedTime.getTime();
};
