import React from "react";
import { Box } from "@mui/material";
import { retrieveTimeFromHHMMSSstring } from "utils/Date/Date";
import BaseRadioGroup from "core-ui/BaseRadioGroup/BaseRadioGroup";
import { _void } from "utils/Objects/Objects";
import PropTypes from "prop-types";

const AvailableTimes = (props) => {
  const renderLabel = (item) => {
    let { hh, mm } = retrieveTimeFromHHMMSSstring(item);
    if (hh >= 12) {
      if (hh > 12) {
        hh -= 12;
      }
      return `${hh}:${mm} م`;
    }
    return `${hh}:${mm} ص`;
  };

  const data = props.data.map((item, idx) => ({
    id: idx + 1,
    value: item,
    name: renderLabel(item),
  }));

  return (
    <Box sx={{ marginBlock: 8 }} className={"date-items-wrapper"}>
      <BaseRadioGroup
        name={props.name}
        onChange={props.onChange || _void}
        data={data}
        inputContainerStyles={{ gap: 5 }}
        inputStyles={{ width: "15rem" }}
        value={props.value}
        validation={{ errorMessage: "الاوقات المتاحة" }}
        register={props.register}
        control={props.control}
        errors={props.errors}
      />
    </Box>
  );
};

AvailableTimes.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  register: PropTypes.func,
  control: PropTypes.object,
  errors: PropTypes.object,
};

export default AvailableTimes;
