import React from "react";
import { Box, Typography } from "@mui/material";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import {
  licenseTests,
  licenseTestsLab,
  licenseTestsFieldUAT,
  licenseTestsUAT,
  subTestsTemplateSingle,
  contractorMixDesignGradationLimits,
} from "services/StaticLookup/TableHeader/TableHeader";
import Layouts from "services/Layouts";
import { bottomBorderedTableTd } from "constants/Table";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import PropTypes from "prop-types";

const LabAddCheckupTests = (props) => {
  const tableDescription = "يرجى تحديد الحد الأدنى والأعلى للاختبارات التالية";

  const mainTableContent = props.isAsphalt
    ? props.raiseNewRequestData?.testsTemplates?.filter(
        (test) =>
          !test.isSingleScore &&
          (test.isMinPassScoreRequired || test.isMaxPassScoreRequired)
      )
    : props.raiseNewRequestData?.testsTemplates;

  const getTableHeader = () => {
    if (!props.isAsphalt) {
      if (Layouts.allowAsphaltReport) {
        return licenseTestsLab;
      } else return licenseTestsFieldUAT;
    } else if (Layouts.allowAsphaltReport) {
      return licenseTests;
    } else return licenseTestsUAT;
  };
  return (
    <Box>
      <BaseAlert
        show={props.showAlert}
        my="2.3rem"
        icon={false}
        type={AlertTypes.INFO}
      >
        الاختبارات الحقلية تتطلب حجز موعد مع المختبر عند جاهزية الموقع
      </BaseAlert>
      {props.label && (
        <Typography sx={{ fontSize: "1.6rem" }} color={"grayDark.color1"}>
          {props.label}
        </Typography>
      )}

      <Box mt={4}>
        <BaseTableCard
          title={"الاختبارات"}
          description={tableDescription}
          tableHeader={getTableHeader()}
          content={mainTableContent || []}
          injectProps={{
            register: props.register,
            errors: props.errors,
            inputName: "SubTestGrade",
            applyValidationsOnInput: true,
          }}
          tableSx={{
            ...bottomBorderedTableTd,
          }}
          tableContainerSx={{ maxHeight: "100%" }}
        />
      </Box>
      {props.isAsphalt && (
        <Box mt={8}>
          <BaseTableCard
            description={"يرجى تحديد الحدود المطلوبة للاختبارات التالية"}
            tableHeader={subTestsTemplateSingle}
            content={props.raiseNewRequestData?.testsTemplates?.filter(
              (test) => test.isSingleScore
            )}
            injectProps={{
              register: props.register,
              errors: props.errors,
              prefix: "single",
              inputName: "SubTestGrade",
            }}
            tableSx={{
              ...bottomBorderedTableTd,
            }}
            tableContainerSx={{ maxHeight: "100%" }}
          />
        </Box>
      )}
      {props.isAsphalt && (
        <Box mt={6}>
          <BaseCardTitle mb={1}>Mix Design Gradation Limits</BaseCardTitle>
          <Typography mb={1} fontSize={"1.6rem"} color={"grayDark.color"}>
            يرجى إضافة الحد الأدنى و الأعلى لأحجام الــ
            <Typography
              component={"span"}
              fontSize={"inherit"}
              fontWeight={"bold"}
            >
              &nbsp;Sieve&nbsp;
            </Typography>
            التالية
          </Typography>
          <BaseTableCard
            tableHeader={contractorMixDesignGradationLimits}
            content={props.raiseNewRequestData?.subTests}
            tableSx={{
              ...bottomBorderedTableTd,
            }}
            injectProps={{
              register: props.register,
              errors: props.errors,
              inputName: "MixGrade",
              applyValidationsOnInput: false,
            }}
            tableContainerSx={{ maxHeight: "100%" }}
          />
        </Box>
      )}

      <TimelineCallToActions timeline={props.timeline} />
    </Box>
  );
};

LabAddCheckupTests.propTypes = {
  raiseNewRequestData: PropTypes.object,
  timeline: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  label: PropTypes.string,
  showAlert: PropTypes.bool,
  isAsphalt: PropTypes.bool,
};

export default LabAddCheckupTests;
