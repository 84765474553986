import { Typography } from "@mui/material";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";
import { defaultEmptyValue } from "constants/Empty";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { enLabelSx } from "constants/Typography";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";

export const drillingLicences = [
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  { serverKey: "contractorCompany", name: "contracting company" },
  {
    serverKey: "typeRefId",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
    name: "Type of license",
  },
  { serverKey: "expiryDate", name: "Expiry date" },
];

export const examinationRequests = [
  { serverKey: "id", name: "request_number" },
  { serverKey: "contractorCompanyName", name: "contracting company" },

  { serverKey: "name", name: "TestLayer" },
  {
    serverKey: "testType",
    name: "test path",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: ["appointmentDate", "appointmentTime"],
    name: "date of test or sample collected",
    format: (value, row) => {
      if (!value || !row?.appointmentTime) return defaultEmptyValue;
      return `${formatDateYYYYMMDD(value)} - ${renderLabAppointmentTimeLabel(
        row?.appointmentTime
      )}`;
    },
  },
];

export const licenses = [
  { serverKey: "licenseNumber", name: "license number" },
  {
    serverKey: "typeRefId",
    name: "النوع",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "expiryDate",
    name: "Expiry date",
    format: (date) => formatDateYYYYMMDD(date),
    fallback: "لا يوجد",
  },
];

export const consultationRequests = [
  {
    serverKey: "requestNumber",
    name: "request_number",
  },
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  {
    serverKey: "contractorCompanyName",
    name: "contracting company",
  },
  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "Request Status",
  },
];

export const consultatioSieveMixDesignGradationLimits = [
  {
    serverKey: "testName",
    name: "Sieve Size",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "",
    name: " ",
  },
  {
    serverKey: "minPassScore",
    name: "  ",
    hasComponent: { comp: <DisplayMinMaxSingleScores /> },
  },
  {
    serverKey: "status",
    name: "   ",
    hasComponent: { comp: <BaseStatus /> },
  },
  {
    serverKey: "score",
    name: "    ",
    fallback: "لا يوجد",
  },
];
