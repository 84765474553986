export const RequestCheckupBreadcrumb = (data) => [
  { to: `/home`, label: `home page` },
  { to: ``, label: `request checkup`, ns: "dashboard" },
];

export const walletBreadcrumb = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `wallet`, ns: "dashboard" },
];

export const invoicesDetailsBreadcrumb = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: `/wallet`, label: `wallet`, ns: "dashboard" },
  { to: ``, label: `العملية رقم ${data.id}`, ns: "dashboard" },
];
export const drillingLicenses = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `رخص الحفر`, ns: "dashboard" },
];

export const drillingLicensesdetails = (data) => [
  // { to: `/home`, label: `home page`, ns: "common" },
  { to: `/drilling-licenses`, label: `رخص الحفر`, ns: "dashboard" },
  {
    to: ``,
    label: ` رقم الرخصة ${data?.licenseYear || ""}/${
      data?.licenseNumber || ""
    }`,
    ns: "dashboard",
  },
];

export const AssignedRequestsDetail = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: `/assigned-requests`, label: `الطلبات المسندة`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data.id}`, ns: "dashboard" },
];

export const AssignedRequestRaiseResults = (data, isEditing) => {
  const lastPageLabel = isEditing
    ? `نموذج تعديل النتائج للطلب رقم #${data.id}`
    : `نموذج رفع النتائج للطلب رقم #${data.id}`;
  return [
    { to: `/home`, label: `home page`, ns: "common" },
    { to: `/assigned-requests`, label: `الطلبات المسندة`, ns: "dashboard" },
    {
      to: `/assigned-requests/${data.id}`,
      label: `الطلب رقم ${data.id}`,
      ns: "dashboard",
    },
    {
      to: ``,
      label: lastPageLabel,
      ns: "dashboard",
    },
  ];
};

export const NewRequestDetail = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `الطلبات`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data.id}`, ns: "dashboard" },
];
export const NewRequestIndx = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `الطلبات الجديدة`, ns: "dashboard" },
];

export const AsseigndeRequestIndx = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `الطلبات المسندة`, ns: "dashboard" },
];

export const ExaminationRequest = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلبات الفحص`, ns: "dashboard" },
];

export const ConsultantsRequest = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلبات إسناد الرخص`, ns: "dashboard" },
];

export const CertificateRequest = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلبات إصدار الشهادة`, ns: "dashboard" },
];

export const ExaminationRequests = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلب فحص`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data.id}`, ns: "dashboard" },
];

export const FAQBreadcrumb = (data) => [
  { to: `/`, label: `home page`, ns: "common" },
  { to: ``, label: `الأسئلة الشائعة`, ns: "dashboard" },
];

export const TermsAndConditionsBreadcrumb = (data) => [
  { to: `/`, label: `home page`, ns: "common" },
  { to: ``, label: `الشروط و الأحكام`, ns: "dashboard" },
];

export const GeneralInstructionsBreadcrumb = (data) => [
  { to: `/home`, label: `home page` },
  { to: ``, label: `request checkup`, ns: "dashboard" },
  { to: ``, label: `general instructions`, ns: "dashboard" },
];

export const TechReassignBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/technician-requests`, label: `technician Requests`, ns: "dashboard" },
];

export const AdminUserManagementDetailsBreadcrumb = (data) => [
  { to: `/`, label: `home page` },
  { to: `/users-management`, label: `users management`, ns: "dashboard" },
  { to: ``, label: data?.name, ns: "dashboard" },
];

export const AdminAddUserManagementBreadcrumb = (data) => [
  { to: `/`, label: `home page` },
  { to: `/users-management`, label: `users management`, ns: "dashboard" },
  { to: ``, label: "new user", ns: "dashboard" },
];

export const CompanyPaymentDetailsBreadcrumb = (data) => [
  { to: `/`, label: `home page` },
  { to: `/companies`, label: `شركات المقاولات`, ns: "dashboard" },
  {
    to: `/companies/${data?.companyId}`,
    label: `${data?.name}`,
    ns: "dashboard",
  },
  { to: ``, label: `بيانات العملية رقم ${data?.id}`, ns: "dashboard" },
];

export const ContractingCompaniesBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/companies`, label: `contracting companies`, ns: "dashboard" },
];

export const ContractingCompaniesDetailsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/companies`, label: `contracting companies`, ns: "dashboard" },
  { to: ``, label: `${data.name}`, ns: "dashboard" },
];

export const ContractingPointsPaymentsBreadcrumb = (data) => [
  { to: `/home`, label: `home page` },
  { to: {}, label: `payments`, ns: "dashboard" },
  { to: `/points-payments`, label: `points payments`, ns: "dashboard" },
];
export const ContractingPointsPaymentsDetailsBreadcrumb = (data) => [
  { to: `/home`, label: `home page` },
  { to: ``, label: `payments`, ns: "dashboard" },
  { to: `/points-payments`, label: `points payments`, ns: "dashboard" },
  { to: ``, label: `العملية رقم ${data.id}`, ns: "dashboard" },
];

export const adminInvoicesDetailsBreadcrumb = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  {
    to: `/companies/${data?.companyId}`,
    label: `${data?.name}`,
    ns: "dashboard",
  },
  { to: ``, label: `العملية رقم ${data?.id}`, ns: "dashboard" },
];

export const LabRequestsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/lab-requests`, label: `lab Requests`, ns: "dashboard" },
];

export const LabRequestsDetailsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/lab-requests`, label: `lab Requests`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];

export const AdminRequestsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/requests`, label: `Examination requests`, ns: "dashboard" },
];

export const AdminRequestsDetailsBreadcrumb = (data) => [
  { to: `/requests`, label: `Examination requests`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];

export const EditRequestsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/edit-requests`, label: `Edit Requests`, ns: "dashboard" },
];

export const EditRequestsDetailsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/edit-requests`, label: `Edit Requests`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];

export const requestLicense = () => [
  {
    to: `/consultation-requests`,
    label: `consultation requests`,
    ns: "dashboard",
  },
  { to: ``, label: `request license title`, ns: "dashboard" },
];

export const ConsultationRequestsDetailsBreadcrumb = (data) => [
  { to: `/consultation-requests`, label: `طلبات إسناد الرخص`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];

export const CertificateRequestsDetailsBreadcrumb = (data) => [
  {
    to: `/certificate-requests`,
    label: `طلبات إصدار الشهادة`,
    ns: "dashboard",
  },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];

export const ConsultantRequestsDetailsBreadcrumb = (data) => [
  { to: `/consultants-requests`, label: `طلبات إسناد الرخص`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data?.id}`, ns: "dashboard" },
];
export const ExaminationEditRequests = (data) => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلب فحص`, ns: "dashboard" },
  { to: ``, label: `الطلب رقم ${data.id}`, ns: "dashboard" },
  { to: ``, label: `تعديل طلب رقم ${data.id}`, ns: "dashboard" },
];

export const LabEditRequests = () => [
  { to: `/home`, label: `home page`, ns: "common" },
  { to: ``, label: `طلبات تعديل النتائج`, ns: "dashboard" },
];


export const ReportRequestsBreadcrumb = (data) => [
  { to: `/drilling-licenses`, label: `home page` },
  { to: `/report-requests`, label: `Report Requests`, ns: "dashboard" },
];

export const LabReportRequestsBreadcrumb = (data) => [
  { to: `/home`, label: `home page`, ns: "common"  },
  { to: `/report-requests`, label: `Report Requests`, ns: "dashboard" },
];