import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AdminUserManagementDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import BaseUserTypeTag from "core-ui/BaseUserTypeTag/BaseUserTypeTag";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { Box, Typography } from "@mui/material";
import { DATA_ID } from "constants/Details";
import { useGetUserQuery } from "modules/Admin/apis/users-management-apis/users-management-apis";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { defaultEmptyValue } from "constants/Empty";
import DashboardIntroAction from "./DashboardIntroAction";
import { _void } from "utils/Objects/Objects";

const AdminUserDetails = () => {
  const params = useParams();

  const { data: user, isFetching: isFetchingUser } = useGetUserQuery(
    params?.id,
    {
      skip: !params?.id,
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين - ${user?.name}`}</title>
      </Helmet>

      <DashboardIntro
        title={user?.name}
        isLoading={isFetchingUser}
        description={"يمكنك الاطلاع على معلومات المستخدم"}
        action={
          <DashboardIntroAction isFetchingUser={isFetchingUser} user={user} />
        }
        breadcrumbData={
          !user ? _void : AdminUserManagementDetailsBreadcrumb(user)
        }
      />

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <BaseDataWrapper
          title={"المعلومات العامة"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"الاسم"} value={user?.name} />
          <BaseDataItem
            label={"حالة الحساب"}
            valueComponentAs={"div"}
            value={
              <BaseStatus
                status={user?.isActive}
                dataId={DATA_ID.ADMIN_USERS_DETAILS}
              />
            }
          />
          {user?.role === "TECHNICIAN" && (
            <BaseDataItem
              label={"المكتب"}
              value={user?.entityName || defaultEmptyValue}
            />
          )}
          <Box>
            <Typography className={"request-data-details__label"}>
              الوظيفة
            </Typography>
            <BaseUserTypeTag type={user?.entitytype} role={user?.role} />
          </Box>
        </BaseDataWrapper>
        <hr />
        <BaseDataWrapper
          title={"معلومات التواصل"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"البريد الإلكتروني"} value={user?.email} />
          <BaseDataItem
            label={"رقم الجوال"}
            value={formatPhoneNumber(user?.mobileNumber)}
          />
        </BaseDataWrapper>
      </BaseCard>
    </>
  );
};

export default AdminUserDetails;
