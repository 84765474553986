import { Box } from "@mui/material";
import { TABLE_DISPLAY_BY } from "constants/Table";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { useGetInvoicesQuery } from "modules/Contractor/apis/payment-apis/payment-apis";
import React from "react";
import { useTranslation } from "react-i18next";
import { invoicesIndex } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useNavigate } from "react-router-dom";
import ViewDetails from "components/TableUtilitiesComponents/TableActionsComponents/ViewDetails/ViewDetails";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function InvoiceIndex() {
  const { t } = useTranslation(["wallet"]);
  const navigate = useNavigate();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data: invoicesQuery, isFetching } = useGetInvoicesQuery({
    page,
    pageSize,
  });

  const invoiceDetails = (element) => {
    navigate(`/invoice-details/${element.id}`, { state: element });
  };

  return (
    <Box mt={7}>
      <BaseTableIndex
        title={"الفواتير"}
        description={
          "تحتوي هذه القائمة على جميع الفواتير المتعلقة بعمليات شحن المحفظة."
        }
        tableHeader={invoicesIndex}
        content={invoicesQuery?.content || []}
        isLoading={isFetching}
        withAction={{
          comp: <ViewDetails label={"الفاتورة"} />,
          action: invoiceDetails,
        }}
        emptyData={{
          title: t("لا يوجد فواتير حاليًا"),
          desc: t("ستجد هنا جميع الفواتير المتعلقة بعمليات شحن المحفظة"),
          img: publicAssetsPath("icons/payments.svg"),
        }}
        pagination={{
          displayBy: TABLE_DISPLAY_BY,
          label: "طلب",
          totalCount: invoicesQuery?.totalElements,
          onChangePaginationDataHandler,
        }}
      />
    </Box>
  );
}

export default InvoiceIndex;
