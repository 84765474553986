import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { newRequestTests } from "services/StaticLookup/TableHeader/TableHeader";
import classes from "../NewRequestsDetails.module.scss";
import { DATA_ID } from "constants/Details";
import Notes from "components/Notes/Notes";
import MixDesignDetails from "modules/Laboratory/components/MixDesignDetails/MixDesignDetails";
import { useGetSamplesForLabQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import LabNewRequestData from "modules/Laboratory/components/DataDetails/NewRequestData/Lab/LabNewRequestData";
import useGetTestsAndSubTests from "hooks/useGetTestsAndSubTests";
import PropTypes from "prop-types";

const LabNewRequestsDetails = (props) => {
  const inspectionLayer = props.inspection.inspectionLayersList[0];

  const { data: samplesData } = useGetSamplesForLabQuery(props.inspection.id);

  const {
    tests: { testsTemplates, isTestsFetching, isTestsSuccess },
  } = useGetTestsAndSubTests(props.inspection);

  const testsData = testsTemplates?.[0]?.inspectionTests;
  return (
    <BaseCard mt={6} py={"5rem"} px={"7rem"}>
      <LabNewRequestData
        inspection={props.inspection}
        inspectionLayer={inspectionLayer}
        samplesData={samplesData}
        dataId={DATA_ID.LAB_NO_ASSIGN_REQUEST_DETAILS}
      />

      {inspectionLayer?.requiresAspeltReport && props.inspection.isAsphalt && (
        <Box mt={7}>
          <Box mb={3}>
            <Notes notes={inspectionLayer?.aspeltMixDesignDetailDto?.notes} />
          </Box>

          <Box mt={5}>
            <MixDesignDetails
              aspeltMixDesignDetailDto={
                inspectionLayer?.aspeltMixDesignDetailDto
              }
            />
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 5, mb: 10 }}>
        <Box>
          <Typography className={classes["new-requests-details__title"]}>
            الاختبارات
          </Typography>
        </Box>
        {isTestsSuccess && (
          <BaseTableCard
            tableHeader={newRequestTests}
            content={testsData}
            isLoading={isTestsFetching}
          />
        )}
      </Box>
    </BaseCard>
  );
};

LabNewRequestsDetails.propTypes = {
  inspection: PropTypes.object,
};

export default LabNewRequestsDetails;
