import { Box, Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import PropTypes from "prop-types";

const DisplayProctorScores = ({ proctorScores }) => {
  const { maxProctor, optimumProctor } = proctorScores;

  return (
    <Box className={"request-data-details__wrapper"} mb={5}>
      <Typography className={"request-data-details__title"}>
        البروكتر المعدل
      </Typography>
      <WrapperContentGrid columnsWidth={24} gap={4}>
        <Box className={"request-data-details__item"} pb={5}>
          <Typography className={"request-data-details__label"}>
            الكثافة الجافة العظمى
          </Typography>
          <Typography className={"request-data-details__value"}>
            {maxProctor ?? defaultEmptyValue}
          </Typography>
        </Box>
        <Box className={"request-data-details__item"}>
          <Typography className={"request-data-details__label"}>
            الرطوبة المثلى
          </Typography>
          <Typography className={"request-data-details__value"}>
            {optimumProctor ?? defaultEmptyValue}
          </Typography>
        </Box>
      </WrapperContentGrid>
      <hr />
    </Box>
  );
};


DisplayProctorScores.propTypes = {
  proctorScores: PropTypes.object,
};

export default DisplayProctorScores;
