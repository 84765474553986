import PropTypes from "prop-types";
import CategorizedTableBody from "../CategorizedTableBody/CategorizedTableBody";
import MainTableBody from "../MainTableBody/MainTableBody";

export const withMainTableBody = (BaseTable) => {
  return (props) => {
    return (
      <BaseTable {...props}>
        {(baseTableProps) => {
          return (
            <MainTableBody {...props}>
              {(tableBodyProps) => {
                return props.children({
                  ...props,
                  ...tableBodyProps,
                  renderTableCell: baseTableProps,
                });
              }}
            </MainTableBody>
          );
        }}
      </BaseTable>
    );
  };
  // Component.propTypes = {
  //   children: PropTypes.oneOfType([
  //     PropTypes.node,
  //     PropTypes.string,
  //     PropTypes.object,
  //     PropTypes.array,
  //     PropTypes.func,
  //   ]),
  // };
};

export const withCategorizedTableBody = (BaseTable) => {
  const Component = (props) => {
    return (
      <BaseTable {...props}>
        {(baseTableProps) => {
          return (
            <CategorizedTableBody {...props} renderTableCell={baseTableProps}>
              {(categorizedTableProps) => {
                return props.children({
                  ...props,
                  ...categorizedTableProps,
                  renderTableCell: baseTableProps,
                });
              }}
            </CategorizedTableBody>
          );
        }}
      </BaseTable>
    );
  };
  Component.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.func,
    ]),
  };
  return <Component />;
};
