import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { _void } from "utils/Objects/Objects";
import PropTypes from "prop-types";

const ImagePreview = (props) => {
  return (
    <Box
      width={"18rem"}
      height={"20rem"}
      borderRadius={"5px"}
      position={"relative"}
      overflow={"hidden"}
    >
      <Box
        sx={{ height: "100%" }}
        onClick={
          props.isUploading ? _void : props.onDisplay?.bind(null, props.photo)
        }
      >
        <img
          src={props.photo}
          alt={`photo-${props.idx}`}
          className="img-fit"
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "4rem",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          background:
            props.isReadonlyMode || props.isUploading ? "" : "#ddfffba6",
        }}
      >
        {!props.isReadonlyMode && !props.isUploading && (
          <CloseIcon
            onClick={props.onDelete.bind(null, props.idx)}
            fontSize="large"
            sx={{
              cursor: "pointer",
              color: "primary.main",
              fontWeight: "bold",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

ImagePreview.propTypes = {
  onDisplay: PropTypes.func,
  onDelete: PropTypes.func,
  photo: PropTypes.string,
  isUploading: PropTypes.bool,
  isReadonlyMode: PropTypes.bool,
  idx: PropTypes.number,
};

export default ImagePreview;
