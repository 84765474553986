import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import { useForm } from "react-hook-form";
import SieveTable from "../../AssignedRequestsDetails/Lab/SieveTable/SieveTable";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import LabAttachment from "../../AssignedRequestsDetails/Lab/LabAttachment/LabAttachment";
import BaseButton from "core-ui/BaseButton/BaseButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { convertFileToBase64 } from "utils/File/File";
import { valueChecker } from "utils/Numbers/Numbers";
import {
  useCheckEditableForLaboratoryQuery,
  useEditLabTestRequestMutation,
  useGetInspectionForLabQuery,
  useGetInspectionTestsDataForLabQuery,
  useGetSamplesForLabQuery,
  useGetSoilClassificationsQuery,
  useGetSubTestsForLabQuery,
  useSaveDraftLabTestMutation,
  useSubmitAssignedRequestMutation,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";

import { useTranslation } from "react-i18next";
import { _scrollToTop, scrollToNonInputElement } from "utils/DOM/DOM";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import useAlert from "hooks/useAlert";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AssignedRequestRaiseResults } from "services/StaticLookup/Breadcrumb";
import { EditableStatusEnum } from "services/StaticLookup/EditableStatus";
import BaseTextArea from "core-ui/BaseTextArea/BaseTextArea";
import BaseModal from "core-ui/BaseModal/BaseModal";
import LabEditRequestModal from "components/AllModalContent/LabEditRequestModal/LabEditRequestModal";
import {
  assignedRequestTestsLabTestForm,
  sampleGradationTestsLabTestForm,
} from "services/StaticLookup/TableHeader/TableHeader";
import { AlertTypes } from "constants/AlertTypes";
import BaseInput from "core-ui/BaseInput/BaseInput";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

let navigatedUserTimer;

const LabAssignedRequestForm = () => {
  // this works for disabling the inputs if the user clicked on saveDraft button
  const [enableDrafting, setEnableDrafting] = useState(true);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const raiseScoresSubmitButtonRef = useRef(null);

  const { t } = useTranslation(["dashboard"]);

  const params = useParams();

  const location = useLocation();

  const { state } = location;

  const isEditing = state?.isEditing;

  const navigate = useNavigate();

  const { data: inspection, isFetching: isInspectionFetching } =
    useGetInspectionForLabQuery(params.id);

  const { data: checkEditable } = useCheckEditableForLaboratoryQuery(
    inspection?.id,
    {
      skip: !inspection?.id,
    }
  );

  const showEditLabRequestModal = () => {
    setShowConfirmModal(true);
  };

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const { data: samplesData, isFetching: isSamplesDataFetching } =
    useGetSamplesForLabQuery(inspection?.id, { skip: !inspection?.id });

  // MAKE A CUSTOM HOOK TO GET BOTH TESTs and SUBTESTs if available
  const { data: testsTemplates, isFetching: isTestsTemplatesFetching } =
    useGetInspectionTestsDataForLabQuery(inspection?.id, {
      skip: !inspection?.id,
    });

  const transformedTestsTemplates = testsTemplates?.[0];

  const hasSubTestsTest = transformedTestsTemplates?.inspectionTests?.find(
    (test) => test.containSubtests
  );

  const { data: subTests, isFetching: isSubTestsFetching } =
    useGetSubTestsForLabQuery(hasSubTestsTest?.id, {
      skip: !hasSubTestsTest?.id,
    });

  // prettier-ignore
  const isSubmitted = !!inspection?.submittedAt;

  const receivedByLabAt = samplesData?.receivedByLabAt;

  const inspectionLayer = inspection?.inspectionLayersList[0];

  // prettier-ignore
  const isDraftedNotSubmitted = inspection?.isRequestInitialSubmissionDrafted && !inspection?.submittedAt;

  const [submitLabTest, submitLabTestResponse] =
    useSubmitAssignedRequestMutation();

  const {
    alert: labDraftAlert,
    showAlertHandler: showLabDraftAlertHandler,
    hideAlertHandler: hideLabDraftAlertAlertHandler,
  } = useAlert();

  const {
    data: soilClassifications,
    isFetching: isSoilClassificationFetching,
  } = useGetSoilClassificationsQuery();

  const selectedSoilClassification = soilClassifications?.find(
    (classification) =>
      classification.name === inspectionLayer?.soilClassification
  );

  const [saveDraftLabTest, saveDraftLabTestResponse] =
    useSaveDraftLabTestMutation();

  const [editLabTestRequest, editLabTestRequestResponse] =
    useEditLabTestRequestMutation();

  const shouldBeNavigatedBack =
    (checkEditable === EditableStatusEnum.TEST_NOT_COMPLETED && isSubmitted) ||
    (checkEditable !== EditableStatusEnum.CAN_EDIT &&
      checkEditable !== EditableStatusEnum.TEST_NOT_COMPLETED);

  const isComponentFetching =
    isInspectionFetching ||
    isSamplesDataFetching ||
    isTestsTemplatesFetching ||
    isSubTestsFetching ||
    isSoilClassificationFetching;

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );
  const isSoil = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_SOIL
  );
  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    clearErrors,
    reset,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const areCTAsDisabled = false;

  const areInputsDisabled = !enableDrafting && isDraftedNotSubmitted;

  const formValues = watch();

  const onClickSubmit = () => {
    return isEditing ? showEditLabRequestModal() : onConfirmEditHandler();
  };

  async function edit(body) {
    return editLabTestRequest(body);
  }
  async function draft(body) {
    return saveDraftLabTest(body);
  }
  function submit() {
    submitLabTest(inspection?.id);
  }

  const onSubmitHandler = (data, e) => {
    let callback = submit;
    if (isEditing) {
      callback = null;
    }
    onDraftHandler(callback);
  };

  const timerHandler = () => {
    navigate(`/assigned-requests/${inspection?.id}`, {
      state: {
        navigatedUserTimer,
      },
    });
  };

  const onDraftHandler = (cb) => {
    let inspectionSubTests = subTests?.inspectionSubTests.map((subTest) => {
      return {
        id: subTest.id,
        score: valueChecker(formValues[`sieve-${subTest.id}`]),
      };
    });

    const labResultsForServer = transformedTestsTemplates?.inspectionTests.map(
      (test) => {
        return {
          id: test.id,
          subTest: !!test.containSubtests,
          score: valueChecker(formValues[`score-${test.id}`]),
          ...(test.containSubtests && {
            inspectionSubTestDTOList: inspectionSubTests,
          }),
        };
      }
    );

    const soilClassificationId = formValues.soilClassificationId?.id;

    let serverData = {
      inspectionId: inspection?.id,
      id: inspectionLayer?.id,
      inspectionTestDTOList: labResultsForServer,
      soilClassificationId: soilClassificationId,
      ...(isABC && { soilGrade: formValues.soilGrade }),
    };

    const mutationFn = (extraData) =>
      isEditing
        ? edit({
            ...serverData,
            ...extraData,
            editReason: formValues?.editReason,
          })
        : draft({ ...serverData, ...extraData });

    // SUBMIT DATA
    if (formValues.attachment?.file) {
      convertFileToBase64(formValues.attachment.file).then((base64File) => {
        mutationFn({ attachment: base64File })
          .then(() => {
            setEnableDrafting(false);

            let message = isEditing
              ? SuccessCodes.SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM
              : SuccessCodes.SUCCESS_ONLY_DRAFT_REQUEST_FORM;

            if (cb) {
              message = SuccessCodes.SUCCESS_SUBMIT_RESULTS;
              cb();
            }

            showLabDraftAlertHandler({
              show: true,
              type: AlertTypes.SUCCESS,
              message: message,
            });
          })
          .catch((err) => {
            console.log("err: ", err);
            showLabDraftAlertHandler({
              show: true,
              type: AlertTypes.ERROR,
              message: err.data?.errorCode || ErrorCodes.UNKNOWN_ERROR,
            });
          })
          .finally(() => {
            navigatedUserTimer = setTimeout(timerHandler, 3000);
            _scrollToTop();
          });
      });
    } else {
      mutationFn({
        attachment: null,
      })
        .then(() => {
          setEnableDrafting(false);

          let message = isEditing
            ? SuccessCodes.SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM
            : SuccessCodes.SUCCESS_ONLY_DRAFT_REQUEST_FORM;

          if (cb) {
            message = SuccessCodes.SUCCESS_SUBMIT_RESULTS;
            cb();
          }

          showLabDraftAlertHandler({
            show: true,
            type: AlertTypes.SUCCESS,
            message,
          });
        })
        .catch((err) => {
          console.log("err: ", err);
          showLabDraftAlertHandler({
            show: true,
            type: AlertTypes.ERROR,
            message: err.data?.errorCode || ErrorCodes.UNKNOWN_ERROR,
          });
        })
        .finally(() => {
          navigatedUserTimer = setTimeout(() => {
            navigate(`/assigned-requests/${inspection?.id}`, {
              state: {
                navigatedUserTimer,
              },
            });
          }, 3000);
          _scrollToTop();
        });
    }
  };

  const onErrorHandler = (errors) => {
    console.log("errors: ", errors);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToNonInputElement(errors);
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (selectedSoilClassification && !isABC) {
      setValue("soilClassificationId", {
        id: selectedSoilClassification?.id,
        name: selectedSoilClassification?.name,
      });
    }
    if (isABC) {
      setValue("soilGrade", inspectionLayer?.soilGrade);
    }
  }, [selectedSoilClassification, isABC, inspectionLayer]);

  useEffect(() => {
    if (!isSamplesDataFetching && !isInspectionFetching) {
      if (shouldBeNavigatedBack) {
        navigate(-1);
      }
    }
  }, [
    checkEditable,
    receivedByLabAt,
    isInspectionFetching,
    shouldBeNavigatedBack,
    isSamplesDataFetching,
    inspection?.submittedAt,
  ]);

  const onConfirmEditHandler = () => {
    raiseScoresSubmitButtonRef.current.click();
  };

  const isCTAsLoading =
    saveDraftLabTestResponse.isLoading ||
    editLabTestRequestResponse.isLoading ||
    submitLabTestResponse.isLoading;

  const draftButton = (
    <BaseButton
      sx={{ minWidth: "12.2rem" }}
      disabled={isCTAsLoading || areInputsDisabled}
      isLoading={isCTAsLoading || areInputsDisabled}
      variant="secondary"
      onClick={onDraftHandler.bind(null, null)}
    >
      {t("حفظ كمسودة")}
    </BaseButton>
  );

  const submitButton = (
    <BaseButton
      onClick={onClickSubmit}
      disabled={isCTAsLoading || areInputsDisabled}
      isLoading={isCTAsLoading || areInputsDisabled}
      sx={{
        minWidth: "12.2rem",
      }}
    >
      {t("إرسال")}
    </BaseButton>
  );

  const cancelButton = (
    <BaseButton
      sx={{ minWidth: "12.2rem" }}
      onClick={() => navigate(-1)}
      variant="secondary"
      disabled={isCTAsLoading || areInputsDisabled}
      isLoading={isCTAsLoading || areInputsDisabled}
    >
      إلغاء
    </BaseButton>
  );

  const dashboardIntroTitle = isEditing
    ? `نموذج تعديل النتائج للطلب رقم ${inspection?.id}#`
    : `نموذج رفع النتائج للطلب رقم ${inspection?.id}#`;

  const dashboardIntroDescription = isEditing
    ? `يمكنك طلب تعديل النتائج من هذه الصفحة، حيث سيتم ارسال طلب التعديل لمدير النظام ليتم اعتماده`
    : `يرجى تعبئة النموذج التالي، كما يمكنك حفظ المدخلات بشكل مؤقت في حال رغبتك في إتمام النموذج لاحقًا.`;

  if (isComponentFetching) return <BaseLoader />;

  return (
    <>
      <BaseModal
        show={showConfirmModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <LabEditRequestModal
          onConfirm={onConfirmEditHandler}
          requestId={inspection?.id}
          onHide={onLeavePageHandler}
        />
      </BaseModal>
      <DashboardIntro
        title={dashboardIntroTitle}
        description={dashboardIntroDescription}
        variant={"secondary"}
        action={
          <BaseAlert
            sx={{ mb: 4 }}
            show={labDraftAlert.show}
            type={labDraftAlert.type}
            message={labDraftAlert.message}
            destroy={hideLabDraftAlertAlertHandler}
            autoClose={5}
          />
        }
        breadcrumbData={AssignedRequestRaiseResults(
          { id: inspection?.id },
          isEditing
        )}
      />
      <BaseCard
        mt={3}
        py={"auto"}
        px={"2.5rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
          paddingTop: "4.6rem",
          paddingBottom: { sm: "5rem", xs: "1rem" },
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          {isABC && (
            <Box sx={{ display: "flex", mb: 5 }}>
              <BaseInput
                inputContainerStyles={{ marginBottom: "0px" }}
                htmlFor="soilGrade"
                sx={{ width: "35rem" }}
                label={t("soil grade")}
                placeholder={t("soil grade")}
                maxLength={50}
                labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
                name="soilGrade"
                register={{
                  ...register("soilGrade", {
                    required: {
                      value: true,
                      message: `${t("field")} ${t("required")}`,
                    },
                  }),
                }}
                errors={errors}
              />
            </Box>
          )}
          {isSoil && (
            <Box sx={{ display: "flex", mb: 5 }}>
              <BaseDropdown
                name="soilClassificationId"
                control={control}
                required
                selectMenuStyles={{ height: "16rem !important" }}
                errors={errors}
                label={"تصنيف التربة"}
                labelStyles={{
                  color: "grayDark.color",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
                width="35rem"
                placeholder="يرجى تحديد تصنيف التربة"
                data={soilClassifications || []}
                value={formValues?.soilClassificationId}
                disabled={areInputsDisabled || areCTAsDisabled}
              />
            </Box>
          )}
          <BaseTableDoubleCard
            title="يرجى إدخال نتائج الاختبارات التالية "
            description={" "}
            doubleLayer={(element) => ({
              visibility: inspectionLayer?.requiresAspeltReport && isAsphalt,
              component: (
                <SieveTable
                  tableHeader={sampleGradationTestsLabTestForm}
                  content={subTests?.inspectionSubTests}
                  injectProps={{
                    inputName: "sieve",
                    testsTemplates: transformedTestsTemplates?.inspectionTests,
                    errors,
                    register,
                    clearErrors,
                    reset,
                    formValues,
                    setValue,
                    dirtyFields,
                    areInputsDisabled,
                    areCTAsDisabled,
                  }}
                />
              ),
            })}
            withSpecificAction={(element, triggeredElements) => ({
              condition: element?.containSubtests,
              actionType: element?.containSubtests ? "dropdown" : "",
              component: (
                <KeyboardArrowDownIcon
                  color="primary"
                  fontSize="large"
                  sx={{
                    marginInline: "1rem",
                    cursor: "pointer",
                    transition: "all .5s ease",
                    transform: triggeredElements?.[element.id]
                      ? "rotate(180deg)"
                      : "",
                  }}
                />
              ),
            })}
            tableContainerSx={{ maxHeight: "100%" }}
            tableSx={{
              border: {
                tableCell: "0.1rem solid",
                color: "#f9f9f9",
                borderInlineColor: "transparent",
              },
            }}
            tableHeader={assignedRequestTestsLabTestForm}
            content={transformedTestsTemplates?.inspectionTests}
            isLoading={isTestsTemplatesFetching}
            injectProps={{
              inputName: "score",
              errors,
              register,
              setValue,
              formValues,
              areInputsDisabled,
              areCTAsDisabled,
              clearErrors,
            }}
          />
          <BaseCardTitle mt={10}>{"ملف نتائج الاختبارات"}</BaseCardTitle>
          <LabAttachment
            isEditing={enableDrafting}
            form={{
              errors,
              control,
              formValues,
            }}
            disabled={areCTAsDisabled || saveDraftLabTestResponse.isLoading}
          />

          <Box mt={5}>
            {isEditing && (
              <BaseTextArea
                inputContainerStyles={{ mt: 5 }}
                textAreaStyles={{ padding: "2rem" }}
                control={control}
                name={"editReason"}
                label={"سبب التعديل"}
                maxLength={800}
                rules={{
                  required: {
                    value: true,
                    message: `الحقل مطلوب`,
                  },
                  maxLength: {
                    value: 800,
                    message: `تجاوزت الحد المسموح`,
                  },
                }}
                htmlFor={"editReason"}
                placeholder={"يرجى كتابة سبب التعديل"}
                errors={errors}
              />
            )}
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              gap={5}
              mt={5}
              mb={3}
            >
              {!isEditing && draftButton}
              {isEditing && cancelButton}
              {submitButton}
            </Box>
          </Box>

          <BaseButton
            ref={raiseScoresSubmitButtonRef}
            type="submit"
            sx={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}
          >
            HIDDEN SUBMIT BUTTON
          </BaseButton>
        </form>
      </BaseCard>
    </>
  );
};

export default LabAssignedRequestForm;
