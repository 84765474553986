import { TestTypes } from "constants/TestTypes";
import { useGetTestsTemplatesMutation } from "modules/Contractor/apis/inspection-apis/inspection-api";
import { useDispatch } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import { getTestsTemplateData } from "utils/Station/Station";

const useGetTestsTemplatesForFieldTest = () => {
  const dispatch = useDispatch();

  // 3. API call for getting "Contractor Layers Test Templates"
  const [getContractorTestsTemplates] = useGetTestsTemplatesMutation();

  const getTestTemplateField = async (formData) => {
    const testsTemplatesList = getTestsTemplateData(formData);
    const fetchedTestsTemplates = [];

    if (testsTemplatesList.length !== 0 && testsTemplatesList[0]?.id) {
      for await (const test of testsTemplatesList) {
        try {
          const responseData = await getContractorTestsTemplates({
            body: { testType: TestTypes.FIELD_TEST },
            layerId: test.id,
          }).unwrap();

          fetchedTestsTemplates.push(responseData[0]);
        } catch (err) {
          console.log("err: ", err);
          return false;
        }
      }

      dispatch(
        contractorActions.saveRaiseNewRequestData({
          data: {
            testsTemplates: fetchedTestsTemplates,
          },
        })
      );

      return fetchedTestsTemplates.length === testsTemplatesList.length;
    }
  };

  return { getTestTemplateField };
};

export default useGetTestsTemplatesForFieldTest;
