import React from "react";
import { CompanyInvoicesHeader } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Box } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { isContentReady } from "utils/Array/Arrays";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";

function CompanyInvoices(props) {
  const { t } = useTranslation(["dashboard"]);

  const isNoSearchData =
    props?.paymentId &&
    !isContentReady(props?.getCompanyInvoicesResponse?.data?.content);

  const emptyTitle = isNoSearchData ? t("No Search Payment") : t("No invoices");

  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No invoices desc");

  return (
    <Box mt={4}>
      <BaseInput
        sx={{ width: `35rem` }}
        htmlFor="paymentId"
        type="number"
        placeholder={"يمكنك البحث برقم العملية"}
        name="paymentId"
        icon="search"
        onChange={props?.setPaymentId}
      />
      <BaseTableIndex
        tableHeader={CompanyInvoicesHeader}
        injectProps={{ tableId: TABLE_ID.COMPANY_INVOICES }}
        content={props?.getCompanyInvoicesResponse?.data?.content || []}
        isLoading={props?.getCompanyInvoicesResponse?.isFetching}
        emptyData={{
          title: emptyTitle,
          desc: emptyDesc,
          img: publicAssetsPath("images/documents2.svg"),
        }}
        withDetails={{
          paramsKey: "id",
          to: (id) => `/companies/${props?.companyId}/invoices/${id}`,
          //   state: { },
        }}
        pagination={{
          displayBy: TABLE_DISPLAY_BY,
          label: "فاتورة",
          onChangePaginationDataHandler: props?.onChangePaginationDataHandler,
          totalCount: props?.getCompanyInvoicesResponse?.data?.totalElements,
        }}
      />
    </Box>
  );
}
CompanyInvoices.propTypes = {
  getCompanyInvoicesResponse: PropTypes.object,
  onChangePaginationDataHandler: PropTypes.func,
  paymentId: PropTypes.string,
  companyId: PropTypes.string,
  setPaymentId: PropTypes.func,
};

export default CompanyInvoices;
