import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const FormTitle = (props) => {
  return (
    <Typography
      mb={4}
      fontSize={20}
      fontWeight={500}
      sx={{ color: "grayDark.color" }}
    >
      {props.title}
    </Typography>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string,
};

export default FormTitle;
