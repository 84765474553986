import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enableDrafting: false,
};

const laboratorySlice = createSlice({
  name: "laboratory",
  initialState,
  reducers: {
    enableDraftingHandler: (state) => {
      state.enableDrafting = true;
    },
    disableDraftingHandler: (state) => {
      state.enableDrafting = false;
    },
  },
});

export const laboratoryActions = laboratorySlice.actions;

export default laboratorySlice.reducer;
