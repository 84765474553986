import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";

const SieveTable = (props) => {
  return (
    <BaseTableCard
      tableSx={{
        border: {
          tableCell: "0.1rem solid",
          color: "#f9f9f9",
          borderInlineColor: "transparent",
        },
      }}
      tableContainerSx={{ maxHeight: "100%" }}
      tableHeader={props.tableHeader}
      content={props.content}
      injectProps={{ ...props.injectProps }}
    />
  );
};

SieveTable.propTypes = {
  tableHeader: PropTypes.array,
  content: PropTypes.array,
  injectProps: PropTypes.object,
};

export default SieveTable;
