import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const WrapperContentGrid = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = matches ? props.columnsWidth / 0.8 : props.columnsWidth;
  const gap = `calc(${props.gap || 0.2} * 1vw)`;
  const gapX = `calc(${props.gapX || 0.2} * 1vw)`;
  const gapY = `calc(${props.gapY || 0.2} * 1vw)`;

  const wrapperContentGridSx = {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(${columns}rem, 1fr))`,
    gridTemplateRows: props.rows ? `repeat(${props.rows}, 1fr)` : null,
    gap: gap,
    rowGap: props.gapY ? gapY : "auto",
    columnGap: props.gapX ? gapX : "auto",
  };

  return (
    <Box sx={{ ...props.sx, ...wrapperContentGridSx }}>{props.children}</Box>
  );
};

WrapperContentGrid.propTypes = {
  gap: PropTypes.number,
  gapX: PropTypes.number,
  gapY: PropTypes.number,
  rows: PropTypes.number,
  sx: PropTypes.object,
  columnsWidth: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default WrapperContentGrid;
