import { Box } from "@mui/material";
import { forwardRef } from "react";
import "./BaseVerticalTimeline.scss";
import PropTypes from "prop-types";

const BaseVerticalTimeline = forwardRef((props, ref) => {
  if (!props.children) return null;
  return (
    <Box className={"vertical-timeline"}>
      <span></span>
      <Box sx={props.sx} className={"vertical-timeline__list"} ref={ref}>
        {props.children}
      </Box>
    </Box>
  );
});

BaseVerticalTimeline.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default BaseVerticalTimeline;
