import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import AddCheckupRequestButton from "./AddCheckupRequestButton";
import ViewCertificateButton from "./ViewCertificateButton";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const PageActionButton = (props) => {
  const { userType, userRole } = useSelector((store) => store.auth);

  function render() {
    if (props.isFetching || !sysopsContractorRoleChecker(userType, userRole)) {
      return null;
    } else if (props.licenseData?.certificateIssued) {
      return (
        <ViewCertificateButton
          licenseData={props.licenseData}
          showAlertHandler={props.showAlertHandler}
        />
      );
    } else {
      return (
        <AddCheckupRequestButton
          licenseData={props.licenseData}
          onClick={props.onClick}
          setShowConfirmModal={props.setShowConfirmModal}
        />
      );
    }
  }

  return <Box>{render()}</Box>;
};

PageActionButton.propTypes = {
  isFetching: PropTypes.bool,
  licenseData: PropTypes.object,
  onClick: PropTypes.func,
  setShowConfirmModal: PropTypes.func,
  showAlertHandler: PropTypes.func,
};

export default PageActionButton;
