import React, { useState, useEffect } from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { Helmet } from "react-helmet";
import SettingsForm from "modules/Contractor/components/Settings/SettingsForm";
import SettingsView from "modules/Contractor/components/Settings/SettingsView";
import useAlert from "hooks/useAlert";
import {
  useGetFullContractorDataQuery,
  useGetUserInfoQuery,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { AlertTypes } from "constants/AlertTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseButton from "core-ui/BaseButton/BaseButton";

function Settings() {
  const { t } = useTranslation(["common"]);
  const { alert, showAlertHandler } = useAlert();
  const {
    alert: updateAlert,
    showAlertHandler: showUpdateAlertHandler,
    hideAlertHandler: hideUpdateAlertHandler,
  } = useAlert();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const { data: userInfo } = useGetUserInfoQuery();
  const { data: companyData, isLoading } = useGetFullContractorDataQuery();

  useEffect(() => {
    if (companyData && !companyData?.isProfileComplete) {
      setIsEditMode(true);
      setIsProfileComplete(false);
      showAlertHandler({
        show: true,
        message: "PROFILE_INCOMPLETE",
        type: AlertTypes.WARNING,
      });
    }
    if (companyData?.isProfileComplete) {
      setIsProfileComplete(true);
    }
  }, [companyData]);

  const onEditBtnClick = () => {
    setIsEditMode(true);
  };

  return (
    <>
      <Helmet>
        <title>{`الإعدادات`}</title>
      </Helmet>

      <DashboardIntro
        title={t("بيانات الحساب", { ns: "common" })}
        descriptionSx={{ maxWidth: "60%" }}
        withAlertHandler={{
          comp: (
            <BaseAlert
              show={updateAlert.show}
              type={updateAlert.type}
              labelSx={{ fontWeight: "bold" }}
              message={updateAlert.message}
              autoClose={5}
              destroy={hideUpdateAlertHandler}
            />
          ),
        }}
        action={
          isEditMode ? (
            <BaseAlert
              show={alert.show}
              type={alert.type}
              labelSx={{ fontWeight: "bold" }}
              message={alert.message}
              icon={false}
            />
          ) : (
            <BaseButton
              variant={"secondary"}
              sx={{ minWidth: "17.3rem" }}
              onClick={onEditBtnClick}
            >
              تعديل
            </BaseButton>
          )
        }
      />

      {isLoading && <BaseLoader />}

      <Box mt={1}>
        {isEditMode ? (
          <SettingsForm
            companyData={companyData}
            userInfo={userInfo}
            t={t}
            setIsEditMode={setIsEditMode}
            hideDisabled={!isProfileComplete}
            showAlertHandler={showUpdateAlertHandler}
          />
        ) : (
          <SettingsView companyData={companyData} userInfo={userInfo} />
        )}
      </Box>
    </>
  );
}

export default Settings;
