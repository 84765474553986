import { Box, Typography } from "@mui/material";

import { ReactComponent as AddImageSvg } from "assets/icons/add-image.svg";
import PropTypes from "prop-types";

function specialDottedBorder(palette) {
  return {
    background: `${palette.background.colorSoft8}`,
    backgroundPosition: `0 0, 0 0, 100% 0, 0 100%`,
    backgroundSize: `1px 100%, 100% 1px, 1px 100% , 100% 1px`,
    backgroundRepeat: `no-repeat`,
    backgroundImage: `
                    repeating-linear-gradient(45deg,  ${palette.primary.colorSoft13}, ${palette.primary.colorSoft13} 6px, transparent 6px, transparent 12px), // left
                    repeating-linear-gradient(135deg, ${palette.primary.colorSoft13}, ${palette.primary.colorSoft13} 6px, transparent 6px, transparent 12px), // top
                    repeating-linear-gradient(225deg, ${palette.primary.colorSoft13}, ${palette.primary.colorSoft13} 6px, transparent 6px, transparent 12px), // right
                    repeating-linear-gradient(315deg, ${palette.primary.colorSoft13}, ${palette.primary.colorSoft13} 6px, transparent 6px, transparent 12px) // bottom
                ;`,
  };
}
const AddImage = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "18rem",
        height: "20rem",
        cursor: "pointer",
        borderRadius: "5px",
        ...specialDottedBorder(props.palette),
      }}
      onClick={props.onOpenCamera}
    >
      <AddImageSvg />

      <Typography
        fontSize={"1.2rem"}
        fontWeight={500}
        color={`text.tags.blueSoft`}
        mt={"1.6rem"}
      >
        إضافة صورة للعينة
      </Typography>
    </Box>
  );
};

AddImage.propTypes = {
  palette: PropTypes.object,
  onOpenCamera: PropTypes.func,
};

export default AddImage;
