import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import classes from "./BaseFilter.module.scss";
import PropTypes from "prop-types";

const BaseFilter = (props) => {
  const [filterBy, setFilterBy] = useState(props.data?.[0].value);

  const filterHandler = (value) => {
    setFilterBy(value);
  };

  useEffect(() => {
    if (!props.value) {
      props.onChange(props.data?.[0].value);
      setFilterBy(props.data?.[0].value);
    }
  }, [props.value]);

  useEffect(() => {
    props.onChange(filterBy);
  }, [filterBy]);

  return (
    <Box className={classes.filter}>
      {props.label && (
        <Typography
          fontSize={"1.4rem"}
          mb={"1.7rem"}
          fontWeight={500}
          color={"grayDark.color"}
        >
          {props.label}
        </Typography>
      )}
      <Stack className={classes["filter__wrapper"]} direction={"row"}>
        {props.data?.length > 0 &&
          props.data.map((filter) => {
            return (
              <Box
                key={filter.id}
                className={`${classes["filter__item"]} ${
                  filterBy === filter.value
                    ? classes["filter__item--active"]
                    : undefined
                }`}
                onClick={filterHandler.bind(null, filter.value)}
              >
                <Typography className={classes["filter__item-label"]}>
                  {filter.label}
                </Typography>
              </Box>
            );
          })}
      </Stack>
    </Box>
  );
};

BaseFilter.propTypes = {
  data: PropTypes.array,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default BaseFilter;
