import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import { useGetTestsForConsultantQuery } from "modules/Consultant/apis/consultant-apis/contractor-apis";
import { useGetSubTestsForConsultantQuery } from "modules/Consultant/apis/consultant-apis/inspection-apis/request-tests";
import ExaminationDataLab from "modules/Consultant/components/ExaminationData/Lab/ExaminationDataLab";
import { useGetAspeltBaseCourseAttachmentQuery } from "modules/Contractor/apis/inspection-apis/asphalt-api";
import PropTypes from "prop-types";
import React from "react";

function LabExaminationRequests({
  requestDetails,
  dataId,
  labTestAppointment,
}) {
  const inspectionLayer = requestDetails.inspectionLayersList?.[0] ?? undefined;

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  const { data: inspectionTests, isFetching: isInspectionTestsFetching } =
    useGetTestsForConsultantQuery(requestDetails?.id);

  const hasSubTestsTest = inspectionTests?.map((inspection) =>
    inspection?.inspectionTests?.find((test) => test?.containSubtests)
  );
  const hasSubTestsTestId = hasSubTestsTest?.[0]?.id ?? undefined;

  const {
    data: AspeltBaseCourseAttachment,
    isFetching: isAspeltBaseCourseAttachmentFetching,
  } = useGetAspeltBaseCourseAttachmentQuery(inspectionLayer?.id, {
    skip: !isAsphalt,
  });

  const { data: subTests, isFetching: isSubTestsFetching } =
    useGetSubTestsForConsultantQuery(hasSubTestsTestId, {
      skip: !hasSubTestsTestId,
    });

  return (
    <ExaminationDataLab
      request={requestDetails}
      inspectionLayer={inspectionLayer}
      inspectionTestsData={inspectionTests}
      AspeltBaseCourseAttachment={AspeltBaseCourseAttachment}
      asphalet={isAsphalt}
      isABC={isABC}
      dataId={dataId}
      isFetching={
        isInspectionTestsFetching ||
        isAspeltBaseCourseAttachmentFetching ||
        isSubTestsFetching
      }
      labTestAppointment={labTestAppointment}
      subTests={subTests?.inspectionSubTests}
    />
  );
}

LabExaminationRequests.propTypes = {
  requestDetails: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labTestAppointment: PropTypes.object,
};

export default LabExaminationRequests;
