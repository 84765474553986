import { useEffect } from "react";
import { useLazyGetTechnicianDataQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import AssignedRequestTestsFeild from "./AssignedRequestTestsFeild/AssignedRequestTestsFeild";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import PropTypes from "prop-types";

const FieldAssignedRequestTests = (props) => {
  const { inspection } = props;

  const inspectionLayer = inspection.inspectionLayersList[0];

  const [getTechnicianData, technicianData] = useLazyGetTechnicianDataQuery();

  useEffect(() => {
    getTechnicianData(inspection.id);
  }, [inspection]);

  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData(inspection);

  return (
    <AssignedRequestTestsFeild
      {...props}
      inspectionLayer={inspectionLayer}
      technicianData={technicianData}
      stations={stations}
      isStationsFetching={isStationsFetching}
    />
  );
};
FieldAssignedRequestTests.propTypes = {
  inspection: PropTypes.object,
};

export default FieldAssignedRequestTests;
