import { useState } from "react";
import { InputLabel, FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./custom.css";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";

const BaseTimePicker = (props) => {
  const inputHasError = props.errors ? props.errors[props.name] : false;
  const [open, setOpen] = useState(false);

  return (
    <Box
      mb={5}
      sx={{
        ...props.containerStyles,
        width: `min(100%,${props.containerStyles?.width})`,
      }}
    >
      {" "}
      <InputLabel
        sx={{
          ...props.labelStyles,
          color: "grayDark.color",
          mb: 2,
          fontSize: "1.6rem",
          fontWeight: "bold",
        }}
        htmlFor={props.htmlFor}
      >
        {props.label}
      </InputLabel>
      <Box
        sx={{
          position: "relative",
          ...props.pickerContainerStyles,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              height: "100%",
              position: "absolute",
              zIndex: 1,
              width: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          ></Box>
          <DesktopTimePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
              },
            }}
            timeSteps={{ minutes: 30 }}
            thresholdToRenderTimeInASingleColumn={50}
            skipDisabled={true}
            format={props.format || "hh:mm A"}
            disableIgnoringDatePartForTimeValidation
            value={props.value}
            disabled={props.disabled}
            onError={props.onError}
            minTime={props.minTime}
            maxTime={props.maxTime}
            onChange={props.onChange}
            defaultValue={props.defaultValue || "06:00 AM"}
            className={`
              custom-time-picker 
              ${inputHasError ? "custom-time-picker--invalid" : ""}
              ${props.disabled ? "custom-time-picker--disabled" : ""}
               
               `}
            sx={{
              ...props.timePickerStyles,
            }}
          />
        </LocalizationProvider>
      </Box>
      {inputHasError && (
        <Box mb={-2.9}>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

BaseTimePicker.propTypes = {
  errors: PropTypes.object,
  containerStyles: PropTypes.object,
  labelStyles: PropTypes.object,
  pickerContainerStyles: PropTypes.object,
  timePickerStyles: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export default BaseTimePicker;
