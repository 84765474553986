import { Box, Stack, Typography } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { valueChecker } from "utils/Numbers/Numbers";

const RequestRaiseScoreLab = (props) => {
  const {
    id,
    score,
    errors,
    register,
    setValue,
    inputName,
    formValues,
    areCTAsDisabled,
    areInputsDisabled,
    clearErrors,
  } = props;

  const _inputName = `${inputName}-${id}`;

  const placeholderText = "النتيجة";

  const inputValue = formValues[_inputName];

  useEffect(() => {
    if (
      typeof valueChecker(score) === "number" ||
      typeof valueChecker(inputValue) === "number"
    ) {
      setValue(_inputName, inputValue ?? score);
    }
  }, []);

  useEffect(() => {
    if (areInputsDisabled) {
      clearErrors(_inputName);
    }
  }, [areInputsDisabled]);

  if (props.containSubtests) return null;

  if (areCTAsDisabled) {
    return <Typography sx={{ fontSize: "1.6rem" }}>{score}</Typography>;
  }

  return (
    <Box position={"relative"}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <BaseInput
          name={_inputName}
          sx={{ width: "14.1rem" }}
          inputContainerStyles={{ marginBottom: 0 }}
          placeholder={placeholderText}
          step={"any"}
          type={"number"}
          maxLength={100}
          disabled={areInputsDisabled}
          register={{
            ...register(_inputName, {
              required: {
                value: true,
                message: "هذا الحقل مطلوب",
              },
              maxLength: {
                value: 9,
                message: `تجاوزت الحد المسموح`,
              },
            }),
          }}
          errors={errors}
        />
      </Stack>
    </Box>
  );
};

RequestRaiseScoreLab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputName: PropTypes.string,
  errors: PropTypes.object,
  formValues: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  areCTAsDisabled: PropTypes.bool,
  areInputsDisabled: PropTypes.bool,
  containSubtests: PropTypes.bool,
};

export default RequestRaiseScoreLab;
