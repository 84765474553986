import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";

const AddLicenseButton = (props) => {
  return (
    <BaseButton
      onClick={() => {
        props.setShowModal(true);
      }}
    >
      إضافة رخص الحفر
    </BaseButton>
  );
};

AddLicenseButton.propTypes = {
  setShowModal: PropTypes.func,
};

export default AddLicenseButton;
