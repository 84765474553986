import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { AdminCertificateRequestsHeader } from "services/StaticLookup/TableHeader/TableHeader";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useLazyGetAdminCertificateRequestsQuery } from "modules/Admin/apis/inspection-apis/inspection-api";
import { TABLE_ID } from "constants/Table";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { CertificateRequest } from "services/StaticLookup/Breadcrumb";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { Stack } from "@mui/material";
import BaseFilter from "core-ui/BaseFilter/BaseFilter";
import { certificateRequestsFilter } from "services/StaticLookup/Filter";
import useDebouncedQuery from "hooks/useDebouncedQuery";
import { isContentReady } from "utils/Array/Arrays";

const CertificateRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [requestDecision, setRequestDecision] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getCertificateRequestsData, getCertificateRequestsResponse] =
    useDebouncedQuery(useLazyGetAdminCertificateRequestsQuery);

  useEffect(() => {
    const timer = setTimeout(() => {
      getCertificateRequestsData({
        page,
        size: pageSize,
        sortId: "id,desc",
        ...(requestDecision && { adminDecision: requestDecision }),
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [page, pageSize, requestDecision]);

  const isNoSearchData =
    requestDecision &&
    !isContentReady(getCertificateRequestsResponse.data?.content);

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: isNoSearchData ? t("No Search") : t("No requests"),
    desc: isNoSearchData
      ? t("No Search Desc")
      : t("no certificate requests desc"),
  };

  const onChangeRequestDecision = (value) => {
    setRequestDecision(value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات إصدار الشهادة`}</title>
      </Helmet>

      <DashboardIntro
        title={`${t("certificate requests", {
          ns: "dashboard",
        })}`}
        description={<>تستعرض الصفحة جميع طلبات إصدار شهادة اتمام الفحص</>}
        breadcrumbData={CertificateRequest({})}
      />

      <BaseCard mt={6} py={"3rem"} px={"3rem"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          mb={2}
          flexWrap={"wrap"}
          gap={2}
        >
          <BaseFilter
            label={"قرار مدير النظام"}
            value={requestDecision}
            data={certificateRequestsFilter}
            onChange={onChangeRequestDecision}
          />
        </Stack>
      </BaseCard>
      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={AdminCertificateRequestsHeader}
          content={getCertificateRequestsResponse.data?.content || []}
          isLoading={getCertificateRequestsResponse.isFetching}
          injectProps={{ tableId: TABLE_ID.CERIFICATE_REQUESTS }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/certificate-requests/${id}`,
          }}
          pagination={{
            displayBy: [5, 10, 15],
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getCertificateRequestsResponse.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default CertificateRequestsIndex;
