import { Box, Stack, Typography } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseRadioWithLabel from "core-ui/BaseRadioWithLabel/BaseRadioWithLabel";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const TechnicianConfirmationDoubleLayer = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const {
    props: { injectProps },
  } = props;

  const formErrors = injectProps.errors?.[props.element.id];

  const customErrors = (index) => ({
    confirmationStation: formErrors?.["confirmation-station"],
    maxProctor: formErrors?.[`max-proctor`],
    optimumProctor: formErrors?.[`optimum-proctor`],
    confirmResult: formErrors?.scores?.[`${props.element.id}-${index + 1}`],
  });

  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"} gap="1rem">
        <Typography
          fontSize="1.4rem"
          fontWeight={"bold"}
          marginInlineEnd={"1rem"}
        >
          تأكيد النتيجة:
        </Typography>
        <BaseRadioWithLabel
          name={`${props.element.id}.confirmation-station`}
          control={injectProps.control}
          customError={customErrors().confirmationStation}
          controllerRules={{
            required: {
              value: true,
              message: `يجب عليك الاختيار`,
            },
            // validate: customValidation(props.index || 0),
          }}
          data={[
            { value: "1", label: "صحيحة" },
            { value: "0", label: "غير صحيحة" },
          ]}
          sx={{ marginTop: -1 }}
        />
      </Stack>
      {!props.element.skipReason &&
        injectProps.formData?.[props.element.id]?.[`confirmation-station`] ==
          "0" && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"1rem"}
            flexWrap={"wrap"}
            marginTop={"1rem"}
          >
            <Typography fontSize={"1.4rem"}>
              يرجى إدخال القيم الصحيحة :
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap=".5rem">
              <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
                الكثافة الجافة العظمى :
              </Typography>
              <BaseInput
                inputContainerStyles={{
                  width: "10rem",
                  margin: 0,
                  flexGrow: 1,
                }}
                inputStyles={{ margin: 0 }}
                type={"number"}
                step={"any"}
                placeholder={"النتيجة"}
                customError={customErrors().maxProctor}
                register={{
                  ...injectProps.register(`${props.element.id}.max-proctor`, {
                    required: {
                      value: true,
                      message: `${t("field")} ${t("required")}`,
                    },
                  }),
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap=".5rem">
              <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
                الرطوبة المثلى :
              </Typography>
              <BaseInput
                inputContainerStyles={{
                  width: "10rem",
                  margin: 0,
                  flexGrow: 1,
                }}
                inputStyles={{ margin: 0 }}
                type={"number"}
                step={"any"}
                placeholder={"النتيجة"}
                customError={customErrors().optimumProctor}
                register={{
                  ...injectProps.register(
                    `${props.element.id}.optimum-proctor`,
                    {
                      required: {
                        value: true,
                        message: `${t("field")} ${t("required")}`,
                      },
                    }
                  ),
                }}
              />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} gap="1rem">
              <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
                النتيجة
              </Typography>
              {props.element.scoresList?.map((score, index) => {
                return (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap=".2rem"
                    width={"10rem"}
                    flexWrap={"wrap"}
                    key={`${index}-${score.score}`}
                  >
                    <BaseInput
                      inputContainerStyles={{
                        width: "10rem",
                        margin: 0,
                        flexGrow: 1,
                      }}
                      inputStyles={{ margin: 0 }}
                      type={"number"}
                      step={"any"}
                      placeholder={"النتيجة %"}
                      register={{
                        ...injectProps.register(
                          `${props.element.id}.scores.${props.element.id}-${
                            index + 1
                          }`,
                          {
                            required: {
                              value: true,
                              message: `${t("field")} ${t("required")}`,
                            },
                          }
                        ),
                      }}
                      customError={customErrors(index).confirmResult}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        )}
    </Box>
  );
};

TechnicianConfirmationDoubleLayer.propTypes = {
  element: PropTypes.object,
  props: PropTypes.object,
};

export default TechnicianConfirmationDoubleLayer;
