import { Box } from "@mui/material";
import { AlertTypes } from "constants/AlertTypes";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { base64ToBlob } from "utils/ApiHelpers";

const ExportInvoiceButton = ({
  issueInvoice,
  reEvaluate,
  showAlertHandler,
}) => {
  const { t } = useTranslation(["wallet"]);

  const openPDFHandler = () => {
    try {
      const blob = base64ToBlob(issueInvoice?.data?.receipt);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } catch (err) {
      showAlertHandler({
        show: true,
        message:
          ErrorCodes.NOT_AUTHORIZED || ErrorCodes.FILE_DOWNLOAD_EXCEPTION,
        type: AlertTypes.ERROR,
      });
    }
  };
  return (
    <Box>
      {issueInvoice?.isLoading || !reEvaluate ? (
        <BaseLoader />
      ) : (
        <BaseButton sx={{ minWidth: "17.3rem" }} onClick={openPDFHandler}>
          {t("print", { ns: "common" })}
        </BaseButton>
      )}
    </Box>
  );
};

ExportInvoiceButton.propTypes = {
  issueInvoice: PropTypes.object,
  reEvaluate: PropTypes.bool,
  showAlertHandler: PropTypes.func,
};

export default ExportInvoiceButton;
