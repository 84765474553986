import { FormControlLabel, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import classes from "./BaseRadio.module.scss";
import PropTypes from "prop-types";

const BaseRadio = (props) => {
  const inputHasErrorClass = props.inputHasError ? "base-radio--invalid" : "";
  const disabledClass = props.disabled ? "base-radio--disabled" : "";
  return (
    <Box sx={{ ...props.parentSx }}>
      <Box
        className={`${classes["base-radio"]} 
        ${classes[inputHasErrorClass]}
        ${classes[disabledClass]}`}
        sx={{ ...props.sx }}
      >
        <FormControlLabel
          value={props.value}
          disabled={props.disabled}
          control={
            <Radio
              checkedIcon={<CheckCircleIcon />}
              checked={props.checked}
              sx={{
                color: "primary.colorSoft6",
                "&.Mui-checked": {
                  color: "secondary.main",
                },
              }}
            />
          }
          label={
            <Typography
              className={classes["base-radio__label"]}
              sx={{ fontSize: "1.4rem", ...props.labelSx }}
            >
              {props.label}
            </Typography>
          }
        />
      </Box>

      {props.helperText && (
        <Typography sx={{ mt: 0.3 }}>{props.helperText}</Typography>
      )}
    </Box>
  );
};

BaseRadio.propTypes = {
  inputHasError: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  parentSx: PropTypes.object,
  sx: PropTypes.object,
  labelSx: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default BaseRadio;
