import { UsersTypes } from "constants/UsersTypes";
import publicDashboardRoutes from "modules/Public/routes/DashboardRoutes";
import PropTypes from "prop-types";
import { sharedRoutesBetweenPublicAndAuth } from "services/Routes";
import { getUserToken } from "services/Storage";

export const assetsPath = (asset) => {
  return require(`../../assets/${asset}`);
};
export const publicAssetsPath = (asset) => {
  return `${process.env.PUBLIC_URL}/assets/${asset}`;
};

/**
 *
 * @param {string} path - The URL
 * @returns {string} - It returns the user type from the url, and if it didn't find it, so it will return an empty string
 */
export const getUserTypeByPath = (path) => {
  let splittedPath = path.split("/");

  for (let i = 0; i < splittedPath.length; i++) {
    if (UsersTypes.includes(splittedPath[i])) {
      return splittedPath[i];
    }
  }
  return "";
};

/**
 *
 * @param {string} path - The URL
 * @param {string} routeFor - The key that should be found in the URL
 * @returns {boolean} - It should return TRUE if the key is in the URL
 */
export const isRouteFor = (path, routeFor) => {
  let segregatedPath = path.split("/");
  return segregatedPath.includes(routeFor);
};

/**
 *
 * @param {Array<string>} routesList - Array of routes
 * @param {boolean} isAuth - It is a boolean value that checks if the user is logged in
 * @returns {Set<string>} - It returns a unique list(set) of routes combined with shared routes if isAuth is true and without shared routes if isAuth is false
 */
export const requiredAuthenticatedRoutes = (routesList, isAuth) => {
  let requiredAuthPathsList = [];
  let excludePath = ":id";

  routesList.forEach((route) => {
    if (!route.children && route.path && route.path !== excludePath) {
      requiredAuthPathsList.push(`/${route.path}`);
    }

    if (route.children) {
      route.children.forEach((childRoute) => {
        if (childRoute) {
          if (childRoute.path && childRoute.path !== excludePath) {
            requiredAuthPathsList.push(`/${childRoute.path}`);
          }
        }
      });
    }
  });

  if (!isAuth) {
    requiredAuthPathsList = removeSharedRoutesMiddleware(requiredAuthPathsList);
  }

  return [...new Set(requiredAuthPathsList)];
};

requiredAuthenticatedRoutes.defaultProps = {
  routesList: [],
};
requiredAuthenticatedRoutes.propTypes = {
  routesList: PropTypes.object,
};

/**
 *
 * @param {Array<string>} requiredAuthPathsList - Array of routes
 * @returns {Array<string>} - It returns a filtered list from shared routes
 */
export const removeSharedRoutesMiddleware = (requiredAuthPathsList) => {
  let removedSharedRoutes = [];
  [...sharedRoutesBetweenPublicAndAuth].forEach((sharedRoute) => {
    if (sharedRoute?.path) {
      // prettier-ignore
      removedSharedRoutes = requiredAuthPathsList.filter((route) => !route.includes(`/${sharedRoute.path}`));
    }
  });
  return removedSharedRoutes;
};

/**
 * @description - it pushes a shared routes to the first path object of routes input list in case the user is authenticated
 * @param {Array} routes - Array of routes
 * @param {Array} sharedRoutes - Array of shared routes that will be added to main routes list
 */
export const addSharedRoutesMiddleware = (routes = [], sharedRoutes = []) => {
  const isLoggedIn = getUserToken();

  sharedRoutes.forEach((sharedRoute) => {
    if (isLoggedIn && routes) {
      if (!isRouteInArray(sharedRoute, routes[0].children)) {
        routes[0].children.push(sharedRoute);
      }
    } else if (
      !isRouteInArray(sharedRoute, publicDashboardRoutes[0].children)
    ) {
      publicDashboardRoutes[0].children.push(sharedRoute);
    }
  });
};

const isRouteInArray = (route, arr) => arr.includes(route);
