import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import {
  assignedRequestTestsFeildSubmitted,
  assignedRequestTestsFeildSubmittedVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import RaiseResults from "modules/Laboratory/pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Lab/RaiseResults/RaiseResults";
import PropTypes from "prop-types";

const AssignedRequestTestsFeild = (props) => {
  const { hasVirtual, tableContent } = useGetVirtualStations(props.stations);

  const tableHeader = hasVirtual
    ? assignedRequestTestsFeildSubmittedVS("lab")
    : assignedRequestTestsFeildSubmitted("lab");

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const isSubmitted = props.inspection?.submittedAt;

  const { reanimate, onHide, vTimelineRef } = useVerticalTimeline();

  useEffect(() => {
    if (vTimelineRef?.current) {
      if (isSubmitted) {
        onHide();
      }
    }
    if (!props.isStationsFetching) {
      reanimate();
    }
  }, [vTimelineRef, isSubmitted, props.isStationsFetching]);

  if (props.isStationsFetching) return <BaseLoader />;

  return (
    <>
      <Box mt={5}>
        <BaseTableDoubleCard
          content={tableContent}
          tableHeader={tableHeader}
          injectProps={{
            mySpanColumns,
            setMySpanColumns,
            myHiddenColumns,
            setMyHiddenColumns,

            submittedFromLabAt: true,
          }}
        />
      </Box>
      <Box
        sx={{
          marginInlineStart: isSubmitted ? "" : "5rem",
        }}
      >
        <BaseVerticalTimeline sx={{ marginBlock: 5 }} ref={vTimelineRef}>
          {!isSubmitted && (
            <RaiseResults form={"field"} isSubmitted={isSubmitted} />
          )}
        </BaseVerticalTimeline>
      </Box>
    </>
  );
};

AssignedRequestTestsFeild.propTypes = {
  inspection: PropTypes.object,
  stations: PropTypes.array,
  isStationsFetching: PropTypes.bool,
};

export default AssignedRequestTestsFeild;
