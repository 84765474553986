import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import CustomPagination from "core-ui/BaseTable/CustomPagination/CustomPagination";
import { Typography } from "@mui/material";
import { isContentReady } from "utils/Array/Arrays";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import TableHeader from "./TableHead/TableHeader";
import EmptyTable from "./EmptyTable/EmptyTable";
import { defaultEmptyValue } from "constants/Empty";
import TableLoader from "./TableLoader/TableLoader";
import PropTypes from "prop-types";

const BaseTable = (props) => {
  const [page, setPage] = useState(0);

  const [insertedProps, setInsertedProps] = useState({});

  const insertNewPropsHandler = useCallback((newProps) => {
    setInsertedProps((prevState) => ({ ...prevState, ...newProps }));
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(
    props?.pagination?.rowsPerPage || 10
  );

  const [content, setContent] = useState(props.content);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const tableHeader = props?.tableHeader || [];

  const isTableContentEmpty = !isContentReady(content);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    if (props.pagination.onChangePaginationDataHandler) {
      props.pagination.onChangePaginationDataHandler(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(+value);
    setPage(0);
    if (props.pagination.onChangePaginationDataHandler) {
      props.pagination.onChangePaginationDataHandler(page, +value);
    }
  };

  const renderTableCell = (dataItem, headerItem) => {
    let renderedTableCell = dataItem[headerItem.serverKey];

    const customFormat = (dataItem) => headerItem.format;
    const _format = customFormat(dataItem);

    if (headerItem.applyNumbers === false)
      return renderedTableCell || headerItem?.fallback || defaultEmptyValue;

    // WE ADDED THIS TO GIVE A FALLBACK VALUE IN CASE IF IS THERE NO hasComponent Key in the headerItem
    if (
      (renderedTableCell === null ||
        renderTableCell === undefined ||
        renderTableCell == "" ||
        !renderTableCell) &&
      !headerItem.hasComponent
    )
      return headerItem?.fallback || defaultEmptyValue;

    const isBasicHeaderItem = !headerItem.innerKey && !headerItem.innerProp;
    const hasInnerKey = headerItem.innerKey && !headerItem.innerProp;
    const hasInnerProp = headerItem.innerKey && headerItem.innerProp;

    const _serverKey = Array.isArray(headerItem.serverKey)
      ? headerItem.serverKey[0]
      : headerItem.serverKey;

    if (hasInnerProp) {
      renderedTableCell =
        dataItem[_serverKey][headerItem.innerKey][headerItem.innerProp] ??
        headerItem?.fallback;

      if (headerItem.format) {
        renderedTableCell = _format(
          dataItem[_serverKey][headerItem.innerKey][headerItem.innerProp] ??
            headerItem?.fallback
        );
      }
    }

    if (hasInnerKey) {
      renderedTableCell =
        dataItem[_serverKey][headerItem.innerKey] ?? headerItem?.fallback;
      if (headerItem.format) {
        renderedTableCell =
          _format(dataItem[_serverKey][headerItem.innerKey], dataItem) ??
          headerItem?.fallback;
      }
    }

    if (isBasicHeaderItem && headerItem.format) {
      renderedTableCell =
        _format(dataItem[_serverKey], dataItem) ?? headerItem?.fallback;
    }

    if (headerItem.hasComponent) {
      const arePropsArray = Array.isArray(headerItem.hasComponent.customProps);
      let componentProps = {
        ...dataItem,
        insertProps: {
          props: { ...insertedProps },
          insertNewPropsHandler: insertNewPropsHandler,
        },
      };

      if (arePropsArray) {
        if (hasInnerKey) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey][headerItem.innerKey[idx]] ??
              headerItem?.fallback;
          });
        } else if (hasInnerProp) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey][headerItem.innerKey][
                headerItem.innerProp[idx]
              ] ?? headerItem?.fallback;
          });
        } else if (isBasicHeaderItem) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey[idx]] ?? headerItem?.fallback;
          });
        }
      } else {
        // 1. CASE SERVER KEY
        if (isBasicHeaderItem) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey] ?? headerItem?.fallback,
          };
        }
        // 1. CASE SERVER KEY && INNER KEY
        else if (hasInnerKey) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey][headerItem.innerKey] ??
              headerItem?.fallback,
          };
        }
        // 3. CASE SERVER KEY && INNER KEY && INNER PROP KEY
        else if (hasInnerProp) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey][headerItem.innerKey][
                headerItem.innerProp
              ] ?? headerItem?.fallback,
          };
        }
      }
      if (props.injectProps) {
        componentProps = {
          ...props.injectProps,
          ...componentProps,
        };
      }
      if (props.subData) {
        componentProps = {
          ...componentProps,
          ...dataItem[props.subData.subDataListServerKey],
        };
      }
      renderedTableCell = React.cloneElement(
        headerItem.hasComponent.comp,
        componentProps
      );
    }
    return renderedTableCell;
  };

  return (
    <Box
      sx={{ ...props?.tableMainWrapperSx, width: "100%", overflow: "hidden" }}
    >
      {props.title && <BaseCardTitle mb={1}>{props.title}</BaseCardTitle>}

      {props.description && (
        <Typography
          sx={{ fontSize: "1.6rem" }}
          color={"grayDark.color1"}
          mb={3}
        >
          {props.description}
        </Typography>
      )}

      <TableContainer
        sx={{
          ...props?.tableContainerSx,
          maxHeight: props.isLoading
            ? "100%"
            : props?.tableContainerSx?.maxHeight || "60vh",
          position: "relative",
          overflow: props.isLoading ? "hidden" : "auto",
        }}
      >
        {props.isLoading && <TableLoader />}
        {/* WHY WE NEED OVERFLOW: AUTO HERE  sx={{ overflow: "auto" }} ON BELOW BOX*/}
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "table",
              tableLayout: "fixed",
              minHeight: "15rem",
            }}
          >
            <Table
              stickyHeader
              sx={{
                ...props.tableSx,
                "& th:first-of-type": {
                  borderRadius:
                    props?.tableSx?.border?.theaderFirstOfType ||
                    "0.5rem 0 0 0.5rem",
                },
                "& th:last-of-type": {
                  ...props?.tableSx?.thLastOfType,
                  borderRadius:
                    props?.tableSx?.border?.theaderLastOfType ||
                    "0 0.5rem 0.5rem 0",
                },
                "& th": {
                  border: props?.tableSx?.border?.tableCell || 0,
                  borderColor: props?.tableSx?.border?.color || 0,
                  borderInlineColor: props?.tableSx?.border?.borderInlineColor,
                },
                "& td": {
                  ...props?.tableSx?.td,
                  border:
                    props?.tableSx?.border?.tableCell ||
                    (props?.tableSx?.td?.borderBottom ? "auto" : 0),
                  borderColor: props?.tableSx?.border?.color || 0,
                  borderInlineColor: props?.tableSx?.border?.borderInlineColor,
                },
                "& td:last-of-type": {
                  ...props?.tableSx?.tdLastOfType,
                },
              }}
            >
              <TableHeader
                dataItemKey={props.dataItemKey}
                tableHeader={tableHeader}
                withAction={props.withAction}
                withDetails={props.withDetails}
                description={props.descriptionRow}
                tableHeadSx={props.tableHeadSx}
                content={content}
                justifyColumnsBetween={props.justifyColumnsBetween}
              />
              {isTableContentEmpty ? (
                <EmptyTable emptyData={props.emptyData} />
              ) : (
                props.children(renderTableCell)
              )}
            </Table>
          </Box>
        </Box>
      </TableContainer>
      {isContentReady(content) && !props.categorized && props.pagination && (
        <CustomPagination
          data={content}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          label={props.pagination.label}
          displayBy={props.pagination.displayBy}
          totalCount={props.pagination.totalCount || 0}
        />
      )}
    </Box>
  );
};

BaseTable.defaultProps = {
  dataItemKey: "id",
};

BaseTable.propTypes = {
  content: PropTypes.array,
  tableHeader: PropTypes.array,
  subData: PropTypes.object,
  pagination: PropTypes.object,
  injectProps: PropTypes.object,
  tableContainerSx: PropTypes.object,
  tableSx: PropTypes.object,
  tableMainWrapperSx: PropTypes.object,
  tableHeadSx: PropTypes.object,
  emptyData: PropTypes.object,
  withDetails: PropTypes.object,
  withAction: PropTypes.object,
  categorized: PropTypes.bool,
  justifyColumnsBetween: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  dataItemKey: PropTypes.string,
  descriptionRow: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default BaseTable;
