import { Stack, Typography } from "@mui/material";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import PropTypes from "prop-types";

const RestoreButton = (props) => {
  const isDisabled = props.values.every((v) => !v);

  return (
    <button
      className={"restore-btn"}
      onClick={props.onRestore}
      disabled={isDisabled}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <RestoreIcon className={"restore-btn__img"} />
        <Typography
          sx={{ fontSize: "1.4rem", fontWeight: "bold" }}
          className={"restore-btn__text"}
        >
          إعادة تعيين
        </Typography>
      </Stack>
    </button>
  );
};

RestoreButton.propTypes = {
  values: PropTypes.array,
  onRestore: PropTypes.func,
};

export default RestoreButton;
