import React, { useMemo } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "../BaseDropdown.module.scss";
import { _void } from "utils/Objects/Objects";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import PropTypes from "prop-types";

const DropdownMainBar = React.forwardRef(
  (
    {
      disabled,
      dropdownInputHasError,
      pickup,
      helperText,
      setOpen,
      pickupData,
      defaultPickupData,
      value,
      placeholder,
      isLoading,
      stackingContextHandler,
      ignoreStackingContext,
      itemNameKey,
    },
    ref
  ) => {
    const pickupDataValues = pickupData?.allData?.map((data) => data.name);

    const defaultPickupDataValues = defaultPickupData?.map((d) => d.name) || [];

    const renderPlaceholder = useMemo(() => {
      if (isLoading) return "جاري التحميل...";

      function condition() {
        if (pickup) {
          return (
            pickupDataValues.join(", ") ||
            defaultPickupDataValues.join(", ") ||
            placeholder
          );
        } else if (itemNameKey) {
          if (value) {
            if (value[itemNameKey]) {
              return value[itemNameKey];
            } else {
              return value?.name || placeholder;
            }
          } else {
            return value?.name || placeholder;
          }
        } else {
          return value?.name || placeholder;
        }
      }

      return condition();
    }, [pickupDataValues, defaultPickupDataValues, placeholder, pickup]);

    const errorClasses = dropdownInputHasError
      ? classes["base-dropdown__main--error"]
      : undefined;

    const disabledClasses =
      disabled || isLoading
        ? classes[`base-dropdown__main--disabled`]
        : undefined;

    return (
      <>
        <Box
          ref={ref}
          className={`
            ${classes["base-dropdown__main"]} 
            ${disabledClasses}
            ${errorClasses}
          `}
          data-id={`${crypto.randomUUID()}`}
          onClick={
            !disabled && !isLoading
              ? () => {
                  setOpen((v) => true);
                  if (ignoreStackingContext) {
                    stackingContextHandler();
                  }
                }
              : _void
          }
        >
          <Typography className={classes["base-dropdown__main-placeholder"]}>
            {renderPlaceholder || "اختر من القائمة"}
          </Typography>
          {!isLoading && (
            <ExpandMoreIcon
              className={classes["base-dropdown__main-icon"]}
              color={"primary"}
            />
          )}
        </Box>
        {(dropdownInputHasError || helperText) && (
          <Box className={classes["base-dropdown__error"]}>
            <FormHelperText
              variant={dropdownInputHasError ? "error" : ""}
              error={!!dropdownInputHasError}
              sx={{
                fontSize: dropdownInputHasError?.message ? "1.1rem" : "1.4rem",
                color: "grayDark.color",
              }}
            >
              {dropdownInputHasError?.message || helperText}
            </FormHelperText>
          </Box>
        )}
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              insetInlineEnd: "1rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <BaseLoader size="sm" />
          </Box>
        )}
      </>
    );
  }
);

DropdownMainBar.propTypes = {
  pickup: PropTypes.bool,
  disabled: PropTypes.bool,
  pickupData: PropTypes.object,
  dropdownInputHasError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  itemNameKey: PropTypes.string,
  ignoreStackingContext: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultPickupData: PropTypes.object,
  setOpen: PropTypes.func,
  stackingContextHandler: PropTypes.func,
};

export default DropdownMainBar;
