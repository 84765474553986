import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import classes from "./CustomPagination.module.scss";
import { useTheme } from "@emotion/react";

import { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

const PAGE_PER_CHUNK = 10;

const CustomPagination = (props) => {
  const numberOfPages = Math.ceil(props.totalCount / props.rowsPerPage);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { rowsPerPage } = props;

  const [
    renderResponsivePaginationButtons,
    setRenderResponsivePaginationButtons,
  ] = useState([]);

  useLayoutEffect(() => {
    props.onPageChange(0);

    let DEFAULT_PAGINATION_PAGES_NUMBER =
      numberOfPages < 10 ? numberOfPages : 10;

    if (sm) {
      DEFAULT_PAGINATION_PAGES_NUMBER = numberOfPages < 5 ? numberOfPages : 5;
    }

    let defaultResponsivePaginationValue = [];

    for (let i = 1; i <= DEFAULT_PAGINATION_PAGES_NUMBER; i++) {
      defaultResponsivePaginationValue.push(i);
    }

    setRenderResponsivePaginationButtons(defaultResponsivePaginationValue);
  }, [rowsPerPage, numberOfPages, sm]);

  const isFirstChunk = renderResponsivePaginationButtons[0] <= 1;
  const isLastChunk =
    renderResponsivePaginationButtons.at(-1) / numberOfPages === 1;

  const isPaginationResponsive = numberOfPages > PAGE_PER_CHUNK;

  const goToNextChunk = () => {
    if (isLastChunk) return;

    let newPaginationChunk = [];

    const lastElement = renderResponsivePaginationButtons.at(-1);

    //prettier-ignore
    const limit = lastElement + PAGE_PER_CHUNK <= numberOfPages ? lastElement + PAGE_PER_CHUNK : numberOfPages;

    for (let i = lastElement; i < limit; i++) {
      newPaginationChunk.push(i + 1);
    }
    setRenderResponsivePaginationButtons(newPaginationChunk);
    props.onPageChange(newPaginationChunk[0] - 1);
  };

  const goToPrevChunk = () => {
    if (isFirstChunk) return;

    let newPaginationChunk = [];

    const firstElement = renderResponsivePaginationButtons[0];

    for (let i = firstElement - (10 + 1); i < firstElement - 1; i++) {
      newPaginationChunk.push(i + 1);
    }
    setRenderResponsivePaginationButtons(newPaginationChunk);
    props.onPageChange(newPaginationChunk[0] - 1);
  };

  const generateDisplayedItemsNumber = () => {
    let displayItemsNumber = 0;

    if (props.page * props.rowsPerPage > props.totalCount) {
      displayItemsNumber = props.totalCount % (props.page * props.rowsPerPage);
    } else {
      displayItemsNumber = props.page * props.rowsPerPage;
    }

    return displayItemsNumber;
  };

  const textStyles = {
    color: "grayDark.color4",
    fontSize: "1.4rem",
    fontWeight: 500,
  };

  return (
    <Box className={classes.pagination}>
      <Stack direction={"row"} spacing={3} alignItems={"center"}>
        <Typography sx={[textStyles, { mr: "-1.5rem" }]}>عرض</Typography>
        <Box className={`${classes["pagination__display-list"]}`}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.rowsPerPage}
            onChange={(e) => props.onRowsPerPageChange(e.target.value)}
          >
            {props.displayBy.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography sx={[textStyles]}>في الصفحة</Typography>
      </Stack>

      <Stack direction={"row"} className="pagination--buttons">
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          {isPaginationResponsive && (
            <Box
              className={`${classes["pagination__control"]}`}
              sx={{ cursor: isFirstChunk ? "not-allowed" : "" }}
              onClick={goToPrevChunk}
            >
              {<KeyboardDoubleArrowRightIcon />}
            </Box>
          )}
          <Stack direction={"row"} alignItems={"center"}>
            {renderResponsivePaginationButtons.map((item, idx) => {
              return (
                <Box
                  key={item}
                  onClick={() => props.onPageChange(item - 1)}
                  className={`${classes["pagination__btn"]} ${
                    classes[
                      `pagination__btn--${
                        props.page === item ? "active" : undefined
                      }`
                    ]
                  }`}
                >
                  {item}
                </Box>
              );
            })}
          </Stack>
          {isPaginationResponsive && (
            <Box
              className={`${classes["pagination__control"]}`}
              sx={{ cursor: isLastChunk ? "not-allowed" : "" }}
              onClick={goToNextChunk}
            >
              {<KeyboardDoubleArrowLeftIcon />}
            </Box>
          )}
        </Stack>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <Typography sx={textStyles}>
          {generateDisplayedItemsNumber()}
        </Typography>
        <Typography sx={textStyles}>من</Typography>
        <Typography sx={textStyles}>{props.totalCount}</Typography>
        <Typography sx={textStyles}>{props.label}</Typography>
      </Stack>
    </Box>
  );
};

CustomPagination.propTypes = {
  label: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  displayBy: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

export default CustomPagination;
