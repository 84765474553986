import React from "react";
import { Box, Typography } from "@mui/material";
import LicenseDataFallbackComp from "components/Dashboard/DashboardDrillingLicensesData/LicenseDataFallbackComp";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";
import { defaultEmptyValue } from "constants/Empty";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function LicensesData({ data, onAddLicenseClick, onCancelLicenseClick }) {
  const { t } = useTranslation(["auth", "dashboard"]);

  const handleAddLicenseClick = () => {
    onAddLicenseClick(data);
  };
  const isDisabled = !data;

  const handleCancelLicenseClick = () => {
    onCancelLicenseClick();
  };

  const lableSX = {
    fontSize: "1.4rem",
    color: "grayDark.color",
    fontWeight: 500,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "1.6rem",
          color: "primary.colorSoft13",
          fontWeight: 500,
          mb: 3,
        }}
      >
        {"بيانات الرخصة"}
      </Typography>

      {!data ? (
        <LicenseDataFallbackComp
          title={t("يرجى البحث عن الرخص وإضافتها إلى قائمة الرخص المضافة", {
            ns: "dashboard",
          })}
          icon="emptySearch2.svg"
        />
      ) : (
        <Box>
          <WrapperContentGrid
            sx={{ marginBottom: "4rem", flexDirection: "row" }}
            columnsWidth={16}
            gap={2}
          >
            <BaseDataItem
              valueComponentAs={"div"}
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}>{"رقم الرخصة"}</Typography>}
              value={
                <CombinedField
                  field1={data?.licenseYear}
                  field2={data?.licenseNumber}
                />
              }
              valueSx={{ color: "base.black" }}
            />
            <BaseDataItem
              valueComponentAs={"div"}
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}> {"النوع"}</Typography>}
              value={<BaseTypeTag type={data?.typeRefId} />}
            />
            <BaseDataItem
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}> {"تاريخ الإنتهاء"}</Typography>}
              value={data?.expiryDate || defaultEmptyValue}
              valueSx={{ color: "base.black" }}
            />
            <BaseDataItem
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}> {"مقدم الخدمة"}</Typography>}
              value={data.serviceProviderName}
              valueSx={{ color: "base.black" }}
            />
            <BaseDataItem
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}> {"نوع الحفر"}</Typography>}
              value={data.drillingType}
              valueSx={{ color: "base.black" }}
            />
            <BaseDataItem
              labelComponentAs={"div"}
              label={<Typography sx={lableSX}> {"نوع الخدمة"}</Typography>}
              value={data.serviceName}
              valueSx={{ color: "base.black" }}
            />
          </WrapperContentGrid>
        </Box>
      )}
      <hr />
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={3}
        justifyContent={"flex-end"}
        mt={2}
      >
        <BaseButton
          sx={{ minWidth: "9.6rem", height: "3.2rem" }}
          variant={isDisabled ? "disabled" : "secondary"}
          disabled={isDisabled}
          onClick={handleCancelLicenseClick}
        >
          {t("إلغاء", { ns: "common" })}
        </BaseButton>

        <BaseButton
          sx={{ minWidth: "9.6rem", height: "3.2rem" }}
          disabled={isDisabled}
          onClick={handleAddLicenseClick}
        >
          {t("إضافة", { ns: "common" })}
        </BaseButton>
      </Box>
    </Box>
  );
}

LicensesData.propTypes = {
  data: PropTypes.object,
  onAddLicenseClick: PropTypes.func,
  onCancelLicenseClick: PropTypes.func,
};

export default LicensesData;
