import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Dashboard from "../../Admin/pages";
import AdminDrillingLicensesIndex from "modules/Admin/pages/Dashboard/DrillingLicenses";
import AdminDrillingLicenses from "modules/Admin/pages/Dashboard/DrillingLicenses/DrillingLicensesIndex/DrillingLicenses";
import DrillingLicensesDetails from "modules/Admin/pages/Dashboard/DrillingLicenses/DrillingLicensesDetails/DrillingLicensesDetails";
import AdminTechnicianRequestsIndex from "modules/Admin/pages/TechnicianRequests";
import TechnicianRequests from "modules/Admin/pages/TechnicianRequests/TechnicianRequestsIndex/TechnicianRequests";
import AdminUserManagement from "../pages/UserManagement";
import AdminUsers from "../pages/UserManagement/AdminUsers/AdminUsers";
import AdminUserDetails from "../pages/UserManagement/AdminUserDetails/AdminUserDetails";
import AdminAddUser from "../pages/UserManagement/AdminAddUser/AdminAddUser";
import ContractingCompanies from "../pages/ContractingCompanies";
import ContractingCompaniesIndex from "../pages/ContractingCompanies/ContractingCompaniesIndex/ContractingCompaniesIndex";
import ContractingCompaniesDetails from "../pages/ContractingCompanies/ContractingCompaniesDetails/ContractingCompaniesDetails";
import InvoicesDetails from "../pages/ContractingCompanies/InvoicesDetails/InvoicesDetails";
import CompanyPaymentDetails from "../components/CompanyDataDetails/CompanyPaymentDetails/CompanyPaymentDetails";
import AdminLabRequestsIndex from "modules/Admin/pages/LabRequests";
import LabRequests from "modules/Admin/pages/LabRequests/LabRequests/LabRequests";
import RequestDetails from "modules/Admin/pages/LabRequests/LabRequestDetails/LabRequestDetails";
import AdminRequests from "modules/Admin/pages/AdminRequests/AdminRequests/AdminRequests";
import AdminRequestsIndex from "modules/Admin/pages/AdminRequests";
import AdminEditRequestsIndex from "../pages/EditRequests";
import EditRequests from "modules/Admin/pages/EditRequests/EditRequestsIndex/EditRequests";
import AdminReportRequestsIndex from "../pages/ReportRequests";
import ReportRequests from "modules/Admin/pages/ReportRequests/ReportRequestsIndex/ReportRequests";
import EditRequestDetails from "modules/Admin/pages/EditRequests/EditRequestDetails";
import ExaminationRequestDetails from "../pages/Dashboard/DrillingLicenses/ExaminationRequestDetails/ExaminationRequestDetails";
import CertificateRequests from "../../Admin/pages/CertificateRequests";
import CertificateRequestsIndex from "../../Admin/pages/CertificateRequests/CertificateRequestsIndex/CertificateRequestsIndex";
import CertificateRequestsDetails from "../../Admin/pages/CertificateRequests/CertificateRequestsDetails/CertificateRequestsDetails";

const AdminDashboardRoutes = {
  path: "/",
  element: <ProtectedRoute component={<Dashboard />} />,
  children: [
    {
      path: "drilling-licenses",
      element: <ProtectedRoute component={<AdminDrillingLicensesIndex />} />,
      children: [
        { index: true, element: <AdminDrillingLicenses /> },
        {
          path: ":id/examination-request/:id",
          element: <ProtectedRoute component={<ExaminationRequestDetails />} />,
        },
        {
          path: ":id",
          element: <ProtectedRoute component={<DrillingLicensesDetails />} />,
        },
      ],
    },
    {
      path: "/requests",
      element: <ProtectedRoute component={<AdminRequestsIndex />} />,
      children: [
        { index: true, element: <AdminRequests /> },
        {
          path: "examination-request/:id",
          element: <ProtectedRoute component={<ExaminationRequestDetails />} />,
        },
      ],
    },
    {
      path: "technician-requests",
      element: <ProtectedRoute component={<AdminTechnicianRequestsIndex />} />,
      children: [{ index: true, element: <TechnicianRequests /> }],
    },
    {
      path: "users-management",
      element: <ProtectedRoute component={<AdminUserManagement />} />,
      children: [
        { index: true, element: <AdminUsers /> },
        {
          path: ":id",
          element: <ProtectedRoute component={<AdminUserDetails />} />,
        },
        {
          path: "add",
          element: <ProtectedRoute component={<AdminAddUser />} />,
        },
      ],
    },
    {
      path: "companies",
      element: <ProtectedRoute component={<ContractingCompanies />} />,
      children: [
        { index: true, element: <ContractingCompaniesIndex /> },
        {
          path: ":id",
          element: (
            <ProtectedRoute component={<ContractingCompaniesDetails />} />
          ),
        },
        {
          path: ":companyId/invoices/:id",
          element: <ProtectedRoute component={<InvoicesDetails />} />,
        },
        {
          path: ":companyId/payments/:paymentsId",
          element: <ProtectedRoute component={<CompanyPaymentDetails />} />,
        },
      ],
    },
    {
      path: "lab-requests",
      element: <ProtectedRoute component={<AdminLabRequestsIndex />} />,
      children: [
        { index: true, element: <LabRequests /> },
        {
          path: ":id",
          element: <ProtectedRoute component={<RequestDetails />} />,
        },
      ],
    },
    {
      path: "edit-requests",
      element: <ProtectedRoute component={<AdminEditRequestsIndex />} />,
      children: [
        { index: true, element: <EditRequests /> },
        {
          path: ":id",
          element: <ProtectedRoute component={<EditRequestDetails />} />,
        },
      ],
    },
    {
      path: "report-requests",
      element: <ProtectedRoute component={<AdminReportRequestsIndex />} />,
      children: [{ index: true, element: <ReportRequests /> }],
    },
    {
      path: "certificate-requests",
      element: <ProtectedRoute component={<CertificateRequests />} />,
      children: [
        { index: true, element: <CertificateRequestsIndex /> },
        {
          path: ":id",
          element: <CertificateRequestsDetails />,
        },
      ],
    },
  ],
};

export default AdminDashboardRoutes;
