import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";
import FieldTechnicianConfirmation from "./FieldTechnicianConfirmation";
import PropTypes from "prop-types";

const FieldExaminationEditRequestConfirmation = ({
  props,
  scoresList,
  element,
  editedStations,
  displayTechnicianConfirmation,
}) => {
  const { breakpoints } = useTheme();
  const lg = useMediaQuery(breakpoints.down("lg"));
  const { tableHeader } = props;

  const gridColumnSpanForConfirmation = `1 / span ${tableHeader.length - 1}`;

  const editedStation = scoresList?.find(
    (scoreItem) => scoreItem?.station === element.id
  );
  const matching = editedStations?.find((station) => station.id === element.id);

  const reason = rejectionReasons?.find(
    (reason) => reason.id === matching?.editedStation?.updatedSkipReason
  );

  const renderTechnicianConfirmations = () => (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontSize: "1.6rem", fontWeight: 500 }}>
        النتيجة البديلة:
      </Typography>
      {matching?.editedStation?.updatedSkipReason && reason && (
        <Typography sx={{ fontSize: "1.6rem", fontWeight: 500, ml: 1 }}>
          {reason.name}
        </Typography>
      )}
    </Box>
  );

  const renderScores = (scores) =>
    scores?.map((score, idx) => {
      return (
        <React.Fragment key={crypto.randomUUID()}>
          <Typography component="span" fontSize="inherit">
            {`${score?.score}`}
          </Typography>
          {idx < scores.length - 1 && ", "}
        </React.Fragment>
      );
    });

  return (
    <>
      {displayTechnicianConfirmation && (
        <FieldTechnicianConfirmation
          props={props}
          scoresList={scoresList}
          element={element}
          editedStations={editedStations}
        />
      )}
      <BaseCard
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${lg ? 3 : tableHeader.length}, 1fr)`,
        }}
        variant="alternative"
        px={"2rem"}
        py={"1rem"}
        mt={2}
      >
        <Box
          sx={{
            gridColumn: lg ? `1 / -1` : gridColumnSpanForConfirmation,
            gridRow: 1,
          }}
        >
          {renderTechnicianConfirmations()}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
            gridColumn: lg ? 2 : `${tableHeader.length}`,
            gridRow: lg ? 3 : 1,
            ml: 4,
          }}
        >
          {editedStation?.testStationMappingEdits?.map((edit, idx) => (
            <Typography
              key={new Date().getTime().toString().substring(0, 10)}
              sx={{ fontSize: "1.8rem" }}
            >
              {renderScores(edit.updatedScores)}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            gridColumn: lg ? 3 : `${tableHeader.length - 1}`,
            gridRow: lg ? 2 : 1,
            ml: 4,
          }}
        >
          <Typography sx={{ fontSize: "1.8rem" }}>
            {editedStation?.updatedOptimumProctor}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            gridColumn: lg ? 1 : `${tableHeader.length - 2}`,
            gridRow: lg ? 3 : 1,
            ml: 4,
          }}
        >
          <Typography sx={{ fontSize: "1.8rem" }}>
            {editedStation?.updatedMaxProctor}
          </Typography>
        </Box>
      </BaseCard>
    </>
  );
};

FieldExaminationEditRequestConfirmation.propTypes = {
  props: PropTypes.object,
  element: PropTypes.object,
  tableHeader: PropTypes.array,
  scoresList: PropTypes.array,
  editedStations: PropTypes.array,
  displayTechnicianConfirmation: PropTypes.bool,
};

export default FieldExaminationEditRequestConfirmation;
