import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const BaseCard = (props) => {
  const { palette } = useTheme();
  const stylesVariations = {
    ...(props.variant === "primary" && {
      background: palette.base.white,
      border: `1px solid ${palette.borders.infoSoft}`,
    }),
    ...(props.variant === "secondary" && {
      background: palette.base.info,
      border: `1px solid ${palette.borders.infoSoft}`,
    }),
    ...(props.variant === "disabled" && {
      background: palette.base.info,
      border: `1px solid ${palette.borders.disabled}`,
    }),
    ...(props.variant === "disabled-secondary" && {
      background: palette.background.colorSoft6,
      border: `1px solid ${palette.borders.colorSoft4}`,
    }),
    ...(props.variant === "primary-transparent" && {
      background: "transparent",
      border: `1px solid ${palette.base.infoBorder}`,
    }),
    ...(props.variant === "secondary-fancy" && {
      background: `linear-gradient(-90deg, ${palette.secondary.main} 70%, ${palette.primary.main} 190%)`,
    }),
    ...(props.variant === "primary-fancy" && {
      background: `linear-gradient(-90deg, ${palette.primary.main} 90%, ${palette.secondary.main} 190%)`,
    }),
    ...(props.variant === "upload" && {
      background: `${palette.background.colorSoft7}`,
      border: `1px dashed ${palette.text.tags.blueSoft}`,
    }),
    ...(props.variant === "background-white" && {
      background: `${palette.base.white}`,
      border: `1px solid ${palette.base.infoBorder}`,
    }),
    ...(props.variant === "error" && {
      background: `${palette.base.dangerLight}`,
      border: `1px solid ${palette.base.dangerBorderLight}`,
    }),
    ...(props.variant === "success" && {
      background: `${palette.base.successSecondary}`,
      border: `1px solid ${palette.base.successBorderSecondary}`,
    }),
    ...(props.variant === "alternative" && {
      background: `rgba(240, 155, 0, 0.149)`,
      border: `1px solid  ${palette.text.tags.orange}`,
    }),
    ...(props.variant === "alternative-light" && {
      background: `rgba(232, 240, 239, 0.2)`,
      border: `1px solid  ${palette.borders.colorSoft7}`,
    }),
  };
  return (
    <Box
      {...props}
      sx={{
        ...stylesVariations,
        maxWidth: "100%",
        py: `min(${props.py || "8rem"}, 5vw)`,
        px: `min(${props.px || "11.0rem"}, 5vw)`,
        borderRadius: props.borderRadius || "5px",

        ...props.sx,
      }}
    >
      {props.title && (
        <Typography
          sx={{ color: "grayDark.color", fontSize: "1.8rem", mb: 1.5 }}
        >
          {props.title}
        </Typography>
      )}
      {props.children}
    </Box>
  );
};

export default BaseCard;

BaseCard.defaultProps = {
  variant: "primary",
  py: "8rem",
  px: "11rem",
  borderRadius: "5px",
};

BaseCard.propTypes = {
  variant: PropTypes.string,
  py: PropTypes.string,
  px: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};
