import React from "react";
import PreviewPDF from "core-ui/BasePDF/PreviewPDF/PreviewPDF";
import { BarcodeContent } from "./BarcodeContent/BarcodeContent";
import PropTypes from "prop-types";

const PrintBarcode = (props) => {
  const { data } = props;
  const headerProps = {
    title: "(barcodes) رموز العينات",
    subtitles: [
      {
        title: "بيانات الطلب",
        data: {
          "رقم الطلب": `${data.id}`,
          "مكان اخذ العينة": `${data.collectSampleDto?.location}`,
          "عدد العينات": `${data.collectSampleDto?.numberOfSamples}`,
        },
      },
    ],
    tableTitle: "(barcodes) رموز العينات",
    TableTitle1: "(barcode) رمز العينة",
    TableTitle2: "رقم العينة",
  };

  return (
    <PreviewPDF {...headerProps} toBeChunkedData={data.samples}>
      {(chunk) => <BarcodeContent barcodeChunks={chunk} />}
    </PreviewPDF>
  );
};

PrintBarcode.propTypes = {
  data: PropTypes.object,
};

export default PrintBarcode;
