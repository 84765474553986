import { Box } from "@mui/material";
import Notes from "components/Notes/Notes";
import MixDesignDetails from "modules/Laboratory/components/MixDesignDetails/MixDesignDetails";
import PropTypes from "prop-types";

const AsphaltReportData = (props) => {
  return (
    <Box mt={7}>
      <Box mb={3}>
        <Notes
          notes={props.inspectionLayerDTO?.aspeltMixDesignDetailDto?.notes}
        />
      </Box>
      <Box mt={4} mb={7}>
        <MixDesignDetails
          aspeltMixDesignDetailDto={
            props.inspectionLayerDTO?.aspeltMixDesignDetailDto
          }
        />
      </Box>
      <hr />
    </Box>
  );
};

AsphaltReportData.propTypes = {
  inspectionLayerDTO: PropTypes.object,
};

export default AsphaltReportData;
