import { TableCell, TableRow } from "@mui/material";
import { isContentReady } from "utils/Array/Arrays";
import { displayCell } from "../TableHead/TableHeader";
import PropTypes from "prop-types";

const TableSubData = (props) => {
  /* NEW TABLE HEADER OF SUB DATA */
  return (
    props.subData && (
      <>
        {/* NEW TABLE DATA OF SUB DATA [LOOP] */}
        {/* THIS WILL MODIFY LATER TO BE .subData to be generic for all sub data content */}
        {props.categoryElement[props.subData?.subDataListServerKey] &&
        isContentReady(
          props.categoryElement[props.subData?.subDataListServerKey]
        )
          ? props.categoryElement[props.subData?.subDataListServerKey].map(
              (subDataItem, idx) => {
                return (
                  <TableRow key={subDataItem.id}>
                    {props.tableHeader.map((headerItem, idx) => {
                      return (
                        <TableCell
                          key={headerItem.name}
                          sx={{
                            background: "base.white",
                            borderBottom: "1px solid borders.colorSoft2",
                            whiteSpace: "nowrap",
                            fontSize: "1.6rem",
                            color: "grayDark.color5",
                            display: displayCell(
                              headerItem,
                              subDataItem,
                              false
                            ),
                          }}
                        >
                          {props.renderTableCell(subDataItem, headerItem)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )
          : null}
      </>
    )
  );
};

TableSubData.propTypes = {
  subData: PropTypes.object,
  categoryElement: PropTypes.array,
  tableHeader: PropTypes.array,
  renderTableCell: PropTypes.func,
};

export default TableSubData;
