import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { formattedNumber } from "utils/Numbers/Numbers";

const OneUseLabContent = ({ data }) => {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          borderColor: "#EDEFF2",
          padding: 10,
          fontSize: 8,
        }}
      >
        <View
          style={{
            fontSize: 8,
            textAlign: "left",
            marginLeft: "31px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={{ marginRight: "2px" }}>نقطة</Text>
          <Text>{formattedNumber(data?.combinedLabTestCost)}</Text>
        </View>
        <View
          style={{
            textAlign: "right",
            marginRight: "10px",
          }}
        >
          <Text>تكلفة الاختبارات المعملية</Text>
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopWidth: 1,
          borderColor: "#EDEFF2",
          padding: 10,
          fontSize: 8,
        }}
      >
        <View
          style={{
            fontSize: 8,
            textAlign: "left",
            marginLeft: "31px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={{ marginRight: "2px" }}>نقطة</Text>
          <Text>{formattedNumber(data?.combinedSampleCollectionCost)}</Text>
        </View>
        <View
          style={{
            textAlign: "right",
            marginRight: "10px",
          }}
        >
          <Text>تكلفة جمع العينات</Text>
        </View>
      </View>
    </>
  );
};

OneUseLabContent.propTypes = {
  data: PropTypes.object,
};

export default OneUseLabContent;
