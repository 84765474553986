import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { newRequests } from "services/StaticLookup/TableHeader/TableHeader";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useGetNoAssignedRequestsQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { NewRequestIndx } from "services/StaticLookup/Breadcrumb";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const NewRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const {
    data: noAssignedRequestsData,

    isFetching,
  } = useGetNoAssignedRequestsQuery({ page, pageSize });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات الجديدة`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>
      <DashboardIntro
        title={`${t("new requests", { ns: "dashboard" })}`}
        description={t("New Request Desc", {
          ns: "dashboard",
        })}
        label={"الطلبات المسندة"}
        to={"/assigned-requests"}
        breadcrumbData={NewRequestIndx(t)}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={newRequests}
          content={noAssignedRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.LAB_NO_ASSIGNED_REQUESTS }}
          emptyData={{
            title: t("NoNewRequest"),
            desc: t("No New Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            transferData: true,
            paramsKey: "id",
            to: (id) => `/new-requests/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            totalCount: noAssignedRequestsData?.totalElements,
            onChangePaginationDataHandler,
          }}
        />
      </BaseCard>
    </>
  );
};

export default NewRequestsIndex;
