import { Box, Typography } from "@mui/material";
import AddConsultantForm from "components/Forms/AddConsultantForm/AddConsultantForm";
import { UsersRolesEnum } from "constants/UsersTypes";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "utils/Numbers/Numbers";

const LabConsultantData = ({ data, requestTypeId, ...props }) => {
  const hasConsultant = data?.consultantEmail || data?.consultantMobile;

  const hasConsultantBE = data?.consultant;

  const isHasPendingRequest = data?.hasPendingRequest;

  const [showAddConsultantForm, setShowAddConsultantForm] = useState(false);

  const valueSx = {
    fontSize: "1.6rem",
    color: "base.black",
  };

  useEffect(() => {
    if (!data) {
      setShowAddConsultantForm(false);
    }
  }, [data]);

  const isConsultant = requestTypeId === UsersRolesEnum.CONSULTANT;

  if (!data && !showAddConsultantForm) {
    return (
      <Wrapper>
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          {"يرجى تحديد الرخصة حتى يتم عرض بيانات الاستشاري."}
        </Typography>
      </Wrapper>
    );
  }

  if (
    data &&
    !hasConsultantBE &&
    !showAddConsultantForm &&
    !isHasPendingRequest &&
    !hasConsultant
  ) {
    return (
      <Wrapper variant="error">
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          لا يمكن رفع طلب فحص على هذه الرخصة بسبب عدم إسنادها لاستشاري، يرجى
          التواصل مع الاستشاري المسؤول وطلب إسنادها.
        </Typography>
      </Wrapper>
    );
  }

  if (data && isConsultant && isHasPendingRequest && !showAddConsultantForm) {
    return (
      <Wrapper variant="error">
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          {"يوجد طلب إسناد سابق لهذه الرخصة، يرجى قبوله لتتمكن من رفع الطلب."}
        </Typography>
      </Wrapper>
    );
  }

  if (data && showAddConsultantForm) {
    return (
      <Wrapper>
        <AddConsultantForm title={false} {...props.consultantForm} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Box
        variant={"secondary"}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <WrapperContentGrid
          sx={{ flexDirection: "row", width: "100%" }}
          columnsWidth={24}
          gap={2}
        >
          <BaseDataItem
            label={"الاسم"}
            valueComponentAs={"div"}
            value={<Typography sx={valueSx}>{data?.consultantName}</Typography>}
          />
          <BaseDataItem
            label={"رقم الجوال"}
            valueComponentAs={"div"}
            value={
              <Typography sx={valueSx}>
                {formatPhoneNumber(data?.consultantMobile)}
              </Typography>
            }
          />
          <BaseDataItem
            label={"البريد الإلكتروني"}
            valueComponentAs={"div"}
            value={
              <Typography sx={valueSx}>{data?.consultantEmail}</Typography>
            }
          />
        </WrapperContentGrid>
      </Box>
    </Wrapper>
  );
};

function Wrapper(props) {
  return (
    <BaseCard
      variant={props.variant || "secondary"}
      py={"3rem"}
      px={"3rem"}
      borderRadius=".9rem"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: "" }}
    >
      {props.children}
    </BaseCard>
  );
}
Wrapper.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

LabConsultantData.propTypes = {
  data: PropTypes.object,
  requestTypeId: PropTypes.string,
  consultantForm: PropTypes.object,
};

export default LabConsultantData;
