import { Box, Stack } from "@mui/material";
import classes from "../BaseCamera.module.scss";
import BaseModal from "core-ui/BaseModal/BaseModal";
import Webcam from "react-webcam";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import PropTypes from "prop-types";

const WebView = (props) => {
  return (
    <BaseModal
      show={props.show}
      blurry={props.blurry}
      onHide={props.onHide}
      mainContainerSx={props.mainContainerSx}
      title={"تصوير النتائج"}
      closeIcon
      isLoading={props.isUploading}
    >
      <BaseAlert
        px={"2rem"}
        show={props.alert.show}
        type={props.alert.type}
        message={props.alert.message}
      />

      <Box p={5} className={classes["base-camera__camera"]}>
        {props.isPhotoCaptured ? (
          <img
            src={props.photo}
            className={classes["base-camera__image"]}
            alt="photo-base-camera-web"
          />
        ) : (
          <Webcam
            ref={props.webcamRef}
            screenshotFormat={props.screenshotFormat || "image/jpeg"}
            audio={props.audio || false}
            videoConstraints={props.videoConstraints}
            mirrored={props.mirrored || false}
            imageSmoothing={props.imageSmoothing || true}
          />
        )}
      </Box>
      <Stack
        p={5}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack gap={2} direction="row">
          {props.isPhotoCaptured ? (
            <>
              {props.isUploadAble ? (
                <BaseButton
                  onClick={props.uploadPhotoHandler}
                  isLoading={props.isUploading}
                >
                  رفع الصورة
                </BaseButton>
              ) : (
                <BaseButton onClick={props.savePhotoHandler}>تأكيد</BaseButton>
              )}
              <BaseButton
                variant={"secondary"}
                sx={{ minWidth: "12rem" }}
                onClick={props.onDeleteHandler}
                disabled={props.isUploading}
              >
                حذف
              </BaseButton>
            </>
          ) : (
            <BaseButton onClick={props.onCaptureHandler}>التقاط</BaseButton>
          )}
        </Stack>
        <BaseButton
          variant={"secondary"}
          sx={{ minWidth: "12rem" }}
          onClick={props.onHide}
          disabled={props.isUploading}
        >
          الغاء
        </BaseButton>
      </Stack>
    </BaseModal>
  );
};

WebView.propTypes = {
  show: PropTypes.bool,
  isUploading: PropTypes.bool,
  isPhotoCaptured: PropTypes.bool,
  isUploadAble: PropTypes.bool,
  audio: PropTypes.bool,
  mirrored: PropTypes.bool,
  blurry: PropTypes.bool,
  imageSmoothing: PropTypes.bool,
  alert: PropTypes.object,
  webcamRef: PropTypes.object,
  videoConstraints: PropTypes.object,
  mainContainerSx: PropTypes.object,
  onHide: PropTypes.func,
  uploadPhotoHandler: PropTypes.func,
  onDeleteHandler: PropTypes.func,
  savePhotoHandler: PropTypes.func,
  onCaptureHandler: PropTypes.func,
  photo: PropTypes.string,
  screenshotFormat: PropTypes.string,
};

export default WebView;
