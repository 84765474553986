import { Box } from "@mui/material";
import PropTypes from "prop-types";

const BaseContainer = (props) => {
  return (
    <Box
      sx={{
        maxWidth: "150rem",
        margin: "auto",
        padding: "5rem",
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

BaseContainer.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default BaseContainer;
