import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const FieldExaminationPointsConfirmation = ({ element, ...rest }) => {
  const { breakpoints } = useTheme();
  const lg = useMediaQuery(breakpoints.down("lg"));
  const { tableHeader } = rest.props;

  const gridColumnSpanForConfirmation = `1 / span ${tableHeader.length - 1}`;

  const currentScoresList =
    element.editedStation?.testStationMappingEdits?.flatMap(
      (edit) => edit.currentScores ?? []
    );

  const allConfirmationsTrue = element.maxProctorConfirmation === true;
  const allConfirmationsFalse = element.maxProctorConfirmation === false;

  const skipReason = element.labSkipReason;

  const renderTechnicianConfirmations = () => {
    let correctness = "";
    if (allConfirmationsTrue) {
      correctness = "صحيحة";
    } else if (allConfirmationsFalse) {
      correctness = skipReason ? "غير صحيحة" : "غير صحيحة، النتيجة النهائية:";
    }
    return (
      <Typography sx={{ fontSize: "1.4rem" }}>
        {skipReason
          ? "نتيجة فني الرقابة: سبب عدم إجراء الاختبار: "
          : "نتيجة فني الرقابة: "}
        <Typography component="span" fontSize="inherit" fontWeight="bold">
          {correctness}
        </Typography>
      </Typography>
    );
  };

  return (
    <BaseCard
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${lg ? 3 : tableHeader.length}, 1fr)`,
      }}
      px="2rem"
      py="1rem"
      variant="alternative-light"
    >
      <Box
        sx={{
          gridColumn: lg ? "1 / -1" : gridColumnSpanForConfirmation,
          gridRow: 1,
        }}
      >
        {renderTechnicianConfirmations()}
      </Box>
      {allConfirmationsFalse && !skipReason && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.5rem",
              gridColumn: lg ? 2 : tableHeader.length,
              gridRow: lg ? 3 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {currentScoresList
                .map((score) => score.technicianCorrectedScore)
                .filter((s) => s !== null && s !== undefined)
                .join(", ")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              gridColumn: lg ? 3 : tableHeader.length - 1,
              gridRow: lg ? 2 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {element.editedStation?.currentOptimumProctorCorrectedScore}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              gridColumn: lg ? 1 : tableHeader.length - 2,
              gridRow: lg ? 3 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {element.editedStation?.currentMaxProctorCorrectedScore}
            </Typography>
          </Box>
        </>
      )}
    </BaseCard>
  );
};

FieldExaminationPointsConfirmation.propTypes = {
  element: PropTypes.object,
};

export default FieldExaminationPointsConfirmation;
