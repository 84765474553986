import BaseButton from "core-ui/BaseButton/BaseButton";

const RaiseNewRequest = () => {
  return (
    <BaseButton
      variant="secondary"
      sx={{
        minWidth: "10rem",
        paddingBlock: ".5rem",
        paddingInline: "1rem",
      }}
    >
      طلب الفحص
    </BaseButton>
  );
};

export default RaiseNewRequest;
