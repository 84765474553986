import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import FieldExaminationActualScores from "./FieldExaminationActualScores";
import PropTypes from "prop-types";

const FieldExaminationPointsConfirmation = ({
  displayActualScores,
  ...props
}) => {
  const { breakpoints } = useTheme();

  const lg = useMediaQuery(breakpoints.down("lg"));

  const gridColumnSpanForConfirmation = `1 / span ${
    props.props.tableHeader.length - 1
  }`;

  const scoresList = props.element.scoresList || [];

  const allConfirmationsTrue = props.element.maxProctorConfirmation === true;

  const allConfirmationsFalse = props.element.maxProctorConfirmation === false;

  const skipReason = props.element[props.skipReasonKey || "skipReason"];

  const renderTechnicianConfirmations = () => {
    if (skipReason) {
      let correctness = "";
      if (allConfirmationsTrue) {
        correctness = "صحيحة";
      }
      if (allConfirmationsFalse) {
        correctness = "غير صحيحة";
      }
      return (
        <Typography sx={{ fontSize: "1.4rem" }}>
          صحة سبب عدم اجراء الاختبار :{" "}
          <Typography
            component={"span"}
            fontSize={"inherit"}
            fontWeight={"bold"}
          >
            {correctness}
          </Typography>
        </Typography>
      );
    } else {
      let correctness = "";
      if (allConfirmationsTrue) {
        correctness = "صحيحة";
      }
      if (allConfirmationsFalse) {
        correctness = "غير صحيحة، النتيجة النهائية:";
      }
      return (
        <Typography sx={{ fontSize: "1.4rem" }}>
          صحة النتيجة:{" "}
          <Typography
            component={"span"}
            fontSize={"inherit"}
            fontWeight={"bold"}
          >
            {correctness}
          </Typography>
        </Typography>
      );
    }
  };

  return (
    <>
      <BaseCard
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${
            lg ? 3 : props.props.tableHeader.length
          } , 1fr)`,
        }}
        px={"2rem"}
        py={"1rem"}
        variant={allConfirmationsTrue ? "success" : "error"}
      >
        <Box
          sx={{
            gridColumn: lg ? `1 / -1` : gridColumnSpanForConfirmation,
            gridRow: 1,
          }}
        >
          {renderTechnicianConfirmations()}
        </Box>
        {allConfirmationsFalse && !skipReason && scoresList.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
                gridColumn: lg ? 2 : `${props.props.tableHeader.length}`,
                gridRow: lg ? 3 : 1,
                ml: 4,
              }}
            >
              <Typography sx={{ fontSize: "1.6rem" }}>
                {scoresList
                  .map((score) => score.technicianCorrectedScore)
                  .filter((s) => s !== null && s !== undefined)
                  .join(", ")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                gridColumn: lg ? 1 : `${props.props.tableHeader.length - 2}`,
                gridRow: lg ? 3 : 1,
                ml: 4,
              }}
            >
              <Typography sx={{ fontSize: "1.6rem" }}>
                {`  ${props.element?.maxProctorCorrectedScore ?? ""} `}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                gridColumn: lg ? 3 : `${props.props.tableHeader.length - 1}`,
                gridRow: lg ? 2 : 1,
                ml: 4,
              }}
            >
              <Typography sx={{ fontSize: "1.6rem" }}>
                {`${props.element?.optimumProctorCorrectedScore ?? ""}`}
              </Typography>
            </Box>
          </>
        )}
      </BaseCard>
      {displayActualScores && <FieldExaminationActualScores {...props} />}
    </>
  );
};

FieldExaminationPointsConfirmation.propTypes = {
  props: PropTypes.object,
  element: PropTypes.object,
  skipReasonKey: PropTypes.string,
  displayActualScores: PropTypes.bool,
};

export default FieldExaminationPointsConfirmation;
