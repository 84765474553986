import { useEffect } from "react";

const useClickOutsideModal = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const isOutside = !ref.main.current?.contains(event.target);
      const isSafeArea = ref.safeArea.current?.contains(event.target);

      if (isSafeArea || isOutside) {
        handler.close();
      }
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useClickOutsideModal;
