import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaymentFormPrevButton from "./PaymentFormPrevButton/PaymentFormPrevButton";
import { useForm } from "react-hook-form";
import PaymentMethodSelector from "./PaymentMethodSelector/PaymentMethodSelector";
import PropTypes from "prop-types";
import CopyToClipboard from "components/CopyToClipboard/CopyToClipboard";
import BaseButton from "core-ui/BaseButton/BaseButton";

const PaymentForm = (props) => {
  const { t } = useTranslation(["wallet"]);

  const { control, watch } = useForm();

  const selectedPaymentMethod = watch("paymentMethod");

  const IBAN = props.contractorData?.virtualIban || "SA1234567890123456789012";

  useEffect(() => {
    // default value because IBAN is disabled for now
    // setValue("paymentMethod", "card");
    //

    if (selectedPaymentMethod === "card") {
      const script = document.createElement("script");
      script.src = props.isProduction
        ? `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${props.checkoutId}`
        : `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${props.checkoutId}`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [selectedPaymentMethod, props.checkoutId]);

  const paymentMethods = [
    {
      id: "card",
      name: "يمكنك استخدام إحدى البطاقات التالية",
      value: "card",
      checked: true,
      content: (
        <Box>
          <form
            action={props.shopperResultUrl}
            className="paymentWidgets"
            data-brands="VISA MASTER MADA"
          ></form>
        </Box>
      ),
    },
    {
      id: "bank",
      name: "الدفع باستخدام التحويل البنكي",
      value: "bank",
      disabled: !props.contractorData?.virtualIban,
      content: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontSize: "1.4rem", fontWeight: 400 }}>
              {`يمكنك تحويل مبلغ التكلفة الكلية من أي بنك باستعمال رقم الآيبان التالي: `}
            </Typography>

            <Typography
              component={"span"}
              sx={{ fontWeight: 500, fontSize: "1.4rem", marginLeft: "0.5rem" }}
            >
              {IBAN}
            </Typography>
            <CopyToClipboard text={IBAN} label="نسخ" />
          </Box>
          <Typography sx={{ fontSize: "1.4rem", fontWeight: 400, mt: "1rem" }}>
            {"سيتم تحديث نقاط المحفظة بعد اتمام التحويل البنكي"}
          </Typography>
          <BaseButton
            onClick={props.resetSelectedPlan}
            sx={{ mb: "1rem", mt: "2rem" }}
          >
            {"عودة إلى الباقات"}
          </BaseButton>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <PaymentFormPrevButton onClick={props.resetSelectedPlan} mb={2} />
      <Typography mb={2} sx={{ fontSize: "1.8rem" }}>
        {t("اختر طريقة الدفع")}
      </Typography>
      <PaymentMethodSelector
        name="paymentMethod"
        control={control}
        data={paymentMethods}
      />
    </Box>
  );
};

PaymentForm.propTypes = {
  isProduction: PropTypes.bool,
  checkoutId: PropTypes.string,
  shopperResultUrl: PropTypes.string,
  resetSelectedPlan: PropTypes.func,
};

export default PaymentForm;
