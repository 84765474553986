import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useGetAssignedRequestsQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { AsseigndeRequestIndx } from "services/StaticLookup/Breadcrumb";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import DisplayTimeRangeForLab from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayTimeRangeForLab/DisplayTimeRangeForLab";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const AssignedRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const {
    data: labAssignedRequestsData,
    isFetching,
    refetch,
  } = useGetAssignedRequestsQuery({ page, pageSize });

  const assignedRequests = useMemo(
    () => [
      { serverKey: "id", name: "request_number" },
      {
        serverKey: "status",
        hasComponent: {
          comp: <BaseStatus />,
        },
        name: "order_status",
      },
      {
        serverKey: ["samplestatus", "testType"],
        hasComponent: {
          customProps: ["status", "testType"],
          comp: <BaseStatus />,
        },
        name: "samples_status",
      },

      {
        serverKey: "testType",
        name: "request_type",
        hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
      },
      {
        serverKey: "labTechName",
        name: "lab_tech_name",
      },
      {
        serverKey: "scheduledAppointment",
        name: "Examinationـdate",
        hasComponent: {
          comp: <DisplayTimeRangeForLab />,
        },
      },
    ],
    []
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <DashboardIntro
        title={`${t("assigned requests", { ns: "dashboard" })}`}
        description={t("assigned requests desc", {
          ns: "dashboard",
        })}
        breadcrumbData={AsseigndeRequestIndx(t)}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={assignedRequests}
          content={labAssignedRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.LAB_ASSIGNED_REQUESTS }}
          emptyData={{
            title: t("No requests"),
            desc: t("LabNoRequest"),
            img: publicAssetsPath("images/documents3.svg"),
          }}
          withDetails={{
            transferData: true,
            paramsKey: "id",
            to: (id) => `/assigned-requests/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            totalCount: labAssignedRequestsData?.totalElements,
            onChangePaginationDataHandler,
          }}
        />
      </BaseCard>
    </>
  );
};

export default AssignedRequestsIndex;
