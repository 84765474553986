import { Box } from "@mui/material";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";
import { MultipleConsultantViewTableHeader } from "services/StaticLookup/TableHeader/TableHeader";

const MultipleConsultantModel = ({ licenses }) => {
  return (
    <Box>
      <BaseTableIndex
        tableHeader={MultipleConsultantViewTableHeader}
        content={licenses}
      />
    </Box>
  );
};

MultipleConsultantModel.propTypes = {
  licenses: PropTypes.array,
};

export default MultipleConsultantModel;
