import React from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { formattedNumber } from "utils/Numbers/Numbers";
import PropTypes from "prop-types";

function PointsCard(props) {
  return (
    <Box
      sx={{
        width: "100%",
        filter: !props.isProfileCompleted ? "grayscale(1)" : "",
      }}
    >
      <BaseCard
        variant="secondary-fancy"
        borderRadius="1.0rem"
        sx={{ width: "auto", height: "10rem" }}
        py={"2rem"}
        px={"4rem"}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            color: "base.white",
            fontSize: "2rem",
          }}
        >
          <Typography sx={{ mr: 1, fontSize: "5rem", fontWeight: "bold" }}>
            {props.isLoading ? 0.0 : formattedNumber(props.walletPoints || 0)}
          </Typography>
          <Typography sx={{ fontSize: "2rem", mt: 4 }}>نقطة</Typography>
        </Box>
      </BaseCard>
    </Box>
  );
}

PointsCard.propTypes = {
  isProfileCompleted: PropTypes.bool,
  isLoading: PropTypes.bool,
  walletPoints: PropTypes.number,
};

export default PointsCard;
