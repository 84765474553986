import { Box } from "@mui/material";
import { CONSULTATION_STATUS } from "constants/Details";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ConsultantActionsButtons = ({
  acceptRequest,
  alert,
  hideAlertHandler,
  requestsDetailsData,
  approveOrRejectRequestResponse,
  setShowRejectionModal,
}) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <BaseAlert
        show={alert.show}
        type={alert.type}
        destroy={hideAlertHandler}
        autoClose={5}
        icon={false}
        closeIcon={true}
        closeIconColor={"black"}
        labelSx={{ fontWeight: "bold", color: "black" }}
        interpolation={alert.interpolation}
        message={alert.message}
      />
      {requestsDetailsData?.status === CONSULTATION_STATUS.PENDING && (
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          <BaseButton
            sx={{
              minWidth: "12.2rem",
            }}
            variant={
              approveOrRejectRequestResponse?.isLoading
                ? "disabled"
                : "secondary"
            }
            onClick={() => setShowRejectionModal(true)}
            disabled={approveOrRejectRequestResponse?.isLoading}
          >
            {t("reject")}
          </BaseButton>
          <BaseButton
            sx={{
              minWidth: "12.2rem",
            }}
            variant={
              approveOrRejectRequestResponse?.isLoading ? "disabled" : ""
            }
            disabled={approveOrRejectRequestResponse?.isLoading}
            onClick={() => acceptRequest()}
          >
            {t("accept")}
          </BaseButton>
        </Box>
      )}
    </Box>
  );
};

ConsultantActionsButtons.propTypes = {
  acceptRequest: PropTypes.func,
  alert: PropTypes.object,
  hideAlertHandler: PropTypes.func,
  requestsDetailsData: PropTypes.object,
  approveOrRejectRequestResponse: PropTypes.object,
  setShowRejectionModal: PropTypes.func,
};

export default ConsultantActionsButtons;
