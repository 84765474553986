export const queryBuilder = (data) => {
  if (!data) return "";

  return Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join("&");
};

export function base64ToBlob(dataURI) {
  if (!dataURI || dataURI.indexOf("base64") === -1) return null;
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = decodeURIComponent(dataURI.split(",")[1]);
  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function convertBlobToFile(blob) {
  if (!blob) return null;
  let fileOfBlob = new File([blob], `file.${blob.type.split("/")[1]}`, {
    type: blob.type,
  });

  return fileOfBlob;
}

export function previwPDFFromBlobToFile(pdfData) {
  if (!pdfData) return null;
  const blob = base64ToBlob(pdfData);
  const fileOfBlob = convertBlobToFile(blob);
  const blobUrl = URL.createObjectURL(fileOfBlob);
  const newTab = window.open("", blobUrl);
  return { newTab, pdfContent: blobUrl };
}
