import { isContentReady } from "utils/Array/Arrays";
import classes from "../BaseSmartSearch.module.scss";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const SmartSearchList = (props) => {
  if (props.isLoading) return;

  return (
    props.value &&
    props.showSmartSearchList && (
      <ul
        className={`${classes["smart-search-list"]} ${
          !isContentReady(props.data) ? classes["smart-search-list--empty"] : ""
        }`}
      >
        {isContentReady(props.data) ? (
          props.data.map((item) => {
            return (
              <Box
                component={"li"}
                className={classes["smart-search-list__item"]}
                key={item.id}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(item);
                  }
                  props.setShowSmartSearchList(false);
                }}
              >
                {props.dynamicItemKey(item)}
              </Box>
            );
          })
        ) : (
          <li className={classes["smart-search-list__item"]}>لا يوجد تطابق</li>
        )}
      </ul>
    )
  );
};

SmartSearchList.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
  setShowSmartSearchList: PropTypes.func,
  dynamicItemKey: PropTypes.func,
  value: PropTypes.string,
  showSmartSearchList: PropTypes.bool,
};

export default SmartSearchList;
