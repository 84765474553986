import React from "react";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import EditRequestField from "modules/Admin/components/DataDetails/EditRequest/Field/EditRequestField";
import PropTypes from "prop-types";

function FieldEditRequestDetails({ data, isFetching }) {
  // stations handlers
  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData({ id: data?.inspectionId });

  return (
    <EditRequestField
      data={data}
      isFetching={isFetching}
      stations={stations}
      isStationsFetching={isStationsFetching}
    />
  );
}

FieldEditRequestDetails.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default FieldEditRequestDetails;
