import inspectionApi from "../../../Inspection/apis";

const inspectionLabApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getSamplesForLab: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/samples/v2/laboratory/details/${inspectionLayerId}`,
      }),
      // ⚠️
      providesTags: [
        "Lab-Assigning",
        "Request",

        "Lab-Inspection-Tests",
        "Lab-Inspection-Sub-Tests",
        "Proctor",
        "Samples",
      ],
    }),
    // NEW
    getSubTestsForLab: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionSubTests/v2/laboratory/${inspectionLayerId}`,
      }),

      // ⚠️
      providesTags: [
        "Lab-Assigning",
        "Request",

        "Lab-Inspection-Tests",
        "Lab-Inspection-Sub-Tests",
        "Proctor",
        "Samples",
      ],
    }),
    // NEW
    getAppointmentDetailsForLab: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/contractor/appointments/v2/laboratory/${inspectionLayerId}`,
      }),
      // ⚠️
      providesTags: [
        "Lab-Assigning",
        "Request",

        "Lab-Inspection-Tests",
        "Lab-Inspection-Sub-Tests",
        "Proctor",
        "Samples",
      ],
    }),
    getNoAssignedRequests: builder.query({
      query: ({ page = 0, pageSize = 10, sortId = "id,desc" }) => ({
        url: `/api/inspection/inspections/nonAssignedRequests?page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      providesTags: ["Lab-Assigning", "Request"],
    }),
    getAssignedRequests: builder.query({
      query: ({ page = 0, pageSize = 10, sortId = "id,desc" }) => ({
        url: `/api/inspection/inspections/labAssignedRequests?page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      providesTags: ["Lab-Assigning", "Request"],
    }),
    getTechnicianData: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/technicianDetails/${inspectionId}`,
      }),
      providesTags: ["Lab-Assigning", "Request"],
    }),

    receiveSamplesByLab: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/samples/receivedByLab`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request", "Samples"],
    }),
    addScoreToTest: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspectionTests/score`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request"],
    }),
    addPointsToTest: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspectionTests/points`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Lab-Inspection-Tests"],
    }),
    addProctorScore: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspectionTests/proctorScores`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Proctor"],
    }),
    getProctorData: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionTests/proctorScores/${inspectionLayerId}`,
      }),
      providesTags: ["Proctor"],
    }),
    // 1. getInspectionTestsData FOR FIELD TESTS
    getInspectionTestsData: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspectionTests/${inspectionId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Tests", "C-Request-Details"],
    }),
    // 2. getInspectionTestsData - NEW
    getInspectionTestsDataForLab: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionTests/v2/laboratory/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Sub-Tests"],
    }),
    labSaveAttachment: builder.mutation({
      query: ({ body, inspectionLayerId }) => ({
        url: `/api/inspection/inspectionLayers/saveAttachment?inspectionLayerId=${inspectionLayerId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request"],
    }),
    addAppointmentReport: builder.mutation({
      query: ({ body, appointmentId }) => ({
        url: `/api/inspection/inspectionTests/appointmentReport/${appointmentId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request", "Record-Form"],
    }),
    getAppointmentReport: builder.query({
      query: (appointmentId) => ({
        url: `/api/inspection/inspectionTests/appointmentReport/${appointmentId}`,
      }),
      providesTags: ["Request", "Record-Form"],
    }),
    getQuestionnaireRecoord: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspectionQuestionnaire/technician/${inspectionId}`,
      }),
      providesTags: ["Request", "Record-Form"],
    }),
    getLaboratoryRequestDetails: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionLayers/laboratory/${inspectionLayerId}`,
      }),
      providesTags: ["Request"],
    }),
    getLabDraftDataForEditRequest: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionEdits/inspectionLayer/${inspectionLayerId}`,
      }),
    }),
    saveDraft: builder.mutation({
      query: ({ body, editRequestId, apiKey }) => ({
        url: `/api/inspection/${apiKey}/${editRequestId}/saveDraft`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        "Request",
        "Test-Attachment",
        "Lab-Inspection-Tests",
        "Lab-Inspection-Sub-Tests",
        "Proctor",
        "Samples",
      ],
    }),
    saveDraftFieldTest: builder.mutation({
      query: ({ body, inspectionId }) => ({
        url: `/api/inspection/inspections/v2/FIELD/${inspectionId}/saveDraft`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        "Request",
        "Lab-Inspection-Tests",
        "Proctor",
        "Samples",
        "Test-Attachment",
      ],
    }),
    saveDraftLabTest: builder.mutation({
      query: ({ inspectionId, ...data }) => {
        return {
          url: `/api/inspection/inspections/v2/LAB/${inspectionId}/saveDraft`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [
        "Request",
        "Lab-Inspection-Tests",
        "Test-Attachment",
        "Samples",
        "Check-Editable",
      ],
    }),
    submitLabFormRequest: builder.mutation({
      query: ({ body, editRequestId, apiKey }) => ({
        url: `/api/inspection/${apiKey}/${editRequestId}/submit`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Request"],
    }),
    submitAssignedRequest: builder.mutation({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/${inspectionId}/submit`,
        method: "PUT",
      }),
      invalidatesTags: ["Request"],
    }),

    editFieldTestRequest: builder.mutation({
      query: ({ inspectionId, body }) => ({
        url: `/api/inspection/inspectionEdits/FIELD/${inspectionId}`,
        body,
        method: "PUT",
      }),
      invalidatesTags: [
        "Request",
        "Lab-Inspection-Tests",
        "Proctor",
        "Samples",
        "Test-Attachment",
      ],
    }),
    editLabTestRequest: builder.mutation({
      query: ({ inspectionId, ...data }) => ({
        url: `/api/inspection/inspectionEdits/LAB/${inspectionId}`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: [
        "Request",
        "Lab-Inspection-Tests",
        "Test-Attachment",
        "Samples",
        "Check-Editable",
      ],
    }),
    labSaveAttachmentNewApi: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspections/${body.inspectionId}/saveAttachment`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request", "Test-Attachment"],
    }),
    getAsphaltGradationTables: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/aspeltGradationLimits/laboratory/inspectionLayer/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Tests"],
    }),
    sendLabAssignmentData: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspections/labassignment`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ["Lab-Assigning"]),
    }),
    geStationNumber: builder.query({
      query: ({ id, isCustomResponse }) => ({
        url: `/api/inspection/station/${id}`,
        params: { isCustomResponse },
      }),
      transformResponse: (response, meta, arg) => {
        if (arg.isCustomResponse) {
          return response.map((station) => ({
            id: station.id,
            from: station.from,
            to: station.to,
            distance: station.distance,
            layerNumber: station.layerNumber,
            layerName: station.layerName,
            name: station.layerName,
          }));
        }
        return response;
      },
      providesTags: ["Request", "Edit-Stations"],
    }),
    checkEditableForLaboratory: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/v2/laboratory/checkIfeCanEditable/${inspectionId}`,
      }),
      providesTags: ["Request", "Test-Attachment", "Check-Editable"],
    }),
    getInspectionForLab: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/v2/laboratory/${inspectionId}`,
      }),
      providesTags: ["Request"],
      invalidatesTags: ["Lab-Tech-List"],
    }),
    getAttachmentForLaboratory: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/${inspectionId}/labResultAttachment`,
      }),
      providesTags: ["Test-Attachment"],
    }),
    getSoilClassifications: builder.query({
      query: () => ({
        url: `/api/inspection/inspections/soilClassifications`,
      }),
      providesTags: ["Request", "Lab-Inspection-Tests"],
    }),
    getTestPrices: builder.query({
      query: () => ({
        url: `/api/inspection/inspections/testPrices`,
      }),
    }),
    getlabTechnicians: builder.query({
      query: ({ page = 0, pageSize = 10, sortId = "id,desc" }) => ({
        url: `/api/admin/laboratorys/labTechnicians?page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Tests", "Add-New-User"],
    }),
    getlabTechniciansById: builder.query({
      query: (labTechnicianId) => {
        return {
          url: `/api/admin/laboratorys/labTechnician/${labTechnicianId}`,
        };
      },
      providesTags: ["Request", "Lab-Inspection-Tests"],
    }),
  }),
});

export const {
  useSaveDraftLabTestMutation,
  useGetAttachmentForLaboratoryQuery,
  useSubmitAssignedRequestMutation,
  useGetInspectionForLabQuery,
  useSaveDraftFieldTestMutation,
  useSendLabAssignmentDataMutation,
  useGeStationNumberQuery,
  useGetAppointmentDetailsForLabQuery,
  useGetInspectionTestsDataForLabQuery,
  useGetSamplesForLabQuery,
  useGetAsphaltGradationTablesQuery,
  useGetSubTestsForLabQuery,
  useGetNoAssignedRequestsQuery,
  useGetAssignedRequestsQuery,
  useReceiveSamplesByLabMutation,
  useAddScoreToTestMutation,
  useAddPointsToTestMutation,
  useAddAppointmentReportMutation,
  useGetTechnicianDataQuery,
  useLazyGetTechnicianDataQuery,
  useGetAppointmentReportQuery,
  useGetQuestionnaireRecoordQuery,
  useLabSaveAttachmentMutation,
  useAddProctorScoreMutation,
  useGetProctorDataQuery,
  useGetInspectionTestsDataQuery,
  useGetLaboratoryRequestDetailsQuery,
  useSaveDraftMutation,
  useSubmitLabFormRequestMutation,
  useLazyGetLabDraftDataForEditRequestQuery,
  useGetLabRequestSummaryQuery,
  useLabSaveAttachmentNewApiMutation,
  useGetSoilClassificationsQuery,
  useCheckEditableForLaboratoryQuery,
  useEditFieldTestRequestMutation,
  useEditLabTestRequestMutation,
  useGetTestPricesQuery,
  useGetlabTechniciansQuery,
  useGetlabTechniciansByIdQuery,
} = inspectionLabApi;

export default inspectionLabApi;
