import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddCheckupRequestButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard"]);

  return (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={() => navigate("/home/request-path")}
    >
      {t("request checkup", { ns: "dashboard" })}
    </BaseButton>
  );
};

export default AddCheckupRequestButton;
