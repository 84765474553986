import ViewCertificateButton from "./ViewCertificateButton";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const PageActionButton = (props) => {
  function render() {
    if (props.licenseData?.certificateIssued) {
      return (
        <ViewCertificateButton
          licenseData={props.licenseData}
          showAlertHandler={props.showAlertHandler}
        />
      );
    }
  }

  return <Box>{render()}</Box>;
};

PageActionButton.propTypes = {
  licenseData: PropTypes.object,
  showAlertHandler: PropTypes.func,
};

export default PageActionButton;
