import _axios from "config/axios.config";
import { baseURL } from "utils/Env/Env";

const axiosBaseQuery = () => {
  return async ({ url, method, body, params }) => {
    try {
      const result = await _axios({
        url: baseURL() + url,
        method,
        data: body,
        params,
      });
      return { data: result.data };
    } catch (err) {
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};

export default axiosBaseQuery;
