export const calculateEditedStations = (stations, data) => {
  return stations?.map((station) => {
    const editedStation = data?.stationEdits?.find(
      (scoreItem) => scoreItem.station === station?.id
    );

    if (editedStation) {
      const maxProctorMatch =
        editedStation?.updatedMaxProctor === editedStation?.currentMaxProctor;
      const optimumProctorMatch =
        editedStation?.updatedOptimumProctor ===
        editedStation?.currentOptimumProctor;

      const scoresMatch = editedStation?.testStationMappingEdits?.every(
        (edit) => {
          if (edit?.currentScores?.length !== edit?.updatedScores?.length) {
            return false;
          }
          return edit.currentScores.every((currentScore, index) => {
            const updatedScore = edit?.updatedScores[index];
            return currentScore?.labScore === updatedScore.labScore;
          });
        }
      );

      return {
        ...station,
        ...editedStation,
        maxProctorMatch,
        optimumProctorMatch,
        scoresMatch,
        editedStation,
        id: station?.id,
      };
    }

    return station;
  });
};
