import { useCallback, useEffect, useRef, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import classes from "./BaseCamera.module.scss";
import { useTheme } from "@emotion/react";
import WebView from "./WebView/WebView";
import MobView from "./MobView/MobView";
import { AlertTypes } from "constants/AlertTypes";
import useAlert from "hooks/useAlert";
import PropTypes from "prop-types";

const BaseCamera = (props) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [isPhotoCaptured, setIsPhotoCaptured] = useState(false);

  const [photo, setPhoto] = useState(null);

  const webcamRef = useRef(null);

  const isUploadAble = !!props.onUpload;

  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "user",
  });

  const onCaptureHandler = useCallback(() => {
    setIsPhotoCaptured(true);
    const photo = webcamRef.current.getScreenshot();
    setPhoto(photo);
    props.onChange({ photo, saved: false });
    if (props.onFormChange) {
      props.onFormChange(photo);
    }
  }, [webcamRef]);

  function onDeleteHandler() {
    setIsPhotoCaptured(false);
    setPhoto(null);
    props.onChange(null);
    hideAlertHandler();
    if (props.onFormChange) {
      props.onFormChange(null);
    }
  }

  function onHide() {
    onDeleteHandler();
    props.onHide();
  }

  useEffect(() => {
    const isSuccess = props.isSuccessUpload;
    const alertType = () => {
      if (isSuccess === true) {
        return AlertTypes.SUCCESS;
      } else if (isSuccess === false) {
        return AlertTypes.ERROR;
      } else {
        return "";
      }
    };
    const alertMessage = isSuccess
      ? ""
      : props.uploadErrors?.data?.errorCode || "";

    showAlertHandler({
      show: props.uploadErrors || props.isSuccessUpload,
      type: alertType(),
      message: alertMessage,
    });
  }, [props.uploadErrors]);

  const uploadPhotoHandler = () => {
    if (isUploadAble) {
      hideAlertHandler();
      props.onUpload(photo);
    } else {
      props.onHide();
    }
  };

  const savePhotoHandler = () => {
    props.onChange({ photo, saved: true });
    if (props.onFormChange) {
      props.onFormChange(photo);
    }
    props.onHide();
    setIsPhotoCaptured(false);
  };

  const switchCamera = () => {
    let facingMode = videoConstraints.facingMode;

    facingMode = facingMode === "user" ? { exact: "environment" } : "user";

    setVideoConstraints({
      facingMode,
    });
  };

  const mirrored = videoConstraints.facingMode === "user";

  useEffect(() => {
    if (props.isSuccessUpload) {
      onHide();
    }
  }, [props.isSuccessUpload]);

  return (
    <Box className={classes["base-camera"]}>
      {matches ? (
        <MobView
          {...props}
          photo={photo}
          webcamRef={webcamRef}
          onCaptureHandler={onCaptureHandler}
          uploadPhotoHandler={uploadPhotoHandler}
          savePhotoHandler={savePhotoHandler}
          isPhotoCaptured={isPhotoCaptured}
          videoConstraints={videoConstraints}
          onDeleteHandler={onDeleteHandler}
          switchCamera={switchCamera}
          onHide={onHide}
          mirrored={mirrored}
          alert={alert}
          isUploadAble={isUploadAble}
          isUploading={props.isUploading}
        />
      ) : (
        <WebView
          {...props}
          photo={photo}
          webcamRef={webcamRef}
          onCaptureHandler={onCaptureHandler}
          uploadPhotoHandler={uploadPhotoHandler}
          savePhotoHandler={savePhotoHandler}
          isPhotoCaptured={isPhotoCaptured}
          videoConstraints={videoConstraints}
          onDeleteHandler={onDeleteHandler}
          onHide={onHide}
          mirrored={false}
          alert={alert}
          isUploadAble={isUploadAble}
          isUploading={props.isUploading}
        />
      )}
    </Box>
  );
};

BaseCamera.propTypes = {
  onUpload: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onHide: PropTypes.func,
  isSuccessUpload: PropTypes.bool,
  isUploading: PropTypes.bool,
  uploadErrors: PropTypes.object,
};

export default BaseCamera;
