import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CopyLogo } from "assets/icons/copy.svg";
import classes from "./CopyToClipboard.module.scss";
import PropTypes from "prop-types";

const CopyToClipboard = ({ text, label }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isCopied) {
        setIsCopied(false);
      }
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCopied]);

  return (
    <Box onClick={copyToClipboard} className={classes["clipboard"]}>
      <Box
        component={"span"}
        className={`${classes["clipboard__tooltip"]} ${
          isCopied ? classes["clipboard__tooltip--copied"] : ""
        }`}
      >
        تم النسخ
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          ml: "1rem",
        }}
      >
        <Box sx={{ mr: "0.5rem" }}>
          <CopyLogo />
        </Box>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: 500,
            color: "#1F766C",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
};

export default CopyToClipboard;
