import { Box, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

const RaiseResults = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography
        mb={2}
        sx={{
          fontWeight: "500",
          fontSize: "1.6rem",
          color: "grayDark.color",
        }}
      >
        رفع النتائج
      </Typography>

      {!props.isSubmitted && (
        <>
          <Typography
            mb={3}
            sx={{ fontSize: "1.6rem", color: "grayDark.color" }}
          >
            يرجى تعبئة نموذج رفع النتائج
          </Typography>

          {/** TO NEW PAGE */}

          <BaseButton
            sx={{ mb: 4 }}
            onClick={() =>
              navigate(`/assigned-requests/${params.id}/${props.form}-form`)
            }
            disabled={props.disabled}
          >
            {props.isDraftedNotSubmitted
              ? "إكمال رفع النتائج"
              : "تعبئة النموذج"}
          </BaseButton>
        </>
      )}
    </Box>
  );
};

RaiseResults.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isDraftedNotSubmitted: PropTypes.bool,
};

export default RaiseResults;
