import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const BaseIcon = (props) => {
  const fontSize = {
    xg: "3.6rem",
    lg: "3.2rem",
    md: "2.8rem",
    sm: "2.2rem",
    xs: "1.6rem",
  };
  const clonedIconElement = React.cloneElement(props.icon, {
    sx: { fontSize: fontSize[props.size] || "md" },
  });

  return <Box sx={{ color: props.color }}>{clonedIconElement}</Box>;
};

BaseIcon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  size: PropTypes.string,
  color: PropTypes.string,
};

export default BaseIcon;
