import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CertificateTestsStatus = (props) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Typography sx={{ fontSize: "1.6rem", color: "grayDark.color" }}>
      {t(props?.status?.replace("[", "")?.replace("]", ""))}
    </Typography>
  );
};

CertificateTestsStatus.propTypes = {
  status: PropTypes.string,
};

export default CertificateTestsStatus;
