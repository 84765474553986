import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { AdminRequestsHeaders } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import RequestsSearch from "../../../components/RequestsSearch/RequestsSearch";
import { AdminRequestsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { fromPage } from "constants/FromPages";
import { useLazyGetRequestsQuery } from "modules/Admin/apis/inspection-apis/inspection-api";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const AdminRequests = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [searchTerm, setSearchTerm] = useState("");
  const [requestType, setRequestType] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getRequests, getRequestsResponse] = useLazyGetRequestsQuery();

  useEffect(() => {
    let timer = setTimeout(
      getRequests.bind(null, {
        page: searchTerm ? 0 : page,
        size: pageSize,
        sort: "id,desc",
        ...(searchTerm && { search: searchTerm }),
        ...(requestType && { testType: requestType }),
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm, requestType]);

  const isNoSearchData =
    (searchTerm || requestType) &&
    !isContentReady(getRequestsResponse?.data?.content);

  const emptyTitle = isNoSearchData ? t("No Search") : t("no requests");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No requests desc");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("Examination requests")}
        breadcrumbData={AdminRequestsBreadcrumb(t)}
        description={
          <>
            {t(
              "تمكنك هذه الصفحة من الاطلاع على جميع طلبات الفحص الموجودة في النظام، كما يمكنك أيضًا الاطلاع على تفاصيل الطلب من خلال الضغط على"
            )}
            <span style={{ fontWeight: 800 }}> التفاصيل.</span>
          </>
        }
      />

      <RequestsSearch
        setSearchTerm={setSearchTerm}
        setRequestType={setRequestType}
        placeholder="يمكنك البحث برقم الطلب أو باسم مقدم الطلب"
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={AdminRequestsHeaders}
          content={getRequestsResponse?.data?.content || []}
          isLoading={getRequestsResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_REQUESTS,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getRequestsResponse?.data?.totalElements,
          }}
          withDetails={{
            paramsKey: "id",
            to: (id) => `examination-request/${id}`,
            state: {
              fromPage: fromPage.ADMIN_REQUESTS,
            },
          }}
        />
      </BaseCard>
    </>
  );
};

export default AdminRequests;
