import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { defaultEmptyValue } from "constants/Empty";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import { adminLabRequestDetails } from "services/StaticLookup/TableHeader/TableHeader";
import {
  useGetSamplesForAdminQuery,
  useGetTestsForAdminQuery,
} from "modules/Admin/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";

function LabRequestDetailsLab({ ...props }) {
  const inspection = props.inspection;
  const { data: samplesData } = useGetSamplesForAdminQuery(inspection.id);
  const { data: inspectionTests } = useGetTestsForAdminQuery(inspection.id);

  const inspectionTestsData = inspectionTests?.[0]?.inspectionTests;

  return inspection ? (
    <Box>
      <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
        <Box className={"request-data-details__wrapper"} mb={6}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid columnsWidth={24} gap={4} sx={{ marginTop: 6 }}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم المختبر
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labName || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.id || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الطلب
              </Typography>
              <BaseStatus
                status={inspection?.status}
                dataId={props.dataId}
                testType={inspection.testType}
                appointment={inspection.appointment}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع الفحص
              </Typography>
              <BaseTypeTag type={inspection?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                عدد العينات
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.numberOfSamples}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موعد الفحص
              </Typography>
              <Typography className={"request-data-details__value"}>
                <DisplayRequestAppointmentDate
                  appointmentDate={props.appointmentData?.scheduledDate}
                  scheduledAppointment={props.appointmentData?.scheduledTime}
                />
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labEstimatedTime
                  ? `${inspection?.labEstimatedTime} أيام`
                  : defaultEmptyValue}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={"request-data-details__wrapper"} mt={6}>
          <Typography className={"request-data-details__title"}>
            الاختبارات
          </Typography>
          <Box>
            <BaseTableCard
              tableSx={{
                td: {
                  borderBottom: "0.1rem solid #EDEFF2",
                },
              }}
              tableHeader={adminLabRequestDetails}
              content={inspectionTestsData}
              isLoading={props.isFetching}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <BaseLoader />
  );
}

LabRequestDetailsLab.propTypes = {
  inspection: PropTypes.object,
  contractorCompanyInfo: PropTypes.object,
  technicianData: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  appointmentData: PropTypes.object,
  columns: PropTypes.number,
  questionnaireRecoord: PropTypes.object,
  inspectionTestsData: PropTypes.array,
  isFetching: PropTypes.bool,
};

export default LabRequestDetailsLab;
