import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import ExaminationDataField from "modules/Contractor/components/DataDetails/ExaminationData/Field/ExaminationDataField/ExaminationDataField";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";

function FieldExaminationRequests({
  dataId,
  requestDetails,
  labtestappointment,
  isFetching,
  canReschedule,
}) {
  const params = useParams();
  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData({ id: params.id });

  return (
    <ExaminationDataField
      title={"تفاصيل الطلب"}
      request={requestDetails}
      dataId={dataId}
      stations={stations}
      labtestappointment={labtestappointment}
      isFetching={isFetching}
      canReschedule={canReschedule}
      isStationsFetching={isStationsFetching}
    />
  );
}

FieldExaminationRequests.propTypes = {
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  requestDetails: PropTypes.object,
  labtestappointment: PropTypes.object,
  isFetching: PropTypes.bool,
  canReschedule: PropTypes.bool,
};

export default FieldExaminationRequests;
