import { isContentReady } from "utils/Array/Arrays";
import classes from "../BaseDropdown.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const PickupList = ({
  pickup,
  safeArea,
  pickupListRef,
  pickupData,
  setPickupData,
  onChange,
}) => {
  const pickupFallbackData = isContentReady(pickupData?.allData)
    ? pickupData?.allData
    : [];

  const removePickupItemHandler = (pickupItem) => {
    if (pickup) {
      let updatedPickupData = pickupData.allData.filter(
        (data) => data.id !== pickupItem.id
      );
      setPickupData({
        allData: updatedPickupData,
        item: pickupItem,
      });
      if (onChange) {
        onChange({
          allData: updatedPickupData,
          item: pickupItem,
        });
      }
    }
  };
  return (
    <ul
      data-safe={safeArea}
      ref={pickupListRef}
      className={classes["base-dropdown__pickup-list"]}
    >
      {pickupFallbackData?.map((data, idx) => {
        return (
          <li key={data.id} className={classes["base-dropdown__pickup-item"]}>
            {data.name}

            <CloseIcon
              className={classes["base-dropdown__pickup-icon"]}
              color={"primary"}
              fontSize={"medium"}
              onClick={removePickupItemHandler?.bind(null, data)}
            />
          </li>
        );
      })}
    </ul>
  );
};

PickupList.propTypes = {
  pickup: PropTypes.object,
  pickupData: PropTypes.object,
  pickupListRef: PropTypes.object,
  safeArea: PropTypes.string,
  onChange: PropTypes.func,
  setPickupData: PropTypes.func,
};

export default PickupList;
