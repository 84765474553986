import { Box } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AdminAddAttachment = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const {
    control,
    watch,

    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const file = watch(props.name);

  useEffect(() => {
    if (props.isError || props.isSuccess) {
      props.onHide();
    }
  }, [props.isError, props.isSuccess]);

  return (
    <BaseModal
      show={props.show}
      py={"3rem"}
      px={"3rem"}
      onHide={() => {
        if (props.isSubmitting) return;
        props.onHide();
      }}
      title={props.title}
    >
      <BaseUploadFile
        maxSize={{ size: 1, unit: "MB" }}
        control={control}
        name={props.name}
        errors={errors}
        onSubmit={props.onSubmit.bind(null, file)}
        availableExtensions={["xls", "xlsx"]}
        displayAvailableExtensions={"XML AND XLSX (Excel)"}
        isSubmitting={props.isSubmitting}
        isSuccess={props.isSuccess}
        isError={props.isError}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
          mt: 5,
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
          }}
          variant={"secondary"}
          onClick={() => {
            if (props.isSubmitting) return;
            props.onHide();
          }}
        >
          {t("إلغاء")}
        </BaseButton>
      </Box>
    </BaseModal>
  );
};

AdminAddAttachment.propTypes = {
  name: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default AdminAddAttachment;
