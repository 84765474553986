import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { consultantsRequests } from "services/StaticLookup/TableHeader/TableHeader";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useGetContractorConsultationRequestsQuery } from "modules/Contractor/apis/consultation-apis/consultation-apis";
import { TABLE_ID } from "constants/Table";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { ConsultantsRequest } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const ConsultantsRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);
  const location = useLocation();
  const navigate = useNavigate();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data: consultantsRequestsData, isFetching } =
    useGetContractorConsultationRequestsQuery({
      page,
      size: pageSize,
      sort: "id,desc",
    });

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: t("No requests"),
    desc: t("no consultation requests desc"),
  };

  useEffect(() => {
    const actionAlert = location.state?.alert;
    if (actionAlert) {
      showAlertHandler(actionAlert);
      navigate("", { replace: true }); //clear state on refresh
    }
  }, [location, showAlertHandler, navigate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات إسناد الرخص`}</title>
      </Helmet>

      <DashboardIntro
        title={`${t("consultation requests", {
          ns: "dashboard",
        })}`}
        description={
          <>
            {t("consultation requests desc", {
              ns: "dashboard",
            })}
            <span style={{ fontWeight: 800 }}> تفاصيل الطلب.</span>
          </>
        }
        breadcrumbData={ConsultantsRequest({})}
        warning={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            destroy={hideAlertHandler}
            autoClose={5}
            icon={false}
            closeIcon={true}
            message={alert.message}
            interpolation={alert.interpolation}
            labelSx={{ fontWeight: "bold", color: "black" }}
            width={"40rem"}
          />
        }
      />
      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={consultantsRequests}
          dataItemKey={"requestNumber"}
          content={consultantsRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.CONTRACTOR_CONSULTANT_REQUESTS }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "requestNumber",
            to: (id) => `/consultants-requests/${id}`,
          }}
          pagination={{
            displayBy: [5, 10, 15],
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: consultantsRequestsData?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ConsultantsRequestsIndex;
