import { Box, Typography } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./DashboardDrillingLicensesData.module.scss";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { defaultEmptyValue } from "constants/Empty";
import PropTypes from "prop-types";

const DrillingLicensesData = (props) => {
  return !props.data ? (
    <BaseLoader />
  ) : (
    <Box className={classes["drilling-data"]} mt={6} ml={4}>
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          {props.title || "الرخصة"}
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الرخصة
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.licenseYear}/{props.data.licenseNumber}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              مقدم الخدمة
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceProviderName}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              النوع
            </Typography>
            <BaseTypeTag type={props.data.typeRefId} />
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              تاريخ الانتهاء
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.expiryDate}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الحفر{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.drillingType || ""}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الخدمة{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceName || ""}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>

      <hr />
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          بيانات الحفر
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الطول
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.licenceLength
                ? `${props.data.licenceLength} م`
                : defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العرض
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryWidth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العمق
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryDepth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الحجم
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.volumNo}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>

      <hr />

      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          معلومات شركة المقاولات
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              اسم شركة المقاولات
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorCompany || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              المقاول
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorName || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              البريد الالكتروني
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorEmail || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الجوال
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {formatPhoneNumber(props.data.contractorMobile) || "لا يوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
    </Box>
  );
};

DrillingLicensesData.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DrillingLicensesData;
