import { useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "../../DrillingLicensesData/DashboardDrillingLicensesData.module.scss";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import { displayFormattedTimeHHMM, formatDateLocal } from "utils/Date/Date";
import DisplayProctorScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayProctorScores/DisplayProctorScores";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import useBaseCameraMultiple from "hooks/useBaseCameraMultiple";
import PreviewMultipleAttachments from "modules/Technician/components/FieldExaminationTestsContent/FieldExaminationTestsActions/PreviewMultipleAttachments/PreviewMultipleAttachments";
import PreviewAttachment from "modules/Technician/components/FieldExaminationTestsContent/FieldExaminationTestsActions/PreviewAttachment/PreviewAttachment";
import { useLazyGetTechnicianAttachmentQuery } from "modules/Technician/apis/inspection-apis/inspection-api";
import { useGetInspectionFieldReportQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { windowOpenPDFHandler } from "utils/File/File";
import Notes from "components/Notes/Notes";
import { useTranslation } from "react-i18next";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import PropTypes from "prop-types";

const AttachmentsDataField = ({
  inspection,
  isFetching,
  appointmentData,
  proctorScores,
  attachment,
  appointmentReportData,
  questionnaireRecoord,
}) => {
  const [previewImage, setPreviewImage] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);

  const { t } = useTranslation();

  const previewImageHandler = () => {
    setPreviewImage(true);
  };
  const closePreviewImageHandler = () => {
    setPreviewImage(false);
  };

  const [getAttachment] = useLazyGetTechnicianAttachmentQuery();

  const [photos, setPhotos] = useState([]);

  const { isFetchingAttachments, isAttachmentIdsEmpty } = useBaseCameraMultiple(
    getAttachment,
    appointmentData?.attachmentIds || [],
    setPhotos
  );

  const inspectionLayer = inspection?.inspectionLayersList?.[0];
  const isSoil =
    inspectionLayerFlagChecker(
      inspectionLayer?.flags,
      InspectionLayerFlags.IS_SOIL
    ) ?? undefined;
  const isRequestDone =
    inspection?.status === "SUCCEED" || inspection?.status === "FAILED";

  const isRequestDoneOrSkipped =
    isRequestDone || inspection?.status === "SKIPPED";

  const { data: inspectionFieldReport } = useGetInspectionFieldReportQuery(
    inspection?.id,
    {
      skip: !inspection?.id || !isSoil || !isRequestDoneOrSkipped,
    }
  );

  const displayIfExists = (condition) => {
    if (condition === null) return "لا يوجد";

    if (condition) {
      return "نعم";
    } else {
      return "لا";
    }
  };

  return !inspection && isFetching ? (
    <BaseLoader />
  ) : (
    <>
      <BaseModal
        show={previewImage}
        py={"2rem"}
        px={"4rem"}
        onHide={closePreviewImageHandler}
        closeIcon
        title={"صور النتائج"}
        modalContentWrapperSx={{ textAlign: "center" }}
      >
        <img
          src={inspection?.attachmentBase64}
          className="img-fluid"
          alt="attachment-base-64"
        />
      </BaseModal>
      <BaseModal
        show={displayImage}
        py={"2rem"}
        px={"4rem"}
        onHide={() => setDisplayImage(null)}
        closeIcon
        title={"صور النتائج"}
        modalContentWrapperSx={{ textAlign: "center" }}
      >
        <img
          src={displayImage}
          className="img-fluid img-fit"
          alt="display-image-attachment"
        />
      </BaseModal>
      <Box className={classes["drilling-data"]} mt={6} px={3}>
        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            محضر الفحص
          </Typography>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل كان الاستشاري متواجد في الموقع عند إجراء الاختبار، وفي الوقت
                المحدد؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.consultantPresent)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل كان المختبر متواجد في الموقع عند إجراء الاختبار، وفي الوقت
                المحدد؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.labPresent)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل كان المقاول متواجد في الموقع عند إجراء الاختبار، وفي الوقت
                المحدد؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.contractorPresent)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل كانت الطبقة جاهزة للاختبار؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.layerReady)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                ما هي حالة الطقس وقت الاختبار؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {questionnaireRecoord && questionnaireRecoord?.weather !== null
                  ? t(questionnaireRecoord?.weather, {
                      ns: "common",
                    })
                  : "لا يوجد"}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل بيانات الموقع مطابقة للبيانات المدخلة بالطلب على المنصة؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.locationIsCorrect)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل يوجد ملاحظات على الموقع؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.havaNotes)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__wrapper"]}>
            <Box className={"request-data-details__item"} mt={6}>
              <Typography className={"request-data-details__label"}>
                هل يوجد تأخير في اغلاق الطلب في الوقت المحدد؟ أذكر السبب في
                الملاحظات؟
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayIfExists(questionnaireRecoord?.delayCloseRequest)}
              </Typography>
            </Box>
          </Box>
          <Box className={"request-data-details__item"} mt={6}>
            <Notes notes={questionnaireRecoord?.notes} />
          </Box>
        </Box>

        <hr />
        {proctorScores?.inspectionLayerId && (
          <DisplayProctorScores proctorScores={proctorScores} />
        )}
        {inspection?.attachmentBase64 && (
          <Box className={classes["drilling-data__wrapper"]} mt={4}>
            <Typography className={classes["drilling-data__title"]}>
              صور نتائج الفحص الحقلي
            </Typography>
            <WrapperContentGrid columnsWidth={24} gap={4}>
              <PreviewAttachment
                attachedAt={inspection?.attachedAt}
                formattedAttachedAtTimes={displayFormattedTimeHHMM(
                  inspection?.attachedAt
                )}
                previewImageHandler={previewImageHandler}
              />
            </WrapperContentGrid>
          </Box>
        )}
        {!isAttachmentIdsEmpty && (
          <Box>
            <BaseDataWrapper
              title={"صور نتائج جمع العينة"}
              titleStyles={{ marginBottom: "3rem" }}
            >
              <BaseDataItem
                label={"تاريخ أخد الصور"}
                value={`
                ${formatDateLocal(appointmentReportData?.attachedAt, "/")} 
              ${displayFormattedTimeHHMM(appointmentReportData?.attachedAt)}`}
              />
            </BaseDataWrapper>

            <PreviewMultipleAttachments
              photos={photos}
              isReadonlyMode={!isAttachmentIdsEmpty}
              isFetchingAttachments={isFetchingAttachments}
              isAttachmentIdsEmpty={isAttachmentIdsEmpty}
            />
          </Box>
        )}

        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            المرفقات
          </Typography>
          <WrapperContentGrid columnsWidth={24} gap={4}>
            {attachment ? (
              <BaseDataItem
                label="نتائج الاختبارات"
                valueComponentAs={"div"}
                value={
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.5}
                    onClick={windowOpenPDFHandler.bind(null, attachment)}
                  >
                    <Typography className="request-data-details__link">
                      المرفق
                    </Typography>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                      alt="popout"
                    />
                  </Box>
                }
              />
            ) : (
              <Box>
                <Typography className="request-data-details__label">
                  نتائج الاختبارات
                </Typography>
                <Typography className={"request-data-details__value"}>
                  لا يوجد
                </Typography>
              </Box>
            )}
            {inspectionFieldReport ? (
              <BaseDataItem
                label="تقرير الاختبار الحقلي"
                valueComponentAs={"div"}
                value={
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.5}
                    onClick={windowOpenPDFHandler.bind(
                      null,
                      inspectionFieldReport.report
                    )}
                  >
                    <Typography className="request-data-details__link">
                      المرفق
                    </Typography>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                      alt="attachment-popout"
                    />
                  </Box>
                }
              />
            ) : (
              <Box>
                <Typography className="request-data-details__label">
                  تقرير الاختبار الحقلي
                </Typography>
                <Typography className={"request-data-details__value"}>
                  لا يوجد
                </Typography>
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
      </Box>
    </>
  );
};

AttachmentsDataField.propTypes = {
  inspection: PropTypes.object,
  isFetching: PropTypes.bool,
  appointmentData: PropTypes.object,
  appointmentReportData: PropTypes.object,
  proctorScores: PropTypes.object,
  questionnaireRecoord: PropTypes.object,
  attachment: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default AttachmentsDataField;
