import React from "react";
import { Box, Typography, FormHelperText } from "@mui/material";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

function RejectionModal({ show, closeModal, confirmRejection, isLoading }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const inputHasError = errors ? errors["rejectionReasons"] : false;

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onLeaveHandler = () => {
    reset({ rejectionReasons: "" });
    closeModal();
  };

  const onSubmitHandler = (formData) => {
    confirmRejection(formData["rejectionReasons"], reset);
  };

  return (
    <BaseModal
      show={show}
      modalContentWrapperSx={{ paddingTop: "2rem" }}
      onHide={onLeaveHandler}
    >
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <Box px="2rem" sx={{ display: "flex", mb: 1 }}>
          <Typography
            sx={{
              mt: 2,
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
              ml: 2,
              mb: 1.5,
            }}
          >
            رفض طلب إسناد الرخصة
          </Typography>
        </Box>
        <hr />

        <Box
          sx={{
            color: "grayDark.color",
            fontSize: "1.6rem",
            lineHeight: "3rem",
            px: "4rem",
            mt: 3,
          }}
        >
          <label htmlFor="rejectionReasons" style={{ color: "black" }}>
            يرجى إدخال سبب رفض طلب إسناد الرخصة
          </label>
          <Box mt={1}>
            <Controller
              control={control}
              name="rejectionReasons"
              rules={{
                required: {
                  value: true,
                  message: `الحقل مطلوب`,
                },
              }}
              render={({ field: { onChange, value = "", name } }) => {
                return (
                  <textarea
                    style={{
                      fontFamily: "times new roman",
                      fontSize: "1.5rem",
                      width: "100%",
                      height: "15rem",
                      padding: "1rem",
                      resize: "none",
                      border: `1px solid ${
                        inputHasError ? "#ffa0a0" : "#449A91"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: inputHasError ? "#fff7f7" : "",
                    }}
                    name={name}
                    id={name}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    maxLength={1000}
                  />
                );
              }}
            />
          </Box>
          {inputHasError && (
            <Box>
              <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
                {inputHasError.message}
              </FormHelperText>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            flexWrap: "wrap",
            px: "4rem",
            mb: 3,
            mt: 2,
          }}
        >
          <BaseButton
            sx={{
              minWidth: "12.2rem",
              mr: 1,
              color: "text.tags.blueSoft",
            }}
            variant="transparent"
            onClick={onLeaveHandler}
            disabled={isLoading}
          >
            إلغاء
          </BaseButton>
          <BaseButton
            sx={{ minWidth: "12.2rem", display: "flex", gap: 2 }}
            variant="secondary"
            type="submit"
            isLoading={isLoading}
          >
            تأكيد
          </BaseButton>
        </Box>
      </form>
    </BaseModal>
  );
}

RejectionModal.propTypes = {
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmRejection: PropTypes.func,
};

export default RejectionModal;
