import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
const { Typography } = require("@mui/material");

const TableTitle = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        m: -1,
        mb: 2,
      }}
    >
      <Typography
        fontSize={16}
        sx={{ color: "grayDark.color1", fontWeight: "bold" }}
      >
        {props.title}
      </Typography>
      {props.view && (
        <Link style={props.linkStyles} to={props.to}>
          {props.view}
        </Link>
      )}
    </Box>
  );
};

TableTitle.propTypes = {
  title: PropTypes.string,
  view: PropTypes.string,
  linkStyles: PropTypes.object,
  to: PropTypes.string,
};

export default TableTitle;
