import { Fragment, useEffect } from "react";
import { Box } from "@mui/system";
import { AlertTypes } from "constants/AlertTypes";
import classes from "./BaseAlert.module.scss";
import { Typography, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import messages from "services/AlertMessages/messages";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const BaseAlert = ({
  show,
  type,
  message,

  autoClose,
  destroy = null,
  closeIcon,
  closeIconColor,

  labelSx,
  interpolation,
  ...props
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    destroy?.();
  };

  useEffect(() => {
    let timeout;
    if (autoClose && !!show) {
      timeout = setTimeout(() => {
        onClose();
      }, autoClose * 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoClose, show]);

  const defaultType =
    (type && AlertTypes[type.toUpperCase()]) || AlertTypes.SUCCESS;
  return (
    <Fragment>
      {!!show && (
        <Box
          aria-label="base-alert"
          my={props.my}
          mt={props.mt}
          mb={props.mb}
          px={props.px}
          width={props.width}
        >
          <Alert
            {...props}
            className={`${classes["base-alert"]} ${
              classes[`base-alert--${defaultType}`]
            }`}
            severity={defaultType}
            action={
              !!destroy &&
              closeIcon && (
                <Box
                  aria-label="close"
                  onClick={() => onClose()}
                  sx={{ cursor: "pointer" }}
                >
                  <Box sx={{ ml: 3, mb: -3 }}>
                    <CloseIcon
                      color={closeIconColor || defaultType}
                      sx={{ width: "2rem", height: "2rem" }}
                    />
                  </Box>
                </Box>
              )
            }
          >
            <Typography
              component={"div"}
              sx={{ lineHeight: 1.5, fontSize: "1.4rem", ...labelSx }}
              className={classes["base-alert__text"]}
            >
              {message && messages[message]
                ? t(messages[message], interpolation)
                : props.children}
            </Typography>
          </Alert>
        </Box>
      )}
    </Fragment>
  );
};

export default BaseAlert;

BaseAlert.defaultProps = {
  show: false,
  type: AlertTypes.SUCCESS,
  closeIcon: false,
  width: undefined,
  my: undefined,
  mt: undefined,
  mb: undefined,
  px: undefined,
};

BaseAlert.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.string,
  closeIcon: PropTypes.bool,
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  destroy: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  my: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  px: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelSx: PropTypes.object,
  interpolation: PropTypes.object,
  closeIconColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]),
};
