import contractorApi from "modules/Contractor/apis";
import { queryBuilder } from "utils/ApiHelpers";

const consultationRequestsApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getConsultantRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/requests?${q}`,
        };
      },
    }),
    getConsultantLicenses: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/licenses?${q}`,
        };
      },
    }),
    getConsultationRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/consultationrequests?${q}`,
        };
      },
      providesTags: ["Consultant-Consultation-Requests"],
    }),
    getConsultantCertificateRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/inspection/inspectionCertificateRequest/consultant/list?${q}`,
        };
      },
      providesTags: ["Consultant-Certificate-Requests"],
    }),
    getConsultationRequestDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/consultant/consultationrequests/${id}`,
        };
      },
    }),
    getConsultantCertificateDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/inspection/inspectionCertificateRequest/consultant/${id}`,
        };
      },
      providesTags: ["Consultant-Certificate-Request-Details"],
    }),
    getContractorCompanies: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/contractorCompanies?${q}`,
        };
      },
    }),
    getConsultationRequestLicense: builder.mutation({
      query: (body) => ({
        url: `/api/consultant/getVaildConsultationRequestLicense`,
        method: "POST",
        body: body,
      }),
    }),
    newConsultantRequest: builder.mutation({
      query: (body) => ({
        url: `/api/consultant/newConsultantRequest`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Consultant-Consultation-Requests"],
    }),
    approveConsultantCertificate: builder.mutation({
      query: ({ id }) => ({
        url: `/api/inspection/inspectionCertificateRequest/consultant/approve/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Consultant-Certificate-Request-Details"],
    }),
    rejectConsultantCertificate: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspectionCertificateRequest/consultant/reject`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Consultant-Certificate-Request-Details"],
    }),
  }),
});

export const {
  useGetConsultantRequestsQuery,
  useLazyGetConsultantRequestsQuery,
  useGetConsultantLicensesQuery,
  useLazyGetConsultantLicensesQuery,
  useLazyGetConsultantCertificateRequestsQuery,
  useGetConsultationRequestsQuery,
  useLazyGetConsultationRequestsQuery,
  useGetConsultationRequestDetailsQuery,
  useGetConsultantCertificateDetailsQuery,
  useLazyGetContractorCompaniesQuery,
  useGetConsultationRequestLicenseMutation,
  useNewConsultantRequestMutation,
  useApproveConsultantCertificateMutation,
  useRejectConsultantCertificateMutation,
} = consultationRequestsApi;

export default consultationRequestsApi;
