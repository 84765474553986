import { Checkbox, Stack, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useCallback, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { Controller } from "react-hook-form";
import { isContentReady } from "utils/Array/Arrays";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";
import { onProctorFieldKeyDown } from "utils/DOM/DOM";
import PropTypes from "prop-types";

const MAX_STATIONS_NUMBER = 5;

const FieldTestLabDoubleLayer = (props) => {
  const {
    element,
    stationInputs,
    enableDrafting,
    isDraftedNotSubmitted,
    props: { injectProps },
  } = props;

  const { t } = useTranslation(["dashboard"]);

  const [isTestApplyChecked, setIsTestApplyChecked] = useState(null);

  const getScoreInputName = (stationId) => `${element.id}.scores.${stationId}`;

  const isStationHasNoInputs =
    !(element.id in stationInputs) || stationInputs[element.id]?.length === 0;

  const notApplicable = injectProps.formValues[element.id]?.["not-applicable"];

  const stationsScoresNumber = Object.values(stationInputs).reduce(
    (acc, cur) => {
      return acc + cur.length;
    },
    0
  );

  const resetStationScores = () => {
    props.setStationInputs((state) => {
      if (!Array.isArray(state[element.id])) return { ...state };
      return {
        ...state,
        [element.id]: state[element.id].slice(0, 1),
      };
    });
  };

  // prettier-ignore
  const isLabEnteredScores = element?.scoresList?.length > 0 ? element.scoresList.length : 0;

  // INITIAL
  useEffect(() => {
    // will add scores if there any scores entered by the lab [initially]
    if (isLabEnteredScores && !element.skipReason) {
      element?.scoresList.forEach((score, idx) => {
        const stationId = addStationInputHandler();
        injectProps?.setValue(getScoreInputName(stationId), score.score);
      });
    } else if (!isLabEnteredScores && !element.skipReason) {
      addStationInputHandler();
    }
  }, []);

  useEffect(() => {
    if (stationsScoresNumber > MAX_STATIONS_NUMBER) {
      resetStationScores();
    }
  }, [stationsScoresNumber]);

  // THIS is important to check for stations if it has skip reason or not
  useEffect(() => {
    if (element.skipReason) {
      const rejectionReason = rejectionReasons.find(
        (reason) => reason.id === element.skipReason
      );
      setIsTestApplyChecked(true);
      injectProps.setValue(`${element.id}.not-applicable`, true);
      injectProps.setValue(`${element.id}.not-applicable-reason`, {
        id: element.skipReason,
        name: rejectionReason.name,
      });
    } else {
      setIsTestApplyChecked(false);
      injectProps.setValue(`${element.id}.not-applicable`, false);
      injectProps.setValue(`${element.id}.not-applicable-reason`, null);
    }
  }, []);

  const areStationsExceededTheMaximum = useCallback(() => {
    let isExceededTheLimits = false;

    if (stationsScoresNumber >= MAX_STATIONS_NUMBER) {
      isExceededTheLimits = true;
    }

    return isExceededTheLimits;
  }, [stationInputs]);

  const addStationInputHandler = () => {
    if (notApplicable === true && stationInputs.length >= MAX_STATIONS_NUMBER)
      return;

    const id = crypto.randomUUID().substring(0, 5);

    props.setStationInputs((state) => {
      if (element.id in state) {
        return {
          ...state,
          [element.id]: state[element.id].concat(id),
        };
      }
      return {
        ...state,
        [element.id]: [id],
      };
    });
    return id;
  };

  const removeStationInputHandler = (id) => {
    injectProps.unregister(`${element.id}.scores.${id}`);

    props.setStationInputs((state) => {
      return {
        ...state,
        [element.id]: state[element.id].filter((stationId) => stationId !== id),
      };
    });
  };

  const clearStation = () => {
    props.setStationInputs((state) => ({ ...state, [element.id]: [] }));
  };

  const checkTestApplicabilityHandler = (checked) => {
    setIsTestApplyChecked(checked);
    clearStation();
    if (checked === false && isStationHasNoInputs) {
      addStationInputHandler();
    }
  };

  const isDisabled = !enableDrafting && isDraftedNotSubmitted;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      {!isTestApplyChecked ? (
        <Stack direction={"row"} alignItems={"center"} gap={"1rem"} mb={"1rem"}>
          <Typography fontSize="1.4rem" fontWeight={"bold"}>
            النتيجة
          </Typography>
          {isContentReady(stationInputs[element.id]) &&
            stationInputs[element.id].map((stationId, idx) => {
              return (
                <Stack
                  key={stationId}
                  direction={"row"}
                  alignItems={"center"}
                  gap={"5px"}
                  width={"min(13rem, 100%)"}
                >
                  <BaseInput
                    inputContainerStyles={{ margin: 0 }}
                    inputStyles={{ margin: 0 }}
                    sx={{ width: "10rem" }}
                    placeholder="النتيجة %"
                    name={getScoreInputName(stationId)}
                    disabled={isDisabled}
                    customError={
                      injectProps.errors?.[element.id]?.["scores"]?.[stationId]
                    }
                    type={"number"}
                    onKeyDown={onProctorFieldKeyDown}
                    step={"any"}
                    max={999999999}
                    maxLength={5}
                    register={{
                      ...injectProps.register(getScoreInputName(stationId), {
                        required: {
                          value: true,
                          message: `${t("field")}  ${t("required")}`,
                        },
                        max: {
                          value: 999999999,
                          message: `الرقم تخطي الحد المسموح`,
                        },
                      }),
                    }}
                  />
                  {!isDisabled && idx > 0 && (
                    <CloseIcon
                      onClick={removeStationInputHandler.bind(null, stationId)}
                      fontSize="medium"
                      sx={{
                        color: "primary.main",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Stack>
              );
            })}
          {!areStationsExceededTheMaximum() && (
            <BaseButton
              variant="transparent"
              sx={{
                minWidth: "max-content",
                fontSize: "1.5rem",
                padding: "1rem 1rem 1rem 0",
              }}
              onClick={addStationInputHandler}
              disabled={isDisabled}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontWeight: "bold",
                  fontSize: "inherit",
                }}
              >
                <AddIcon fontSize="inherit" />
                نتيجة إضافية
              </Typography>
            </BaseButton>
          )}
        </Stack>
      ) : (
        <Stack direction={"row"} alignItems={"center"} gap={"1rem"} mb={1}>
          <Typography fontSize="1.4rem" fontWeight={"bold"}>
            سبب عدم إجراء الإختبار
          </Typography>

          <BaseDropdown
            ignoreStackingContext={props.ignoreStackingContext}
            control={injectProps.control}
            name={`${element.id}.not-applicable-reason`}
            required={{
              message: "حقل مطلوب",
            }}
            disabled={isDisabled}
            customError={
              injectProps.errors?.[element.id]?.["not-applicable-reason"]
            }
            errors={injectProps.errors}
            placeholder={"يرجي تحديد السبب"}
            mainStyles={{ width: "25rem" }}
            data={rejectionReasons}
          />
        </Stack>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap="5px"
        marginInlineStart={"auto"}
        flexShrink={"0"}
      >
        <Controller
          control={injectProps.control}
          name={`${element.id}.not-applicable`}
          render={({ field: { onChange, name } }) => {
            return (
              <Checkbox
                name={name}
                label={"Label"}
                checked={!!isTestApplyChecked}
                disabled={isDisabled}
                onChange={(e) => {
                  checkTestApplicabilityHandler(e.target.checked);
                  onChange(e);
                }}
              />
            );
          }}
        />
        <Typography fontSize={"1.2rem"}>لا يمكن إجراء هذا الإختبار</Typography>
      </Stack>
    </Stack>
  );
};

FieldTestLabDoubleLayer.propTypes = {
  element: PropTypes.object,
  stationInputs: PropTypes.object,
  props: PropTypes.object,
  enableDrafting: PropTypes.bool,
  isDraftedNotSubmitted: PropTypes.bool,
  ignoreStackingContext: PropTypes.bool,
  setStationInputs: PropTypes.func,
};

export default FieldTestLabDoubleLayer;
