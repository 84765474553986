import useAlert from "hooks/useAlert";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import { Outlet } from "react-router-dom";

const CollectSamplesRequests = () => {
  const { vTimelineRef, reanimate, goNext, goPrev, onHide, completeStage } =
    useVerticalTimeline();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  return (
    <Outlet
      context={{
        vTimelineRef,
        reanimate,
        goToNextStage: goNext,
        goToPrevStage: goPrev,
        completeStage,
        onHide,
        alert,
        showAlertHandler,
        hideAlertHandler,
      }}
    />
  );
};

export default CollectSamplesRequests;
