import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export const displayCell = (headerItem, dataItem) => {
  const display = () => {
    if (dataItem) {
      if (headerItem.show === true || headerItem.show === false) {
        if (headerItem.show) {
          return "table-cell";
        } else return "none";
      } else if (dataItem[headerItem.show] === undefined) {
        return "1";
      } else if (
        dataItem[headerItem.show] === true ||
        dataItem[headerItem.show] === false
      ) {
        return "table-cell";
      } else return "none";
    } else return "table-cell";
  };
  return display();
};

const TableHeader = (props) => {
  const { palette } = useTheme();
  const { t } = useTranslation(["common", "payment", "dashboard"]);
  const { tableHeadSx } = props;
  const columnsN = props.justifyColumnsBetween
    ? "1.15"
    : props.tableHeader.length;

  const tableHeadCellSx = {
    background: `${palette.background.colorSoft3}`,
    fontWeight: "bold",
    fontSize: "1.6rem",
    width: `calc(100% / ${columnsN})`,
    textWrap: "balance",
    lineHeight: 1.3,
    ...tableHeadSx,
  };

  return (
    <TableHead sx={{ whiteSpace: "nowrap" }}>
      <TableRow>
        {props.tableHeader.map((headerItem, idx) => {
          const dataItem = props.content?.find((dataItem) => {
            return dataItem[props.dataItemKey];
          });

          return (
            <TableCell
              key={
                idx +
                headerItem.serverKey +
                (dataItem?.[props.dataItemKey] || crypto.randomUUID())
              }
              sx={{
                ...tableHeadCellSx,
                ...headerItem.customSx,
                display: displayCell(headerItem, dataItem),
              }}
            >
              {t(headerItem.name, { ns: "tableHeader" })}
            </TableCell>
          );
        })}
        {props.withAction && (
          <TableCell sx={tableHeadCellSx}>
            {props.withAction.header || null}
          </TableCell>
        )}
        {props.withDetails && (
          <TableCell sx={tableHeadCellSx}>{null}</TableCell>
        )}
        {props.withDelete && <TableCell sx={tableHeadCellSx}>{null}</TableCell>}
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  dataItemKey: "id",
};

TableHeader.propTypes = {
  content: PropTypes.array,
  tableHeader: PropTypes.array,
  tableHeadSx: PropTypes.object,
  justifyColumnsBetween: PropTypes.bool,
  withDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  withDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  withAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  dataItemKey: PropTypes.string,
};

export default TableHeader;
