import React from "react";
import { Box, Typography } from "@mui/material";
import classes from "./UploaderProgressCard.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { sizes } from "constants/File";
import BaseProgressBar from "core-ui/BaseProgressBar/BaseProgressBar";
import { convertFileToBase64WithPrefix, openPDFHandler } from "utils/File/File";
import PropTypes from "prop-types";

const UploaderProgressCard = (props) => {
  const convertSize = Math.floor(
    Math.log(props.fileList.size) / Math.log(1024)
  );

  const fileSize = `${Math.round(
    parseFloat((props.fileList.size / Math.pow(1024, convertSize)).toFixed(2))
  )} ${sizes[convertSize]}`;

  const openAttachmentHandler = () => {
    convertFileToBase64WithPrefix(props.fileList).then((base64Attachment) => {
      openPDFHandler(base64Attachment);
    });
  };

  return (
    <Box
      className={`${classes["uploader-progress"]} ${
        props.disabled ? classes[`uploader-progress--disabled`] : ""
      }`}
    >
      {!props.isFileUploaded && !props.readOnly && (
        <Box
          className={classes["uploader-progress__close"]}
          onClick={props.abortUploadHandler}
        >
          <CloseIcon />
        </Box>
      )}

      {props.readOnly && (
        <Box
          className={classes["uploader-progress__close"]}
          onClick={props.abortUploadHandler}
        >
          <CloseIcon />
        </Box>
      )}

      {!props.isFileUploading && (
        <Typography className={classes["uploader-progress__image"]}>
          <img src={publicAssetsPath("icons/pdf.svg")} alt="pdf" />
        </Typography>
      )}

      <Box className={classes["uploader-progress__info"]}>
        <Box>
          {props.isFileUploading ? (
            <BaseLoader />
          ) : (
            <Box onClick={openAttachmentHandler} sx={{ cursor: "pointer" }}>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: "500" }}>
                {props.fileList.name}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontSize: "1.4rem", fontWeight: "300" }}>
                  {` ${fileSize} `}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        {props.isFileUploaded && !props.readOnly && (
          <Box display={"flex"} flexWrap={"wrap"} gap={3}>
            <BaseButton
              variant={"secondary"}
              disabled={props.isFileUploading || props.isSubmitting}
              sx={{ minWidth: "12.2rem" }}
              onClick={props.abortUploadHandler}
            >
              حذف
            </BaseButton>
            <BaseButton
              type={"submit"}
              sx={{ minWidth: "12.2rem" }}
              onClick={props.onSubmit}
              isLoading={props.isFileUploading || props.isSubmitting}
            >
              إرسال
            </BaseButton>
          </Box>
        )}
        {!props.isFileUploaded && <BaseProgressBar progress={props.progress} />}
      </Box>
    </Box>
  );
};

UploaderProgressCard.propTypes = {
  fileList: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isFileUploaded: PropTypes.bool,
  isFileUploading: PropTypes.bool,
  onSubmit: PropTypes.func,
  abortUploadHandler: PropTypes.func,
  name: PropTypes.string,
  progress: PropTypes.number,
};

export default UploaderProgressCard;
