import { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LongLength from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongLength/LongLength";
import { enLabelSx } from "constants/Typography";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import SieveTable from "../SieveTable/SieveTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import SamplesDelivery from "../SamplesDelivery/SamplesDelivery";
import RaiseResults from "../RaiseResults/RaiseResults";
import AsphaltReportData from "../AsphaltReportData/AsphaltReportData";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseAttachment from "core-ui/BaseAttachment/BaseAttachment";
import { useGetAttachmentForLaboratoryQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import ShowResults from "components/TableUtilitiesComponents/TableDynamicLabComponents/ShowResults";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import PropTypes from "prop-types";

const AssignedRequestTests = (props) => {
  const { samplesData } = props;

  const receivedByLabAt = samplesData?.receivedByLabAt;

  const params = useParams();

  const { data: attachment } = useGetAttachmentForLaboratoryQuery(
    props.inspection?.id,
    {
      skip:
        !props.inspection?.id ||
        !props.inspection?.isLabResultAttachmentSubmitted,
    }
  );

  // prettier-ignore
  const isDraftedNotSubmitted = props.inspection.isRequestInitialSubmissionDrafted && !props.inspection.submittedAt;

  const assignedRequestTests = useMemo(
    () => [
      { serverKey: "testName", name: "test name" },
      {
        serverKey: "testStandard",
        name: "testـstandards",
        hasComponent: { customProps: "testStandard", comp: <LongLength /> },
        fallback: "لا يوجد",
      },
      {
        serverKey: "score",
        name: "score percentage",
        hasComponent: { customProps: "score", comp: <ShowResults /> },
        show: !!receivedByLabAt,
      },
    ],
    [receivedByLabAt]
  );

  const formatter = (cell, row) => {
    return (
      <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{cell}</Typography>
    );
  };

  const sampleGradationTests = useMemo(
    () => [
      {
        serverKey: "testName",
        name: "sieve size",
        format: formatter,
      },
      {},
      {
        serverKey: "score",
        name: "score percentage",
        hasComponent: { customProps: "score", comp: <ShowResults /> },
        show: !!receivedByLabAt,
      },
    ],
    [receivedByLabAt]
  );

  const { completeStage, vTimelineRef, reanimate, onHide } =
    useVerticalTimeline();

  useEffect(() => {
    if (receivedByLabAt && !props.inspection.submittedAt) {
      completeStage(1);
    }
    if (receivedByLabAt && props.inspection.submittedAt) {
      completeStage(2);
    }
    if (props.inspection.submittedAt) {
      onHide();
    }
    if (!props.isComponentFetching) {
      reanimate();
    }
  }, [
    receivedByLabAt,
    props.inspection.submittedAt,
    props.isComponentFetching,
  ]);

  const isAsphalt = inspectionLayerFlagChecker(
    props.inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  if (props.isComponentFetching) return <BaseLoader />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{` الطلبات المسندة - رقم الطلب ${params.id}`}</title>
      </Helmet>

      <Box mt={6}>
        <BaseTableDoubleCard
          doubleLayer={(element) => ({
            visibility:
              props.inspectionLayer?.requiresAspeltReport && isAsphalt,
            component: (
              <SieveTable
                tableHeader={sampleGradationTests}
                content={props.subTests?.inspectionSubTests}
                injectProps={{
                  inputName: "sieve",
                  testsTemplates: props.testsTemplates?.inspectionTests,
                }}
              />
            ),
          })}
          withSpecificAction={(element, triggeredElements) => ({
            condition: element?.containSubtests,
            actionType: "dropdown",
            component: (
              <KeyboardArrowDownIcon
                color="primary"
                fontSize="large"
                sx={{
                  marginInline: "1rem",
                  cursor: "pointer",
                  transition: "all .5s ease",
                  transform: triggeredElements?.[element.id]
                    ? "rotate(180deg)"
                    : "",
                }}
              />
            ),
          })}
          tableContainerSx={{ maxHeight: "100%" }}
          tableSx={{
            border: {
              tableCell: "0.1rem solid",
              color: "#f9f9f9",
              borderInlineColor: "transparent",
            },
          }}
          tableHeader={assignedRequestTests}
          content={props.testsTemplates?.inspectionTests}
          isLoading={props.isTestsTemplatesFetching}
          injectProps={{
            inputName: "score",
          }}
        />

        {props.inspectionLayer?.isSoil &&
          props.inspectionLayer?.soilClassification && (
            <Box sx={{ display: "flex", mb: 5 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "1.6rem", fontWeight: 500 }}>
                  {"تصنيف التربة"}
                </Typography>
                <Typography sx={{ fontSize: "1.4rem", fontWeight: 400, mt: 1 }}>
                  {props.inspectionLayer?.soilClassification}
                </Typography>
              </Box>
            </Box>
          )}

        <hr />

        {props.inspectionLayer?.requiresAspeltReport && isAsphalt && (
          <AsphaltReportData inspectionLayerDTO={props.inspectionLayer} />
        )}

        <Box
          sx={{ marginInlineStart: props.inspection.submittedAt ? "" : "5rem" }}
        >
          <BaseVerticalTimeline sx={{ marginBlock: 5 }} ref={vTimelineRef}>
            <Box>
              <SamplesDelivery
                inspectionLayerDTO={props.inspectionLayer}
                receivedByLabAt={receivedByLabAt}
                samplesData={samplesData}
                inspection={props.inspection}
              />
            </Box>
            {!props.inspection.submittedAt && (
              <Box>
                <RaiseResults
                  form="lab"
                  disabled={!receivedByLabAt}
                  isDraftedNotSubmitted={isDraftedNotSubmitted}
                  isSubmitted={props.inspection.submittedAt}
                />
              </Box>
            )}
          </BaseVerticalTimeline>
        </Box>
        {props.inspection?.isLabResultAttachmentSubmitted && attachment ? (
          <>
            <hr style={{ marginBottom: "5rem" }} />
            <Typography
              mb={2}
              sx={{
                fontWeight: "500",
                fontSize: "1.8rem",
                color: "grayDark.color",
              }}
            >
              ملف نتائج الاختبارات
            </Typography>
            <BaseAttachment file={attachment} />
          </>
        ) : (
          props.inspection?.isLabResultAttachmentSubmitted &&
          !attachment && <BaseLoader />
        )}
      </Box>
    </>
  );
};

AssignedRequestTests.propTypes = {
  inspection: PropTypes.object,
  samplesData: PropTypes.object,
  inspectionLayer: PropTypes.object,
  testsTemplates: PropTypes.object,
  subTests: PropTypes.object,
  isComponentFetching: PropTypes.bool,
  isTestsTemplatesFetching: PropTypes.bool,
};

export default AssignedRequestTests;
