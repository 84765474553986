import AuthFormIntro from "components/Forms/_Utilites/AuthFormIntro/AuthFormIntro";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import CenteredWrapper from "core-ui/CenteredWrapper/CenteredWrapper";
import OtpHandler from "components/OtpHandler/OtpHandler";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { Box } from "@mui/material";
import useAlert from "hooks/useAlert";
import { useLocation } from "react-router-dom";

const VerifyOtp = () => {
  const { state } = useLocation();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  return (
    <CenteredWrapper width={"60.7rem"}>
      <AuthFormIntro />
      <BaseCard py={"2rem"} px={"2rem"}>
        <BaseAlert
          mt={0}
          mb={0}
          show={alert.show}
          type={alert.type}
          labelSx={{ fontWeight: "bold" }}
          destroy={hideAlertHandler}
          message={"RESEND_OTP_SUCCESSFULLY"}
          autoClose={3}
        />

        <Box paddingY={"3rem"} paddingX={"5.4rem"}>
          <FormTitle title="رمز التحقق" />
          <OtpHandler
            registerData={state?.data}
            validateResponse={state?.validateResponse}
            alertData={{
              alert,
              showAlertHandler,
              hideAlertHandler,
            }}
          />
        </Box>
      </BaseCard>
    </CenteredWrapper>
  );
};

export default VerifyOtp;
