import { ReactComponent as FileIcon } from "assets/icons/file.svg";
import { ReactComponent as FileDarkIcon } from "assets/icons/license-dark.svg";

import { ReactComponent as HardHatIcon } from "assets/icons/hard-hat.svg";
import { ReactComponent as HardHatDarkIcon } from "assets/icons/requests-dark.svg";

import { ReactComponent as Assigned } from "assets/icons/assigned-request.svg";

import { ReactComponent as WalletDark } from "assets/icons/wallet-dark.svg";
import { ReactComponent as Wallet } from "assets/icons/wallet-white.svg";

import { ReactComponent as PaymentsDark } from "assets/icons/payments-dark.svg";
import { ReactComponent as Payments } from "assets/icons/payments-white.svg";

import { ReactComponent as Setting } from "assets/icons/setting-white.svg";
import { ReactComponent as SettingDark } from "assets/icons/setting-dark.svg";

import { ReactComponent as TestsIcon } from "assets/icons/tests-icon.svg";
import { ReactComponent as TestsIconDark } from "assets/icons/tests-icon-dark.svg";

import { ReactComponent as CompanyIcon } from "assets/icons/company-white.svg";
import { ReactComponent as CompanyIconDark } from "assets/icons/company-dark.svg";

import { ReactComponent as CertificateIcon } from "assets/icons/certificates.svg";
import { ReactComponent as CertificateIconDark } from "assets/icons/certificates-dark.svg";

import { ReactComponent as TechIcon } from "assets/icons/technician-icon-white.svg";
import { ReactComponent as TechIconDark } from "assets/icons/technician-icon-dark.svg";

import { ReactComponent as ReportIcon } from "assets/icons/report-icon.svg";
import { ReactComponent as ReportIconDark } from "assets/icons/report-icon-dark.svg";

import { ReactComponent as EditRequest } from "assets/icons/edit-request-white.svg";

import { ReactComponent as HomeIcon } from "assets/icons/home-white.svg";
import { ReactComponent as HomeDarkIcon } from "assets/icons/home-dark.svg";
import { UsersTypesEnum } from "constants/UsersTypes";

export const contractorNavData = [
  {
    to: "/home",
    label: "home page",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
  {
    to: "/drilling-licenses",
    label: "drilling licences",
    icon: <FileIcon />,
    iconMobile: <FileDarkIcon />,
  },
  {
    to: "/examination-requests",
    label: "Examination requests",
    icon: <HardHatIcon />,
    iconMobile: <HardHatDarkIcon />,
  },
  {
    to: "/consultants-requests",
    label: "consultation requests",
    icon: <TestsIcon />,
    iconMobile: <TestsIconDark />,
  },
  {
    label: "payments",
    icon: <Payments />,
    iconMobile: <PaymentsDark />,
    children: [
      {
        to: "/points-payments",
        label: "points payments",
      },
    ],
  },
  {
    to: "/wallet",
    label: "wallet",
    icon: <Wallet />,
    iconMobile: <WalletDark />,
  },
];

export const consultantNavData = [
  {
    to: "/home",
    label: "home page",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
  {
    to: "/drilling-licenses",
    label: "drilling licences",
    icon: <FileIcon />,
    iconMobile: <FileDarkIcon />,
  },
  {
    to: "/examination-requests",
    label: "Examination requests",
    icon: <HardHatIcon />,
    iconMobile: <HardHatDarkIcon />,
  },
  {
    to: "/consultation-requests",
    label: "consultation requests",
    icon: <TestsIcon />,
    iconMobile: <TestsIconDark />,
  },
  {
    to: "/certificate-requests",
    label: "certificate requests",
    icon: <CertificateIcon />,
    iconMobile: <CertificateIconDark />,
  },
];

export const laboratoryNavData = [
  {
    to: "/home",
    label: "home page",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
  {
    to: "/new-requests",
    label: "new requests",
    icon: <FileIcon />,
    iconMobile: <FileDarkIcon />,
  },
  {
    to: "/assigned-requests",
    label: "assigned requests",
    icon: <Assigned />,
  },
  {
    to: "/edit-requests",
    label: "Requests to modify results",
    icon: <EditRequest />,
    iconMobile: <EditRequest />,
  },
  {
    to: "/users-management",
    label: "users management",
    icon: <Setting />,
    iconMobile: <SettingDark />,
  },
  {
    to: "/report-requests",
    label: "Report Requests",
    icon: <ReportIcon />,
    iconMobile: <ReportIconDark />,
  },
];

export const laboratoryTechnicianNavData = [
  {
    to: "/home",
    label: "assigned requests",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
];

export const centerNavData = [
  {
    to: "/home",
    label: "requests",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
];

export const technicianNavData = [
  {
    to: "/home",
    label: "home page",
    icon: <HomeIcon />,
    iconMobile: <HomeDarkIcon />,
  },
  {
    to: "/collect-samples-requests",
    label: "Collect Samples Requests",
    icon: <FileIcon />,
    iconMobile: <FileDarkIcon />,
  },
  {
    to: "/field-examination-requests",
    label: "Field Examination Requests",
    icon: <HardHatIcon />,
    iconMobile: <HardHatDarkIcon />,
  },
];

export const adminNavData = [
  {
    to: "/drilling-licenses",
    label: "drilling licences",
    icon: <FileIcon />,
    iconMobile: <FileDarkIcon />,
  },
  {
    to: "/requests",
    label: "Examination requests",
    icon: <HardHatIcon />,
    iconMobile: <HardHatDarkIcon />,
  },
  {
    to: "/technician-requests",
    label: "technician Requests",
    icon: <TechIcon />,
    iconMobile: <TechIconDark />,
  },
  {
    to: "/users-management",
    label: "users management",
    icon: <Setting />,
    iconMobile: <SettingDark />,
  },
  {
    to: "/companies",
    label: "contracting companies",
    icon: <CompanyIcon />,
    iconMobile: <CompanyIconDark />,
  },
  {
    label: "laboratory",
    icon: <TestsIcon />,
    iconMobile: <TestsIconDark />,
    children: [
      {
        to: "/lab-requests",
        label: "lab Requests",
      },
      {
        to: "/edit-requests",
        label: "Edit Requests",
      },
      {
        to: "/report-requests",
        label: "Report Requests",
      },
    ],
  },
  {
    to: "/certificate-requests",
    label: "certificate requests",
    icon: <CertificateIcon />,
    iconMobile: <CertificateIconDark />,
  },
];

const getPathsFromRoute = (routes) => routes.map((d) => d.to || `/${d.label}`);

export const allNavData = {
  [UsersTypesEnum.CONTRACTOR]: getPathsFromRoute(contractorNavData),
  [UsersTypesEnum.CONSULTANT]: getPathsFromRoute(consultantNavData),
  [UsersTypesEnum.LABORATORY]: [
    ...getPathsFromRoute(laboratoryNavData),
    ...getPathsFromRoute(laboratoryTechnicianNavData),
  ],
  [UsersTypesEnum.CENTER]: getPathsFromRoute(centerNavData),
  [UsersTypesEnum.RESAA]: [
    ...getPathsFromRoute(technicianNavData),
    ...getPathsFromRoute(adminNavData),
  ],
};

export const allRoutesPaths = Object.keys(allNavData).map((key) => {
  return {
    userType: key,
    routes: allNavData[key].filter((route) => route.to),
  };
});
