import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import PropTypes from "prop-types";

const AssignLabTechnicianModal = (props) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <>
      {props.step === 1 ? (
        <>
          <Box sx={{ mb: 14.5 }}>
            <BaseDropdown
              name="selectedLabTechnician"
              control={props.control}
              required
              selectMenuStyles={{ height: "16rem !important" }}
              errors={props.errors}
              label={"فني المختبر"}
              labelStyles={{
                color: "grayDark.color",
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
              placeholder="يرجى تحديد فني المختبر"
              data={props.labTechniciansData}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              flexWrap: "wrap",
              gap: "2rem",
            }}
          >
            <BaseButton
              sx={{
                minWidth: "11.2rem",
              }}
              variant={"secondary"}
              onClick={props.onHide}
            >
              {t("إلغاء")}
            </BaseButton>
            <BaseButton
              type="button"
              disabled={!props.selectedLabTechnician}
              sx={{ minWidth: "11.2rem" }}
              onClick={props.nextStepHandler}
            >
              {t("إسناد")}
            </BaseButton>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Typography
              sx={{ color: "grayDark.color1", fontSize: "1.4rem" }}
              mb={10}
            >
              {props.confirmMessage}{" "}
              <span style={{ fontWeight: "bold" }}>
                {props.selectedLabTechnician?.name} {" ؟"}
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              flexWrap: "wrap",
              gap: "2rem",
            }}
          >
            <BaseButton
              sx={{
                minWidth: "11.2rem",
              }}
              variant={"secondary"}
              onClick={props.onHide}
            >
              {t("إلغاء")}
            </BaseButton>
            <BaseButton
              isLoading={props.isLoading}
              sx={{ minWidth: "11.2rem" }}
              type="submit"
            >
              {t("تأكيد الإسناد")}
            </BaseButton>
          </Box>
        </>
      )}
    </>
  );
};

AssignLabTechnicianModal.propTypes = {
  step: PropTypes.number,
  control: PropTypes.object,
  errors: PropTypes.object,
  labTechniciansData: PropTypes.array,
  onHide: PropTypes.func,
  selectedLabTechnician: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  nextStepHandler: PropTypes.func,
  confirmMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default AssignLabTechnicianModal;
