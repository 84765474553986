import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";

const EmptyList = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        padding: "5rem",
      }}
    >
      {props.img && (
        <img src={props.img} width="auto" height="auto" alt="img-empty-list" />
      )}

      <Typography
        fontSize={16}
        sx={{
          color: "primary.colorSoft5",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        sx={{ mt: -1 }}
      >
        <Typography
          fontSize={16}
          sx={{ color: "grayDark.color3", textAlign: "center" }}
        >
          {props.desc}
        </Typography>
        <Link style={props.linkStyles} to={props.to}>
          {props.label}
        </Link>
      </Stack>

      {props.extraInfo && (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{ mt: -1 }}
        >
          <BaseButton
            size="sm"
            sx={{ textAlign: "center" }}
            onClick={props?.onClick}
          >
            {props.extraInfo}
          </BaseButton>
        </Stack>
      )}
    </Box>
  );
};

EmptyList.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  extraInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  desc: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  linkStyles: PropTypes.object,
};

export default EmptyList;
