import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { publicAssetsPath } from "utils/Paths/Paths";
import { defaultEmptyValue } from "constants/Empty";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import PropTypes from "prop-types";

const RequestData = (props) => {
  const inspectionLayer = props.inspection?.inspectionLayersList?.[0];

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  return props.inspection ? (
    <Box className={"request-data-details"} mt={4}>
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          تفاصيل الطلب
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الطلب
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection?.id}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اسم المختبر
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection?.labName}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              عدد العينات
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.samplesData?.collectSampleDto?.numberOfSamples ||
                defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              الحالة
            </Typography>
            <BaseStatus
              status={props.samplesData?.samplestatus}
              dataId={props.dataId}
            />
          </Box>
          {isABC && (
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                درجة التربة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspectionLayer?.soilGrade || defaultEmptyValue}
              </Typography>
            </Box>
          )}
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              موقع المختبر
            </Typography>
            <Typography
              className={"request-data-details__value"}
              sx={{
                fontWeight: "bold !important",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {props.inspection?.labLocation ? (
                <>
                  <a
                    href={props.inspection?.labLocation}
                    target="_blank"
                    className="request-data-details__link"
                  >
                    الموقع
                  </a>
                  <img
                    src={publicAssetsPath("icons/popout-svgrepo.svg")}
                    width="auto"
                    alt="popout-location"
                  />
                </>
              ) : (
                defaultEmptyValue
              )}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تاريخ الاستلام
            </Typography>
            {props.samplesData?.collectedAt ? (
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(props.samplesData?.collectedAt)}
              </Typography>
            ) : (
              <BaseStatus status="not-found" />
            )}
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تاريخ التسليم
            </Typography>

            {props.samplesData?.deliveredToDistCenterAt ? (
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(props.samplesData?.deliveredToDistCenterAt)}
              </Typography>
            ) : (
              <BaseStatus status="not-found" />
            )}
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
    </Box>
  ) : (
    <BaseLoader />
  );
};

RequestData.propTypes = {
  inspection: PropTypes.object,
  samplesData: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RequestData;
