import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import RequestData from "modules/Technician/components/DataDetails/RequestData";
import { NewRequestDetail } from "services/StaticLookup/Breadcrumb";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import { useDispatch } from "react-redux";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { technicianActions } from "store/technician-slice/technician-slice";
import CollectSampleData from "modules/Technician/components/DataDetails/CollectSampleData/CollectSampleData";
import {
  useDeliverSamplesToDistributionCenterMutation,
  useGetInspectionForTechnicianQuery,
  useGetSamplesForTechnicianQuery,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import { Helmet } from "react-helmet";
import { DATA_ID } from "constants/Details";
import { fromPage } from "constants/FromPages";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";

const CollectSamplesRequestDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const params = useParams();

  const { state } = useLocation();

  const fromPageId = state?.fromPageId;

  const dispatch = useDispatch();

  const {
    vTimelineRef,
    reanimate,
    completeStage,
    onHide,
    alert,
    showAlertHandler,
    hideAlertHandler,
  } = useOutletContext();

  const [deliverSamplesToDistCenter, deliverSamplesToDistCenterResponse] =
    useDeliverSamplesToDistributionCenterMutation();

  const { data: inspection, isSuccess: isInspectionSuccess } =
    useGetInspectionForTechnicianQuery(params.id);

  const { data: samplesData, isFetching: isSamplesDataFetching } =
    useGetSamplesForTechnicianQuery(params.id);

  useEffect(() => {
    reanimate();

    if (samplesData?.collectedAt) {
      completeStage(1);
    }
    if (samplesData?.deliveredToDistCenterAt) {
      completeStage(2);
    }
    if (samplesData?.collectedAt && samplesData?.deliveredToDistCenterAt) {
      onHide();
    }
  }, [samplesData, isInspectionSuccess, isSamplesDataFetching]);

  useEffect(() => {
    if (fromPageId) {
      showAlertHandler({
        show: fromPageId === fromPage.COLLECT_TECH_DATA_UPDATE_SUCCESS,
        message: SuccessCodes.SUCCESS_COLLECT_TECH_DATA,
        type: AlertTypes.SUCCESS,
      });
    }
  }, []);

  const acceptSampleDeliveryHandler = () => {
    deliverSamplesToDistCenter({
      inspectionId: inspection?.id,
    })
      .unwrap()
      .then(() => {
        dispatch(technicianActions.acceptSampleDelivery());
        completeStage(2);
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_COLLECT_TECH_DATA,
          type: AlertTypes.SUCCESS,
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات - طلب رقم ${params.id}`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>
      <DashboardIntro
        title={`${t("طلب رقم " + params.id + "", {
          ns: "dashboard",
        })}`}
        description={t("RequestDetails", {
          ns: "dashboard",
        })}
        action={
          <DashboardIntroAction>
            <BaseAlert
              destroy={hideAlertHandler}
              show={alert.show}
              type={alert.type}
              labelSx={{ fontWeight: "bold" }}
              message={alert.message}
              autoClose={3}
            />
          </DashboardIntroAction>
        }
        breadcrumbData={NewRequestDetail({ id: params.id })}
      />

      <BaseCard mt={6} py={"5rem"} px={"7rem"}>
        <RequestData
          inspection={inspection}
          isInspectionSuccess={isInspectionSuccess}
          dataId={DATA_ID.TECH_REQUEST_DETAILS}
        />

        {isInspectionSuccess && !isSamplesDataFetching && (
          <BaseVerticalTimeline sx={{ marginTop: 3 }} ref={vTimelineRef}>
            <Box>
              <BaseCardTitle>جمع العينات</BaseCardTitle>
              {!samplesData?.collectedAt ? (
                <Box>
                  <Typography
                    sx={{
                      color: "grayDark.color",
                      fontSize: "1.6rem",
                      mb: "2.5rem",
                    }}
                  >
                    عليك التوجة إلى موقع الحفر وجمع العينات اللازمة ثم تعبئة
                    نموذج جمع العينة لتأكيد إتمام عملية الجمع
                  </Typography>
                  <BaseButton
                    sx={{ minWidth: "17.3rem" }}
                    onClick={() =>
                      navigate(
                        `/collect-samples-requests/${params.id}/collect-sample`
                      )
                    }
                  >
                    {t("تأكيد جمع العينة", { ns: "dashboard" })}
                  </BaseButton>
                </Box>
              ) : (
                <CollectSampleData
                  inspection={inspection}
                  samplesData={samplesData}
                />
              )}
            </Box>
            <Box>
              <BaseCardTitle>تسليم العينة إلى مركز التوزيع</BaseCardTitle>
              <Box>
                {!samplesData?.deliveredToDistCenterAt ? (
                  <Box>
                    <Box sx={{ display: "flex", mb: "2.5rem" }}>
                      <Typography
                        sx={{
                          color: "grayDark.color",
                          fontSize: "1.6rem",
                        }}
                      >
                        يرجى كتابة رقم الطلب على العينة قبل تسليم العينة إلى
                        مركز التوزيع
                      </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1}>
                      <BaseButton
                        sx={{ minWidth: "17.3rem" }}
                        isLoading={deliverSamplesToDistCenterResponse.isLoading}
                        disabled={!samplesData?.collectedAt}
                        onClick={acceptSampleDeliveryHandler}
                      >
                        {t("ConfirmDeliverySamples")}
                      </BaseButton>
                    </Stack>
                  </Box>
                ) : (
                  <WrapperContentGrid
                    columnsWidth={24}
                    gap={4}
                    sx={{ marginTop: 4 }}
                  >
                    <Box className={"request-data-details__item"}>
                      <Typography className={"request-data-details__label"}>
                        تاريخ التسليم
                      </Typography>
                      <Typography className={"request-data-details__value"}>
                        {formatDateYYYYMMDD(
                          samplesData?.deliveredToDistCenterAt
                        )}
                      </Typography>
                    </Box>
                    <Box className={"request-data-details__item"}>
                      <Typography className={"request-data-details__label"}>
                        وقت التسليم
                      </Typography>
                      <Typography className={"request-data-details__value"}>
                        {displayFormattedTimeHHMM(
                          samplesData?.deliveredToDistCenterAt
                        )}
                      </Typography>
                    </Box>
                  </WrapperContentGrid>
                )}
              </Box>
            </Box>
          </BaseVerticalTimeline>
        )}
      </BaseCard>
    </>
  );
};

export default CollectSamplesRequestDetails;
