import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SmartSearchList from "./SmartSearchList/SmartSearchList";
import SmartSearchError from "./SmartSearchError/SmartSearchError";
import useDebouncedQuery from "hooks/useDebouncedQuery";
import PropTypes from "prop-types";

const BaseSmartSearch = (props) => {
  const _defaultComponentName = "smartSearch";

  const { t } = useTranslation();

  const [showSmartSearchList, setShowSmartSearchList] = useState(false);

  const [queryFn, response] = useDebouncedQuery(props.queryHook, 500);

  const isSuccess = response.isSuccess;

  const isLoading = response.isFetching;

  useEffect(() => {
    if (!isLoading) {
      setShowSmartSearchList(false);
    }
    if (isSuccess) {
      setShowSmartSearchList(true);
    }
  }, [isLoading, isSuccess]);

  const inputHasError = () => {
    if (props.customError) {
      return props.customError;
    } else if (props.errors) {
      return props.errors[props.name || "smartSearch"];
    } else {
      return null;
    }
  };

  const dynamicItemKey = (item) => item[props.displayKey || "name"];

  const _value = (v) => (typeof v === "object" ? dynamicItemKey(v) : v || "");

  let sharedProps = {
    htmlFor: props.htmlFor || _defaultComponentName,
    label: props?.label || "البحث",
    type: props?.type || "text",
    placeholder: props?.placeholder || "البحث",
    disabled: props.disabled,
    isLoading: isLoading,
    helperText: props.helperText,
  };

  const listSharedProps = {
    showSmartSearchList: showSmartSearchList,
    setShowSmartSearchList: setShowSmartSearchList,
    dynamicItemKey: dynamicItemKey,
    data: response.data,
    isLoading: isLoading,
  };

  return (
    <Box sx={{ position: "relative" }}>
      {props.control ? (
        <Controller
          control={props.control}
          name={props.name || _defaultComponentName}
          rules={{
            ...props.rules,
            required: {
              value: props.required === false ? false : true,
              message:
                props.required?.message ||
                `${t("field", { ns: "auth" })} ${t(props.name, {
                  ns: "dashboard",
                })} ${t("required", { ns: "auth" })}`,
            },
          }}
          render={({ field: { onChange, value, name, ref } }) => {
            return (
              <>
                <BaseInput
                  value={_value(value)}
                  onChange={(v) => {
                    onChange(v);
                    queryFn({
                      name: v,
                    });
                    if (!isSuccess) {
                      setShowSmartSearchList(false);
                    }
                  }}
                  name={name}
                  {...sharedProps}
                />
                <SmartSearchError inputHasError={inputHasError()} />
                <SmartSearchList
                  value={value}
                  onChange={onChange}
                  {...listSharedProps}
                />
              </>
            );
          }}
        />
      ) : (
        <>
          <BaseInput
            name={props.name || _defaultComponentName}
            value={_value(props.value)}
            onChange={(value) => {
              if (props.onChange) {
                props.onChange(value);
              }
              if (!isSuccess) {
                setShowSmartSearchList(false);
              }
            }}
            {...sharedProps}
          />
          <SmartSearchError inputHasError={inputHasError()} />
          <SmartSearchList
            listValue={props.value}
            listOnChange={props.onChange}
            {...listSharedProps}
          />
        </>
      )}
    </Box>
  );
};

BaseSmartSearch.propTypes = {
  queryHook: PropTypes.func,
  customError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errors: PropTypes.object,
  control: PropTypes.object,
  rules: PropTypes.object,
  name: PropTypes.string,
  displayKey: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BaseSmartSearch;
