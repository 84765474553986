import { useTheme } from "@emotion/react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import EmptyList from "core-ui/EmpyList/EmptyList";
import PropTypes from "prop-types";
import { _void } from "utils/Objects/Objects";

const EmptyTable = (props) => {
  const { palette } = useTheme();

  return (
    <TableBody
      component={props.component}
      sx={{ backgroundColor: `${palette.background.colorSoft2}`, ...props.sx }}
    >
      <TableRow component={props.component}>
        <TableCell colSpan={"100%"} component={props.component}>
          <EmptyList
            title={props?.emptyData?.title || ""}
            desc={props?.emptyData?.desc || ""}
            label={props?.emptyData?.link || ""}
            img={props?.emptyData?.img || ""}
            to={props?.emptyData?.to || ""}
            linkStyles={{
              color: `${palette.primary.colorSoft5}`,
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
            extraInfo={props?.emptyData?.extraInfo || ""}
            onClick={props?.emptyData?.onClick || _void}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

EmptyTable.propTypes = {
  sx: PropTypes.object,
  emptyData: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default EmptyTable;
