import { Box, Stack, Typography } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import classes from "./ViewDetails.module.scss";
import PropTypes from "prop-types";

const ViewDetails = (props) => {
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <Typography
        className={classes["reassign-btn"]}
        sx={{ fontSize: "1.6rem" }}
      >
        {props.label}
      </Typography>
      <Box>
        <img
          src={publicAssetsPath("icons/popout-svgrepo.svg")}
          width="auto"
          alt="popout-svgrepo"
        />
      </Box>
    </Stack>
  );
};

ViewDetails.propTypes = {
  label: PropTypes.string,
};

export default ViewDetails;
