import { useState } from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseInput from "core-ui/BaseInput/BaseInput";
import classes from "./BaseSearch.module.scss";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const BaseSearch = (props) => {
  const theme = useTheme();

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [searchTerm, setSearchTerm] = useState("");

  const onChangeSearchTerm = (value) => {
    setSearchTerm(value);
    if (props.getSearchTerm) {
      props.getSearchTerm(value);
    }
  };

  const onRestore = () => {
    setSearchTerm("");
    if (props.getSearchTerm) {
      props.getSearchTerm("");
    }
  };
  return (
    <BaseCard py={"3rem"} px={"3rem"} mt={6}>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center", gap: 5 }}
      >
        <Grid item md={8}>
          <BaseInput
            buttonContainerStyles={{ marginBottom: 0 }}
            htmlFor={props.name}
            label={"البحث"}
            type={"text"}
            placeholder={props.placeholder}
            name={props.name}
            icon={"search"}
            value={searchTerm}
            onChange={onChangeSearchTerm}
          />
        </Grid>
        <Grid item sx={{ marginLeft: mdDown ? "0" : "auto" }}>
          <button
            className={classes["restore-btn"]}
            onClick={onRestore}
            disabled={!searchTerm}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <RestoreIcon className={classes["restore-btn__img"]} />
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "bold" }}
                className={classes["restore-btn__text"]}
              >
                إعادة تعيين
              </Typography>
            </Stack>
          </button>
        </Grid>
      </Grid>
    </BaseCard>
  );
};

BaseSearch.propTypes = {
  name: PropTypes.string,
  getSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
};

export default BaseSearch;
