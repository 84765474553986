import {
  useGetAppointmentDetailsForLabQuery,
  useGetInspectionTestsDataForLabQuery,
  useGetSamplesForLabQuery,
  useGetSubTestsForLabQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import AssignedRequestTests from "./AssignedRequestTests/AssignedRequestTests";
import PropTypes from "prop-types";

const LabAssignedRequestTests = (props) => {
  const { inspection } = props;

  const { data: samplesData, isFetching: isSamplesDataFetching } =
    useGetSamplesForLabQuery(inspection.id);

  const inspectionLayer = inspection.inspectionLayersList[0];

  const { data: appointmentData } = useGetAppointmentDetailsForLabQuery(
    inspection.id
  );

  // MAKE A CUSTOM HOOK TO GET BOTH TESTs and SUBTESTs if available
  const { data: testsTemplates, isFetching: isTestsTemplatesFetching } =
    useGetInspectionTestsDataForLabQuery(inspection?.id);

  const transformedTestsTemplates = testsTemplates?.[0];

  const hasSubTestsTest = transformedTestsTemplates?.inspectionTests?.find(
    (test) => test.containSubtests
  );

  const { data: subTests, isFetching: isSubTestsFetching } =
    useGetSubTestsForLabQuery(hasSubTestsTest?.id, {
      skip: !hasSubTestsTest?.id,
    });

  const isComponentFetching =
    isSamplesDataFetching || isTestsTemplatesFetching || isSubTestsFetching;

  return (
    <AssignedRequestTests
      {...props}
      inspectionLayer={inspectionLayer}
      samplesData={samplesData}
      isSamplesDataFetching={isSamplesDataFetching}
      appointmentData={appointmentData}
      testsTemplates={transformedTestsTemplates}
      isTestsTemplatesFetching={isTestsTemplatesFetching}
      isComponentFetching={isComponentFetching}
      subTests={subTests}
    />
  );
};

LabAssignedRequestTests.propTypes = {
  inspection: PropTypes.object,
};

export default LabAssignedRequestTests;
