import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Component } from "react";
import { isProduction } from "utils/Env/Env";

class ErrorBoundary extends Component {
  state = { hasErrors: false, error: null };

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError(error) {
    if (isProduction) return;
    return { hasErrors: true, error };
  }

  render() {
    if (this.state.hasErrors) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "5rem",
            textTransform: "uppercase",
            direction: "ltr",
            gap: "1rem",
            letterSpacing: "10px",
            maxWidth: "125rem",
            margin: "auto",
          }}
        >
          {this.props.fallback || `Something went wrong`}
          <Typography
            sx={{
              fontSize: "3rem",
              color: "orangered",
              letterSpacing: "3px",
            }}
          >
            {this.state.error?.message}
          </Typography>
        </Box>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default ErrorBoundary;
