import { Box, Stack, Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import { enLabelSx } from "constants/Typography";
import PropTypes from "prop-types";

const MixDesignDetails = (props) => {
  return (
    <Box className="request-data-details">
      <Typography className={"request-data-details__title"}>
        Mix Design Details
      </Typography>
      <Stack direction="row" alignItems={"center"} gap={3}>
        <Box display="flex" alignItems={"center"} gap={1.5}>
          <Typography
            fontSize={"1.6rem"}
            color={"base.black"}
            fontWeight={500}
            sx={enLabelSx}
          >
            :Gb.
          </Typography>
          <Typography
            fontSize={"1.6rem"}
            color={"primary.main"}
            fontWeight={500}
          >
            {props.aspeltMixDesignDetailDto?.gb ||
            props.aspeltMixDesignDetailDto?.gb === 0
              ? props.aspeltMixDesignDetailDto?.gb
              : defaultEmptyValue}
          </Typography>
        </Box>
        <Box display="flex" alignItems={"center"} gap={1.5}>
          <Typography
            fontSize={"1.6rem"}
            color={"base.black"}
            fontWeight={500}
            sx={enLabelSx}
          >
            :Gsb.
          </Typography>
          <Typography
            fontSize={"1.6rem"}
            color={"primary.main"}
            fontWeight={500}
          >
            {props.aspeltMixDesignDetailDto?.gsb ||
            props.aspeltMixDesignDetailDto?.gsb === 0
              ? props.aspeltMixDesignDetailDto?.gsb
              : defaultEmptyValue}
          </Typography>
        </Box>
        <Box display="flex" alignItems={"center"} gap={1.5}>
          <Typography
            fontSize={"1.6rem"}
            color={"base.black"}
            fontWeight={500}
            sx={enLabelSx}
          >
            :Gse.
          </Typography>
          <Typography
            fontSize={"1.6rem"}
            color={"primary.main"}
            fontWeight={500}
          >
            {props.aspeltMixDesignDetailDto?.gse ||
            props.aspeltMixDesignDetailDto?.gse === 0
              ? props.aspeltMixDesignDetailDto?.gse
              : defaultEmptyValue}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
MixDesignDetails.propTypes = {
  aspeltMixDesignDetailDto: PropTypes.object,
};

export default MixDesignDetails;
