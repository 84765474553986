import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid, useMediaQuery, Typography } from "@mui/material";
import BaseCalendar from "core-ui/BaseCalendar/BaseCalendar";
import { Controller, useForm } from "react-hook-form";
import { baseCalendarDateFormatter } from "utils/Date/Date";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import BaseTimePicker from "core-ui/BaseTimePicker/BaseTimePicker";
import { TestTypes } from "constants/TestTypes";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import BookExaminationData from "modules/Contractor/components/DataDetails/BookExaminationData/BookExaminationData";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { ScheduleAppointment } from "services/ScheduleAppointment";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import PropTypes from "prop-types";

const AddCheckupExamination = (props) => {
  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const defaultValues =
    raiseNewRequestData?.[`stage-${props.timeline.currentStage}-formData`];

  const { control, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
  });
  const formData = watch();

  const testType = raiseNewRequestData?.testType;

  const [disableTime, setDisableTime] = useState(true);

  const dispatch = useDispatch();

  const { breakpoints } = useTheme();

  const lgDown = useMediaQuery(breakpoints.down("lg"));

  // global values
  const selectedDate = watch("date");
  const selectedTime = watch("time");

  const isField = testType === TestTypes.FIELD_TEST;
  const isLab = testType === TestTypes.LAB_TEST;

  const scheduleAppointment = new ScheduleAppointment(formData?.date, {
    isLab,
    isField,
  });

  useEffect(() => {
    if (selectedDate) {
      setDisableTime(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: { selectedTime: new Date() },
      })
    );
  }, [selectedTime]);

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          [`stage-${props.timeline.currentStage}-formData`]: formData,
        },
      })
    );
    props.timeline.goNext();
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      <BaseAlert show={alert.show} type={alert.type} message={alert.message} />
      <Box>
        {isField && (
          <BaseAlert
            mb={5}
            show={true}
            labelSx={{ fontWeight: "bold" }}
            type={AlertTypes.WARNING}
            message={"CONTRACTOR_RAISE_REQUEST_APPOINTMENT_WARNING_MSG"}
            icon={false}
          />
        )}
        <BaseCardTitle mb={5}>تحديد موعد الفحص</BaseCardTitle>
        <>
          <Box>
            <Typography
              mb={1.5}
              sx={{ mt: 2, fontSize: "1.6rem", fontWeight: "bold" }}
            >
              تعليمات هامة:
            </Typography>
            <ol type="decimal" className="examination-dates-list">
              <li>يتوجب عليك الالتزام بحضور الموعد في الوقت المحدد</li>
              <li>
                یحق للعمیل إلغاء الطلب قبل موعد (الفحص / جمع العينة) بساعتين
              </li>
              <li>لا يمكنك حجز موعد في أيام العطل أو الإجازات الرسمية</li>
            </ol>
          </Box>
          <hr />
          <Box mt={5} sx={{ flexGrow: 1 }}>
            <Grid container paddingRight={"10rem"}>
              <Grid
                item
                md={6}
                paddingRight={"10rem"}
                width={lgDown ? "100%" : ""}
              >
                <Box mb={10}>
                  <Controller
                    control={control}
                    name="date"
                    rules={{
                      required: "فضلا حدد تاريخاً",
                      // validate: dateCustomValidation,
                    }}
                    render={({
                      field: { onChange, name, value },
                      formState: { errors },
                    }) => {
                      let formattedValue = baseCalendarDateFormatter(
                        value,
                        "dddd, YYYY/MM/DD"
                      );
                      return (
                        <BaseCalendar
                          disableWeekends={true}
                          hijri={false}
                          placeholder={formattedValue || "حدد يوم الفحص"}
                          name={name}
                          label="التاريخ"
                          value={value || ""}
                          minDate={scheduleAppointment.minDateCalculation()}
                          errors={errors}
                          format={"dddd, YYYY/MM/DD"}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </Box>
                {/* Time */}
                <Controller
                  control={control}
                  name="time"
                  rules={{
                    required: {
                      value: true,
                      message: "فضلا حدد وقتاً",
                    },
                    // validate: customTimeValidation,
                  }}
                  render={({
                    field: { onChange, name, value },
                    formState: { errors },
                  }) => {
                    return (
                      <BaseTimePicker
                        name={name}
                        errors={errors}
                        label={"الوقت"}
                        format={"hh:mm A"}
                        value={value || ""}
                        onChange={onChange}
                        disabled={disableTime}
                        placeholder={"حدد وقت الفحص"}
                        minTime={scheduleAppointment.minTimeCalculation()}
                        maxTime={scheduleAppointment.maxTimeCalculation()}
                      />
                    );
                  }}
                />
              </Grid>

              {isField && (
                <Grid item md={6} position={"relative"}>
                  {!lgDown && (
                    <Typography
                      component={"span"}
                      sx={{
                        position: "absolute",
                        top: "0",
                        left: "-5rem",
                        width: "1px",
                        height: "100%",
                        background: "#f1f1f1",
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      gap: "1.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grayDark.color",
                        fontSize: "1.6rem ",
                        fontWeight: "500",
                        mb: "1rem",
                      }}
                    >
                      تفاصيل الموعد
                    </Typography>
                    <BaseCard
                      variant="secondary"
                      py={"0rem"}
                      px={"0rem"}
                      height="25rem"
                      borderRadius=".9rem"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <BookExaminationData data={formData} />
                    </BaseCard>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      </Box>
      <TimelineCallToActions timeline={props.timeline} />
    </form>
  );
};

AddCheckupExamination.propTypes = {
  timeline: PropTypes.object,
};

export default AddCheckupExamination;
