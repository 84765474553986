import { useState } from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { licenseTestTypes } from "services/StaticLookup/LicenseDetails";
import classes from "./TechnicianSearch.module.scss";
import { ReactComponent as RestoreIcon } from "../../../../assets/icons/restore.svg";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const TechnicianSearch = (props) => {
  const theme = useTheme();

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [technicianName, setTechnicianName] = useState("");
  const [requestType, setRequestType] = useState("");

  const onChangesTechnicianName = (value) => {
    setTechnicianName(value);
    if (props.getTechnicianName) {
      props.getTechnicianName(value);
    }
  };
  const onChangeRequestType = (value) => {
    setRequestType(value);
    if (props.getRequestType) {
      props.getRequestType(value.value);
    }
  };

  const onRestore = () => {
    setTechnicianName("");
    setRequestType(null);
    if (props.getTechnicianName && props.getRequestType) {
      props.getTechnicianName("");
      props.getRequestType("");
    }
  };
  return (
    <BaseCard py={"5rem"} px={"5rem"} mt={6}>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center", gap: 5 }}
      >
        <Grid item md={8}>
          <Grid container spacing={6}>
            <Grid item md={6} sx={{ width: "100%" }}>
              <BaseInput
                buttonContainerStyles={{ marginBottom: 0 }}
                htmlFor="technicianName"
                label={"البحث"}
                type={"text"}
                placeholder={"يمكنك البحث باسم فني الرقابة"}
                name="technicianName"
                icon={"search"}
                value={technicianName}
                onChange={onChangesTechnicianName}
              />
            </Grid>
            <Grid item md={6} sx={{ width: "100%" }}>
              <BaseDropdown
                name={"requestTypes"}
                value={requestType}
                onChange={onChangeRequestType}
                label={"نوع الفحص"}
                selectMenuStyles={{
                  height: "auto !important",
                }}
                labelStyles={{
                  color: "grayDark.color",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
                placeholder="حدد نوع الفحص"
                data={licenseTestTypes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginLeft: mdDown ? "0" : "auto" }}>
          <button
            className={classes["restore-btn"]}
            onClick={onRestore}
            disabled={!technicianName && !requestType}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <RestoreIcon className={classes["restore-btn__img"]} />
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "bold" }}
                className={classes["restore-btn__text"]}
              >
                إعادة تعيين
              </Typography>
            </Stack>
          </button>
        </Grid>
      </Grid>
    </BaseCard>
  );
};

TechnicianSearch.propTypes = {
  getTechnicianName: PropTypes.func,
  getRequestType: PropTypes.func,
};

export default TechnicianSearch;
