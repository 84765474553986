import { Box, Typography } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import classes from "./BaseTooltip.module.scss";
import PropTypes from "prop-types";

const BaseTooltip = (props) => {
  return (
    <Box
      className={`${classes["license-status__tooltip-trigger"]} ${props.className}`}
    >
      <img
        style={{ transform: "translateY(3px)" }}
        width="16px"
        alt={`tooltip-${props.type}`}
        height="16px"
        src={publicAssetsPath(`icons/tooltip/${props.type}.svg`)}
        className={classes["license-status__tooltip-icon"]}
      />
      <Box
        className={`${classes["license-status__tooltip"]} ${
          classes[`license-status__tooltip--${props.type}`]
        }`}
      >
        <Typography className={classes["license-status__tooltip-text"]}>
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
};

BaseTooltip.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default BaseTooltip;
