import { Box, FormHelperText, TextField } from "@mui/material";
import { OTP } from "constants/Forms";
import BaseButton from "core-ui/BaseButton/BaseButton";
import useCatchUserType from "hooks/useCatchUserType";
import { useContractorOTPMutation } from "modules/Contractor/apis/auth-apis/register-api";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getFormInputs, resetFormInputs } from "utils/Forms/Forms";
import "./VerifyOtpForm.scss";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import messages from "services/AlertMessages/messages";
import { useConsultantOTPMutation } from "modules/Auth/apis/consultant-auth-apis/register-api";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const VerifyOtpForm = (props) => {
  const [otpSetup] = useState(new Array(OTP.OTP_NUMBER_OF_KEYS).fill(1));

  const [otp, setOtp] = useState([]);
  const { t } = useTranslation(["dashboard", "common"]);

  const [otpItemValueOnFocus, setOtpItemValueOnFocus] = useState("");

  const otpFormRef = useRef();

  const navigate = useNavigate();

  const { userRoleFromURL } = useCatchUserType();

  const [, setOtpErrorMessage] = useState(null);

  const { state: params } = useLocation();

  const [validateOtp] = useContractorOTPMutation();
  const [validateOtpConsultant] = useConsultantOTPMutation();

  const { isOtpExpired } = props;

  const { alert, showAlertHandler } = useAlert();

  const { handleSubmit } = useForm();

  useEffect(() => {
    // add focus to the first otp item
    getFormInputs(otpFormRef)[0].focus();
    if (isOtpExpired) {
      setOtp([]);
      resetFormInputs(getFormInputs(otpFormRef));
    }
  }, [isOtpExpired]);

  const isDisabled =
    otp.length !== OTP.OTP_NUMBER_OF_KEYS || !otp.every((i) => i);

  const onChangeOTPHandler = (currentPos, e) => {
    const regex = /^[0-9]*$/;
    const current = e.target;
    const { value } = current;
    if (value.length > 1 || +value < 0 || !regex.test(value)) {
      current.value = "";
      return;
    }
    const inputs = getFormInputs(otpFormRef);

    const next = inputs[currentPos + 1];
    if (next) {
      next.focus();
      //   setOtp((prevState) => prevState.concat(current.value));
    }
    // props.getOtp({ val: current.value, pos: currentPos });
    setOtp((prevState) => {
      const otpItem = { val: current.value, pos: currentPos };
      const otp = [].concat(prevState);
      otp[otpItem.pos] = otpItem.val;
      return otp;
    });
  };

  const onFocusOTPHandler = (currentPos, e) => {
    const currentValue = e.target.value;
    setOtpItemValueOnFocus({ pos: currentPos, oldValue: currentValue });
    e.target.value = "";
  };

  const onBlurOTPHandler = (e) => {
    if (
      (otp[otpItemValueOnFocus.pos] === 0 || otp[otpItemValueOnFocus.pos]) &&
      e.target.value === ""
    ) {
      e.target.value = otpItemValueOnFocus.oldValue;
      setOtp((prevState) => {
        prevState[otpItemValueOnFocus.pos] = otpItemValueOnFocus.oldValue;
        return prevState;
      });
    }
  };
  const navigateToRegisterForm = () => {
    navigate(`/auth/${userRoleFromURL}/register`, {
      state: {
        fromOtp: true,
        registrationData: params.data,
      },
    });
  };

  const onSubmit = (data, e) => {
    const nextScreenURLAppend = props.isForRegister
      ? "register/cont"
      : "reset-password/confirm";

    const validateOtpFun =
      userRoleFromURL === "consultant" ? validateOtpConsultant : validateOtp;

    validateOtpFun({
      otp: otp.join(""),
      nationalIdNumber: params.data.idNumber || params.data.nationalId,
    })
      .unwrap()
      .then((responseData) => {
        setOtpErrorMessage(null);
        navigate(`/auth/${userRoleFromURL}/${nextScreenURLAppend}`, {
          state: {
            data,
            userId: responseData.userId,
            registerData: props.registerData,
            isForRegister: props.isForRegister,
          },
        });
      })
      .catch((err) => {
        setOtpErrorMessage(err.data.errorCode);
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };
  const onError = (errors, e) => {};

  return (
    <Box mt={props.mt} className={"otp"}>
      <form
        ref={otpFormRef}
        className={"otp__form"}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Box
          display={"flex"}
          flexDirection={"row-reverse"}
          justifyContent={"center"}
          gap={2}
        >
          {otpSetup.map((_, idx) => {
            return (
              <TextField
                key={idx}
                placeholder={otp[idx]}
                onFocus={onFocusOTPHandler.bind(null, idx)}
                onBlur={onBlurOTPHandler}
                onChange={onChangeOTPHandler.bind(null, idx)}
                onInput={onChangeOTPHandler.bind(null, idx)}
                className={`otp__item otp__item--${
                  isOtpExpired || messages[alert.message] == messages.WRONG_OTP
                    ? "expired"
                    : undefined
                }`}
                disabled={isOtpExpired}
                sx={{
                  border: "1px solid borders.primary",
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    fontSize: 30,
                    color: "primary.main",
                    "&:hover": {
                      border: "1px solid red",
                    },
                  },
                }}
              />
            );
          })}
        </Box>
        {/* {isOtpExpired && ( */}
        {/* { messages[alert.message] != } */}
        <Box mt={2}>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {messages[alert.message]}
          </FormHelperText>
        </Box>

        {/* )} */}
        {props.isConsultant ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <BaseButton
              sx={{
                minWidth: "15rem",
                color: `text.tags.blueSoft`,
                mr: 5,
              }}
              variant={"secondary"}
              onClick={navigateToRegisterForm}
            >
              {t("previous", { ns: "common" })}
            </BaseButton>
            <BaseButton
              sx={{ minWidth: "15rem" }}
              type="submit"
              disabled={isDisabled}
            >
              {t("تأكيد")}
            </BaseButton>
          </Box>
        ) : (
          <Box sx={{ marginTop: 10, textAlign: "center" }}>
            <BaseButton type="submit" disabled={isDisabled}>
              إرسال
            </BaseButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

VerifyOtpForm.defaultProps = {
  mt: 7,
};

VerifyOtpForm.propTypes = {
  isOtpExpired: PropTypes.bool,
  isForRegister: PropTypes.bool,
  mt: PropTypes.number,
  isConsultant: PropTypes.bool,
  registerData: PropTypes.object,
};

export default VerifyOtpForm;
