import { Box } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DashboardIntroAction = (props) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
      <BaseButton
        onClick={() => props.setShowRejectionModal(true)}
        sx={{
          minWidth: "12.2rem",
        }}
        variant="secondary"
        disabled={props.disabled}
      >
        {t("reject")}
      </BaseButton>
      <BaseButton
        sx={{
          minWidth: "12.2rem",
        }}
        onClick={() => props.setShowAcceptModal(true)}
        disabled={props.disabled}
      >
        {t("accept")}
      </BaseButton>
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  setShowRejectionModal: PropTypes.func,
  setShowAcceptModal: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DashboardIntroAction;
