import React from "react";
import { licenseDetailsLocationsURL } from "services/StaticLookup/TableHeader/TableHeader";
import { Box } from "@mui/material";
import RaiseNewRequest from "components/TableUtilitiesComponents/TableActionsComponents/RaiseNewRequest/RaiseNewRequest";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";

function DrillingSites(props) {
  const isCertificateIssued = props.selectedLicense?.certificateIssued;
  const isCertificatePending = props.selectedLicense?.certificateRequestPending;

  const withAction = sysopsContractorRoleChecker(
    props.userData.userType,
    props.userData.userRole
  )
    ? {
        comp:
          isCertificateIssued || isCertificatePending ? null : (
            <RaiseNewRequest />
          ),
        action: props.onClickHandler,
      }
    : {};

  return (
    <Box mt={5} px={"3.2rem"}>
      <BaseTableIndex
        title={"مواقع الحفر"}
        description={
          "يجب إجراء فحوصات على جميع مواقع الحفر للتتمكن من الحصول على شهادة إتمام الفحص"
        }
        tableHeader={licenseDetailsLocationsURL}
        withAction={withAction}
        content={props.location || []}
      />
    </Box>
  );
}

DrillingSites.propTypes = {
  userData: PropTypes.object,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClickHandler: PropTypes.func,
  selectedLicense: PropTypes.string,
};

export default DrillingSites;
