import { Box } from "@mui/material";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import useAlert from "hooks/useAlert";
import { useGetInvoiceQuery } from "modules/Contractor/apis/payment-apis/payment-apis";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { base64ToBlob } from "utils/ApiHelpers";

const ExportInvoiceButton = () => {
  const { t } = useTranslation(["wallet"]);

  const params = useParams();

  const { data: issueInvoice, isLoading } = useGetInvoiceQuery(params?.id);

  const { showAlertHandler } = useAlert();

  const openPDFHandler = () => {
    if (!issueInvoice?.receipt) return;
    try {
      const blob = base64ToBlob(issueInvoice?.receipt);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } catch (err) {
      showAlertHandler({
        show: true,
        message:
          ErrorCodes.NOT_AUTHORIZED || ErrorCodes.FILE_DOWNLOAD_EXCEPTION,
        type: AlertTypes.ERROR,
      });
    }
  };

  return (
    <Box>
      {isLoading ? (
        <BaseLoader />
      ) : (
        <>
          {!issueInvoice?.receipt && (
            <Box>
              <BaseAlert
                show={true}
                type={AlertTypes.WARNING}
                message={"RECEIPT_NULL"}
              />
            </Box>
          )}
          <BaseButton
            sx={{ minWidth: "17.3rem" }}
            onClick={openPDFHandler}
            disabled={!issueInvoice?.receipt}
            variant={!issueInvoice?.receipt ? "disabled" : ""}
          >
            {t("print", { ns: "common" })}
          </BaseButton>
        </>
      )}
    </Box>
  );
};

export default ExportInvoiceButton;
