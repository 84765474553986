import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import BaseCalendar from "core-ui/BaseCalendar/BaseCalendar";
import AvailableTimes from "./AvailableTimes";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { ExaminationRequests } from "services/StaticLookup/Breadcrumb";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  useAddNewAppointmentMutation,
  useCheckLabAvailabilityMutation,
} from "modules/Contractor/apis/appointments-apis/appointments-apis";
import { useGetFullContractorDataQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { UsersTypesEnum } from "constants/UsersTypes";
import {
  baseCalendarDateFormatter,
  retrieveTimeFromHHMMSSstring,
} from "utils/Date/Date";
import { isContentReady } from "utils/Array/Arrays";
import { calculateEndTime } from "utils/BookAppointment/BookAppointment";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import BookExaminationEmptyList from "components/BookExaminationEmptyList/BookExaminationEmptyList";
import { useGetContractorInspectionQuery } from "modules/Contractor/apis/inspection-apis/inspection-api";

const BookExamination = () => {
  const { t } = useTranslation(["payment"]);

  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const formData = watch();

  const navigate = useNavigate();

  const params = useParams();

  const { onShowGlobalAlert } = useOutletContext();

  const { userType } = useSelector((store) => store.auth);

  const [addNewAppointment, addNewAppointmentResponse] =
    useAddNewAppointmentMutation();

  const { data: contractorData } = useGetFullContractorDataQuery(undefined, {
    skip: userType !== UsersTypesEnum.CONTRACTOR,
  });

  const { alert, showAlertHandler } = useAlert();

  const { data: contractorRequestDetails } = useGetContractorInspectionQuery(
    params.id
  );

  const [checkLabAvailability, checkLabAvailabilityResponse] =
    useCheckLabAvailabilityMutation();

  useEffect(() => {
    if (formData.date) {
      checkLabAvailability({
        plannedDate: baseCalendarDateFormatter(formData.date),
        labId: contractorRequestDetails?.lab,
      });
    }
  }, [formData.date]);

  const onSubmit = (data) => {
    const { endTimeHours, endTimeMinutes } = calculateEndTime(
      formData.date,
      formData.availableTime
    );
    const { hh, mm } = retrieveTimeFromHHMMSSstring(formData.availableTime);

    const serverData = {
      contractorId: contractorData.id,
      labId: contractorRequestDetails.lab,
      inspectionLayer: contractorRequestDetails.id,
      inspection: contractorRequestDetails.inspection,
      scheduledTime: `${hh}:${mm}`,
      endTime: `${endTimeHours}:${endTimeMinutes}`,
      scheduledDate: baseCalendarDateFormatter(formData.date),
    };

    addNewAppointment(serverData)
      .unwrap()
      .then(() => {
        onShowGlobalAlert({
          show: true,
          type: AlertTypes.SUCCESS,
          message: SuccessCodes.APPOINTMENT_BOOKING_SUCCESS,
        });
        navigate(-1);
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: err.data.errCode,
        });
      });
  };

  const OnPrevHaHandler = () => {
    navigate(-1);
  };

  const isDisabled =
    addNewAppointmentResponse.isLoading ||
    !isContentReady(checkLabAvailabilityResponse.data);

  const render = () => {
    if (
      checkLabAvailabilityResponse.isError ||
      checkLabAvailabilityResponse.isSuccess
    ) {
      return (
        <BookExaminationEmptyList
          title="لا يوجد أوقات متاحة"
          desc="الرجاء اختيار يوم اخر"
          iconName="no-available-times"
        />
      );
    } else if (checkLabAvailabilityResponse.isLoading) {
      return <BaseLoader />;
    } else {
      return (
        <BookExaminationEmptyList
          title="قم بتحديد التاريخ لأستعراض المواعيد"
          iconName="no-available-times"
        />
      );
    }
  };

  return (
    <>
      <DashboardIntro
        title={t("المواعيد المتاحة", { ns: "wallet" })}
        description={t("حدد التاريخ والوقت المناسب", { ns: "wallet" })}
        breadcrumbData={ExaminationRequests({ id: params.id })}
      />
      <BaseCard
        mt={6}
        my={6}
        py={"5.2rem"}
        px={"6.3rem"}
        sx={{
          position: "relative",
        }}
      >
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <Typography
                mb={1.5}
                sx={{ mt: 2, fontSize: "1.6rem", fontWeight: "bold" }}
              >
                تعليمات هامة:
              </Typography>
              <ol type="decimal" className="examination-dates-list">
                <li>يتوجب عليك الالتزام بحضور الموعد في الوقت المحدد</li>
                <li>لا يمكنك إلغاء الموعد او تغيير الوقت بعد إتمام الحجز</li>
                <li>لا يمكنك حجز موعد في أيام العطل أو الإجازات الرسمية</li>
              </ol>
            </Box>
            <Box>
              <Controller
                control={control}
                name="date"
                rules={{ required: "فضلا حدد تاريخاً" }}
                render={({
                  field: { onChange, name, value },
                  formState: { errors },
                }) => {
                  return (
                    <BaseCalendar
                      hijri={false}
                      placeholder={"حدد يوم الفحص"}
                      name={name}
                      label="التاريخ"
                      sx={{ width: "50rem" }}
                      value={value || ""}
                      minDate={new Date()}
                      errors={errors}
                      format={"dddd, YYYY/MM/DD"}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Box>

            {isContentReady(checkLabAvailabilityResponse.data) ? (
              <Box>
                <Typography
                  mb={1.5}
                  sx={{ mt: 2, fontSize: "1.6rem", fontWeight: "bold" }}
                >
                  الاوقات المتاحة:
                </Typography>
                <AvailableTimes
                  plannedDate={formData.date}
                  name={"availableTime"}
                  data={checkLabAvailabilityResponse?.data}
                  inputStyles={{ width: "100%" }}
                  value={formData.availableTime}
                  register={register}
                  control={control}
                  errors={errors}
                />
              </Box>
            ) : (
              render()
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexWrap: "wrap",
                mt: 5,
                gap: "1.5rem",
                width: "100%",
              }}
            >
              <Box display={"flex"} flexWrap={"wrap"} gap={3}>
                <BaseButton
                  sx={{ minWidth: "12.2rem" }}
                  variant={"secondary"}
                  onClick={OnPrevHaHandler}
                >
                  {t("التراجع")}
                </BaseButton>
                <BaseButton
                  sx={{ minWidth: "12.2rem" }}
                  variant={isDisabled ? "disabled" : ""}
                  disabled={isDisabled}
                  type={"submit"}
                >
                  {t("حجز")}
                </BaseButton>
                {addNewAppointmentResponse.isLoading && <BaseLoader />}
              </Box>
            </Box>
          </Box>
        </form>
      </BaseCard>
    </>
  );
};

export default BookExamination;
