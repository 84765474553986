import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { publicAssetsPath } from "utils/Paths/Paths";

const LicenseLink = ({ license }) => {
  return (
    <Box className={"request-data-details__item"}>
      <Typography className={"request-data-details__label"}>
        رقم الرخصة
      </Typography>

      <Link to={`/drilling-licenses/${license?.id}`}>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <Typography className={"request-data-details__value"}>
            {`${license?.licenseYear}/${license?.licenseNumber}`}
          </Typography>
          <img
            src={publicAssetsPath("icons/popout-svgrepo.svg")}
            width="auto"
            alt="popout"
          />
        </Stack>
      </Link>
    </Box>
  );
};

LicenseLink.propTypes = {
  license: PropTypes.object,
};

export default LicenseLink;
