import EditRequestLab from "modules/Admin/components/DataDetails/EditRequest/Lab/EditRequestLab";
import PropTypes from "prop-types";
import React from "react";

function LabEditRequestDetails({ data, isFetching }) {
  const isAsphalt = data?.inspectionName?.includes("Asphalt");
  return (
    <EditRequestLab isAsphalt={isAsphalt} data={data} isFetching={isFetching} />
  );
}

LabEditRequestDetails.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default LabEditRequestDetails;
