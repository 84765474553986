import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import {
  useLazyGetLayersDataQuery,
  useGetTestsTemplatesMutation,
} from "modules/Contractor/apis/inspection-apis/inspection-api";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import SoilLocation from "components/SoilLocation/SoilLocation";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import {
  displayAvailableExtensions,
  labAttachmentAvailableExtensions,
} from "constants/File";
import BaseTextArea from "core-ui/BaseTextArea/BaseTextArea";
import { enLabelSx } from "constants/Typography";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { isContentReady } from "utils/Array/Arrays";
import PropTypes from "prop-types";

const LabAddCheckupSiteSpecs = (props) => {
  const { t } = useTranslation(["auth", "dashboard"]);

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { formData } = props;

  const dispatch = useDispatch();

  const [getLayersData] = useLazyGetLayersDataQuery();

  const isAsphalt = formData?.licenseLayer?.name?.includes("Asphalt");

  // 3. API call for getting "Contractor Layers Test Templates"
  const [getContractorTestsTemplates] = useGetTestsTemplatesMutation();

  const getTestTemplate = (testType, layer) => {
    if (testType && layer) {
      getContractorTestsTemplates({ body: { testType }, layerId: layer.id })
        .unwrap()
        .then((responseData) => {
          const subTests =
            formData.licenseLayer?.inspectionTemplate === 2 &&
            formData.licenseLayer?.isPassingCriteriaEditable
              ? responseData.find((test) => test.containSubtests)
                  ?.subTestTemplateDTOList
              : null;
          dispatch(
            contractorActions.saveRaiseNewRequestData({
              data: {
                testsTemplates: responseData,
                isTestsTemplatesLoading: false,
                subTests,
              },
            })
          );
        });
    }
  };

  useEffect(() => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          layersData: [formData.licenseLayer],
          licenseLayer: formData.licenseLayer,
        },
      })
    );

    if (props.raiseNewRequestData?.testType) {
      getTestTemplate(
        props.raiseNewRequestData?.testType,
        formData.licenseLayer
      );
    }
  }, [formData.licenseLayer]);

  const onChangeLicenseTestTypesHandler = () => {
    getLayersData({ testType: props.raiseNewRequestData?.testType })
      .unwrap()
      .then((responseData) => {
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { fetchedLayers: responseData },
          })
        );
      });
  };

  useEffect(onChangeLicenseTestTypesHandler, []);

  const onSaveContractorAttachment = (value) => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: { contractorAttachment: formData?.contractorAttach },
      })
    );
  };

  if (
    !props.raiseNewRequestData?.fetchedLayers ||
    !isContentReady(props.raiseNewRequestData?.fetchedLayers)
  ) {
    return <BaseLoader />;
  }

  // prettier-ignore
  const isLayerTemplateConcreteProduct = formData.licenseLayer?.inspectionTemplate === 3;

  const clearSupplierField = () => {
    props.setValue("supplier", "");
    props.unregister("supplier");
  };

  return (
    <>
      <Stack direction={"row"} flexWrap={"wrap"} mt={1} gap={2}>
        <Box sx={{ width: "100%" }}>
          <Box mb={sm ? 0 : 7}>
            <BaseDropdown
              width={"40rem"}
              control={props.control}
              required
              name={"licenseLayer"}
              errors={props.errors}
              label={"اختبارات مواد الأعمال المدنية"}
              labelStyles={{
                color: "grayDark.color",
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
              onChange={() => clearSupplierField()}
              placeholder="حدد نوع اختبار مواد الأعمال المدنية "
              data={props.raiseNewRequestData?.fetchedLayers}
              helperText={"يمكنك اختيار اختبار واحد فقط"}
            />
          </Box>
        </Box>
        <Box sx={{ maxWidth: "100%" }}></Box>
      </Stack>
      {isLayerTemplateConcreteProduct && (
        <Box>
          <BaseInput
            sx={{ width: "40rem" }}
            htmlFor="supplier"
            label="Supplier"
            placeholder="Supplier"
            name="supplier"
            maxLength={50}
            register={{
              ...props.register("supplier", {
                required: {
                  value: true,
                  message: `${t("field")} Supplier ${t("required")}`,
                },
                maxLength: {
                  value: 50,
                  message: `تجاوزت الحد المسموح`,
                },
              }),
            }}
            errors={props.errors}
          />
        </Box>
      )}
      <Box mt={8}>
        <Grid container gap={3}>
          <Grid item md={6}>
            <Controller
              control={props.control}
              name="soilLocation"
              rules={{
                required: {
                  value: true,
                  message: `${t("field")} ${t("soilLocation", {
                    ns: "dashboard",
                  })} ${t("required")}`,
                },
              }}
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <SoilLocation
                    sm={sm || ""}
                    containerStyles={{ mt: 0 }}
                    name={name}
                    onChange={onChange}
                    setValue={props.setValue}
                    value={value}
                    errors={props.errors}
                    licenseLayerName={formData?.licenseLayer?.name}
                  />
                );
              }}
            />
          </Grid>
          {isAsphalt && (
            <Grid item md={6} sx={{ maxWidth: "100%" }}>
              <Stack direction="row" alignItems={"center"} gap={3}>
                <BaseInput
                  htmlFor="asphaltGrade"
                  label="Asphalt Grade"
                  placeholder="Asphalt Grade"
                  name="asphaltGrade"
                  maxLength={50}
                  register={{
                    ...props.register("asphaltGrade", {
                      required: {
                        value: true,
                        message: `${t("field")} Asphalt Grade ${t("required")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `تجاوزت الحد المسموح`,
                      },
                    }),
                  }}
                  errors={props.errors}
                />
                <BaseInput
                  htmlFor="supplier"
                  label="Supplier"
                  placeholder="Supplier"
                  name="supplier"
                  maxLength={50}
                  register={{
                    ...props.register("supplier", {
                      required: {
                        value: true,
                        message: `${t("field")} Supplier ${t("required")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `تجاوزت الحد المسموح`,
                      },
                    }),
                  }}
                  errors={props.errors}
                />
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* TEST REQUIREMENTS TABLE */}
        {isAsphalt && (
          <Box mt={6}>
            <BaseCardTitle mb={1}>نموذج متطلبات الاختبار</BaseCardTitle>
            <Typography mb={1} fontSize={"1.6rem"} color={"grayDark.color"}>
              يرجى تعبئة النموذج التالي
            </Typography>
            <Box
              sx={{
                mt: 3,
                border: `1px solid #E6E6E6`,
                borderRadius: "10px",
                padding: "5rem",
              }}
            >
              {/* 1. */}
              <Box>
                <BaseCardTitle mb={1}>Mix Design Details</BaseCardTitle>
                <Stack
                  direction={sm ? "column" : "row"}
                  alignItems={"center"}
                  gap={3}
                  mt={3}
                >
                  <BaseInput
                    sx={{ width: "28rem" }}
                    labelStyles={enLabelSx}
                    htmlFor="Gb"
                    label="Gb."
                    placeholder="مثال 2.7"
                    name="Gb"
                    register={{
                      ...props.register("Gb", {
                        required: {
                          value: true,
                          message: `${t("field")} Gb. ${t("required")}`,
                        },
                        pattern: {
                          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: `${t(" يجب ان تكون المدخلات أرقام ")}`,
                        },
                        maxLength: {
                          value: 9,
                          message: `تجاوزت الحد المسموح`,
                        },
                      }),
                    }}
                    errors={props.errors}
                  />
                  <BaseInput
                    sx={{ width: "28rem" }}
                    labelStyles={enLabelSx}
                    htmlFor="Gsb"
                    label="Gsb."
                    placeholder="مثال 2.7"
                    name="Gsb"
                    register={{
                      ...props.register("Gsb", {
                        required: {
                          value: true,
                          message: `${t("field")} Gsb. ${t("required")}`,
                        },
                        pattern: {
                          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: `${t(" يجب ان تكون المدخلات أرقام ")}`,
                        },
                        maxLength: {
                          value: 9,
                          message: `تجاوزت الحد المسموح`,
                        },
                      }),
                    }}
                    errors={props.errors}
                  />
                  <BaseInput
                    sx={{ width: "28rem" }}
                    labelStyles={enLabelSx}
                    htmlFor="Gse"
                    label="Gse."
                    placeholder="مثال 2.7"
                    name="Gse"
                    register={{
                      ...props.register("Gse", {
                        required: {
                          value: true,
                          message: `${t("field")} Gse. ${t("required")}`,
                        },
                        pattern: {
                          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: `${t(" يجب ان تكون المدخلات أرقام ")}`,
                        },
                        maxLength: {
                          value: 9,
                          message: `تجاوزت الحد المسموح`,
                        },
                      }),
                    }}
                    errors={props.errors}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        )}
        {/* TEST REQUIREMENTS TABLE */}

        {/* ATTACHMENT */}
        {isAsphalt && (
          <Box mt={6}>
            <BaseUploadFile
              control={props.control}
              rules={{
                required: {
                  value: true,
                  message:
                    "يرجى إرفاق ملف تصميم خلطة الأسفلت Asphalt mix design",
                },
              }}
              title={"المرفقات"}
              description={" يرجى إرفاق ملف تصميم خلطة الأسفلت"}
              name={"contractorAttach"}
              readOnly
              uploadedFile={formData.contractorAttach}
              onSubmit={onSaveContractorAttachment}
              availableExtensions={labAttachmentAvailableExtensions}
              displayAvailableExtensions={displayAvailableExtensions}
              errors={props.errors}
            />
          </Box>
        )}

        {/* NOTES TEXTAREA */}
        {isAsphalt && (
          <Box mt={6}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <BaseCardTitle>{t("notes", { ns: "dashboard" })}</BaseCardTitle>
              <Typography component={"span"} fontSize={"1.4rem"}>
                (اختياري)
              </Typography>
            </Box>
            <BaseTextArea
              textAreaStyles={{ padding: "2rem" }}
              control={props.control}
              name={"notes"}
              maxLength={255}
              rules={{
                maxLength: {
                  value: 255,
                  message: `تجاوزت الحد المسموح`,
                },
              }} // 👈 this is empty because we don't need to add any validation on this textarea
              htmlFor={"notes"}
              placeholder={t("notes", {
                ns: "dashboard",
              })}
              errors={props.errors}
            />
          </Box>
        )}
      </Box>
      <TimelineCallToActions timeline={props.timeline} />
    </>
  );
};

LabAddCheckupSiteSpecs.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  formData: PropTypes.object,
  errors: PropTypes.object,
  raiseNewRequestData: PropTypes.object,
  timeline: PropTypes.object,
};

export default LabAddCheckupSiteSpecs;
