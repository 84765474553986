import { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { useTheme } from "@emotion/react";
import RestoreButton from "components/RestoreButton/RestoreButton";
import PropTypes from "prop-types";

const Search = (props) => {
  const theme = useTheme();

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [searchValue, setSearchInput] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");

  const onChangeSearchInput = (value) => {
    setSearchInput(value);
    if (props.getSearchValue) {
      props.getSearchValue(value || "");
    }
  };
  const onChangeDropdownValue = (value) => {
    setDropdownValue(value);
    if (props.getDropdownValue) {
      props.getDropdownValue(value || "");
    }
  };

  const onRestore = () => {
    setSearchInput("");
    setDropdownValue("");
    if (props.getSearchValue && props.getDropdownValue) {
      props.getSearchValue("");
      props.getDropdownValue("");
    }
  };
  return (
    <BaseCard py={"5rem"} px={"5rem"} mt={6}>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center", gap: 5 }}
      >
        <Grid item md={8}>
          <Grid container spacing={6}>
            <Grid item md={6} sx={{ width: "100%" }}>
              <BaseInput
                inputContainerStyles={{ marginBottom: 0 }}
                htmlFor="searchValue"
                label={props?.input?.label || "البحث"}
                type={props?.input?.type || "text"}
                placeholder={props?.input?.placeholder}
                name="searchValue"
                icon={"search"}
                value={searchValue}
                onChange={onChangeSearchInput}
              />
            </Grid>
            <Grid item md={6} sx={{ width: "100%" }}>
              <BaseDropdown
                name={"dropdownValue"}
                value={dropdownValue}
                onChange={onChangeDropdownValue}
                label={props?.dropdown?.label}
                labelStyles={{
                  color: "grayDark.color",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
                placeholder={props?.dropdown?.placeholder}
                data={props?.dropdown?.data}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginLeft: mdDown ? "0" : "auto" }}>
          <RestoreButton
            onRestore={onRestore}
            values={[searchValue, dropdownValue]}
          />
        </Grid>
      </Grid>
    </BaseCard>
  );
};

Search.propTypes = {
  getSearchValue: PropTypes.func,
  getDropdownValue: PropTypes.func,
  input: PropTypes.object,
  dropdown: PropTypes.object,
};

export default Search;
