/* eslint-disable import/no-anonymous-default-export */
export default {
  AUTHENTICATION_ERROR: "عذرًا، لا يوجد حساب يطابق البيانات المدخلة.",
  LAYER_CAN_NOT_BE_SELECTED: "تم إسناد الطلب مسبقاً",
  ASSIGNED_LAB_SUCCESSFULLY: "تم إسناد الطلب إلى المختبر بنجاح   ",
  ILLEGAL_LOGIN: "غير مسموح بدخول هذا المستخدم",
  UNKNOWN_ERROR_CODE: "حدث خطأ ما",
  UNKNOWN_TRY_AGAIN_ERROR_CODE: "حدث خطأ ما، الرجاء المحاولة مرة أخرى",
  E_LICENSE_MISMATCH: "",
  ILLEGAL_REQUEST: "تم دفع هذه الطبقة من قبل",
  NO_AVAILABLE_SAMPLES: "",
  CONTRACTOR_INVALID_INPUT_ERROR_CODE: "",
  USERNAME_ALREADY_REGISTERED: "اسم المستخدم مسجل مسبقاً في النظام",
  EMAIL_ALREADY_REGISTERED: "البريد الإلكتروني مسجل مسبقاً في النظام",
  NATIONAL_ID_REGISTERED: "رقم الهوية مسجل مسبقاً في النظام",
  MOBILE_NUMBER_REGISTERED: "رقم الجوال مسجل مسبقاً في النظام",
  EMAIL_NOT_REGISTERED_CODE: "عذرًا، البريد الإلكتروني ليس مسجل لدينا",
  RESET_PASSWORD_LINK_EXPIRED:
    "عذرًا انتهت صلاحية الرابط، يمكنك إرسال الطلب مرة أخرى",
  CONTRACTOR_NOT_EXIST_IN_INTEGRATION_SERVICE_CODE: "",
  WRONG_OTP_CODE: "",
  CONTRACTOR_BALANCE:
    "رصيد المحفظة لا يكفي لإتمام عملية الدفع، يمكنك شحن رصيد المحفظة",
  CONTRACTOR_NO_BALANCE:
    "عذرًا ليس لديك رصيد نقاط كافي، يرجى شحن المحفظة لإتمام هذه العملية",
  SAMPLE_CAN_NOT_BE_COLLECTED: "تم جمع العينات من قبل",
  FILE_UPLOAD_EXCEPTION: "حدث خطأ اثناء رفع الملف",
  INVALID_FILE_EXTENSION: "امتداد الملف غير صحيح",
  INVALID_FILE_SIZE: "حجم الملف كبير",
  USER_TYPE_NOT_MATCH: "غير مسموح بإعادة تعيين كلمة المرور إلى هذا المستخدم",
  SUCCESS_RESET_PASSWORD:
    "تم إرسال طلب إعادة ضبط كلمة المرور إلى بريدك الإلكتروني بنجاح",
  SUCCESS_REGISTER: "تم إنشاء حسابك بنجاح، سيتم نقلك إلى الصفحة الرئيسة",
  SUCCESS_FORGOT_PASSWORD:
    "تم تغيير كلمة المرور بنجاح، سوف يتم تحويلك لصفحة الدخول",
  TOKEN_NOT_VALID: "عذرًا انتهت صلاحية الرابط، يمكنك إرسال الطلب مرة أخرى",
  SUCCESS_PAYMENT_LAYER: "تم إرسال الطلب بنجاح",
  SUCCESS_SUBMIT_RESULTS: "تم إرسال الدرجات بنجاح",
  SUCCESS_EXAMINATION_RECORD_FORM: "تم إرسال نموذج محضر الفحص بنجاح",
  SUCCESS_UPLOAD_ATTACHMENT: "تم إرسال المرفقات بنجاح",
  UPDATED_SUCCESSFULLY: "تم تحديث معلومات الطلب بنجاح",
  LICENSE_EXPIRED: "عذراً، صلاحية الرخصة منتهية",
  UNKNOWN_ERROR: "الرجاء التحقق من المدخلات",
  INTEGRATION_SERVICE_ERROR: "الرجاء التحقق من المدخلات",
  WRONG_OTP: "رمز التحقق غير صحيح",
  EMAIL_INTEGRATION_ERROR: "الرجاء التحقق من المدخلات",
  NOT_FOUND_ERROR: "لا توجد نتائج تطابق المدخلات",
  LICENSE_TYPE_NOT_SUPPORTED: "يمكنك طلب إجراء اختبار على الرخص العادية فقط",
  EMPTY_MESSAGE: "عذرا، حدث خطأ ما، يرجى المحاولة مرة أخرى في وقت لاحق",
  SUCCESS_COLLECT_TECH_DATA: "تم تحديث معلومات الطلب بنجاح",
  CONTRACTOR_NOT_EXIST_IN_INTEGRATION_SERVICE: "لا توجد نتائج تطابق المدخلات",
  RESEND_OTP_SUCCESSFULLY: "تم إرسال رمز تحقق جديد",
  LAB_EXCEED_DAILY_LIMITS:
    "لقد وصلت إلى الحد الأقصى لقبول الطلبات، يمكنك قبول هذا الطلب غداً",
  NO_APPOINTMENTS_AVAILABLE: "لا توجد مواعيد متاحة في هذا التاريخ",
  APPOINTMENT_BOOKING_SUCCESS: "تم حجز الموعد بنجاح",
  LAB_NOT_AVAILABLE_ON_FRIDAY: "المختبر غير متاح يوم الجمعة",
  USER_DEACTIVATED: "عذرًا، الحساب غير نشط الرجاء التواصل مع الدعم الفني",
  ADDRESS_ADDED_SUCCESSFULLY: "تم إضافة العنوان بنجاح",
  CERTIFICATE_ISSUED_SUCCESSFULLY: "تم إصدار الشهادة بنجاح",
  SUCCESS_PAYMENT_TRANSACTION: "تم إضافة {{points}} نقطة إلي المحفطة بنجاح",
  FAILED_PAYMENT_TRANSACTION: "عفوا لم تتم عملية الدفع بنجاح، جرب بطاقة اخرى",
  FAILED_PAYMENT_TRANSACTION_GENERIC_ERROR:
    "عفوا لم تتم عملية الدفع بنجاح، الرجاء المحاولة مرة اخرى",
  ALREADY_CERTIFICATED_LICENSE: "عذرا، تم إصدار شهادة لرقم الرخصة المدخل",
  CERTIFICATED_LICENSE_PENDING:
    "عذرا، يوجد طلب إصدار شهادة معلق لرقم الرخصة المدخل",
  NO_ATTACHMENT_EXIST_FOR_THIS_LICENSE: "عذرا، لم تصدر الشهادة",
  TECHNICIAN_REASSIGNED_SUCCESSFULLY: "تمت عملية إعادة إسناد الطلب بنجاح",
  SUCCESS_CON_SAVE_ATTACHMENT: "تم رفع الملف بنجاح",
  SUCCESS_LAB_RAISE_SCORES_AND_PROCTOR:
    "تم رفع النتائج و البروكتر المعدل بنجاح",
  USER_CREATED_SUCCESSFULLY: "تم اضافة المستخدم بنجاح",
  "Error: Mobile is already in use!": "رقم الجوال مستخدم من قبل في النظام",
  TECHNICIAN_HAS_ACTIVE_ASSIGNMENTS: "لا يمكن تعطيل الحساب",
  USER_ACTIVATED_SUCCESSFULLY: "تم تنشيط الحساب بنجاح",
  USER_DECTIVATED_SUCCESSFULLY:
    "تم تعطيل الحساب بنجاح، لن يتمكن المستخدم من الوصول إلى المنصة",
  USER_PASSWORD_NOT_SET: "يجب تحديث كلمة المرور لهذا المستخدم",
  LAB_HAS_ACTIVE_REQUESTS: "لا يمكن تعطيل الحساب ،نظرا لوجود طلب قائم",
  CONTRACTOR_RAISE_REQUEST_APPOINTMENT_WARNING_MSG:
    "تنبيه : يجب تحديد موعد الاختبار الحقلي قبل 24 ساعة من الموعد ، ونخلي مسؤوليتنا في حال عدم قبول المختبر للطلب.",
  LABORATORY_NEW_REQUESTS_WARNING_MSG:
    "تنبيه : على المختبر الالتزام بمواعيد الاختبارات الحقلية عند قبول الطلب بحيث يكون وصول الفني في الموعد المحدد، أي تأخير عن الموعد المحدد يعرض المختبر للمسؤولية. كما نحيطكم علمًا بأن تكلفة الاختبار تشمل خمس نقاط فحص.",
  USER_REINVITED_SUCCESSFULLY: "تم إرسال دعوة الانضمام الى المستخدم بنجاح",
  LAB_DEASSIGNED_SUCCESSFULLY: "تم إعادة الطلب لنظام التوزيع بنجاح",
  LAYER_CAN_NOT_BE_DEASSIGNED:
    "عذراً، لا يمكن إعادة الطلب لنظام التوزيع لوجود تحديث على الطلب",
  PROFILE_INCOMPLETE:
    "للاستفادة من خدمات المنصة يرجى إضافة كل من عنوان الشركة و الرقم الضريبي",
  PROFILE_UPDATED_SUCCESSFULLY: "تم تحديث البيانات بنجاح",
  SUCCESS_MODIFY_THE_REQUEST: "تم تعديل الطلب بنجاح",
  SUCCESS_SUBMIT_TEST_REQUEST_FORM: "تم رفع النتائج بنجاح",
  RECEIPT_NULL: "لم تصدر الفاتورة بعد",
  SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM:
    "تم تعديل النتائج ، سيتم اعتماد النتائج المعدلة بعد قبول طلب التعديل من قبل مدير النظام",
  FILE_DOWNLOAD_EXCEPTION: "عذرا، حدث خطأ ما حاول في وقت لاحق",
  NOT_AUTHORIZED: "عذرا، حدث خطأ ما حاول في وقت لاحق",
  SUCCESS_APPROVE_EDIT_REQUEST: "تم قبول طلب التعديل بنجاح",
  FAILURE_APPROVE_EDIT_REQUEST:
    "عذراً، لا يمكن اعتماد نتيجة التعديل لوجود تحديث على الطلب",
  APPROVED_EDIT_REQUEST: "تم قبول طلب تعديل النتائج",
  REJECTED_EDIT_REQUEST: "عذرًا، لم يتم قبول طلب تحديث النتائج",
  REQUEST_IS_OUTDATED:
    "تم إلغاء هذا الطلب لعدم توفر مختبر شاغر في الوقت الذي تم اختياره، تم إرجاع كافة النقاط التي تم دفعها إلى المحفظة، كما يمكنكم إعادة رفع الطلب أو انشاء طلب فحص جديد",
  SUCCESS_APPOINTMENT_RESCHEDULE:
    "تم خصم {{points}} نقطة و إعادة رفع الطب بنجاح",
  SUCCESS_ONLY_DRAFT_REQUEST_FORM: "تم حفظ النتائج بنجاح",
  CONTRACTOR_OUTDATED_REQUEST_WARNING:
    "عزيزي المقاول، نفيدكم بأن لديكم طلب أو أكثر لم يتم قبوله و ذلك لعدم توفر مختبر شاغر في الوقت الذي تم اختياره، تم إرجاع كافة النقاط التي تم دفعها إلى المحفظة، كما يمكنكم إعادة رفع الطلب مرة أخرى من خلال الانتقال إلى طلبات الفحص والدخول على تفاصيل الطلب أو إنشاء طلب فحص جديد.",
  STATIONS_REQUIRED: "الرجاء التحقق من المدخلات",
  APPOINTMENT_REQUIRED: "الرجاء التحقق من المدخلات",
  CONSULTANT_INFO_NEEDED: "الرجاء التحقق من مدخلات الاستشاري",
  LICENSE_ALREADY_ADDED: "تم إضافة الرخصة من قبل",
  ASPELT_ATTACHMENT_REQUIRED: "الرجاء التحقق من المدخلات",
  CONTRACTOR_APPOINTMENT_BOOKING:
    "وقت الفحص الذي تم اختياره لم يعد متاح، يرجى تغيير وقت الفحص من خلال الرجوع إلى خطوة",
  SUCCESS_SUBMIT_ISSUE:
    "نشكرك على تواصلك معنا، تم إنشاء تذكرة رقم {{issue_key}}# و سيتم التواصل معكم قريبًا.",
  FILE_TOO_LARGE: "الملف المرفق أكبر من الحجم المسموح",
  WRONG_FILE_EXTENSION: "إمتداد الملف غير صحيح",
  REQUEST_STATUS_DOES_NOT_ALLOW_ACTION:
    "التقرير غير جاهز للإصدار بعد، حاول في وقت آخر",
  LABORATORY_EDIT_REQUEST_STATUS_DOES_NOT_ALLOW_ACTION:
    "عذراً، لا يمكن تعديل النتيجة لوجود تحديث على الطلب",
  LICENSE_CONTRACTOR_NOT_MATCHED:
    "عذرا، الرخصة ليست من ضمن رخص شركة المقاولات التي تم اختيارها",
  LICENSE_NOT_FETCHED:
    "لم يتم استرجاع بيانات هذه الرخصة، يرجى التواصل مع المقاول وطلب استرجاع البيانات وذلك عن طريق الدخول إلى بوابة المقاولين ثم الانتقال الى صفحة رخص الحفر والبحث برقم الرخصة المراد استرجاع بياناتها",
  LICENSE_ALREADY_HAS_SUPERVISION:
    "عذرا، لا يمكن إضافة هذه الرخصة وذلك بسبب إسنادها لاستشاري آخر",
  LICENSE_HAS_PENDING_CONSULTANT_REQUEST:
    "عذرا، لا يمكن إضافة هذه الرخصة وذلك بسبب وجود طلب آخر",
  CONSULTANT_REQUEST_REJECTED: "تم رفض طلب إسناد الرخص رقم {{id}}.",
  CONSULTANT_REQUEST_APPROVED: "تم قبول طلب إسناد الرخص رقم {{id}} بنجاح",
  LICENSE_CERTIFICATE_ALREADY_ISSUED:
    "عذرا، لا يمكن إضافة هذه الرخصة لوجود شهادة مصدرة على الرخصة",
  SUCCESSFUL_SENDING_REQUEST: "تم رفع طلب إسناد الرخص بنجاح",
  LICENSE_ALREADY_ASSIGNED_TO_YOURSELF:
    "تم إسناد هذه الرخصة لك مسبقا، ويمكنك استعراضها من خلال صفحة رخص الحفر",
  CONSULTANT_REQUEST_ALREADY_HANDLED_BEFORE:
    "عذرًا، تم معالجة هذا الطلب مسبقًا من قبل مقاول آخر",
  STATIONS_STARTPOINT_IS_BIGGER_THAN_ENDPOINT:
    "* يرجى الملاحظة أن نقطة النهاية يجب أن تكون أكبر من نقطة البداية.",
  STATIONS_STARTPOINT_IS_OVERLAPPING:
    "* أحد النقاط المدخلة تتداخل مع باقي نقاط الـ station الأخرى، يرجى مراجعة النقاط المدخلة والتأكد منها.",
  REQUEST_CANNOT_BE_CANCELLED:
    "لا يمكن الغاء الطلب لان الوقت المتبقي على الموعد ساعتين",
  SUCCESS_UPDATE_REQUEST: "تم تعديل الطلب بنجاح",
  TESTED_BY_LAB: "لا يمكن التعديل لوجود نتائج مدخلة من قبل المختبر",
  LIMIT_EXCEEDED: "تم تجاوز العدد المسموح لك بالتعديل",
  CAN_NOT_SCHEDULE_APPOINTMENT_BEFORE_REQUIRED_HOURS:
    "وقت الفحص الذي تم اختياره لم يعد متاح، يرجى تغيير وقت الفحص",
  REGECT_EDIT_REASON: "عذرًا، لم يتم قبول طلب تحديث النتائج",
  "Assignment Status Must Be New or Assigned":
    "لا يمكن إعادة اسناد الطلب، يوجد نتائج مدخلة",
  PENDING_EDIT:
    "تم طلب تحديث النتائج، سيتم اعتماد النتائج المحدثة بعد قبول طلب التعديل من قبل مدير النظام",
  FILE_HAS_ALREADY_EXISTING_LICENSE:
    "يوجد ترخيص في ورقة العمل إكسل موجود مسبقًا في قاعدة البيانات.",
  FILE_HAS_ALREADY_EXISTING_USER:
    "يوجد مستخدم مقاول في ورقة العمل إكسل موجود مسبقًا في قاعدة البيانات",
  FILE_VALIDATION_ERROR:
    "خطأ في التحقق من صحة البيانات (فارغ، فارغ، ليس في التنسيق الصحيح، ليس من النوع الصحيح ...).",
  FILE_PROCESSING_EXCEPTION:
    "استثناء معالجة الملف (خطأ في الإدخال/الإخراج ... مش عارف يعمل ستريم للفايل لأي سبب)",
  ACCESS_REQUEST_NOT_ALLOWED: "عذرًا، لا يمكنك رفع طلب إصدار شهادة",
  SUCCESSFUL_REQUEST: "تم رفع الطلب بنجاح",
  SUCCESS_APPROVE_ISSUANCE_INSPECTION_CERTIFICATE:
    "تم قبول طلب اصدار الشهادة بنجاح",
  SUCCESS_REJECT_ISSUANCE_INSPECTION_CERTIFICATE:
    "تم رفض طلب اصدار الشهادة بنجاح",
  ADMIN_CLOSED_REQ: "تم اقفال الطلب من قبل مدير النظام",
  REJECT_REASON_IS_MANDATORY: "حقل سبب الرفض مطلوب",
  ADMIN_TAKE_DECISION: "تم اقفال الطلب من قبل مدير النظام",
  MAKE_DECISION_BEFORE: "عذرًا، لا يمكن اتخاذ القرار مره اخرى",
  LOCATION_DATA_REQUIRED: "بالرجاء التأكد من موقع أخذ العينة",
  FAILED_GENERATE_REPORT: "FAILED_GENERATE_REPORT",
  REPORT_STATUS_DOES_NOT_ALLOW_ACTION: "REPORT_STATUS_DOES_NOT_ALLOW_ACTION",
};
