import { Box } from "@mui/material";
import HistoryItem from "../HistoryItem/HistoryItem";
import PropTypes from "prop-types";

const HistoryRender = (props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
      {props.logs.map((log) => {
        return (
          <HistoryItem
            key={log.date}
            date={log.date}
            label={log.label}
            value={log.value}
          />
        );
      })}
    </Box>
  );
};

HistoryRender.propTypes = {
  logs: PropTypes.array,
};

export default HistoryRender;
