import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PackagesList from "../PackagesList/PackagesList";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { useTranslation } from "react-i18next";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { walletActions } from "store/wallet-slice";
import { useGetCheckoutIdMutation } from "modules/Contractor/apis/payment-apis/payment-apis";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import PropTypes from "prop-types";

const PackagesContent = (props) => {
  const { t } = useTranslation(["wallet"]);

  const { isCustomPlanChecked, selectedPlan } = useSelector(
    (store) => store.wallet
  );

  const [getCheckoutId, getCheckoutIdResponse] = useGetCheckoutIdMutation();

  const dispatch = useDispatch();

  const submitBtnRef = useRef();

  const onSubmit = () => {
    if (isCustomPlanChecked) {
      submitBtnRef.current.click();
    } else {
      const vat = selectedPlan.price * props.packagesData?.vatPercentage;
      // show payment form
      getCheckoutId({
        id: selectedPlan.id,
        price: selectedPlan.price,
        vat,
      })
        .unwrap()
        .then((responseData) => {
          dispatch(walletActions.setCheckoutId(responseData.id));
          dispatch(walletActions.showPaymentFormHandler());
        });
    }
  };

  const nextButtonVariant = () => {
    if (!selectedPlan?.points) {
      return "disabled-fancy";
    } else if (getCheckoutIdResponse.isLoading) {
      return "disabled";
    } else {
      return "primary-fancy";
    }
  };

  const nextButtonDisable =
    !selectedPlan?.points || getCheckoutIdResponse.isLoading;

  return (
    <Box>
      <BaseCardTitle mb={2}>{t("packages")}</BaseCardTitle>
      <Typography sx={{ fontSize: "1.6rem", mb: 3 }}>
        يمكنك اختيار إحدى الباقات التالية أو تحديد عدد النقاط المرادة، علما بأن
        كل نقطة تساوي 1 ريال سعودي
      </Typography>
      <PackagesList
        submitBtnRef={submitBtnRef}
        plans={props.packagesData?.prepaymentPackages || []}
        vatPercentage={props.packagesData?.vatPercentage}
        getCheckoutId={getCheckoutId}
        getCheckoutIdResponse={getCheckoutIdResponse}
        isWalletDisabled={props.isWalletDisabled}
      />
      <Box sx={{ textAlign: "center" }}>
        <BaseButton
          type="submit"
          sx={{ width: "26rem" }}
          variant={nextButtonVariant()}
          disabled={nextButtonDisable}
          onClick={onSubmit}
        >
          {getCheckoutIdResponse.isLoading ? <BaseLoader /> : "التالي"}
        </BaseButton>
      </Box>
    </Box>
  );
};

PackagesContent.propTypes = {
  packagesData: PropTypes.object,
  isWalletDisabled: PropTypes.bool,
};

export default PackagesContent;
