import { useLoginMutation } from "modules/Auth/apis/login-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getLocalStorage,
  removeLocalStorage,
  setUserToken,
} from "services/Storage";
import { authActions } from "store/auth-slice";
import { parseJwt } from "utils/Auth/Auth";
import useCatchUserType from "./useCatchUserType";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const [login, loginResponse] = useLoginMutation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { userRoleFromURL } = useCatchUserType();

  const [customError, setCustomError] = useState(null);

  const loginHandler = (loginData) => {
    login({ realm: `${userRoleFromURL}`, ...loginData })
      .unwrap()
      .then((data) => {
        setCustomError(null);

        const userData = parseJwt(data.accessToken);

        if (userData.user_type.toLowerCase() !== userRoleFromURL) {
          throw new Error("ILLEGAL_LOGIN");
        }
        setUserToken({ data: userData, token: data.accessToken });
        dispatch(
          authActions.login({
            isAuth: true,
            user: { data: userData, token: data.accessToken },
          })
        );
        const backlink = getLocalStorage("backlink");
        if (backlink) {
          navigate(backlink, { replace: true });
        }
      })
      .catch((err) => {
        if (err.message === "ILLEGAL_LOGIN") {
          setCustomError({ show: true, message: err.message });
        }
      });
  };

  useEffect(() => {
    return () => {
      removeLocalStorage("backlink");
    };
  }, []);

  return { loginHandler, loginResponse, customError };
};

export default useLogin;
