import EmptyList from "core-ui/EmpyList/EmptyList";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";

const LicenseDataFallbackComp = (props) => {
  const { t } = useTranslation(["auth", "dashboard"]);

  return (
    <EmptyList
      title={t(props.title)}
      desc={t(props.desc)}
      img={props.icon ? publicAssetsPath(`icons/${props.icon}`) : null}
    />
  );
};

LicenseDataFallbackComp.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
};

export default LicenseDataFallbackComp;
