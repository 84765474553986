import { Box, FormHelperText, RadioGroup, Typography } from "@mui/material";
import BaseRadio from "../BaseRadio/BaseRadio";
import classes from "./BaseRadioGroup.module.scss";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import PropTypes from "prop-types";

const BaseRadioGroup = (props) => {
  const { t } = useTranslation(["auth", "dashboard"]);
  const inputHasError = props.errors ? props.errors[props.name] : false;

  const { palette } = useTheme();

  const selectedItemSx = (testType) =>
    testType.value === props.value
      ? {
          background: palette.base.info,
          // border: `1px solid ${palette.borders.infoSoft}`,
        }
      : {};

  return (
    <Box
      className={`${classes["base-radio-group"]} `}
      sx={{ width: "100%", ...props.containerStyles }}
    >
      {props.label && (
        <Typography className={classes["base-radio-group__label"]}>
          {props.label}
        </Typography>
      )}
      <Controller
        control={props.control}
        name={props.name}
        rules={{
          required: {
            value: true,
            message: `${t("field")} ${props.validation?.errorMessage} ${t(
              "required"
            )}`,
          },
        }}
        render={({ field: { onChange, value, name, ref } }) => {
          return (
            <RadioGroup
              row
              name={name}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e.target.value);
                }
                onChange(e);
              }}
              sx={{
                display: "flex",
                width: "100%",
                gap: "2rem",
                justifyContent: "space-between",
                ...props.inputContainerStyles,
              }}
              value={value || ""}
            >
              {props.name === "testType" ? (
                props.data.map((dataItem, idx) => {
                  return (
                    <BaseRadio
                      key={dataItem.id}
                      sx={{
                        ...props.inputStyles,
                        ...selectedItemSx(dataItem),
                      }}
                      labelSx={{ color: "black" }}
                      parentSx={{ flex: "1 1" }}
                      value={dataItem.value}
                      label={dataItem.name}
                      disabled={dataItem.disabled}
                      helperText={dataItem.helperText}
                      inputHasError={inputHasError}
                    />
                  );
                })
              ) : (
                <WrapperContentGrid
                  columnsWidth={props.columns || 14}
                  gap={props.gap || 3}
                  sx={{ width: "100%" }}
                >
                  {props.data.map((dataItem, idx) => {
                    return (
                      <BaseRadio
                        key={dataItem.id}
                        sx={{
                          ...props.inputStyles,
                          ...selectedItemSx(dataItem),
                        }}
                        labelSx={{ color: "black", opacity: 1 }}
                        value={dataItem.value}
                        label={dataItem.name}
                        disabled={dataItem.disabled}
                        helperText={dataItem.helperText}
                        inputHasError={inputHasError}
                      />
                    );
                  })}
                </WrapperContentGrid>
              )}
            </RadioGroup>
          );
        }}
      />
      {inputHasError && (
        <Box mb={-2.9}>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

BaseRadioGroup.propTypes = {
  errors: PropTypes.object,
  containerStyles: PropTypes.object,
  control: PropTypes.object,
  validation: PropTypes.object,
  inputStyles: PropTypes.object,
  inputContainerStyles: PropTypes.object,
  data: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  columns: PropTypes.number,
  gap: PropTypes.number,
};

export default BaseRadioGroup;
