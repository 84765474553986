import { Box, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

const SmartSearchError = (props) => {
  return (
    props.inputHasError && (
      <Box>
        <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
          {props.inputHasError.message}
        </FormHelperText>
      </Box>
    )
  );
};

SmartSearchError.propTypes = {
  inputHasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default SmartSearchError;
