import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetCompanyQuery,
  useLazyGetCompanyInvoicesQuery,
} from "modules/Admin/apis/contractor-apis/companies-apis";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import CompanyDetails from "modules/Admin/components/CompanyDataDetails/CompanyDetails/CompanyDetails";
import CompanyInvoices from "modules/Admin/components/CompanyDataDetails/CompanyInvoices/CompanyInvoices";
import CompanyPayments from "modules/Admin/components/CompanyDataDetails/CompanyPayments/CompanyPayments";
import { Helmet } from "react-helmet";
import { ContractingCompaniesDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { renderTabs } from "utils/Tabs/Tabs";
import { DATA_ID } from "constants/Details";
import { AdminContractingCompaniesTabs } from "services/StaticLookup/Tabs";
import classes from "./ContractingCompaniesDetails.modules.scss";
import useBaseTablePagination from "hooks/useBaseTablePagination";

const ContractingCompaniesDetails = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);
  const [paymentId, setPaymentId] = useState("");

  const params = useParams();

  //prettier-ignore
  const { data: companyData, isFetching , isSuccess} = useGetCompanyQuery(params.id);
  const [getCompanyInvoices, getCompanyInvoicesResponse] =
    useLazyGetCompanyInvoicesQuery();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  useEffect(() => {
    let timer = setTimeout(
      getCompanyInvoices.bind(null, {
        page: paymentId ? 0 : page,
        size: pageSize,
        sortId: "id,desc",
        contractorId: params.id,
        prePaymentId: paymentId,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, paymentId]);

  const handleChange = (event, newValue) => {
    setTabsActiveIndex(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`شركات المقاولات - ${companyData?.name}`}</title>
        <link rel="canonical" href="/companies" />
      </Helmet>

      <DashboardIntro
        title={`${t("", { ns: "dashboard" })} ${companyData?.name || ""}`}
        breadcrumbData={ContractingCompaniesDetailsBreadcrumb({
          name: companyData?.name,
        })}
      />

      <BaseCard mt={2} py={"1rem"} px={"3rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className={classes["small-tabs"]}
            >
              {renderTabs(AdminContractingCompaniesTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && isSuccess && (
              <Box>
                <CompanyDetails
                  isFetching={isFetching}
                  data={companyData}
                  dataId={DATA_ID.ADMIN_COMPANY}
                />
              </Box>
            )}
            {tabsActiveIndex === 2 && (
              <Box>
                <CompanyPayments companyId={params.id} />
              </Box>
            )}
            {tabsActiveIndex === 3 && (
              <Box>
                <CompanyInvoices
                  getCompanyInvoicesResponse={getCompanyInvoicesResponse}
                  setPaymentId={setPaymentId}
                  paymentId={paymentId}
                  onChangePaginationDataHandler={onChangePaginationDataHandler}
                  companyId={params.id}
                />
              </Box>
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default ContractingCompaniesDetails;
