import React from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { Typography } from "@mui/material";
import { useGetEditRequestsDetailsQuery } from "modules/Admin/apis/inspection-apis/edit-requests";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import { _void } from "utils/Objects/Objects";
import { TestTypes } from "constants/TestTypes";
import LabEditRequestDetails from "./Lab";
import FieldEditRequestDetails from "./Field";
import DashboardIntroAction from "./DashboardIntroAction";

const EditRequestDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  // main data handlers
  const params = useParams();

  const { data: editRequestsDetails, isFetching } =
    useGetEditRequestsDetailsQuery(params?.id, {
      skip: !params?.id,
    });

  const isLab = editRequestsDetails?.testtype === TestTypes.LAB_TEST;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t("Edit Requests")} - ${editRequestsDetails?.id}`}</title>
      </Helmet>

      <DashboardIntro
        title={`طلب تعديل رقم ${editRequestsDetails?.id || ""}`}
        isLoading={isFetching}
        description="يمكنك من خلال هذه الصفحة الاطلاع على تفاصيل طلب التعديل و معرفة حالته."
        action={
          <DashboardIntroAction editRequestsDetails={editRequestsDetails} />
        }
        breadcrumbData={
          !editRequestsDetails
            ? _void
            : EditRequestsDetailsBreadcrumb(editRequestsDetails)
        }
      />

      <BaseCard py="3rem" px="5rem" mt={2} minHeight="75%">
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            {editRequestsDetails?.adminDecisionReasoning && (
              <BaseAlert
                mb={5}
                show={true}
                labelSx={{ fontWeight: "bold", color: "black" }}
                type={AlertTypes.WARNING}
                icon={false}
              >
                <Typography
                  sx={{
                    fontSize: "inherit !important",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  سبب رفض طلب تعديل النتائج :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "normal",
                  }}
                >
                  {editRequestsDetails?.adminDecisionReasoning}
                </Typography>
              </BaseAlert>
            )}
            {isLab ? (
              <LabEditRequestDetails
                data={editRequestsDetails}
                isFetching={isFetching}
              />
            ) : (
              <FieldEditRequestDetails
                data={editRequestsDetails}
                isFetching={isFetching}
              />
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default EditRequestDetails;
