import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import ManageUserButtonAction from "./ManageUserButtonAction";
import {
  useActivateUserMutation,
  useDeActivateUserMutation,
  useResendInviteMutation,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";

const DashboardIntroAction = ({ user, isFetchingUser }) => {
  const { t } = useTranslation(["dashboard"]);

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [activateUser, activateUserResponse] = useActivateUserMutation();
  const [deActivateUser, deActivateUserResponse] = useDeActivateUserMutation();
  const [resendInvite, resendInviteResponse] = useResendInviteMutation();

  const isUserActionSubmitting =
    activateUserResponse.isLoading ||
    deActivateUserResponse.isLoading ||
    isFetchingUser ||
    resendInviteResponse.isLoading;

  const resendUserInvite = () => {
    resendInvite({ userId: user.id })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_REINVITED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <BaseAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        destroy={hideAlertHandler}
        autoClose={10}
      />
      {user?.registered ? (
        <ManageUserButtonAction
          user={user}
          activateUser={activateUser}
          isFetchingUser={isFetchingUser}
          deActivateUser={deActivateUser}
          showAlertHandler={showAlertHandler}
          resendInviteResponse={resendInviteResponse}
          isUserActionSubmitting={isUserActionSubmitting}
        />
      ) : (
        <BaseButton
          onClick={resendUserInvite}
          disabled={!user?.reInvite}
          variant={!user?.reInvite ? "disabled-fancy" : ""}
          isLoading={isUserActionSubmitting}
        >
          {t("resend invite")}
        </BaseButton>
      )}
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  user: PropTypes.object,
  isFetchingUser: PropTypes.bool,
};

export default DashboardIntroAction;
