import { TABLE_ID } from "constants/Table";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";
import {
  contractorSieveAnalysisDesignGradationLimits,
  contractorSieveMixDesignGradationLimits,
} from "services/StaticLookup/TableHeader/TableHeader";
const DoubleLayerData = ({ subTests, request, asphalet }) => {
  const isAsphalet = asphalet
    ? contractorSieveMixDesignGradationLimits
    : contractorSieveAnalysisDesignGradationLimits;

  return (
    <BaseTableCard
      tableHeader={isAsphalet}
      content={subTests}
      injectProps={{
        tableId: TABLE_ID.SUB_TEST_STATUS,
        extraStatus: request?.status,
      }}
      tableSx={{
        td: {
          borderBottom: "0.1rem solid background.disabled",
        },
      }}
    />
  );
};

DoubleLayerData.propTypes = {
  subTests: PropTypes.array,
  request: PropTypes.object,
  asphalet: PropTypes.bool,
};

export default DoubleLayerData;
