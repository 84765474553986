import { Box, List, Typography } from "@mui/material";
import classes from "../BaseDropdown.module.scss";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { isContentReady } from "utils/Array/Arrays";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { _void } from "utils/Objects/Objects";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const EmptyListComp = (props) => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography
      sx={{
        fontSize: "1.6rem",
      }}
    >
      {props.emptyListPlaceholder
        ? props.emptyListPlaceholder
        : "لا يوجد بيانات"}
    </Typography>
  </Box>
);

EmptyListComp.propTypes = {
  emptyListPlaceholder: PropTypes.string,
};

const DropdownList = ({
  pickup,
  selectMenuStyles,
  disabled,
  open,
  isFetching,
  data,
  onChange,
  pickupData,
  value,
  setPickupData,
  setOpen,
  ignoreStackingContext,
  dropdownPosition,
  itemNameKey,
  emptyListPlaceholder,
  itemDirection,
}) => {
  const addPickupItemHandler = (pickupItem) => {
    if (pickup) {
      setPickupData((prevState) => ({
        allData: prevState.allData.concat(pickupItem),
        item: pickupItem,
      }));
      if (onChange) {
        onChange({
          allData: pickupData.allData.concat(pickupItem),
          item: pickupItem,
        });
      }
    } else if (onChange) {
      onChange(pickupItem);
    }

    setOpen(false);
  };

  const [dropdownPositionState, setDropdownPositionState] =
    useState(dropdownPosition);

  useEffect(() => {
    const windowScrollHandler = () => {
      setDropdownPositionState(null);
    };

    document.addEventListener("scroll", windowScrollHandler);

    return () => document.removeEventListener("scroll", windowScrollHandler);
  }, []);

  useEffect(() => {
    setDropdownPositionState(dropdownPosition);
  }, [dropdownPosition, open]);

  function render() {
    if (!isFetching && isContentReady(data)) {
      return data.map((item) => {
        const selectedCase =
          pickupData.allData?.find((d) => d.id === item.id) ||
          value?.id === item.id;
        return (
          <Box
            key={item.id}
            onClick={
              selectedCase || disabled
                ? _void
                : addPickupItemHandler.bind(null, item)
            }
          >
            <li
              className={`${classes["base-dropdown__item"]} ${
                classes[
                  `base-dropdown__item--${
                    selectedCase ? "selected" : undefined
                  }`
                ]
              }`}
            >
              <span style={{ direction: itemDirection }}>
                {itemNameKey && item[itemNameKey]
                  ? item[itemNameKey]
                  : item.name}
              </span>
              {selectedCase && (
                <DoneIcon className={classes["base-dropdown__item-icon"]} />
              )}
            </li>
          </Box>
        );
      });
    } else if (isFetching) {
      return <BaseLoader />;
    } else {
      return <EmptyListComp />;
    }
  }

  return (
    <List
      className={`${classes["base-dropdown__list"]} ${
        open ? classes[`base-dropdown__list--open`] : undefined
      } `}
      sx={{
        ...selectMenuStyles,
        ...(ignoreStackingContext &&
          dropdownPositionState && {
            transition: "none",
            position: `fixed !important`,
            top: `${dropdownPositionState?.y + 50}px !important`,
            right: `${dropdownPositionState?.x}px !important`,
            width: `${dropdownPositionState?.width}px !important`,
          }),
      }}
    >
      {render()}
    </List>
  );
};

DropdownList.propTypes = {
  onChange: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  dropdownPosition: PropTypes.number,
  pickupData: PropTypes.object,
  selectMenuStyles: PropTypes.object,
  data: PropTypes.array,
  setPickupData: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  itemNameKey: PropTypes.string,
  emptyListPlaceholder: PropTypes.string,
  pickup: PropTypes.bool,
  open: PropTypes.bool,
  ignoreStackingContext: PropTypes.bool,
  isFetching: PropTypes.bool,
  disabled: PropTypes.bool,
  itemDirection: PropTypes.string,
};

export default DropdownList;
