import PropTypes from "prop-types";
import AssignedRequestTestsFeild from "./AssignedRequestTestsFeild/AssignedRequestTestsFeild";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";

const FieldAssignedRequestTests = (props) => {
  const { inspection } = props;

  const inspectionLayer = inspection?.inspectionLayersList[0];

  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData(inspection);

  return (
    <AssignedRequestTestsFeild
      {...props}
      inspectionLayer={inspectionLayer}
      stations={stations}
      isStationsFetching={isStationsFetching}
    />
  );
};

FieldAssignedRequestTests.propTypes = {
  inspection: PropTypes.object,
};

export default FieldAssignedRequestTests;
