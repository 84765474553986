import { Box } from "@mui/material";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import PropTypes from "prop-types";
import { multipleLicensesViewTableHeader } from "services/StaticLookup/TableHeader/TableHeader";

const MultipleLicensesModal = (props) => {
  return (
    <Box>
      <BaseTableIndex
        tableHeader={multipleLicensesViewTableHeader}
        content={props.licenses}
        withDetails={{
          paramsKey: "id",
          to: (id) => `/drilling-licenses/${id}`,
        }}
      />
    </Box>
  );
};

MultipleLicensesModal.propTypes = {
  licenses: PropTypes.array,
};

export default MultipleLicensesModal;
