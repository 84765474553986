import { TestTypes } from "constants/TestTypes";
import HistoryLab from "./HistoryLab/HistoryLab";
import HistoryField from "./HistoryField/HistoryField";
import { getReassignedTechnicians } from "./utils";
import createSortedCycle from "./utils/createSortedCycle";
import PropTypes from "prop-types";

const HistoryLogger = (props) => {
  const transformedData = Object.entries(props.data);

  // prettier-ignore
  const {
    sortedCycleItems,
    reAssignmentCycleItems,
    deassignmentCycleItems,
  } = createSortedCycle(transformedData);

  //prettier-ignore
  const hasReassignedTechnicians = reAssignmentCycleItems.some(getReassignedTechnicians)

  const hasDeassignItems = deassignmentCycleItems.length > 0;

  const sharedProps = {
    inspection: props.inspection,
    rawData: props.data,
    sortedCycleItems,
    hasDeassignItems,
    reAssignmentCycleItems,
    hasReassignedTechnicians,
  };

  return props.historyType === TestTypes.LAB_TEST ? (
    <HistoryLab {...sharedProps} />
  ) : (
    <HistoryField {...sharedProps} />
  );
};

HistoryLogger.propTypes = {
  data: PropTypes.object,
  inspection: PropTypes.object,
  historyType: PropTypes.string,
};

export default HistoryLogger;
