import BaseButton from "core-ui/BaseButton/BaseButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { ReactComponent as EditingIcon } from "assets/icons/editing.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { windowOpenPDFHandler } from "utils/File/File";
import {
  useGetInspectionAsphaltReportQuery,
  useGetInspectionConcreteProductReportQuery,
  useGetInspectionFieldReportQuery,
  useGetInspectionSoilReportQuery,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { REQUEST_TYPE } from "constants/RequestType";
import PropTypes from "prop-types";
import { useEffect } from "react";

const getInspectionLayer = (inspection) =>
  inspection?.inspectionLayersList?.[0];

export const RescheduleButton = ({
  canReschedule,
  setShowRescheduleModal,
  isLoadingInspectionAsphaltReport,
}) => {
  const { t } = useTranslation();

  return canReschedule ? (
    <BaseButton
      sx={{ minWidth: "21rem" }}
      onClick={() => setShowRescheduleModal(true)}
      disabled={isLoadingInspectionAsphaltReport}
    >
      {t("resubmit request", {
        ns: "dashboard",
      })}
    </BaseButton>
  ) : null;
};
RescheduleButton.propTypes = {
  canReschedule: PropTypes.bool,
  isLoadingInspectionAsphaltReport: PropTypes.bool,
  setShowRescheduleModal: PropTypes.func,
};

export const CancelButton = ({ canCancel, setShowCancelModal }) => {
  const { t } = useTranslation();

  return canCancel ? (
    <BaseButton
      sx={{ minWidth: "2.1rem" }}
      onClick={() => setShowCancelModal(true)}
      variant="secondary"
    >
      {t("إلغاء الطلب", {
        ns: "dashboard",
      })}
      <CloseIcon
        fontSize="large"
        sx={{
          color: "grayDark.color",
          fontWeight: "bold",
        }}
      />
    </BaseButton>
  ) : null;
};
CancelButton.propTypes = {
  canCancel: PropTypes.bool,
  setShowCancelModal: PropTypes.func,
};

export const EditButton = ({ inspection }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditClick = () => {
    navigate(`/examination-requests/${inspection?.id}/edit-request`);
  };
  return (
    <BaseButton
      sx={{ minWidth: "2.1rem" }}
      onClick={handleEditClick}
      variant="secondary"
    >
      {t("تعديل الطلب", {
        ns: "dashboard",
      })}
      <Typography component={"span"} sx={{ ml: "0.30rem" }}>
        <EditingIcon />
      </Typography>
    </BaseButton>
  );
};
EditButton.propTypes = {
  inspection: PropTypes.object,
};

export const ViweAsphaltReport = ({
  inspection,
  isRequestDone,
  isAsphalt,
  isLab,
  getIsAsphaltLoading,
}) => {
  const inspectionLayer = getInspectionLayer(inspection);

  const {
    data: inspectionAsphaltReport,
    isLoading: isLoadingInspectionAsphaltReport,
  } = useGetInspectionAsphaltReportQuery(inspectionLayer?.id, {
    skip:
      !isLab ||
      !isAsphalt ||
      (inspection?.status !== REQUEST_TYPE.SUCCEED &&
        inspection?.status !== REQUEST_TYPE.FAILED),
  });

  useEffect(() => {
    if (getIsAsphaltLoading) {
      getIsAsphaltLoading(isLoadingInspectionAsphaltReport);
    }
  }, [isLoadingInspectionAsphaltReport]);

  return (
    <BaseButton
      disabled={!isRequestDone || !inspectionAsphaltReport?.report}
      sx={{ minWidth: "21rem" }}
      onClick={windowOpenPDFHandler.bind(null, inspectionAsphaltReport?.report)}
      isLoading={isLoadingInspectionAsphaltReport}
    >
      {"تحميل تقرير الأسفلت"}
    </BaseButton>
  );
};
ViweAsphaltReport.defaultProps = {
  getIsAsphaltLoading: () => {},
};

ViweAsphaltReport.propTypes = {
  inspection: PropTypes.object,
  isRequestDone: PropTypes.bool,
  isAsphalt: PropTypes.bool,
  isLab: PropTypes.bool,
  getIsAsphaltLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export const ViweSoilReport = ({
  inspection,
  isRequestDone,
  isLab,
  isABC,
  isSoil,
}) => {
  const inspectionLayer = getInspectionLayer(inspection);

  const {
    data: inspectionSoilReport,
    isLoading: isLoadingInspectionSoilReport,
  } = useGetInspectionSoilReportQuery(inspectionLayer?.id, {
    skip:
      !isLab ||
      (!isABC && !isSoil) ||
      (inspection?.status !== REQUEST_TYPE.SUCCEED &&
        inspection?.status !== REQUEST_TYPE.FAILED),
  });
  return (
    <BaseButton
      disabled={!isRequestDone || !inspectionSoilReport?.report}
      sx={{ minWidth: "21rem" }}
      onClick={windowOpenPDFHandler.bind(null, inspectionSoilReport?.report)}
      isLoading={isLoadingInspectionSoilReport}
    >
      {"تحميل تقرير التربة"}
    </BaseButton>
  );
};
ViweSoilReport.propTypes = {
  inspection: PropTypes.object,
  isRequestDone: PropTypes.bool,
  isLab: PropTypes.bool,
  isABC: PropTypes.bool,
  isSoil: PropTypes.bool,
};

export const ViweConcreteProductReport = ({
  isRequestDone,
  isLab,
  isConcreteProduct,
  inspection,
}) => {
  const {
    data: inspectionConcreteProductReport,
    isLoading: isLoadingInspectionConcreteProductReport,
  } = useGetInspectionConcreteProductReportQuery(inspection?.id, {
    skip:
      !isLab ||
      !isConcreteProduct ||
      (inspection?.status !== REQUEST_TYPE.SUCCEED &&
        inspection?.status !== REQUEST_TYPE.FAILED),
  });
  return (
    <BaseButton
      disabled={!isRequestDone || !inspectionConcreteProductReport?.report}
      sx={{ minWidth: "21rem" }}
      onClick={windowOpenPDFHandler.bind(
        null,
        inspectionConcreteProductReport?.report
      )}
      isLoading={isLoadingInspectionConcreteProductReport}
    >
      {`تحميل تقرير العينة`}
    </BaseButton>
  );
};
ViweConcreteProductReport.propTypes = {
  isRequestDone: PropTypes.bool,
  isLab: PropTypes.bool,
  isConcreteProduct: PropTypes.bool,
  inspection: PropTypes.object,
};

export const ViweFieldReport = ({
  isRequestDoneOrSkipped,
  isField,
  isSoil,
  inspection,
}) => {
  const {
    data: inspectionFieldReport,
    isLoading: isLoadingInspectionFieldReport,
  } = useGetInspectionFieldReportQuery(inspection?.id, {
    skip: !inspection?.id || !isField || !isSoil || !isRequestDoneOrSkipped,
  });
  return (
    <BaseButton
      disabled={!isRequestDoneOrSkipped || !inspectionFieldReport?.report}
      sx={{ minWidth: "21rem" }}
      onClick={windowOpenPDFHandler.bind(null, inspectionFieldReport?.report)}
      isLoading={isLoadingInspectionFieldReport}
    >
      {"تحميل تقرير الاختبار الحقلي"}
    </BaseButton>
  );
};
ViweFieldReport.propTypes = {
  isRequestDoneOrSkipped: PropTypes.bool,
  isField: PropTypes.bool,
  isSoil: PropTypes.bool,
  inspection: PropTypes.object,
};
