import { Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import PropTypes from "prop-types";
import { publicAssetsPath } from "utils/Paths/Paths";

const RequestPathItem = (props) => {
  return (
    <BaseCard
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "100%",
      }}
      py={"5rem"}
      variant="secondary"
      gap={"3rem"}
    >
      <BaseCard>
        <img
          src={publicAssetsPath(`${props.publicImageUrl}`)}
          width={70}
          height={70}
          alt="request-path"
        />
      </BaseCard>
      <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
        {props.description}
      </Typography>
      <BaseButton sx={{ marginTop: "auto" }} onClick={props.onClick}>
        {props.buttonText}
      </BaseButton>
    </BaseCard>
  );
};

RequestPathItem.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  publicImageUrl: PropTypes.string,
};

export default RequestPathItem;
