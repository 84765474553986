import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import Dashboard from "../pages";
import DashboardHome from "../pages/Dashboard/Home/DashboardIndex";
import DashboardIndex from "../pages/Dashboard/Home/DashboardIndex/DashboardIndex";
import Requests from "../pages/Requests";
import RequestDetails from "../pages/Requests/RequestDetails/RequestDetails";

const distributionCenterRoutes = {
  path: "/",
  element: <ProtectedRoute component={<Dashboard />} />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<DashboardHome />} />,
      children: [{ index: true, element: <DashboardIndex /> }],
    },
    {
      path: "requests-details",
      element: <ProtectedRoute component={<Requests />} />,
      children: [
        {
          path: ":id",
          element: <RequestDetails />,
        },
      ],
    },
  ],
};

export default distributionCenterRoutes;
