export const ExaminationRecordFormData = [
  { value: 1, label: "نعم" },
  { value: 0, label: "لا" },
];

export const ExaminationRecordWeatherFormData = [
  { value: "RAINY", label: "ممطر" },
  { value: "CLOUDY", label: "غائم" },
  { value: "SUNNY", label: "مشمس" },
];

export const ExaminationRecordConfirmResult = [
  { value: 1, label: "صحيحة" },
  { value: 0, label: "غير صحيحة" },
];
