import BaseCameraMultiple from "core-ui/BaseCameraMultiple/BaseCameraMultiple";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const PreviewMultipleAttachments = (props) => {
  const render = () => {
    if (props.isFetchingAttachments) {
      return <BaseLoader />;
    } else if (!props.isAttachmentIdsEmpty) {
      return (
        <BaseCameraMultiple
          photos={props.photos}
          isReadonlyMode={props.isReadonlyMode}
          uploadFn={props.uploadFn}
          uploadResponse={props.uploadResponse}
        />
      );
    } else return null;
  };

  return render();
};

export default PreviewMultipleAttachments;
