import { Box } from "@mui/material";
import { useGetInspectionForLabQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { useForm } from "react-hook-form";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useNavigate, useParams } from "react-router-dom";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import RaiseResultsFieldTest from "../../AssignedRequestsDetails/Field/RaiseResultsFieldTest/RaiseResultsFieldTest";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";
import { AssignedRequestRaiseResults } from "services/StaticLookup/Breadcrumb";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { _scrollToTop } from "utils/DOM/DOM";
import { AlertTypes } from "constants/AlertTypes";
import { prepareDataForFieldTestResultSubmission } from "modules/Laboratory/pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Field/helpers";
import { useSubmitAssignedRequestScoresMutation } from "modules/LaboratoryTechnician/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";

let navigatedUserTimer;

const FieldAssignedRequestForm = (props) => {
  const params = useParams();

  const { data: inspection, isFetching: isInspectionFetching } =
    useGetInspectionForLabQuery(params.id);

  const inspectionLayer = inspection?.inspectionLayersList[0];

  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData(inspection);

  const isDraftedNotSubmitted =
    inspection?.isRequestInitialSubmissionDrafted && !inspection?.submittedAt;

  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    register,
    setValue,
    unregister,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange", shouldUnregister: true });

  const formData = watch();

  const inspectionLayerDTO = inspectionLayer;

  const {
    alert: raiseResultsAlert,
    showAlertHandler: showRaiseResultsAlertHandler,
    hideAlertHandler: hideRaiseResultsAlertHandler,
  } = useAlert();

  const [submitFieldTest, submitFieldTestResponse] =
    useSubmitAssignedRequestScoresMutation();

  const onSubmitHandler = () => {
    const backendData = prepareDataForFieldTestResultSubmission(
      formData,
      stations
    );

    let serverData = {
      inspectionId: inspection?.id,
      body: { stations: backendData },
    };

    submitFieldTest(serverData)
      .then(() => {
        showRaiseResultsAlertHandler({
          show: true,
          type: AlertTypes.SUCCESS,
          message: SuccessCodes.SUCCESS_SUBMIT_RESULTS,
        });
        navigatedUserTimer = setTimeout(() => {
          navigate(`/assigned-requests/${inspection?.id}`, {
            state: {
              navigatedUserTimer,
            },
          });
        }, 3000);
      })
      .catch((err) => {
        showRaiseResultsAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: err.data?.errorCode || ErrorCodes.UNKNOWN_ERROR,
        });
      })
      .finally(() => {
        _scrollToTop();
      });
  };

  const onErrorHandler = (errors) => {
    console.log("[ERROR] - errors: ", errors);
  };

  // prettier-ignore
  const isSubmitted = !!inspection?.submittedAt;

  const isCTAsDisabled =
    submitFieldTestResponse.isLoading || submitFieldTestResponse.isSuccess;

  const virtualStation = stations?.find((station) => station.isVirtual);

  const tableContent = virtualStation
    ? [virtualStation]
    : stations?.filter((s) => !s.isVirtual);

  const isComponentFetching = isInspectionFetching || isStationsFetching;

  const dashboardIntroTitle = `نموذج رفع النتائج للطلب رقم ${inspection?.id}#`;

  const dashboardIntroDescription = `يرجى تعبئة النموذج التالي.`;

  if (isComponentFetching) return <BaseLoader />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة - رقم الطلب ${inspection?.id}`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <DashboardIntro
        title={dashboardIntroTitle}
        description={dashboardIntroDescription}
        variant={"secondary"}
        action={
          <DashboardIntroAction>
            <BaseAlert
              sx={{ mb: 2 }}
              show={raiseResultsAlert.show}
              type={raiseResultsAlert.type}
              message={raiseResultsAlert.message}
              destroy={hideRaiseResultsAlertHandler}
              autoClose={5}
            />
          </DashboardIntroAction>
        }
        breadcrumbData={AssignedRequestRaiseResults({ id: inspection?.id })}
      />
      <BaseCard
        mt={6}
        py={"2rem"}
        px={"3rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <Box>
            {!isStationsFetching ? (
              <RaiseResultsFieldTest
                _tableContent={{ content: tableContent }}
                isRequestDetailsFetching={props.isRequestDetailsFetching}
                inspection={inspection}
                inspectionLayerDTO={inspectionLayerDTO}
                enableDrafting={!isSubmitted}
                isDraftedNotSubmitted={isDraftedNotSubmitted}
                isSubmitted={isSubmitted}
                injectProps={{
                  errors,
                  control,
                  setValue,
                  register,
                  formValues: formData,
                  unregister,
                  clearErrors,
                }}
              />
            ) : (
              <BaseLoader />
            )}
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={5}
            mt={5}
            mb={3}
          >
            <BaseButton
              type="submit"
              sx={{ minWidth: "12.2rem" }}
              disabled={isCTAsDisabled}
              isLoading={isCTAsDisabled}
            >
              إرسال
            </BaseButton>
          </Box>
        </form>
      </BaseCard>
    </>
  );
};

FieldAssignedRequestForm.propTypes = {
  isRequestDetailsFetching: PropTypes.bool,
};

export default FieldAssignedRequestForm;
