import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { ConsultantRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { DATA_ID, CONSULTATION_STATUS } from "constants/Details";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import RejectionModal from "modules/Contractor/components/RejectionModal/RejectionModal";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import {
  useGetContractorConsultationRequestDetailsQuery,
  useApproveOrRejectConsultationRequestMutation,
} from "modules/Contractor/apis/consultation-apis/consultation-apis";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import ConsultantActionsButtons from "./ConsultantActionsButtons";

const ConsultantsRequestsDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const navigate = useNavigate();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const params = useParams();

  const { data: requestsDetailsData, isFetching } =
    useGetContractorConsultationRequestDetailsQuery(params.id);

  const [approveOrRejectRequest, approveOrRejectRequestResponse] =
    useApproveOrRejectConsultationRequestMutation();

  const acceptRequest = () => {
    approveOrRejectRequest({
      requestNumber: requestsDetailsData?.requestNumber,
      status: CONSULTATION_STATUS.APPROVED,
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate(`/consultants-requests`, {
            state: {
              alert: {
                show: true,
                message: SuccessCodes.CONSULTANT_REQUEST_APPROVED,
                type: AlertTypes.SUCCESS,
                interpolation: { id: requestsDetailsData?.requestNumber },
              },
            },
          });
        }, 2000);
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: err?.data.errorCode || ErrorCodes.UNKNOWN_ERROR,
        });
      });
  };

  const confirmRejection = (rejectionReason, reset) => {
    approveOrRejectRequest({
      requestNumber: requestsDetailsData?.requestNumber,
      reason: rejectionReason,
      status: CONSULTATION_STATUS.REJECTED,
    })
      .unwrap()
      .then(() => {
        reset({ rejectionReasons: "" });
        setShowRejectionModal(false);
        navigate(`/consultants-requests`, {
          state: {
            alert: {
              show: true,
              message: SuccessCodes.CONSULTANT_REQUEST_REJECTED,
              type: AlertTypes.WARNING,
              interpolation: { id: requestsDetailsData?.requestNumber },
            },
          },
        });
      })
      .catch((err) => {
        setShowRejectionModal(false);
        showAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: ErrorCodes.UNKNOWN_ERROR,
        });
      });
  };

  return (
    <>
      <RejectionModal
        show={showRejectionModal}
        closeModal={() => setShowRejectionModal(false)}
        confirmRejection={confirmRejection}
        isLoading={approveOrRejectRequestResponse?.isLoading}
      />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلب الإسناد رقم ${requestsDetailsData?.requestNumber}`}</title>
      </Helmet>
      <DashboardIntro
        title={t(
          `طلب الإسناد رقم ${requestsDetailsData?.requestNumber || ""}`,
          {
            ns: "dashboard",
          }
        )}
        description={
          "يمكنك من خلال هذه الصفحة الاطلاع على تفاصيل طلب إسناد الرخص و قبوله أو رفضه في حال رغبتك بذلك."
        }
        breadcrumbData={ConsultantRequestsDetailsBreadcrumb({
          id: requestsDetailsData?.requestNumber || "",
        })}
        action={
          <ConsultantActionsButtons
            acceptRequest={acceptRequest}
            alert={alert}
            approveOrRejectRequestResponse={approveOrRejectRequestResponse}
            hideAlertHandler={hideAlertHandler}
            requestsDetailsData={requestsDetailsData}
            setShowRejectionModal={setShowRejectionModal}
          />
        }
      />
      <BaseCard
        py={"2rem"}
        px={"5rem"}
        mt={4}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching ? (
          <BaseLoader />
        ) : (
          <Box className={"request-data-details"} mt={4}>
            <Box className={"request-data-details__wrapper"} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الطلب
              </Typography>

              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.requestNumber}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ رفع الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(
                      requestsDetailsData?.requestCreationDate
                    )}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    حالة الطلب
                  </Typography>
                  <BaseStatus
                    status={requestsDetailsData?.status}
                    dataId={DATA_ID?.CONTRACTOR_CONSULTANT_REQUEST}
                  />
                </Box>
                {requestsDetailsData?.requestProcessingDate && (
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      تاريخ معالجة الطلب
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {formatDateYYYYMMDD(
                        requestsDetailsData?.requestProcessingDate
                      )}
                    </Typography>
                  </Box>
                )}
              </WrapperContentGrid>
            </Box>

            <hr />
            {requestsDetailsData?.rejectionReason && (
              <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
                <Typography className={"request-data-details__title"}>
                  سبب الرفض
                </Typography>
                <Typography
                  sx={{ fontSize: "1.6rem" }}
                  color={"grayDark.color1"}
                  mb={3}
                >
                  {requestsDetailsData?.rejectionReason}
                </Typography>
                <hr />
              </Box>
            )}

            <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الاستشاري
              </Typography>

              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    الاسم
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultantName}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    شركة الاستشارات
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultantCompanyName}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    البريد الإلكتروني
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultantEmail}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الجوال
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatPhoneNumber(requestsDetailsData?.consultantMobile)}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>

            <hr />

            <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الرخصة
              </Typography>

              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الرخصة
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {`${requestsDetailsData?.licenseYear}/${requestsDetailsData?.licenseNumber}`}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    مقدم الخدمة
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.serviceProviderName}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    النوع
                  </Typography>
                  <BaseTypeTag type={requestsDetailsData?.licenseTypeRefId} />
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ الانتهاء
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(requestsDetailsData?.licenseExpiryDate)}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    نوع الحفر{" "}
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.licenseDrillingType || ""}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    نوع الخدمة{" "}
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.licenseServiceName || ""}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>
        )}
      </BaseCard>
    </>
  );
};

export default ConsultantsRequestsDetails;
