import { Box, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseModal from "core-ui/BaseModal/BaseModal";
import PropTypes from "prop-types";
import { publicAssetsPath } from "utils/Paths/Paths";

const WarningModal = ({
  showConfirm,
  onLeaveHandler,
  navigate,
  children = null,
}) => (
  <BaseModal
    show={showConfirm}
    modalContentWrapperSx={{ paddingTop: "2rem" }}
    onHide={onLeaveHandler}
    mainContainerSx={{ width: "62rem" }}
  >
    <Box px={"2rem"} sx={{ display: "flex", mb: 1 }}>
      <img
        src={publicAssetsPath("icons/exclamationMark.svg")}
        style={{ width: "4rem" }}
        alt="exclamation-mark"
      />
      <Typography
        sx={{
          mt: 2,
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "grayDark.color",
          ml: 2,
          mb: 1.5,
        }}
      >
        بيانات شركة المقاولات غير مكتملة
      </Typography>
    </Box>
    <hr />
    <Typography
      sx={{
        color: "grayDark.color",
        fontSize: "1.6rem",
        lineHeight: "3rem",
        px: "4rem",
        mb: 6,
        mt: 3,
      }}
    >
      لإتمام عملية الدفع يرجى إكمال بيانات الشركة وذلك من خلال الانتقال إلى صفحة{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        بيانات الحساب.
      </span>
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "flex-end",
        flexWrap: "wrap",
        px: "4rem",
        mb: 1,
      }}
    >
      <BaseButton
        sx={{
          minWidth: "12.2rem",
          mr: 1,
          color: "text.tags.blueSoft",
        }}
        variant={"transparent"}
        onClick={onLeaveHandler}
      >
        إلغاء
      </BaseButton>
      <BaseButton
        sx={{ minWidth: "12.2rem", display: "flex", gap: 2 }}
        onClick={() => navigate("/settings")}
      >
        إكمال البيانات
      </BaseButton>
    </Box>
  </BaseModal>
);

WarningModal.propTypes = {
  showConfirm: PropTypes.bool,
  navigate: PropTypes.func,
  onLeaveHandler: PropTypes.func,
  children: PropTypes.node,
};

export default WarningModal;
