import PropTypes from "prop-types";

const CombinedField = (props) => {
  return (
    <div>
      {props.field1}/{props.field2}
    </div>
  );
};

CombinedField.propTypes = {
  field1: PropTypes.number,
  field2: PropTypes.number,
};

export default CombinedField;
