import { Box, Stack, Typography } from "@mui/material";
import classes from "./MobView.module.scss";
import baseCameraClasses from "../BaseCamera.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTheme } from "@emotion/react";
import Webcam from "react-webcam";
import CaptureButton from "./CaptureButton/CaptureButton";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { _void } from "utils/Objects/Objects";
import PropTypes from "prop-types";

const MobView = (props) => {
  const { palette } = useTheme();
  return (
    props.show && (
      <Box className={classes.mobview}>
        <Box className={classes["mobview__header"]}>
          <Typography className={classes["mobview__header-title"]}>
            تصوير النتائج
          </Typography>
          <Box onClick={props.isUploading ? _void : props.onHide}>
            <CloseIcon
              style={{ color: palette.base.white }}
              sx={{ fontSize: 36 }}
            />
          </Box>
        </Box>

        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
        />
        <Box className={classes["mobview__body"]}>
          {props.isPhotoCaptured ? (
            <img
              src={props.photo}
              className={baseCameraClasses["base-camera__image"]}
              alt="photo-base-camera-mob"
            />
          ) : (
            <Webcam
              ref={props.webcamRef}
              screenshotFormat={props.screenshotFormat || "image/jpeg"}
              audio={props.audio || false}
              videoConstraints={props.videoConstraints}
              mirrored={props.mirrored || false}
              imageSmoothing={props.imageSmoothing || true}
            />
          )}
        </Box>
        <Box className={classes["mobview__footer"]}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            height={"100%"}
            px={"4rem"}
          >
            {props.isPhotoCaptured ? (
              <>
                {props.isUploadAble ? (
                  <BaseButton
                    variant={"white"}
                    onClick={props.uploadPhotoHandler}
                    isLoading={props.isUploading}
                  >
                    رفع الصورة
                  </BaseButton>
                ) : (
                  <BaseButton onClick={props.savePhotoHandler}>
                    تأكيد
                  </BaseButton>
                )}
                <BaseButton
                  variant="transparent"
                  onClick={props.onDeleteHandler}
                  disabled={props.isUploading}
                >
                  <Typography className={classes["mobview__delete"]}>
                    حذف
                  </Typography>
                </BaseButton>
                {/* <Box
                  onClick={props.onDeleteHandler}
                  disabled={props.isUploading}
                >
                  <Typography className={classes["mobview__delete"]}>
                    حذف
                  </Typography>
                </Box> */}
              </>
            ) : (
              <>
                <Box onClick={props.switchCamera}>
                  <ReplayIcon
                    style={{ color: palette.base.white }}
                    sx={{ fontSize: 36 }}
                  />
                </Box>
                <CaptureButton onCapture={props.onCaptureHandler} />
                <Box></Box>
              </>
            )}
          </Stack>
        </Box>
      </Box>
    )
  );
};

MobView.propTypes = {
  show: PropTypes.bool,
  isUploading: PropTypes.bool,
  isPhotoCaptured: PropTypes.bool,
  isUploadAble: PropTypes.bool,
  audio: PropTypes.bool,
  mirrored: PropTypes.bool,
  imageSmoothing: PropTypes.bool,
  webcamRef: PropTypes.object,
  videoConstraints: PropTypes.object,
  onHide: PropTypes.func,
  uploadPhotoHandler: PropTypes.func,
  onDeleteHandler: PropTypes.func,
  switchCamera: PropTypes.func,
  savePhotoHandler: PropTypes.func,
  onCaptureHandler: PropTypes.func,
  photo: PropTypes.string,
  screenshotFormat: PropTypes.string,
};

export default MobView;
