import RequestDataLab from "./Lab/RequestDataLab";
import RequestDataField from "./Field/RequestDataField";
import PropTypes from "prop-types";

const RequestData = ({ inspectionTestsData, ...props }) => {
  return props.isLab ? (
    <RequestDataLab inspectionTestsData={inspectionTestsData} {...props} />
  ) : (
    <RequestDataField {...props} />
  );
};

RequestData.propTypes = {
  isLab: PropTypes.bool,
  inspectionTestsData: PropTypes.array,
};

export default RequestData;
