import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { requestLicense } from "services/StaticLookup/Breadcrumb";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import { useTheme } from "@emotion/react";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import useAlert from "hooks/useAlert";
import LicensesData from "modules/Consultant/components/LicensesData/LicensesData";
import AddedLicense from "./AddedLicense";
import { useNavigate } from "react-router-dom";
import {
  useGetConsultationRequestLicenseMutation,
  useLazyGetContractorCompaniesQuery,
  useNewConsultantRequestMutation,
} from "../../apis/consultation-requests-apis/consultation-requests-apis";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import BaseSmartSearch from "core-ui/BaseSmartSearch/BaseSmartSearch";

const RaisingRequest = (props) => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      licenseYear: "",
      companyContractorName: "",
    },
  });

  const { t } = useTranslation(["auth", "dashboard"]);
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const stackDirection = isSmallScreen ? "column" : "row";
  const boxWidth = isSmallScreen ? "100%" : "40rem";

  const [licenseErrorMessage, setLicenseErrorMessage] = useState(null);
  const { alert, showAlertHandler } = useAlert();

  const formData = watch();

  const [getContractorLicense, getContractorLicenseResponse] =
    useGetConsultationRequestLicenseMutation();

  const [requestNewLicense, getRequestNewLicenseResponse] =
    useNewConsultantRequestMutation();

  const [currentLicenseData, setCurrentLicenseData] = useState(null);

  const [addedLicenses, setAddedLicenses] = useState([]);

  const handleAddLicenseClick = (licenseData) => {
    const isLicenseAlreadyAdded = addedLicenses.some(
      (license) => license.licenseNumber === licenseData.licenseNumber
    );

    if (licenseData && !isLicenseAlreadyAdded) {
      setAddedLicenses((prevItems) => [...prevItems, licenseData]);
    }
    setCurrentLicenseData(null);
    setValue("licenseYear", null);
    setValue("licenseNumber", undefined);
  };

  const handleDeleteLicense = (licenseId) => {
    const licenses = addedLicenses.filter(
      (license) => license.id !== licenseId
    );
    setAddedLicenses(licenses);
  };

  const handleCancelLicenseSelection = () => {
    setCurrentLicenseData(null);
  };

  const isSearchBtnDisabled =
    !formData.licenseNumber ||
    !formData.licenseYear ||
    !formData.companyContractorName ||
    getContractorLicenseResponse.isLoading;

  const isSubmitRequestDisabled = addedLicenses.length === 0;

  const handleSearchClick = () => {
    getContractorLicense({
      licenseNumber: formData.licenseNumber,
      licenseYear: formData.licenseYear?.name,
      contractorId: formData.companyContractorName?.id,
    })
      .unwrap()
      .then((responseData) => {
        setLicenseErrorMessage(null);
        setCurrentLicenseData(responseData);
      })
      .catch((err) => {
        let errorCode = "";
        if (err.data) {
          errorCode = err.data.errorCode;
        } else {
          errorCode = err.message;
        }
        setLicenseErrorMessage(errorCode);
        showAlertHandler({
          show: true,
          message: errorCode,
          type: AlertTypes.ERROR,
        });
        setCurrentLicenseData(null);
      });
  };

  const RaiseNewRequest = (data, event) => {
    const licenseNumbers = addedLicenses.map(
      (license) => license?.licenseNumber
    );
    const contractorId = formData.companyContractorName?.id;

    requestNewLicense({
      licenseNumbers: licenseNumbers,
      contractorId: contractorId,
    })
      .unwrap()
      .then((_) => {
        setTimeout(() => {
          navigate(`/consultation-requests`, {
            state: {
              alert: {
                show: true,
                message: SuccessCodes.SUCCESSFUL_SENDING_REQUEST,
                type: AlertTypes.SUCCESS,
              },
            },
          });
        }, 2000);
      })
      .catch((err) => {
        let errorCode = "";
        if (err.data) {
          errorCode = err.data.errorCode;
        } else {
          errorCode = err.message;
        }
        showAlertHandler({
          show: true,
          message: errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t("request license title", {
          ns: "dashboard",
        })}`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={`${t("request license title", {
          ns: "dashboard",
        })}`}
        description={t(
          "يرجى تحديد شركة المقاولات و إضافة الرخص المراد إسنادها"
        )}
        breadcrumbData={requestLicense(t)}
      />
      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <Grid container>
          <Grid item lg={5} xs={12}>
            <Box>
              <BaseCardTitle mb={5}>إضافة الرخص</BaseCardTitle>
              <Stack direction={stackDirection} flexWrap={"wrap"} gap="2rem">
                <Box sx={{ width: boxWidth }}>
                  <BaseSmartSearch
                    queryHook={useLazyGetContractorCompaniesQuery}
                    control={control}
                    name={"companyContractorName"}
                    label={t("companyContractorName", {
                      ns: "dashboard",
                    })}
                    placeholder={t("شركة المقاولات")}
                    errors={errors}
                    helperText={"يرجى إدخال اسم شركة المقاولات"}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    flexGrow: 1,
                    width: {
                      lg: "55rem",
                      xs: "40rem",
                      sm: "40rem",
                      md: "40rem",
                    },
                  }}
                >
                  <BaseInput
                    inputContainerStyles={{
                      flexGrow: "1",
                      flexBasis: "24rem",
                      marginBottom: 0,
                      alignSelf: "end",
                    }}
                    htmlFor="licenseNumber"
                    type="number"
                    label={"رقم الرخصة"}
                    placeholder={"ادخل رقم الرخصة"}
                    name="licenseNumber"
                    register={{
                      ...register("licenseNumber", {
                        required: {
                          value: true,
                          message: `${t("field")} ${t("license number", {
                            ns: "dashboard",
                          })} ${t("required")}`,
                        },
                      }),
                    }}
                    errors={errors}
                  />

                  <BaseDropdown
                    control={control}
                    name="licenseYear"
                    required
                    sx={{ mr: 10 }}
                    width="14rem"
                    errors={errors}
                    label={t("سنة الرخصة")}
                    labelStyles={{
                      fontWeight: "bold",
                      color: "grayDark.color",
                    }}
                    placeholder={t("سنة الرخصة")}
                    data={licenseYearsData()}
                    selectMenuStyles={{ height: "12rem !important" }}
                  />
                  <BaseButton
                    sx={{
                      minWidth: "0rem",
                      alignSelf: "end",
                      flexGrow: 1,
                      height: "4rem",
                    }}
                    variant={isSearchBtnDisabled ? "disabled" : "secondary"}
                    disabled={isSearchBtnDisabled}
                    isLoading={getContractorLicenseResponse.isLoading}
                    onClick={handleSearchClick}
                  >
                    بحث
                  </BaseButton>
                </Box>
              </Stack>

              {licenseErrorMessage && (
                <Box mt={3}>
                  <BaseAlert
                    show={alert.show}
                    type={alert.type}
                    message={alert.message}
                    icon={false}
                    mt={2}
                  />
                </Box>
              )}
            </Box>
            <Box mt={3}>
              <BaseCard
                variant="secondary"
                py={"3rem"}
                px={"3rem"}
                minHeight="30rem"
                borderRadius=".9rem"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <LicensesData
                  licenseErrorMessage={licenseErrorMessage}
                  data={currentLicenseData}
                  columns={11}
                  onAddLicenseClick={handleAddLicenseClick}
                  onCancelLicenseClick={handleCancelLicenseSelection}
                />
              </BaseCard>
            </Box>
          </Grid>
          <Grid item lg={1} md={1} xs={12}></Grid>
          <Grid item lg={6} xs={12} mt={matches ? 5 : "21.2rem"}>
            <AddedLicense
              addedLicenses={addedLicenses}
              onDeleteLicense={handleDeleteLicense}
            />
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          flexWrap={"wrap"}
          gap={3}
          justifyContent={"flex-end"}
        >
          <BaseButton
            sx={{ minWidth: "12.2rem" }}
            variant={"secondary"}
            onClick={() => navigate(-1)}
          >
            {t("تراجع", { ns: "common" })}
          </BaseButton>

          <BaseButton
            type="submit"
            sx={{ minWidth: "12.2rem" }}
            variant={isSubmitRequestDisabled ? "disabled" : ""}
            disabled={isSubmitRequestDisabled}
            isLoading={getRequestNewLicenseResponse.isLoading}
            onClick={RaiseNewRequest}
          >
            {t("رفع طلب", { ns: "common" })}
          </BaseButton>
        </Box>
      </BaseCard>
    </>
  );
};

export default RaisingRequest;
