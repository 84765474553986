import { Box } from "@mui/material";
import PropTypes from "prop-types";

const CenteredWrapper = (props) => {
  return (
    <Box
      {...props}
      width={`min(${props?.width || "58rem"}, 100%)`}
      margin={`min(${props?.margin || "1rem"}, 2.5vw) auto`}
    >
      {props.children}
    </Box>
  );
};

export default CenteredWrapper;

CenteredWrapper.defaultProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "100%",
  paddingInline: "1rem",
};

CenteredWrapper.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  display: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  maxWidth: PropTypes.string,
  paddingInline: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};
