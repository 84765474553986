import { Typography, Box } from "@mui/material";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import React from "react";
import { openPDFHandler } from "utils/File/File";
import { ReactComponent as PopoutIcon } from "assets/icons/popout-svgrepo.svg";
import PropTypes from "prop-types";

function BaseFileDataItem(props) {
  return (
    <BaseDataItem
      label={props.hideMainLabel ? "" : "الملف"}
      valueComponentAs={"div"}
      value={
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={0.5}
          onClick={openPDFHandler.bind(null, props.attachment)}
        >
          <Typography className="request-data-details__link">
            <span>{props.label || "الملف"} </span>
            <PopoutIcon />
          </Typography>
        </Box>
      }
    />
  );
}

BaseFileDataItem.propTypes = {
  label: PropTypes.string,
  attachment: PropTypes.string,
  hideMainLabel: PropTypes.bool,
};

export default BaseFileDataItem;
