import { Box, Typography } from "@mui/material";
import { AlertTypes } from "constants/AlertTypes";
import {
  displayAvailableExtensions,
  labAttachmentAvailableExtensions,
} from "constants/File";
import BaseAttachment from "core-ui/BaseAttachment/BaseAttachment";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import { useLabSaveAttachmentNewApiMutation } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { convertFileToBase64 } from "utils/File/File";

const FieldAttachment = ({
  inspection,
  attachment,
  disabled,
  isEditing,
  showSaveAttachmentAlert,
}) => {
  const {
    handleSubmit: handleFileSubmit,
    watch: fileWatch,
    control: fileControl,
    formState: { errors: fileErrors },
  } = useForm({
    mode: "onChange",
  });

  const attachmetFormValue = fileWatch();

  // prettier-ignore
  const [uploadAttachment, uploadAttachmentResponse] = useLabSaveAttachmentNewApiMutation();

  const onSubmitFileHandler = (data, e) => {
    if (attachmetFormValue?.attachment) {
      convertFileToBase64(attachmetFormValue.attachment.file).then(
        (base64File) => {
          uploadAttachment({
            inspectionId: inspection?.id,
            attachment: base64File,
          })
            .unwrap()
            .then((_) => {
              showSaveAttachmentAlert({
                show: true,
                type: AlertTypes.SUCCESS,
                message: SuccessCodes.SUCCESS_UPLOAD_ATTACHMENT,
              });
            })
            .catch((err) => {
              showSaveAttachmentAlert({
                show: true,
                type: AlertTypes.ERROR,
                message: ErrorCodes.UNKNOWN_ERROR,
              });
            });
        }
      );
    }
  };

  const onErrorFileHandler = (err, e) => {
    console.log("ERR: ", err);
  };

  const render = () => {
    if (!inspection.isLabResultAttachmentSubmitted) {
      return (
        <form
          aria-label="form"
          onSubmit={handleFileSubmit(onSubmitFileHandler, onErrorFileHandler)}
        >
          <BaseUploadFile
            maxSize={{
              size: 10,
              unit: "mb",
            }}
            control={fileControl}
            errors={fileErrors}
            uploadedFile={attachmetFormValue.attachment}
            rules={{
              required: {
                value: true,
                message: "مرفق  نتائج الاختبارات إلزامي",
              },
            }}
            disabled={disabled}
            name={"attachment"}
            availableExtensions={labAttachmentAvailableExtensions}
            displayAvailableExtensions={displayAvailableExtensions}
            isSubmitting={uploadAttachmentResponse?.isLoading}
            isSuccess={uploadAttachmentResponse?.isSuccess}
            isError={uploadAttachmentResponse?.isError}
          />
        </form>
      );
    } else if (inspection.isLabResultAttachmentSubmitted && !attachment) {
      return <BaseLoader />;
    } else return null;
  };

  return (
    <Box mt={5}>
      <Typography
        mb={2}
        sx={{
          fontWeight: "500",
          fontSize: "1.6rem",
          color: "grayDark.color",
        }}
      >
        مرفق نتائج الاختبارات
      </Typography>
      {attachment && !isEditing ? (
        <BaseAttachment file={attachment} />
      ) : (
        render()
      )}
    </Box>
  );
};

FieldAttachment.propTypes = {
  inspection: PropTypes.object,
  attachment: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  showSaveAttachmentAlert: PropTypes.func,
};

export default FieldAttachment;
