import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import classes from "./BaseTimeline.module.scss";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import PropTypes from "prop-types";

const BaseTimeline = ({
  activeStageIndex = 0,
  completedStagesIndexes = [],
  stages = [],
}) => {
  const checkupTimelineRef = useRef();

  const numberOfStages = stages.length - 1;

  const availableIndexesList =
    completedStagesIndexes.length >= numberOfStages
      ? numberOfStages
      : completedStagesIndexes.length;

  useEffect(() => {
    const setLabelPositionByLength = () => {
      const labelList = Array.from(
        checkupTimelineRef.current.querySelectorAll("p")
      );
      labelList.forEach((label) => {
        const currentLabelLength = label.textContent.length;
        if (currentLabelLength <= 10) {
          label.classList.add(classes["checkup-timeline__label--sm"]);
        }
      });
    };
    setLabelPositionByLength();
  }, []);

  const timelineContentDynamic = stages.map((content, idx) => {
    return (
      <Fragment key={content.name}>
        <div className={`${classes["checkup-timeline__circle"]}`}>
          <span>{idx + 1}</span>
          <span className={`${classes["checkup-timeline__icon-done"]}`}>
            <DoneIcon />
          </span>
        </div>
        <Typography className={classes["checkup-timeline__label"]}>
          {content.name}
        </Typography>
      </Fragment>
    );
  });

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={`${classes["checkup-timeline"]} ${
        classes[`checkup-timeline--${stages.length > 5 ? "fluid" : undefined}`]
      }`}
      ref={checkupTimelineRef}
    >
      <span className={classes["checkup-timeline__line"]}></span>
      <span
        className={`
            ${classes["checkup-timeline__line"]}
            ${classes["checkup-timeline__line--active"]}
          `}
        style={{
          zIndex: 2,
          width: `${(100 / numberOfStages) * availableIndexesList}%`,
        }}
      ></span>

      {timelineContentDynamic.map((content, idx) => {
        const isActiveStage = activeStageIndex === idx;
        const areDoneStages = completedStagesIndexes.includes(idx);
        return (
          <Box
            key={crypto.randomUUID()}
            className={`
                ${classes["checkup-timeline__wrapper"]}
                ${
                  isActiveStage
                    ? classes["checkup-timeline__wrapper--active"]
                    : ""
                }
                ${
                  areDoneStages
                    ? classes["checkup-timeline__wrapper--done"]
                    : ""
                }
                `}
          >
            {content}
          </Box>
        );
      })}
    </Box>
  );
};

BaseTimeline.propTypes = {
  activeStageIndex: PropTypes.number,
  completedStagesIndexes: PropTypes.array,
  stages: PropTypes.array,
};

export default BaseTimeline;
