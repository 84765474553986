import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { distributionRequest } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { useGetDistributionCenterReuqestsQuery } from "modules/DistributionCenter/apis/inspection-apis/inspection-api";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const DashboardIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data: distributionCenterRequestsData, isFetching } =
    useGetDistributionCenterReuqestsQuery({ page, pageSize });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("Request")}
        description={t("Distribution Desc")}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={distributionRequest}
          content={distributionCenterRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.CENTER_REQUESTS }}
          emptyData={{
            title: t("No requests"),
            desc: t("No New DS Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/requests-details/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: distributionCenterRequestsData?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DashboardIndex;
