import { Box, Typography } from "@mui/material";
import { UsersTypes } from "constants/UsersTypes";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom";
import { allNavData } from "services/StaticLookup/NavigationData";
import { setLocalStorage } from "services/Storage";
import { getUserTypeByPath } from "utils/Paths/Paths";

const NotFound = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { pathname } = useResolvedPath();
  const navigate = useNavigate();
  const userRoleFromURL = getUserTypeByPath(pathname);

  const [searchParams] = useSearchParams();

  const portal = searchParams.get("portal");

  useEffect(() => {
    if (!isAuth && UsersTypes.some((type) => pathname.includes(type))) {
      navigate(`/auth/${userRoleFromURL}/login`);
    }
    if (portal && allNavData[portal].some((type) => pathname.includes(type))) {
      setLocalStorage("backlink", pathname);
      navigate(`/auth/${portal}/login`);
    }
  }, [isAuth, portal]);
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component={"h1"}>404 Page Not Found</Typography>
    </Box>
  );
};

export default NotFound;
