import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { examinationRequests } from "services/StaticLookup/TableHeader/Consultant";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { TABLE_ID } from "constants/Table";
import { ExaminationRequest } from "services/StaticLookup/Breadcrumb";
import { Stack, Typography } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseFilter from "core-ui/BaseFilter/BaseFilter";
import { consultantExaminationRequestsFilter } from "services/StaticLookup/Filter";
import RestoreButton from "components/RestoreButton/RestoreButton";
import { useLazyGetConsultantRequestsQuery } from "modules/Consultant/apis/consultation-requests-apis/consultation-requests-apis";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import useDebouncedQuery from "hooks/useDebouncedQuery";
import { isContentReady } from "utils/Array/Arrays";

const ExaminationRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [requestSearchValue, setRequestSearchValue] = useState("");
  const [requestType, setRequestType] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getRequestsData, getRequestsResponse] = useDebouncedQuery(
    useLazyGetConsultantRequestsQuery
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      getRequestsData({
        page,
        size: pageSize,
        sortId: "id,desc",
        searchTerm: requestSearchValue,
        testType: requestType,
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [page, pageSize, requestSearchValue, requestType]);

  const onChangeRequestType = (value) => {
    setRequestType(value);
  };

  const isNoSearchData =
    (requestSearchValue || requestType) &&
    !isContentReady(getRequestsResponse.data?.content);
  const tableEmptyTitle = isNoSearchData ? t("No Search") : t("No requests");
  const tableEmptyDescription = isNoSearchData
    ? t("No Search Desc")
    : t("ستجد هنا جميع طلبات الفحص التابعة للرخص المسندة لك");

  const onRestore = () => {
    setRequestSearchValue("");
    setRequestType("");
  };

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: tableEmptyTitle,
    desc: tableEmptyDescription,
    to: "/home/request-path",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"رخص الحفر"}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>
      <DashboardIntro
        title={`${t("Examination requests", { ns: "dashboard" })}`}
        description={
          <Typography component={"span"} fontSize={"inherit"}>
            {t("examination request page description consultant", {
              ns: "dashboard",
            })}{" "}
            <Typography
              component={"strong"}
              fontWeight={"bold"}
              fontSize={"inherit"}
            >
              التفاصيل
            </Typography>
          </Typography>
        }
        breadcrumbData={ExaminationRequest({})}
      />

      <BaseCard mt={6} py={"3rem"} px={"3rem"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          mb={2}
          flexWrap={"wrap"}
          gap={2}
        >
          <BaseInput
            inputContainerStyles={{ marginBottom: "0px" }}
            sx={{ width: `45rem` }}
            label={"بحث"}
            htmlFor="request-search-value"
            value={requestSearchValue}
            placeholder={"يمكنك البحث برقم الطلب أو باسم مقدم الطلب"}
            name="request-search-value"
            icon="search"
            onChange={setRequestSearchValue}
          />

          <BaseFilter
            label={"نوع الفحص"}
            value={requestType}
            data={consultantExaminationRequestsFilter}
            onChange={onChangeRequestType}
          />
          <RestoreButton
            onRestore={onRestore}
            values={[requestSearchValue, requestType]}
          />
        </Stack>
      </BaseCard>

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={examinationRequests}
          content={getRequestsResponse.data?.content}
          isLoading={getRequestsResponse.isFetching}
          injectProps={{ tableId: TABLE_ID.REQUEST }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/examination-requests/${id}`,
          }}
          pagination={{
            displayBy: [5, 10, 15],
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getRequestsResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ExaminationRequestsIndex;
