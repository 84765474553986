import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showPaymentForm: false,
  selectedPlan: null,
  isCustomPlanChecked: false,
  defaultCustomPointsObject: { id: 100, points: 0, price: 0 },
  checkoutId: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    selectPlans: (state, action) => {
      state.selectedPlan = action.payload.plan;
    },
    resetPlans: (state) => {
      state.selectedPlan = null;
    },
    showPaymentFormHandler: (state) => {
      state.showPaymentForm = true;
    },
    hidePaymentFormHandler: (state) => {
      state.showPaymentForm = false;
    },
    checkCustomPointsHandler: (state) => {
      state.isCustomPlanChecked = true;
    },
    unCheckCustomPointsHandler: (state) => {
      state.isCustomPlanChecked = false;
    },
    setCheckoutId: (state, action) => {
      state.checkoutId = action.payload;
    },
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
