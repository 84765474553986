import React, { useEffect } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Box, useMediaQuery } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import { dropdownActions } from "store/dropdown-slice/dropdown-slice";
import { stationInputsActions } from "store/staions-inputs/station-inputs";
import InstructionItem from "./InstructionItem";
import { useTheme } from "@emotion/react";

const GeneralInstructionsContent = (props) => {
  const navigate = useNavigate();

  const { palette, breakpoints } = useTheme();

  const matchesMd = useMediaQuery(breakpoints.down("md"));

  const location = useLocation();

  const state = location.state;

  const { t } = useTranslation();

  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const dispatch = useDispatch();

  const goToRaiseNewRequest = () => {
    dispatch(dropdownActions.resetDropdown({ id: "licenseYear" }));
    navigate("/home/add-checkup-request", {
      state: { testType: raiseNewRequestData?.testType, ...state },
    });
  };

  async function resetAndLeave() {
    dispatch(contractorActions.resetRaiseNewRequestData());
    dispatch(stationInputsActions.resetStations());
  }

  useEffect(() => {
    if (!raiseNewRequestData?.testType) {
      resetAndLeave().then((_) => {
        navigate("/home/request-path");
      });
    }
  }, []);

  return (
    <BaseCard
      mt={6}
      py={"auto"}
      px={"18.3rem"}
      sx={{
        minHeight: "70vh",
        position: "relative",
        paddingTop: "10rem",
        paddingBottom: { sm: "10rem", xs: "17rem" },
      }}
    >
      <Box
        sx={{
          minHeight: "50rem",
          display: "grid",
          gridTemplateColumns: matchesMd ? "1fr" : "1fr 1fr",
          columnGap: "3rem",
        }}
      >
        <InstructionItem number={1}>
          تحديد نوع مسار الفحص، يمكنك اختيار معملي أو حقلي.
        </InstructionItem>
        <InstructionItem number={2}>
          التأكد من توفر رصيد كافي في المحفظة لإتمام عملية الطلب، يمكنك شحن
          الرصيد من خلال صفحة{" "}
          <Link
            style={{
              color: `${palette.primary.main}`,
            }}
            to="/wallet"
          >
            المحفظة
          </Link>{" "}
          .
        </InstructionItem>
        <InstructionItem number={3}>
          تحديد رخصة الحفر، يجب أن يكون نوع الرخصة عادية.
        </InstructionItem>
        <InstructionItem number={4}>إسناد الرخصة لاستشاري.</InstructionItem>
        <InstructionItem number={5}>
          تحديد الاختبارات المطلوبة و موعد الفحص أو جمع العينة.
        </InstructionItem>
        <InstructionItem number={6}>
          عرض ملخص الطلب وتأكيد خصم النقاط.
        </InstructionItem>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexWrap: "wrap",
          position: "absolute",
          gap: "1.5rem",
          bottom: "2rem",
          width: "100%",
          left: 0,
          paddingInline: "3rem",
        }}
      >
        <Box display={"flex"} flexWrap={"wrap"} gap={3}>
          <BaseButton
            sx={{ minWidth: "12.2rem" }}
            variant={"secondary"}
            onClick={() => navigate(-1)}
          >
            {t("تراجع", { ns: "common" })}
          </BaseButton>

          <BaseButton
            sx={{ minWidth: "12.2rem" }}
            onClick={goToRaiseNewRequest}
          >
            {t("continue", { ns: "common" })}
          </BaseButton>
        </Box>
      </Box>
    </BaseCard>
  );
};

export default GeneralInstructionsContent;
