import { useState } from "react";
import { Box, Typography, FormHelperText, Checkbox } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import PropTypes from "prop-types";

const SamplesSelectBox = ({
  receivedSamples,
  checkedSamples,
  setCheckedSamples,
  setShowSamplesConfirm,
  setShowSamplesBox,
  isLoading,
  t,
}) => {
  const [noSamplesSelectedError, setNoSamplesSelectedError] = useState(false);

  const handleCheck = (event, value) => {
    let updatedList = [...checkedSamples];
    if (event.target.checked) {
      setNoSamplesSelectedError(false);
      updatedList = [...checkedSamples, value];
    } else {
      updatedList.splice(checkedSamples.indexOf(value), 1);
    }
    setCheckedSamples(updatedList);
  };

  const validateSampleSelect = () => {
    if (checkedSamples.length > 0) {
      setNoSamplesSelectedError(false);
      setShowSamplesConfirm(true);
    } else {
      setNoSamplesSelectedError(true);
    }
  };

  const renderBarcodes = () => {
    return receivedSamples.map((item) => (
      <Box
        key={item.id}
        my={2}
        mr={6}
        py={"0.6rem"}
        px={"0.8rem"}
        pr={"1.4rem"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "2px",
          backgroundColor: "white",
          borderRadius: "5px",
          border: "1px solid #CCE3E3CC",
        }}
      >
        <Checkbox
          sx={{
            color: "primary.colorSoft6",
            "&.Mui-checked": {
              color: "secondary.main",
            },
          }}
          checked={checkedSamples.includes(item.formattedCode)}
          onChange={(e) => handleCheck(e, item.formattedCode)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "grayDark.color1",
          }}
        >
          {item.formattedCode}
        </Typography>
      </Box>
    ));
  };

  return (
    <Box>
      <BaseCard
        variant={"secondary"}
        py={"3rem"}
        px={"3rem"}
        mt={2}
        sx={{ minHeight: "19rem", borderColor: "#CCE3E3CC" }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "grayDark.color" }}>
          يرجى تحديد العينات التي سيتم تسليمها للمختبر
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>{renderBarcodes()}</Box>
        {noSamplesSelectedError && (
          <FormHelperText error variant="error" sx={{ fontSize: "1.1rem" }}>
            يجب اختيار عينة واحدة على الأقل
          </FormHelperText>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <BaseButton
            sx={{
              minWidth: "10rem",
              mr: 1,
              color: "text.tags.blueSoft",
            }}
            variant={"secondary"}
            onClick={() => setShowSamplesBox(false)}
            disabled={isLoading}
          >
            {t("إلغاء")}
          </BaseButton>
          <BaseButton
            sx={{ minWidth: "10rem" }}
            onClick={validateSampleSelect}
            isLoading={isLoading}
          >
            {t("تأكيد")}
          </BaseButton>
        </Box>
      </BaseCard>
    </Box>
  );
};

SamplesSelectBox.propTypes = {
  receivedSamples: PropTypes.array,
  checkedSamples: PropTypes.array,
  setCheckedSamples: PropTypes.func,
  setShowSamplesConfirm: PropTypes.func,
  setShowSamplesBox: PropTypes.func,
  isLoading: PropTypes.bool,
  t: PropTypes.func,
};

export default SamplesSelectBox;
