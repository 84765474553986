import { Box } from "@mui/material";
import {
  displayAvailableExtensions,
  labAttachmentAvailableExtensions,
} from "constants/File";
import BaseFileDataItem from "core-ui/BaseFileDataItem/BaseFileDataItem";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import PropTypes from "prop-types";

const LabAttachment = (props) => {
  if (props.isLabResultAttachmentSubmitted && !props.attachment) {
    return <BaseLoader />;
  }

  return (
    <Box mt={5}>
      {props.isLabResultAttachmentSubmitted &&
      props.attachment &&
      !props.isEditing ? (
        <BaseFileDataItem attachment={props.attachment} />
      ) : (
        <BaseUploadFile
          maxSize={{
            size: 10,
            unit: "mb",
          }}
          control={props.form?.control}
          description={
            props.description ||
            "رجاءً قم بإرفاق ملف نتائج الاختبارات عند الانتهاء من رفع جميع النتائج، علمًا بأن لن يتم معالجة و إغلاق الطلب حتى يتم إرفاق الملف"
          }
          rules={{
            required: {
              value: true,
              message: "يرجى إرفاق ملف نتائج الاختبارات",
            },
          }}
          readOnly
          name={"attachment"}
          uploadedFile={props.form?.formValues?.attachment}
          disabled={props.disabled}
          availableExtensions={labAttachmentAvailableExtensions}
          displayAvailableExtensions={displayAvailableExtensions}
          errors={props.form?.errors}
        />
      )}
    </Box>
  );
};

LabAttachment.propTypes = {
  form: PropTypes.object,
  attachment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  isLabResultAttachmentSubmitted: PropTypes.bool,
  isEditing: PropTypes.bool,
  description: PropTypes.string,
};

export default LabAttachment;
