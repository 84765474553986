import { createAsyncThunk } from "@reduxjs/toolkit";
import { walletActions } from ".";
import { removeLocalStorage, setLocalStorage } from "services/Storage";

//prettier-ignore
export const savePackageAction = createAsyncThunk("wallet/savePackage", async (data, {dispatch}) => {
  dispatch(walletActions.selectPlans({plan: data}));
  setLocalStorage('selectedPackage', data);
}
);

//prettier-ignore
export const clearPackageAction = createAsyncThunk("wallet/savePackage", async (data, {dispatch}) => {
    dispatch(walletActions.resetPlans());
    removeLocalStorage('selectedPackage');
  }
);
