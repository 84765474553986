import React from "react";
import { Box, Typography } from "@mui/material";
import classes from "./BaseAttachment.module.scss";
import { openPDFHandler, getBlobInfo } from "utils/File/File";
import { publicAssetsPath } from "utils/Paths/Paths";
import PropTypes from "prop-types";

const BaseAttachment = (props) => {
  const fileInfo = getBlobInfo(props.file);
  const fileType = fileInfo?.type === "jpeg" ? "jpg" : fileInfo?.type;
  const filteredFileType = ["jpg", "png", "svg", "pdf"].includes(fileType)
    ? fileType
    : "pdf";

  return (
    <Box
      className={classes["base-attachment"]}
      onClick={openPDFHandler.bind(null, props.file)}
    >
      <Typography>
        <img
          src={publicAssetsPath(`icons/${filteredFileType}.svg`)}
          alt={filteredFileType}
        />
      </Typography>

      <Box className={classes["base-attachment__info"]}>
        <Box>
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "500" }}>
            {`ملف.${filteredFileType}`}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontSize: "1.4rem", fontWeight: "300" }}>
              {fileInfo?.size}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BaseAttachment.propTypes = {
  file: PropTypes.string,
};

export default BaseAttachment;
