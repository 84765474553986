import { Box } from "@mui/material";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import PropTypes from "prop-types";
import { useState } from "react";

const DashboardIntroAction = ({ editRequestsDetails }) => {
  const [showAlert, setShowAlert] = useState(true);

  const hideAlertHandler = () => {
    setShowAlert(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      {editRequestsDetails?.adminDecisionReasoning && (
        <BaseAlert
          show={showAlert}
          type={AlertTypes.WARNING}
          message={"REGECT_EDIT_REASON"}
          destroy={hideAlertHandler}
          labelSx={{ fontWeight: "bold", color: "black" }}
          closeIconColor={"black"}
          closeIcon={true}
          icon={false}
          autoClose={10}
        />
      )}
      {editRequestsDetails?.status === "PENDING" && (
        <BaseAlert
          show={showAlert}
          type={AlertTypes.WARNING}
          message={"PENDING_EDIT"}
          destroy={hideAlertHandler}
          labelSx={{ fontWeight: "bold", color: "black" }}
          closeIconColor={"black"}
          icon={false}
        />
      )}
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  editRequestsDetails: PropTypes.object,
};

export default DashboardIntroAction;
