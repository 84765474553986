export const REQUEST_TYPE = {
  OUTDATED: "OUTDATED",
  CREATED: "CREATED",
  LAB_ASSIGNED: "LAB_ASSIGNED",
  TEST_STARTED: "TEST_STARTED",
  SUCCEED: "SUCCEED",
  FAILED: "FAILED",
  PENDING_APPOINTMENT: "PENDING_APPOINTMENT",
  CANCELED: "CANCELED",
  INCOMPLETE: "INCOMPLETE",
  SKIPPED: "SKIPPED",
};
