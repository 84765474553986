import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DashboardIntroAction = ({
  alert,
  deassignLabResponse,
  hideAlertHandler,
  setShowConfirm,
}) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <BaseAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        destroy={hideAlertHandler}
        autoClose={10}
      />
      <BaseButton
        onClick={() => setShowConfirm(true)}
        variant={deassignLabResponse.isSuccess ? "disabled" : ""}
        disabled={deassignLabResponse.isSuccess}
      >
        {t("إعادة الطلب لنظام التوزيع")}
      </BaseButton>
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  alert: PropTypes.object,
  deassignLabResponse: PropTypes.object,
  hideAlertHandler: PropTypes.func,
  setShowConfirm: PropTypes.func,
};

export default DashboardIntroAction;
