import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseModal from "core-ui/BaseModal/BaseModal";
import PropTypes from "prop-types";

const BaseConfimationModal = ({
  title,
  message,
  onConfirm,
  onHide,
  showModal,
  children,
  mainContainerSx,
  isLoading,
}) => {
  return (
    <BaseModal
      show={showModal}
      onHide={onHide}
      modalContentWrapperSx={{ paddingTop: "2rem" }}
      mainContainerSx={{
        ...mainContainerSx,
        width: mainContainerSx?.width || "80rem",
      }}
    >
      <Box px={"2rem"} sx={{ display: "flex", mb: 1 }}>
        <img
          src={publicAssetsPath("icons/exclamationMark.svg")}
          width="auto"
          alt="exclamation"
        />

        <Typography
          sx={{
            mt: 2,
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "grayDark.color",
            ml: 2,
            mb: 1.5,
          }}
        >
          {title}
        </Typography>
      </Box>
      <hr />
      <Typography
        sx={{
          color: "grayDark.color",
          fontSize: "1.6rem",
          lineHeight: "3rem",
          px: "4rem",
          mb: 6,
          mt: 3,
        }}
      >
        {message || children}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
          px: "4rem",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
            color: "text.tags.blueSoft",
          }}
          variant={"transparent"}
          onClick={onHide}
          disabled={isLoading}
        >
          إلغاء
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          onClick={onConfirm}
          isLoading={isLoading}
        >
          تأكيد
        </BaseButton>
      </Box>
    </BaseModal>
  );
};

BaseConfimationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  mainContainerSx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default BaseConfimationModal;
