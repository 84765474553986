import PropTypes from "prop-types";
import { valueChecker } from "utils/Numbers/Numbers";

const ShowResults = (props) => {
  if (props.containSubtests) return null;

  return valueChecker(props.score);
};

ShowResults.propTypes = {
  containSubtests: PropTypes.bool,
  score: PropTypes.number,
};

export default ShowResults;
