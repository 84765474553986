import { Box, Stack, TableCell, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classes from "../../../BaseTable.module.scss";
import PropTypes from "prop-types";
const WithDetails = (props) => {
  const { t } = useTranslation(["common", "payment", "dashboard"]);

  return (
    <TableCell
      component={props.component || "td"}
      className={classes["base-table__details-link"]}
      sx={{
        background: "base.white",
        whiteSpace: "nowrap",
        borderBottom: "none",
        padding: "0 1.6rem",
        "&:hover": {
          color: "primary.colorSoft3",
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{ cursor: "pointer" }}
        onClick={props.navigateWithDataHandler.bind(null, props.element)}
        style={{
          color: "grayDark.color1",
        }}
      >
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          className={classes["base-table__details-wrapper"]}
        >
          <Typography sx={{ fontSize: "1.6rem" }}>{t("Details")}</Typography>
          <Box className={classes["base-table__details-arrow"]}>
            <div></div>
            <span></span>
          </Box>
        </Stack>
      </Box>
    </TableCell>
  );
};

WithDetails.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  sx: PropTypes.object,
  element: PropTypes.object,
  navigateWithDataHandler: PropTypes.func,
};

export default WithDetails;
