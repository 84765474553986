import { TableCell } from "@mui/material";
import { displayCell } from "../../TableHead/TableHeader";
import PropTypes from "prop-types";

const CardTableCell = ({ headerItem, element, renderTableCell }) => {
  return (
    <TableCell
      key={element.id}
      sx={{
        whiteSpace: "nowrap",
        fontSize: "1.6rem",
        display: displayCell(headerItem, element, false),
      }}
    >
      {renderTableCell(element, headerItem)}
    </TableCell>
  );
};

CardTableCell.propTypes = {
  headerItem: PropTypes.object,
  renderTableCell: PropTypes.func,
  element: PropTypes.object,
};

export default CardTableCell;
