import { Box, Stack, Typography } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import classes from "./PrintReportBtn.module.scss";
import PropTypes from "prop-types";
import { useLazyGetLabReportQuery } from "modules/Admin/apis/inspection-apis/lab-apis";
import { openPDFHandler } from "utils/File/File";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { AlertTypes } from "constants/AlertTypes";
import BaseMUITooltip from "core-ui/BaseTooltip/BaseMUITooltip";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const PrintReportBtn = (props) => {
  const [getReport, getReportResponse] = useLazyGetLabReportQuery();

  const printReport = () => {
    getReport({ reportId: props.id })
      .unwrap()
      .then((responseData) => {
        openPDFHandler(responseData?.report);
      })
      .catch((err) => {
        props.showAlertHandler({
          show: true,
          message: ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  const btnObject =
    props.printable && !getReportResponse?.isFetching
      ? {
          cursor: "pointer",
          func: () => printReport(),
          tooltip: "",
          class: "print-btn",
          icon: "icons/popout-svgrepo.svg",
        }
      : {
          cursor: "not-allowed",
          func: () => null,
          tooltip: getReportResponse?.isFetching
            ? ""
            : "الرجاء إكمال طلبات الشهر لتتمكن من تحميل البيان",
          class: "print-btn-disabled",
          icon: "icons/popout-svgrepo-grey.svg",
        };

  return (
    <Box
      sx={{
        cursor: btnObject.cursor,
      }}
      onClick={btnObject.func}
    >
      <BaseMUITooltip title={btnObject.tooltip}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <Typography
            className={classes[btnObject.class]}
            sx={{
              fontSize: "1.6rem",
            }}
          >
            طباعة{" "}
          </Typography>
          <Box>
            {getReportResponse?.isFetching ? (
              <BaseLoader color="grayDark" size="sm" />
            ) : (
              <img
                src={publicAssetsPath(btnObject.icon)}
                width="auto"
                alt="popout-svgrepo"
              />
            )}
          </Box>
        </Stack>
      </BaseMUITooltip>
    </Box>
  );
};

PrintReportBtn.propTypes = {
  printable: PropTypes.bool,
  id: PropTypes.number,
  showAlertHandler: PropTypes.func,
};

export default PrintReportBtn;
