import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const BaseLoader = (props) => {
  const getSize = () => {
    let size = "4rem";
    switch (props.size) {
      case "xs":
        size = "1rem";
        break;
      case "sm":
        size = "2rem";
        break;
      case "md":
        size = "3rem";
        break;
      case "xl":
        size = "5rem";
        break;
    }
    return size;
  };
  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...props.sx,
      }}
    >
      <CircularProgress color={props.color || "primary"} size={getSize()} />
    </Box>
  );
};

BaseLoader.propTypes = {
  size: PropTypes.string,
  sx: PropTypes.object,
  color: PropTypes.string,
};

export default BaseLoader;
