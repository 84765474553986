import PropTypes from "prop-types";
import { formattedNumber } from "utils/Numbers/Numbers";

const CostTag = ({ cost, type }) => {
  const color = () => {
    if (type === "DEDUCTED") {
      return "#FF0017";
    } else if (type === "HELD") {
      return "#6E6E6E";
    } else {
      return "#50A200";
    }
  };
  const sign = type === "DEDUCTED" || type === "HELD" ? "-" : "+";

  return (
    <div>
      <span
        style={{
          color: color(),
          marginLeft: "1rem",
          fontWeight: "bold",
        }}
      >
        {sign}
      </span>
      {formattedNumber(cost)} نقطة
    </div>
  );
};

CostTag.propTypes = {
  type: PropTypes.string,
  cost: PropTypes.number,
};

export default CostTag;
